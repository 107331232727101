import {SpinnerContainer} from './SpinnerStyles';

export const MiniSpinner = ({cls = 'text-light'}) => (
    <SpinnerContainer className={`spinner-border ${cls}`} role="status">
        <span className="visually-hidden">Loading...</span>
    </SpinnerContainer>
);

export const RingSpinner = ({cls, text = 'Loading...'}) => (
    <SpinnerContainer>
        <div className={`ring ${cls}`} />
        <span className="ring-text">{text}</span>
    </SpinnerContainer>
);
