import {useState} from 'react';
import {RegistrationTitle} from './RegistrationTitle';
import {CustomInputBox, CustomDatePicker, CustomSelect, FormValidator, Button, Alert} from 'utils';
import {useBusinessRegistration} from './RegistrationContent';
import {useRegisterBusiness} from 'hooks';
import {businessRegistrationSteps} from '../data';
import {fetchValues, getError, timeDateFormat} from 'utils/helpers';

const defaultValues = {
    business_start_date: '',
};

export const RegistrationBusinessInformation = () => {
    const {setCurrentStep, result, handleStep} = useBusinessRegistration();
    const [formValues, setFormValues] = useState({
        ...defaultValues,
        ...fetchValues(defaultValues, result),
    });
    const [formErrors, setFormErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState();

    const {mutate, isLoading} = useRegisterBusiness({
        onMutate: () => setErrorMsg(),
        onSuccess: ({data, status}) => {
            if (
                (status === 200 || status === 201) &&
                (data?.status_code === 200 || data?.status_code === 201)
            ) {
                handleStep({
                    step: businessRegistrationSteps.COMPANY_ADDRESS.step,
                    data: {
                        ...formValues,
                        completed_step: Math.max(
                            result?.completed_step || 0,
                            parseInt(data?.data?.completed_step)
                        ),
                        business_information_result: data?.data,
                    },
                });
            } else setErrorMsg(data?.message);
        },
        onError: error => setErrorMsg(getError(error)),
    });

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    const goBack = () => setCurrentStep(businessRegistrationSteps.PROPRIETORS_ADDRESS.step);

    const handleSubmit = e => {
        e.preventDefault();

        const {isValid, formErrors} = FormValidator(formValues, 2);

        if (!isValid) return setFormErrors(formErrors);

        const payload = {
            ...formValues,
            step: '4',
            request_id: result?.request_id,
            business_line: result?.business_line?.value,
            business_start_date: timeDateFormat(formValues?.business_start_date, 'YYYY/MM/DD').date,
        };

        if ((result?.completed_step || 0) >= 4) {
            payload.backStep = '4';
            delete payload?.step;
        }

        mutate(payload);
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <RegistrationTitle
                title="Business Information"
                subtitle="This helps our team review your application quickly. This information is never shared publicly."
            />
            {errorMsg && (
                <div className="pb-4">
                    <Alert message={errorMsg} onClose={() => setErrorMsg()} />
                </div>
            )}
            <div className="row">
                <div className="col-12">
                    <CustomSelect
                        label="Line of Business"
                        placeholder="Select Line of Business"
                        name="business_line"
                        value={result?.business_line}
                        disabled
                    />
                </div>
                <div className="col-12">
                    <CustomDatePicker
                        label="Business Commencement Date"
                        placeholder="DD MM YYYY"
                        name="business_start_date"
                        value={formValues?.business_start_date}
                        error={formErrors?.business_start_date}
                        dateInputBordered
                        onChange={value => handleSelect('business_start_date', value)}
                        maxDate={new Date()}
                    />
                </div>
                <div className="col-12">
                    <CustomInputBox
                        label="Proposed Business Name"
                        value={result?.business_name}
                        disabled
                    />
                </div>
            </div>
            <div className="d-flex gap-3 mt-5">
                <Button
                    cls="btn w-100 btn-lg btn--gray"
                    name="Back"
                    handleClick={goBack}
                    disabled={isLoading}
                />
                <Button cls="btn w-100 btn-lg" type="submit" name="Proceed" disabled={isLoading} />
            </div>
        </form>
    );
};
