import {apiVersion} from '.';

const baseURL = `${apiVersion.v2}/CAC/BN`;
const baseURL2 = `${apiVersion.v2}/CACRegistration/BN`;

export const {nameCheck, registerBusiness, businessRegistration} = {
    nameCheck: `${baseURL2}/Compliance`,
    registerBusiness: `${baseURL2}/Registration`,
    businessRegistration: `${baseURL}/Registration/App/`,
};
