import axios from 'axios';

const baseURLs = [process.env.REACT_APP_BLUSALT_API, process.env.REACT_APP_PAYMENT_PRO_BASE_URL];

const APIs = [];

baseURLs.forEach(url => {
    let API = axios.create({
        baseURL: url,
        headers: {
            Accept: 'application/json',
        },
    });

    API.interceptors.request.use(config => {
        return config;
    });

    API.interceptors.response.use(
        async response => {
            return response;
        },
        async error => {
            const status = error.response.status;
            if (status === 401) {
                // Perform UnAuthorized action here
                return Promise.reject(error);
            }

            return Promise.reject(error);
        }
    );

    APIs.push(API);
});

export default APIs[0];
export const paymentProAPI = APIs[1];
