import styled from 'styled-components';

export const AddressStyles = styled.div`
    ${({colors}) => `
      padding-inline: 48px;
      padding-block: 50px;

      .back-arrow {
        margin-bottom: 13px;
      }

      .address-header {
        h4 {
          font-size: 26px;
          font-weight: 700;
        }

        &.landing-header {
          padding-top: 77px;
          margin-bottom: 56px;
        }
      }

      .house {
        margin-bottom: 51px;
      }

      .instructions {
        margin-bottom: 24px;
        
        .icon {
          width: 5%;
        }

        p {
          width: 95%;
        }
      }

      .steps-container {
        display: flex;
        gap: 4px;
        margin-bottom: 14px;
      
        .step {
          background: ${colors?.grayThree};
          width: 38.13px;
          height: 6px;
          border-radius: 8px;

          &.active {
            background: ${colors?.primaryFull}
          }
        }
      }

      .info-section {
        &.reverse {
          .key {
            color: ${colors?.grayOne};
          }

          .value {
            color: ${colors?.blackFull};
            text-align: right;
          }
        }
        .title {
          font-size: 14px;
          font-weight: 500;
        }

        .key, .value {
          font-weight: 400;
        }

        .key {
          color: ${colors?.blackFull};
        }

        .value {
          color: ${colors?.grayOne};
          text-align: right;
        }

        .final-image {
          width: 100%;
          border-radius: 8px;
          height: 208px;

          &.bill {
            height: 400px;
          }
        }
      }

      .liveness-image {
        height: 153px;
        width: 136px;
        object-fit: cover;
      }

      .black {
        color: ${colors?.blackFull} !important;
      }

      .btn--transparent {
        background: transparent !important;
      }

      .underline {
        text-decoration: underline;
      }

      .bill {
        border: 1px solid ${colors?.grayThree};
        padding-block: 14px;
        padding-inline: 16px;
      }

      .image-frame {
        position:relative;
        height: 274px;
        border-radius: 8px;
        border: 1px dashed ${colors?.grayThree};

        .image-frame-loading,
        img,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: auto;
            background: ${colors.grayThree};
            overflow: hidden;
            object-fit: cover;   
            z-index:2; 
        }

        img {
          z-index: 3;
        }

        img,
        canvas,
        video {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        img,
        canvas,
        video {
          &.reverse {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }
      }

      .bill-payment {
        .image-frame {
          height: 400px;
        }
      }

  `}
`;
