import {createContext, useContext, useMemo, useState} from 'react';

import AddressBill from './AddressBill';
import {AddressHouse} from './AddressHouse';
import {AddressStyles} from './AddressStyles';
import {AddressConfirm} from './AddressConfirm';
import {AddressLanding} from './AddressLanding';
import {AddressCountry} from './AddressCountry';
import {AddressLiveness} from './AddressLiveness';
import {AddressStandard} from './AddressStandard';
import {AddressIdentity} from './AddressIdentity';
import {AddressLivenessResult} from './AddressLivenessResult';

import AppContext from 'components/App/AppContext';
import AddressMobile from './AddressMobile';

export const STEPS = {
    HOME: 'HOME',
    COUNTRY: 'COUNTRY',
    ID: 'ID',
    LIVENESS: 'LIVENESS',
    LIVENESS_RESULT: 'LIVENESS_RESULT',
    ADDRESS_STANDARD: 'ADDRESS_STANDARD',
    BILL: 'BILL',
    HOUSE: 'HOUSE',
    CONFIRM: 'CONFIRM',
};

export const AddressContext = createContext({
    currentStep: '',
    isContinue: false,
    setCurrentStep: () => {},
    result: {},
    setResult: () => {},
    handleStep: () => {},
});

export const AddressContent = ({values = {}, step = STEPS.HOME, isContinue}) => {
    const [currentStep, setCurrentStep] = useState(step);
    const [result, setResult] = useState({...values});

    const {params, colors} = useContext(AppContext);

    const isMini =
        params?.otherParams?.country &&
        params?.otherParams?.id_type &&
        params?.otherParams?.id_number &&
        params?.otherParams?.phone_number;

    const handleStep = ({step, data = {}}) => {
        setResult(prev => ({...prev, ...data}));
        setCurrentStep(step);
    };

    const value = useMemo(
        () => ({
            isMini,
            currentStep,
            setCurrentStep,
            result,
            setResult,
            handleStep,
            isContinue,
        }),
        [currentStep, isMini, result]
    );

    const renderSteps = () => {
        switch (currentStep) {
            default:
            case STEPS.HOME:
                return <AddressLanding />;
            case STEPS.COUNTRY:
                return <AddressCountry />;
            case STEPS.ID:
                return <AddressIdentity />;
            case STEPS.LIVENESS:
                return <AddressLiveness />;
            case STEPS.LIVENESS_RESULT:
                return <AddressLivenessResult />;
            case STEPS.ADDRESS_STANDARD:
                return <AddressStandard />;
            case STEPS.BILL:
                return (
                    <AddressMobile>
                        <AddressBill />
                    </AddressMobile>
                );
            case STEPS.HOUSE:
                return (
                    <AddressMobile>
                        <AddressHouse />
                    </AddressMobile>
                );
            case STEPS.CONFIRM:
                return <AddressConfirm />;
        }
    };

    return (
        <AddressContext.Provider value={value}>
            <AddressStyles colors={colors}>{renderSteps()}</AddressStyles>
        </AddressContext.Provider>
    );
};

export const AddressHeader = ({title, text, cls = ''}) => {
    return (
        <div className={`address-header ${cls}`}>
            <h4>{title}</h4>
            <span>{text}</span>
        </div>
    );
};
