export const Alert = ({message, variant = 'danger', onClose}) => {
    return (
        <div
            className={`alert alert-${variant} alert-dismissible fade show g-bold`}
            style={{minHeight: '37px'}}
            role="alert"
        >
            {message}
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={onClose}
            />
        </div>
    );
};
