export const ArrowRightIcon = ({width = 12, height = 12, ...rest}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M4.45496 9.95906L7.71496 6.69906C8.09996 6.31406 8.09996 5.68406 7.71496 5.29906L4.45496 2.03906"
            stroke="#292D32"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
