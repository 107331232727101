import {InfoCircleErrorIcon} from 'assets/icons';
import {CustomInputBoxStyles} from './CustomInputBoxStyles';
import {blockInvalidChar} from '../helpers';

export const CustomInputBox = ({
    type = 'text',
    name,
    label,
    inputRef,
    labelIcon,
    placeholder,
    value,
    readonly,
    error,
    hide,
    icon,
    iconRight,
    secondary,
    inputCls = '',
    infoText,
    inputLeft,
    onChange,
    onFocus,
    required,
    length,
    question,
    cls = '',
    disabled,
    showError = true,
    iconCls = ' p-3',
    ...rest
}) => {
    const isError = error ? ' error' : '';
    const isRequired = required ? ' required' : '';
    const isDisabled = disabled ? ' disabled' : '';
    const inputWidth = icon || inputLeft ? '' : ' w-100';
    const isSecondary = secondary ? ' secondary-input' : '';
    const inputWithIconCls = icon && iconRight ? 'rounded-0' : '';

    const commonProperties = {
        className: `w-100 ${inputWithIconCls}${inputWidth}`,
        type,
        id: name,
        ref: inputRef,
        name,
        placeholder,
        value,
        readOnly: readonly,
        autoComplete: 'off',
        disabled,
        onChange,
        step: 'any',
        ...rest,
    };

    return (
        <CustomInputBoxStyles>
            <div className={`form-group ${cls}${isSecondary}`}>
                {label && (
                    <label htmlFor={name} className={`form-group-label d-flex${isRequired}`}>
                        {label}
                        {question && <div className="question">?</div>}
                        {labelIcon && <div className="ms-2 pointer">{labelIcon}</div>}
                    </label>
                )}
                <div className={`flex__start input-wrapper${isDisabled}${isError} ${inputCls}`}>
                    {icon && <div className={`${iconCls} h-100 `}>{icon}</div>}
                    {inputLeft}
                    {!hide && type !== 'number' ? (
                        <input {...commonProperties} />
                    ) : (
                        <input
                            onKeyDown={blockInvalidChar}
                            onWheel={event => event.currentTarget.blur()}
                            {...commonProperties}
                        />
                    )}
                    {iconRight && iconRight}
                </div>
                {infoText && <div className="auth-text-gray mt-1">{infoText}</div>}
                {typeof error === 'string' && error && showError && (
                    <small className="input-text-error">
                        <span className="me-2">
                            <InfoCircleErrorIcon />
                        </span>
                        {error}
                    </small>
                )}
            </div>
        </CustomInputBoxStyles>
    );
};
