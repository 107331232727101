export const EditIcon = props => (
    <svg
        width={19}
        height={18}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.75 1.5H7.25C3.5 1.5 2 3 2 6.75V11.25C2 15 3.5 16.5 7.25 16.5H11.75C15.5 16.5 17 15 17 11.25V9.75"
            stroke="#17568B"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.5304 2.26592L6.6204 8.17592C6.3954 8.40092 6.1704 8.84342 6.1254 9.16592L5.8029 11.4234C5.6829 12.2409 6.2604 12.8109 7.0779 12.6984L9.3354 12.3759C9.6504 12.3309 10.0929 12.1059 10.3254 11.8809L16.2354 5.97092C17.2554 4.95092 17.7354 3.76592 16.2354 2.26592C14.7354 0.765922 13.5504 1.24592 12.5304 2.26592Z"
            stroke="#17568B"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.6816 3.11328C12.1841 4.90578 13.5866 6.30828 15.3866 6.81828"
            stroke="#17568B"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
