import _, {toLower} from 'lodash';
import moment from 'moment';
import {piexif} from 'piexifjs';
import {serialize} from 'object-to-formdata';
import {Country, State} from 'country-state-city';
import {AsYouType, parsePhoneNumber} from 'libphonenumber-js';
import {SUCCESS, SUCCESSFUL} from 'components/constants';

export const getError = err => {
    const errorMsg = err?.response?.data || err?.data || {};
    const {error, message, errorData} = errorMsg || {};
    let errorMessage = '';

    if (err) {
        if (error?.message) errorMessage = titleCase(error.message?.replace(/[^a-z0-9+]+/gi, ' '));
        else if (errorData?.message)
            errorMessage = titleCase(errorData?.message?.replace(/[^a-z0-9+]+/gi, ' '));
        else if (message) errorMessage = titleCase(message?.replace(/[^a-z0-9+]+/gi, ' '));
        else errorMessage = 'Error Processing Request. Try again.';
    } else errorMessage = 'Error Processing Request. Try again.';

    return errorMessage;
};

export const truncate = (str, len = 50) => {
    if (str?.length > len) {
        return str?.substring(0, len) + '...';
    } else {
        return str;
    }
};

export const titleCase = string => {
    if (string === null || string === undefined) {
        return '';
    } else {
        let text = string.toLowerCase().split('_');
        for (var i = 0; i < text.length; i++) {
            text[i] = text[i][0]?.toUpperCase() + text[i]?.slice(1);
        }
        return text.join(' ');
    }
};

export const toCurrency = n => {
    if (n === null || n === undefined || n === 'NaN') return 0;
    return Number(n)
        .toFixed(2)
        .replace(/./g, function (c, i, a) {
            return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
        });
};

export const timeDateFormat = (obj, format = 'YYYY-MM-DD') => {
    if (!obj || obj === undefined) return '';

    const timeAgo = moment(obj).fromNow();

    const newObj = new Date(obj);

    const fullTime = moment(newObj).format('hh:mm:ss a');
    const time = moment(newObj).format('hh:mm:ss');
    const date = moment(newObj).format(format);

    return {time, date, fullTime, timeAgo};
};

export const timeRangeFormat = (obj, init) => {
    if (!obj) return null;

    const newDate = new Date(obj);
    init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
    return newDate;
};

export const dateToString = obj => {
    if (!obj) return null;

    const newDate = new Date(obj);
    const date = moment(newDate).format('LL');
    return date;
};

export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const flattenObject = (obj, concatenator = '-') => {
    const newObj = Object.keys(obj).reduce((acc, key) => {
        if (typeof obj[key] !== 'object' || !obj[key]) {
            return {
                ...acc,
                [key]: obj[key],
            };
        }

        const flattenedChild = flattenObject(obj[key], concatenator);

        return {
            ...acc,
            ...Object.keys(flattenedChild).reduce(
                (childAcc, childKey) => ({
                    ...childAcc,
                    [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
                }),
                {}
            ),
        };
    }, {});

    return newObj;
};

export const appendPlusToNumber = number => {
    if (!number) return;
    const cleanedNumber = number.replace(/\s/g, '').replace(/^\+/, '');

    if (!cleanedNumber) return;

    return `+${cleanedNumber}`;
};

export const isAvailable = (value, returnValue) => {
    if (value) return returnValue || value;
    else return 'Not Available';
};

export const getStates = countryCode =>
    State.getStatesOfCountry(countryCode).map(state => ({
        ...state,
        label: state?.name,
        value: state?.name,
    }));

export const base64ToFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
        return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
        return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, {type: mime});
};

export const getLocalPhoneFormat = number => {
    const value = appendPlusToNumber(number);

    const formatted = new AsYouType().input(value);
    const countryCode = formatted.split(' ')[0];

    const nationalNumber = parsePhoneNumber(number, {
        defaultCallingCode: countryCode.replace(/\+/g, ''),
    }).formatNational();

    return nationalNumber.replace(/\D+/g, '');
};

export const injectExifData = (dataUrl, exifObj) => {
    const base64Data = dataUrl.split(',')[1];
    const buffer = Buffer.from(base64Data, 'base64');
    const binary = buffer.toString('binary');

    const exifBytes = piexif.dump(exifObj);
    const newData = piexif.insert(exifBytes, binary);

    const newBuffer = Buffer.from(newData, 'binary');

    return 'data:image/jpeg;base64,' + newBuffer.toString('base64');
};

export const getCountry = countryName => {
    if (!countryName) return null;

    const country = Country.getAllCountries().find(
        c => c?.name?.toLowerCase() === countryName.toLowerCase()
    );

    if (!country) return null;

    return {
        ...country,
        label: country.name,
        value: country.isoCode,
        code: country.isoCode,
    };
};

export const getState = (stateName, countryCode) => {
    if (!countryCode || !stateName) return null;
    const states = State.getStatesOfCountry(countryCode);

    const state = states.find(s => s.name.toLowerCase() === stateName.toLowerCase());

    return state ? {...state, label: state?.name, value: state?.name} : null;
};

export const getFormData = (object, options = {}) => {
    return serialize(object, options);
};

export const checkSuccess = (status, value) => {
    if (!value || !status) return false;
    if (
        ((status === 200 || status === 201) && toLower(value) == toLower(SUCCESS)) ||
        toLower(value) == toLower(SUCCESSFUL) ||
        value == true
    )
        return true;
    else return false;
};

export const maskText = (text, start = 0) => {
    if (!text) return '';
    const strArray = text?.split('');

    for (let i = start; i < strArray.length; i++) {
        strArray[i] = '*';
    }
    return strArray.join('');
};

export const fetchValues = (defaultValues, obj) => {
    const result = {};
    const keys = Object.keys(defaultValues);
    for (const key of keys) {
        if (obj?.[key]) result[key] = obj?.[key];
    }
    return result;
};
