import {useState} from 'react';

export function useGPS({onSuccess = () => {}, onError = () => {}}) {
    const [location, setLocation] = useState(null);

    const getLocation = () => {
        if (!navigator.geolocation) {
            onError(new Error('Geolocation is not supported by this browser.'));
            return;
        }

        const success = position => {
            const coordinates = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            };
            setLocation(coordinates);
            onSuccess(coordinates);
        };

        const error = err => onError(err);

        navigator.geolocation.getCurrentPosition(success, error);
    };

    const stopLocation = () => {
        setLocation(null);
    };

    return {
        location,
        getLocation,
        stopLocation,
    };
}
