export const RecordIcon = ({width = 14, height = 14, ...rest}) => (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...rest}>
        <path
            d="M11.0266 4.48775L11.0266 4.48776L11.0292 4.49177C11.0383 4.50609 11.0424 4.52449 11.0367 4.54741C11.031 4.57073 11.0165 4.59154 10.9947 4.60503L10.653 4.80772L10.651 4.80892L3.00354 9.39391L3.00208 9.39479C2.96077 9.41975 2.90689 9.40486 2.88393 9.36468L2.88331 9.36361C2.35538 8.44501 2.11769 7.32657 2.32393 6.1526L2.32398 6.1523C2.67159 4.16673 4.30926 2.58154 6.30594 2.29695C8.28698 2.01709 10.0756 2.96606 11.0266 4.48775Z"
            fill="#509FEF"
            stroke="#509FEF"
        />
        <path
            d="M4.37578 10.9545L4.3758 10.9545L4.37096 10.9513C4.31568 10.9147 4.31912 10.8337 4.37321 10.8011C4.37339 10.801 4.37358 10.8009 4.37377 10.8008L11.61 6.54997C11.6101 6.54992 11.6102 6.54986 11.6103 6.5498C11.6284 6.53929 11.6419 6.53714 11.6517 6.53711C11.6629 6.53708 11.6759 6.53994 11.6892 6.54713C11.7148 6.56096 11.7362 6.58722 11.74 6.6293C11.7661 6.99768 11.7504 7.37303 11.692 7.75606C11.3331 9.9939 9.34571 11.7123 7.07372 11.7492C6.06882 11.765 5.14287 11.4746 4.37578 10.9545Z"
            fill="#509FEF"
            stroke="#509FEF"
        />
    </svg>
);
