import Avatar from 'assets/icons/avatar.svg';
import {LivenessHeader} from './LivenessContent';
import {LivenessCheckAcceptedResult} from './LivenessCheckAcceptedResult';
import {LivenessCheckRejectedResult} from './LivenessCheckRejectedResult';
import {testLogic} from './logic';

export const LivenessCheckResult = ({result = {}, handleStep}) => {
    const image = result?.image_best ? `data:image/png;base64, ${result?.image_best}` : Avatar;
    const {logicFailed} = testLogic(result);

    return (
        <>
            <LivenessHeader
                title={
                    <span>
                        Result{' '}
                        {logicFailed ? (
                            <span className="text-danger ms-2"> (Liveness Check Rejected)</span>
                        ) : (
                            <span className="text-success">(Liveness Check Completed)</span>
                        )}
                    </span>
                }
                text="Below is the result and analysis of your liveness check"
            />
            <div className="verification_result">
                <img src={image} alt="" />

                {logicFailed ? (
                    <LivenessCheckRejectedResult result={result} handleStep={handleStep} />
                ) : (
                    <LivenessCheckAcceptedResult result={result} />
                )}
            </div>
        </>
    );
};
