import React from 'react';

export const DateIcon = ({color = '#292D32', width = '15', height = '16'}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="path-1-inside-1_1677_15026" fill="white">
                <path d="M5 4.09375C4.74375 4.09375 4.53125 3.88125 4.53125 3.625V1.75C4.53125 1.49375 4.74375 1.28125 5 1.28125C5.25625 1.28125 5.46875 1.49375 5.46875 1.75V3.625C5.46875 3.88125 5.25625 4.09375 5 4.09375Z" />
            </mask>
            <path
                d="M5 4.09375C4.74375 4.09375 4.53125 3.88125 4.53125 3.625V1.75C4.53125 1.49375 4.74375 1.28125 5 1.28125C5.25625 1.28125 5.46875 1.49375 5.46875 1.75V3.625C5.46875 3.88125 5.25625 4.09375 5 4.09375Z"
                fill={color}
            />
            <path
                d="M5 3.09375C5.29603 3.09375 5.53125 3.32897 5.53125 3.625H3.53125C3.53125 4.43353 4.19147 5.09375 5 5.09375V3.09375ZM5.53125 3.625V1.75H3.53125V3.625H5.53125ZM5.53125 1.75C5.53125 2.04603 5.29603 2.28125 5 2.28125V0.28125C4.19147 0.28125 3.53125 0.941465 3.53125 1.75H5.53125ZM5 2.28125C4.70397 2.28125 4.46875 2.04603 4.46875 1.75H6.46875C6.46875 0.941465 5.80853 0.28125 5 0.28125V2.28125ZM4.46875 1.75V3.625H6.46875V1.75H4.46875ZM4.46875 3.625C4.46875 3.32897 4.70397 3.09375 5 3.09375V5.09375C5.80853 5.09375 6.46875 4.43353 6.46875 3.625H4.46875Z"
                fill="#B2B6BA"
                mask="url(#path-1-inside-1_1677_15026)"
            />
            <mask id="path-3-inside-2_1677_15026" fill="white">
                <path d="M10 4.09375C9.74375 4.09375 9.53125 3.88125 9.53125 3.625V1.75C9.53125 1.49375 9.74375 1.28125 10 1.28125C10.2563 1.28125 10.4688 1.49375 10.4688 1.75V3.625C10.4688 3.88125 10.2563 4.09375 10 4.09375Z" />
            </mask>
            <path
                d="M10 4.09375C9.74375 4.09375 9.53125 3.88125 9.53125 3.625V1.75C9.53125 1.49375 9.74375 1.28125 10 1.28125C10.2563 1.28125 10.4688 1.49375 10.4688 1.75V3.625C10.4688 3.88125 10.2563 4.09375 10 4.09375Z"
                fill={color}
            />
            <path
                d="M10 3.09375C10.296 3.09375 10.5312 3.32897 10.5312 3.625H8.53125C8.53125 4.43353 9.19147 5.09375 10 5.09375V3.09375ZM10.5312 3.625V1.75H8.53125V3.625H10.5312ZM10.5312 1.75C10.5312 2.04603 10.296 2.28125 10 2.28125V0.28125C9.19147 0.28125 8.53125 0.941465 8.53125 1.75H10.5312ZM10 2.28125C9.70397 2.28125 9.46875 2.04603 9.46875 1.75H11.4688C11.4688 0.941465 10.8085 0.28125 10 0.28125V2.28125ZM9.46875 1.75V3.625H11.4688V1.75H9.46875ZM9.46875 3.625C9.46875 3.32897 9.70397 3.09375 10 3.09375V5.09375C10.8085 5.09375 11.4688 4.43353 11.4688 3.625H9.46875Z"
                fill="#B2B6BA"
                mask="url(#path-3-inside-2_1677_15026)"
            />
            <path
                d="M5.3125 9.56294C5.23125 9.56294 5.15 9.5442 5.075 9.51295C4.99375 9.4817 4.93125 9.43793 4.86875 9.38168C4.75625 9.26293 4.6875 9.10669 4.6875 8.93794C4.6875 8.85669 4.70625 8.77544 4.7375 8.70044C4.76875 8.62544 4.8125 8.55669 4.86875 8.49419C4.93125 8.43794 4.99375 8.39418 5.075 8.36293C5.3 8.26918 5.58125 8.31919 5.75625 8.49419C5.86875 8.61294 5.9375 8.77544 5.9375 8.93794C5.9375 8.97544 5.93125 9.0192 5.925 9.06295C5.91875 9.10045 5.90625 9.13794 5.8875 9.17544C5.875 9.21294 5.85625 9.25044 5.83125 9.28794C5.8125 9.31919 5.78125 9.35043 5.75625 9.38168C5.6375 9.49418 5.475 9.56294 5.3125 9.56294Z"
                fill={color}
            />
            <path
                d="M7.5 9.56181C7.41875 9.56181 7.3375 9.54307 7.2625 9.51182C7.18125 9.48057 7.11875 9.43681 7.05625 9.38056C6.94375 9.26181 6.875 9.10556 6.875 8.93681C6.875 8.85556 6.89375 8.77431 6.925 8.69931C6.95625 8.62431 7 8.55557 7.05625 8.49307C7.11875 8.43682 7.18125 8.39305 7.2625 8.3618C7.4875 8.2618 7.76875 8.31807 7.94375 8.49307C8.05625 8.61182 8.125 8.77431 8.125 8.93681C8.125 8.97431 8.11875 9.01807 8.1125 9.06182C8.10625 9.09932 8.09375 9.13681 8.075 9.17431C8.0625 9.21181 8.04375 9.24931 8.01875 9.28681C8 9.31806 7.96875 9.34931 7.94375 9.38056C7.825 9.49306 7.6625 9.56181 7.5 9.56181Z"
                fill={color}
            />
            <path
                d="M9.6875 9.56181C9.60625 9.56181 9.525 9.54307 9.45 9.51182C9.36875 9.48057 9.30625 9.43681 9.24375 9.38056C9.21875 9.34931 9.19375 9.31806 9.16875 9.28681C9.14375 9.24931 9.125 9.21181 9.1125 9.17431C9.09375 9.13681 9.08125 9.09932 9.075 9.06182C9.06875 9.01807 9.0625 8.97431 9.0625 8.93681C9.0625 8.77431 9.13125 8.61182 9.24375 8.49307C9.30625 8.43682 9.36875 8.39305 9.45 8.3618C9.68125 8.2618 9.95625 8.31807 10.1312 8.49307C10.2437 8.61182 10.3125 8.77431 10.3125 8.93681C10.3125 8.97431 10.3062 9.01807 10.3 9.06182C10.2937 9.09932 10.2812 9.13681 10.2625 9.17431C10.25 9.21181 10.2313 9.24931 10.2063 9.28681C10.1875 9.31806 10.1562 9.34931 10.1312 9.38056C10.0125 9.49306 9.85 9.56181 9.6875 9.56181Z"
                fill={color}
            />
            <path
                d="M5.3125 11.75C5.23125 11.75 5.15 11.7313 5.075 11.7C5 11.6688 4.93125 11.625 4.86875 11.5687C4.75625 11.45 4.6875 11.2875 4.6875 11.125C4.6875 11.0437 4.70625 10.9625 4.7375 10.8875C4.76875 10.8062 4.8125 10.7375 4.86875 10.6813C5.1 10.45 5.525 10.45 5.75625 10.6813C5.86875 10.8 5.9375 10.9625 5.9375 11.125C5.9375 11.2875 5.86875 11.45 5.75625 11.5687C5.6375 11.6812 5.475 11.75 5.3125 11.75Z"
                fill={color}
            />
            <path
                d="M7.5 11.75C7.3375 11.75 7.175 11.6812 7.05625 11.5687C6.94375 11.45 6.875 11.2875 6.875 11.125C6.875 11.0437 6.89375 10.9625 6.925 10.8875C6.95625 10.8062 7 10.7375 7.05625 10.6813C7.2875 10.45 7.7125 10.45 7.94375 10.6813C8 10.7375 8.04375 10.8062 8.075 10.8875C8.10625 10.9625 8.125 11.0437 8.125 11.125C8.125 11.2875 8.05625 11.45 7.94375 11.5687C7.825 11.6812 7.6625 11.75 7.5 11.75Z"
                fill={color}
            />
            <path
                d="M9.6875 11.7506C9.525 11.7506 9.3625 11.6818 9.24375 11.5693C9.1875 11.5131 9.14375 11.4443 9.1125 11.3631C9.08125 11.2881 9.0625 11.2068 9.0625 11.1256C9.0625 11.0443 9.08125 10.9631 9.1125 10.8881C9.14375 10.8068 9.1875 10.7381 9.24375 10.6818C9.3875 10.5381 9.60625 10.4693 9.80625 10.513C9.85 10.5193 9.8875 10.5318 9.925 10.5505C9.9625 10.563 10 10.5818 10.0375 10.6068C10.0688 10.6256 10.1 10.6568 10.1312 10.6818C10.2437 10.8006 10.3125 10.9631 10.3125 11.1256C10.3125 11.2881 10.2437 11.4506 10.1312 11.5693C10.0125 11.6818 9.85 11.7506 9.6875 11.7506Z"
                fill={color}
            />
            <path
                d="M12.8125 6.65039H2.1875C1.93125 6.65039 1.71875 6.43789 1.71875 6.18164C1.71875 5.92539 1.93125 5.71289 2.1875 5.71289H12.8125C13.0688 5.71289 13.2812 5.92539 13.2812 6.18164C13.2812 6.43789 13.0688 6.65039 12.8125 6.65039Z"
                fill={color}
            />
            <path
                d="M10 14.7188H5C2.71875 14.7188 1.40625 13.4062 1.40625 11.125V5.8125C1.40625 3.53125 2.71875 2.21875 5 2.21875H10C12.2812 2.21875 13.5938 3.53125 13.5938 5.8125V11.125C13.5938 13.4062 12.2812 14.7188 10 14.7188ZM5 3.15625C3.2125 3.15625 2.34375 4.025 2.34375 5.8125V11.125C2.34375 12.9125 3.2125 13.7812 5 13.7812H10C11.7875 13.7812 12.6562 12.9125 12.6562 11.125V5.8125C12.6562 4.025 11.7875 3.15625 10 3.15625H5Z"
                fill={color}
            />
        </svg>
    );
};
