import {useContext} from 'react';
import QRCode from 'react-qr-code';
import {useLocation} from 'react-router-dom';
import {useDeviceType} from 'hooks';
import {CameraIcon} from 'assets/icons';
import {AddressContext} from './AddressContent';

const AddressMobile = ({children}) => {
    const {isMobile, hasBackCamera} = useDeviceType();
    const {result} = useContext(AddressContext);
    const url = `${window.location.origin}/IdentityVerification/Address/${result?.id_result?.reference}`;

    if (!isMobile && !hasBackCamera) {
        return (
            <div className="d-flex gap-5 flex-column align-items-center">
                <QRCode
                    size={200}
                    style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                    value={url}
                    viewBox={`0 0 256 256`}
                />
                <div className="d-flex">
                    <CameraIcon className="me-3 icon" />
                    <p className="flex-grow-1 mb-0 heading-3 fw-bold">
                        Scan this QR Code to complete the verification process on your mobile device
                    </p>
                </div>
            </div>
        );
    }

    return children;
};

export default AddressMobile;
