export const DocumentIcon = ({width = 24, height = 24, ...rest}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
            stroke="#292D32"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
            stroke="#292D32"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7 13H13"
            stroke="#292D32"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7 17H11"
            stroke="#292D32"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const DocumentIconTwo = props => (
    <svg
        width={42}
        height={42}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={42} height={42} rx={4} fill="#ECF1F6" />
        <path
            opacity={0.4}
            d="M33 14.3346V27.668C33 31.668 31 34.3346 26.3333 34.3346H15.6667C11 34.3346 9 31.668 9 27.668V14.3346C9 10.3346 11 7.66797 15.6667 7.66797H26.3333C31 7.66797 33 10.3346 33 14.3346Z"
            fill="#82898F"
        />
        <path
            d="M29.6673 17.3333H27.0007C24.974 17.3333 23.334 15.6933 23.334 13.6667V11C23.334 10.4533 23.7873 10 24.334 10C24.8807 10 25.334 10.4533 25.334 11V13.6667C25.334 14.5867 26.0807 15.3333 27.0007 15.3333H29.6673C30.214 15.3333 30.6673 15.7867 30.6673 16.3333C30.6673 16.88 30.214 17.3333 29.6673 17.3333Z"
            fill="#292D32"
        />
        <path
            d="M20.9993 23.332H15.666C15.1193 23.332 14.666 22.8787 14.666 22.332C14.666 21.7854 15.1193 21.332 15.666 21.332H20.9993C21.546 21.332 21.9993 21.7854 21.9993 22.332C21.9993 22.8787 21.546 23.332 20.9993 23.332Z"
            fill="#292D32"
        />
        <path
            d="M26.3327 28.668H15.666C15.1193 28.668 14.666 28.2146 14.666 27.668C14.666 27.1213 15.1193 26.668 15.666 26.668H26.3327C26.8793 26.668 27.3327 27.1213 27.3327 27.668C27.3327 28.2146 26.8793 28.668 26.3327 28.668Z"
            fill="#292D32"
        />
    </svg>
);
