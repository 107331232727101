import {useContext, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {AddressContent} from 'components/Identity';
import AppContext from 'components/App/AppContext';
import {WidgetLayout} from 'components/App/WidgetLayout';
import {WidgetWrapper} from 'components/App/WidgetWrapper';
import API from 'api';
import {getSavedAddress} from 'api/routes/address';
import {useQuery} from 'react-query';
import {STEPS} from 'components/Identity/Address/AddressContent';
import {getCountry, getState} from 'utils/helpers';
import {addressOptions} from 'components/Identity/data';

export const ContinueAddress = () => {
    const {id} = useParams();
    const {push} = useHistory();
    const [errorMsg, setErrorMsg] = useState('');
    const {colors} = useContext(AppContext);
    const [values, setValues] = useState({});
    const [step, setStep] = useState();

    const getAdressQuery = useQuery(
        ['address', id],
        async () => {
            setErrorMsg();
            const response = await API.get(getSavedAddress, {
                params: {
                    reference: id,
                },
            });
            return response.data;
        },
        {
            onSuccess: ({data}) => {
                const country = getCountry(data?.address_country);
                const state = getState(data?.address_state, country?.isoCode);
                const values = {
                    id_type: data?.id_type,
                    id_number: data?.id_number,
                    phone_number: data?.phone_number,
                    useIdAddress:
                        data?.use_id_address &&
                        addressOptions.find(
                            option => option.value === Boolean(data?.use_id_address)
                        ),
                    addressLandmark: data?.address_landmark,
                    alias: data?.alias,
                    addressCountry: country,
                    addressState: state,
                    addressCity: data?.address_country,
                    addressLine1: data?.address_line1,
                    addressLine2: data?.address_line2,
                    flatNumber: data?.flat_number,
                    buildingName: data?.building_name,
                    buildingNumber: data?.building_number,
                    postalCode: data?.postal_code,
                    id_result: {
                        reference: id,
                        first_name: data?.first_name,
                        last_name: data?.last_name,
                        phone_number: data?.phone_number,
                        email: data?.email,
                        date_of_birth: data?.date_of_birth,
                        nationality: data?.nationality,
                        id_image_url: data?.id_image_url,
                        residential_info: {
                            address: data?.id_address,
                            lga_of_residence: data?.id_address_lga,
                            state_of_residence: data?.id_address_state,
                        },
                    },
                    liveness_result: {
                        liveness: {
                            procedure_validation: data?.liveness_procedure_validation,
                            face_genuineness: data?.liveness_face_genuineness,
                        },
                        percentage_resemblance: data?.liveness_percentage_resemblance,
                        liveness_image_url: data?.liveness_image_url,
                        id_image_url: data?.id_image_url,
                    },
                };
                setValues(values);
                if (data?.home_image) return push('/');
                if (values?.addressCountry) return setStep(STEPS.BILL);
                if (data?.liveness_procedure_validation) return setStep(STEPS.LIVENESS_RESULT);
                if (values?.liveness_result?.id_result) return setStep(STEPS.LIVENESS);
                return push('/');
            },
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    return (
        <WidgetWrapper>
            <WidgetLayout currentQuery={getAdressQuery} colors={colors} errorMsg={errorMsg}>
                <AddressContent values={values} step={step} isContinue />
            </WidgetLayout>
        </WidgetWrapper>
    );
};
