import styled from 'styled-components';

export const ComplianceWrapper = styled.div`
    h1 {
        color: var(--primaryFull);
        font-family: gilroy-bold;
    }

    .loader_wrapper {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .check {
        gap: 40px;
        display: flex;
        margin: 15px auto;

        > div {
            display: flex;
            align-items: center;
        }
        .box {
            width: 13px;
            height: 13px;
            border-radius: 2px;
            margin-right: 5px;
            margin-bottom: 1px;

            &.success {
                background: var(--successSec);
            }
            &.warning {
                background: var(--cautionMid);
            }
        }
    }

    .services {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;

        .service {
            font-weight: 600;
            color: var(--grayOne);
            border: 1px solid var(--grayThree);
            border-radius: 23px;
            padding: 3px 7px;
            background: var(--grayFour);

            &.success {
                color: var(--successSec);
                background: var(--successOutline);
                border: 1px solid var(--successMid);
            }
            &.warning {
                color: var(--cautionMid);
                border: 1px solid var(--cautionMid);
            }
        }
    }

    .add_service {
        margin-top: 20px;
        label {
            font-weight: 600 !important;
            margin-bottom: 0 !important;
        }
        .custom-check-box input:checked {
            border: none;
            background-color: var(--secondaryFull);
        }
    }

    .complete {
        padding: 80px 30px;
        text-align: center;

        svg {
            width: 60px;
            height: 60px;
        }
    }
`;
