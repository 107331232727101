import {useContext, useState} from 'react';
import {useMutation} from 'react-query';
import {Button} from 'utils';
import {AddressSuccessIcon, BackArrowIcon} from 'assets/icons';
import {StepIndicator} from './StepIndicator';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';
import API from 'api';
import {DONE} from 'components/constants';
import {validateAddress} from 'api/routes/address';
import AppContext from 'components/App/AppContext';
import {base64ToFile, getError, isAvailable, titleCase} from 'utils/helpers';

export const AddressConfirm = () => {
    const {params} = useContext(AppContext);
    const {clientid, appname, apikey} = params || {};

    const [success, setSuccess] = useState();
    const [errorMsg, setErrorMsg] = useState();

    const {currentStep, result, setResult, handleStep} = useContext(AddressContext);
    const sendEvent = data => window.parent.postMessage(data, '*');

    const fullname = `${titleCase(result?.id_result?.first_name)} ${titleCase(
        result?.id_result?.last_name
    )}`;
    const residence = result?.id_result?.residential_info;
    const fullAddress = `${residence?.address}, ${residence?.lga_of_residence}, ${residence?.state_of_residence}`;

    const {mutate, isLoading} = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(validateAddress, payload, {
                headers: {
                    clientid,
                    appname,
                    apikey,
                },
            });
            return response;
        },
        {
            onSuccess: ({data, status}) => {
                if (
                    (status === 200 || status === 201) &&
                    (data?.status_code === 200 || data?.status_code === 201)
                )
                    setSuccess(true);
                else setErrorMsg(data?.message);
            },
            onError: error => setErrorMsg(getError(error)),
        }
    );

    const handleSubmit = e => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('utilityType', result?.utilityType?.value);
        formData.append('reference', result?.id_result?.reference);
        formData.append('home', base64ToFile(result?.home, 'home.jpeg'));
        formData.append('utility', base64ToFile(result?.utility, 'bill.jpeg'));

        mutate(formData);
    };

    return !success ? (
        <div className="d-flex flex-column align-items-center">
            <div className="w-100">
                <BackArrowIcon
                    className="pointer back-arrow"
                    onClick={() => handleStep({step: STEPS.HOUSE})}
                />
            </div>
            <StepIndicator currentStep={currentStep} />
            <AddressHeader
                title="Confirmation"
                text="Review and edit information where needed"
                cls="text-center mb-4"
            />
            <div className="info-section reverse mb-5">
                {errorMsg && <h4 className="text-center text-danger py-4">{errorMsg}</h4>}
                <p className="title">Personal Details</p>
                <div className="d-flex justify-content-between">
                    <p className="key">Name</p>
                    <p className="value">{fullname}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Phone no</p>
                    <p className="value">{isAvailable(result?.id_result?.phone_number)}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Country</p>
                    <p className="value">{result?.country?.value}</p>
                </div>
                <div className="d-flex gap-3 justify-content-between mb-4">
                    <p className="key">Address</p>
                    <p className="value">{fullAddress}</p>
                </div>

                <p className="title">Address Details</p>
                <div className="d-flex justify-content-between">
                    <p className="key">Country</p>
                    <p className="value">{result?.addressCountry?.value}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">State</p>
                    <p className="value">{result?.addressState?.value}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">City</p>
                    <p className="value">{result?.addressCity}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Address Line 1</p>
                    <p className="value">{result?.addressLine1}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Address Line 2</p>
                    <p className="value">{result?.addressLine2}</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Landmark</p>
                    <p className="value">{result?.addressLandmark}</p>
                </div>
                <div className="d-flex justify-content-between mb-4">
                    <p className="key">Alias</p>
                    <p className="value">{result?.alias}</p>
                </div>

                <p className="title">Picture of Building</p>
                <img className="final-image mb-4" src={result?.home} />

                <p className="title">Picture of Document</p>
                <img className="final-image bill mb-4" src={result?.utility} />
            </div>
            <Button
                cls="w-100 btn btn-lg rounded-0 mt-5"
                name={isLoading ? 'Loading' : 'Complete'}
                handleClick={handleSubmit}
                disabled={isLoading}
            />
            <Button
                cls="w-100 btn btn-lg btn--transparent black rounded-0 underline"
                name="Back"
                handleClick={() => handleStep({step: STEPS.HOUSE})}
                disabled={isLoading}
            />
        </div>
    ) : (
        <div className="d-flex flex-column align-items-center mt-5 pt-5 px-5 mx-3">
            <AddressSuccessIcon className="mb-5" />
            <p className="text-center mb-5 pb-5">
                You've successfully Submitted your Address Verification
            </p>
            <Button
                cls="w-100 btn btn-lg rounded-0 mt-5"
                name="Close"
                handleClick={() => {
                    sendEvent({name: DONE, result});
                    setResult({});
                }}
                disabled={isLoading}
            />
        </div>
    );
};
