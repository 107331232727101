import styled from 'styled-components';

const getColor = props => {
    if (props.isDragReject) {
        return 'var(--errorFull)';
    }
    if (props.isFocused) {
        return 'var(--surfaceFocus)';
    }
    return 'var(--secondaryDisabled)';
};

export const Container = styled.div`
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: var(--primaryFour);
    color: var(--textTwo);
    outline: none;
    transition: border 0.24s ease-in-out;
    height: auto;
    .sub-text {
        font-size: 12px;
        line-height: 16px;
        color: var(--textTwo);
    }
`;
