import {Button} from 'utils';
import {useCompliance} from './ComplianceContent';
import {SuccessIcon} from 'assets/icons';
import {DONE} from 'components/constants';

export const ComplianceComplete = () => {
    const {data} = useCompliance();
    const {gateway, verificationServices} = data || {};
    const sendEvent = data => window.parent.postMessage(data, '*');

    const verificationDone = () =>
        sendEvent({name: DONE, response: {...gateway, ...verificationServices}});

    return (
        <div className="complete">
            <SuccessIcon />
            <h1 className="mt-5 mb-4">Verification Suite Integrated</h1>

            <span className="text-secondary">
                We have created a verification suite for your account, but we need you to connect it
                with this account, Enter the password sent to your mail,
            </span>

            <div className="col-8 mx-auto my-5">
                <Button name="Close" cls="btn--lg  btn--gray" handleClick={verificationDone} />
            </div>
        </div>
    );
};
