export const HouseIcon = ({width = 292, height = 242, ...rest}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 292 242"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <g clipPath="url(#clip0_5949_104214)">
            <path
                d="M244.988 41.3257C246.91 42.5177 249.046 43.433 251.366 43.9935C257.362 45.4622 263.385 44.334 268.245 41.3115C272.999 38.3741 276.66 33.6275 278.094 27.7598C280.988 15.8897 273.723 3.9202 261.853 1.03249C249.99 -1.86942 238.027 5.40309 235.125 17.2732C232.798 26.8233 237.055 36.4301 244.988 41.3257Z"
                fill="#EBEBEB"
            />
            <path
                d="M249.89 19.9918C249.89 23.7097 252.906 26.718 256.616 26.718C260.334 26.718 263.342 23.7026 263.342 19.9918C263.342 16.274 260.327 13.2656 256.616 13.2656C252.898 13.2656 249.89 16.2811 249.89 19.9918Z"
                fill="white"
            />
            <path
                d="M244.988 38.554V41.3282C246.91 42.5202 249.046 43.4354 251.366 43.9959C257.361 45.4646 263.385 44.3365 268.245 41.314V38.5469C268.245 32.14 263.045 26.918 256.616 26.918C250.188 26.918 244.988 32.14 244.988 38.5469V38.554Z"
                fill="white"
            />
            <path
                d="M58.9772 42.2543C61.8215 35.1032 58.3302 27.0004 51.1791 24.156C44.028 21.3117 35.9251 24.803 33.0808 31.9541C30.2364 39.1052 33.7278 47.2081 40.8789 50.0524C48.03 52.8968 56.1329 49.4054 58.9772 42.2543Z"
                fill="#EBEBEB"
            />
            <path
                d="M53.1353 38.8456C51.0281 43.0885 46.026 45.6073 46.026 45.6073C46.026 45.6073 41.0168 43.0885 38.9167 38.8456C37.1642 35.3052 38.271 31.4809 41.0381 30.6437C45.0043 29.4446 46.0189 36.3552 46.0189 36.3552C46.0189 36.3552 47.0335 29.4446 51.0068 30.6437C53.7739 31.4809 54.8878 35.3052 53.1282 38.8456H53.1353Z"
                fill="white"
            />
            <path
                d="M267.829 163.141C273.893 153.24 270.784 140.298 260.883 134.233C250.982 128.168 238.04 131.278 231.975 141.179C225.91 151.079 229.02 164.022 238.921 170.087C248.821 176.152 261.764 173.042 267.829 163.141Z"
                fill="#EBEBEB"
            />
            <path
                d="M259.405 157.757C254.517 162.972 246.187 164.434 246.187 164.434C246.187 164.434 240.05 158.616 238.858 151.563C237.864 145.681 241.135 140.637 245.499 140.637C251.749 140.637 250.217 151.052 250.217 151.052C250.217 151.052 254.694 141.517 259.909 144.979C263.542 147.391 263.485 153.401 259.405 157.757Z"
                fill="white"
            />
            <path
                d="M145.84 241.804C226.355 241.804 291.624 233.395 291.624 223.023C291.624 212.651 226.355 204.242 145.84 204.242C65.3264 204.242 0.0568848 212.651 0.0568848 223.023C0.0568848 233.395 65.3264 241.804 145.84 241.804Z"
                fill="#EBEBEB"
            />
            <path
                d="M0.000244141 179.488L36.4621 179.31L72.9169 179.253L145.841 179.133L218.757 179.246L255.219 179.31L291.674 179.488L255.219 179.658L218.757 179.722L145.841 179.842L72.9169 179.722L36.4621 179.658L0.000244141 179.488Z"
                fill="#263238"
            />
            <path
                d="M239.879 88.5644C238.886 96.6812 232.181 103.649 232.181 103.649C232.181 103.649 222.588 102.414 216.735 96.7096C211.853 91.9488 211.47 85.0878 215.493 82.1362C221.262 77.9146 226.874 88.5715 226.874 88.5715C226.874 88.5715 224.575 76.7439 231.72 76.4246C236.701 76.1976 240.702 81.7957 239.879 88.5573V88.5644Z"
                fill="#B9D9F9"
            />
            <path
                d="M244.519 55.2537C240.901 60.0642 234.068 62.0437 234.068 62.0437C234.068 62.0437 228.406 57.7299 226.767 51.9474C225.405 47.1156 227.683 42.6031 231.33 42.1987C236.559 41.624 236.225 50.4858 236.225 50.4858C236.225 50.4858 239.106 42.0993 243.782 44.5188C247.038 46.2074 247.535 51.245 244.527 55.2537H244.519Z"
                fill="#B9D9F9"
            />
            <path
                d="M63.4445 48.7568C59.9395 53.6454 53.1495 55.781 53.1495 55.781C53.1495 55.781 47.3883 51.602 45.6216 45.8478C44.1458 41.0515 46.324 36.4823 49.9567 35.9998C55.1716 35.3045 55.0368 44.1734 55.0368 44.1734C55.0368 44.1734 57.7259 35.7231 62.4512 38.0361C65.7504 39.6467 66.3606 44.67 63.4374 48.7497L63.4445 48.7568Z"
                fill="#B9D9F9"
            />
            <g clipPath="url(#clip1_5949_104214)">
                <mask
                    id="mask0_5949_104214"
                    style={{
                        maskType: 'luminance',
                    }}
                    maskUnits="userSpaceOnUse"
                    x={-14}
                    y={-68}
                    width={349}
                    height={349}
                >
                    <path d="M335 -68H-14V281H335V-68Z" fill="white" />
                </mask>
                <g mask="url(#mask0_5949_104214)">
                    <mask
                        id="mask1_5949_104214"
                        style={{
                            maskType: 'luminance',
                        }}
                        maskUnits="userSpaceOnUse"
                        x={32}
                        y={-22}
                        width={256}
                        height={256}
                    >
                        <path
                            d="M287.769 -21.0039H32.9988V233.766H287.769V-21.0039Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask1_5949_104214)">
                        <g filter="url(#filter0_d_5949_104214)">
                            <path
                                d="M101.999 58.6094H91.3838V74.5326H101.999V58.6094Z"
                                fill="#C3CFD8"
                            />
                            <path
                                d="M102.002 58.6094V106.379H86.0784"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M91.3861 106.379V58.6094H75.4629V106.379H91.3861Z"
                                fill="white"
                            />
                            <path
                                d="M75.4629 58.6094V106.379H91.3861V58.6094"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M70.1553 54.631C69.4226 54.631 68.8284 54.037 68.8284 53.3041V45.3425C68.8284 44.6099 69.4226 44.0156 70.1553 44.0156H104.656C105.388 44.0156 105.982 44.6099 105.982 45.3425V53.3041C105.982 54.037 105.388 54.631 104.656 54.631H70.1553Z"
                                fill="#36487F"
                            />
                            <path
                                d="M128.542 108.232L189.784 58.6094L255.926 116.994V212.533H128.542V108.232Z"
                                fill="#EDF1F4"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M64.8477 108.232L115.884 58.6094L171.002 108.232V212.533H64.8477V108.232Z"
                                fill="white"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M202.848 132.922H234.695V148.845H202.848V132.922ZM128.542 159.46H160.388V170.075H128.542V159.46Z"
                                fill="#C3CFD8"
                            />
                            <path
                                d="M202.848 146.191H234.695V175.385H202.848V146.191Z"
                                fill="white"
                            />
                            <path
                                d="M234.695 132.922H202.848V175.384H234.695V132.922Z"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M80.7708 201.922H112.617V212.536H80.7708V201.922Z"
                                fill="#C3CFD8"
                            />
                            <path
                                d="M178.964 119.651L115.271 53.3047H192.233L269.195 119.651H178.964Z"
                                fill="#C3CFD8"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M117.925 96.0311C111.868 97.2605 107.309 102.615 107.309 109.035C107.309 115.455 111.868 120.809 117.925 122.039C117.067 122.213 116.18 122.304 115.271 122.304C107.942 122.304 102.001 116.363 102.001 109.035C102.001 101.707 107.942 95.7656 115.271 95.7656C116.18 95.7656 117.067 95.8571 117.925 96.0311Z"
                                fill="#C3CFD8"
                            />
                            <path
                                d="M115.271 122.305C122.599 122.305 128.54 116.364 128.54 109.035C128.54 101.707 122.599 95.7656 115.271 95.7656C107.942 95.7656 102.001 101.707 102.001 109.035C102.001 116.364 107.942 122.305 115.271 122.305Z"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                            />
                            <path
                                d="M160.388 159.461H128.542V180.692H160.388V159.461Z"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M112.617 159.461H80.7708V212.538H112.617V159.461Z"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M64.8477 125.391V111.688L112.971 65.351C115.012 63.4403 118.184 63.4403 120.225 65.351L171.002 114.19V127.611L116.598 76.4964L64.8477 125.391Z"
                                fill="#C3CFD8"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M112.97 54.7377C115.011 52.827 118.184 52.827 120.225 54.7377L189.579 119.651H175.335L116.598 65.8831L57.8596 120.872H43.6167L112.97 54.7377Z"
                                fill="white"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M54.2322 212.535H263.888"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M234.695 154.152H202.848"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M64.8477 116.996V212.536H171.002V116.996"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M218.771 132.922V175.384"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                                strokeLinejoin="round"
                            />
                            <path
                                d="M171.002 199.266H255.926V212.535H171.002V199.266Z"
                                fill="white"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                            />
                            <path
                                d="M172.371 204.574H254.6V211.249H172.371V204.574Z"
                                fill="#C3CFD8"
                            />
                            <path
                                d="M112.617 199.266H171.002V212.535H112.617V199.266Z"
                                fill="white"
                                stroke="#36487F"
                                strokeWidth={2.6538}
                            />
                            <path
                                d="M113.986 204.574H169.675V211.25H113.986V204.574ZM172.371 120.977H254.6V127.611H172.371V120.977Z"
                                fill="#C3CFD8"
                            />
                            <path
                                d="M104.655 191.304C106.121 191.304 107.309 190.116 107.309 188.65C107.309 187.184 106.121 185.996 104.655 185.996C103.19 185.996 102.001 187.184 102.001 188.65C102.001 190.116 103.19 191.304 104.655 191.304Z"
                                fill="#C3CFD8"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M95.3639 180.689V174.054H86.0752V178.035C86.0752 179.5 87.2634 180.689 88.729 180.689H95.3639ZM98.0177 180.689H104.652C106.118 180.689 107.306 179.5 107.306 178.035V174.054H98.0177V180.689ZM107.306 171.401V167.42C107.306 165.955 106.118 164.766 104.652 164.766H98.0177V171.401H107.306ZM95.3639 164.766H88.729C87.2634 164.766 86.0752 165.955 86.0752 167.42V171.401H95.3639V164.766Z"
                                fill="#36487F"
                            />
                        </g>
                    </g>
                </g>
                <path
                    d="M59.945 192.869C59.6347 194.188 59.3049 195.488 59.2428 196.843C59.2787 196.909 59.2722 197.157 59.233 197.088C59.2265 197.307 58.8934 197.268 58.8934 197.052C58.8966 196.912 58.8999 196.772 58.9064 196.634C58.1291 195.671 57.0024 195.06 56.0912 194.221C55.3531 193.542 54.8273 192.735 54.5464 191.765C53.7887 189.159 54.4974 186.399 53.5895 183.799C52.8056 181.559 51.398 179.609 50.2092 177.581C48.9551 175.435 48.0276 173.178 47.9328 170.664C47.8577 168.737 48.0896 166.173 49.556 164.729C51.1106 163.198 53.0375 164.347 54.2296 165.712C57.6817 169.674 59.2787 174.681 59.9221 179.798C60.2781 182.63 60.6472 185.481 60.5623 188.342C60.5133 189.88 60.2944 191.383 59.945 192.875V192.869Z"
                    fill="#B9D9F9"
                />
                <path
                    d="M59.2594 197.882C59.439 195.204 59.4259 192.522 59.1647 189.857C59.1679 189.848 59.1777 189.841 59.1777 189.828C59.2202 187.659 59.3933 185.497 59.3084 183.326C59.2169 180.968 58.8707 178.652 58.3547 176.353C58.3482 176.327 58.309 176.333 58.3123 176.359C58.7368 178.603 58.985 180.896 59.0667 183.179C59.1092 184.403 59.1026 185.631 59.0373 186.856C59.0177 187.255 58.9785 187.656 58.9524 188.058C58.0771 181.951 55.8236 176.157 52.4989 170.974C52.4532 170.902 52.329 170.98 52.3715 171.052C54.7654 174.961 56.5323 179.259 57.6264 183.717C55.9673 180.641 53.4591 178.113 51.6007 175.157C51.5909 175.141 51.5648 175.157 51.5713 175.174C53.2435 178.463 56.0032 181.056 57.7636 184.299C58.0673 185.618 58.3188 186.954 58.505 188.293C58.7597 190.135 58.8805 191.987 58.9067 193.845C57.8975 191.954 56.8459 190.148 56.0196 188.149C55.9967 188.097 55.9216 188.133 55.9379 188.185C56.6401 190.262 57.5872 192.496 58.9099 194.27C58.9165 195.583 58.8773 196.899 58.8022 198.212C58.5964 201.716 57.9987 205.171 57.7211 208.666C57.7113 208.771 57.8616 208.784 57.8812 208.682C58.6095 205.158 59.0242 201.474 59.2627 197.885L59.2594 197.882Z"
                    fill="#263238"
                />
                <path
                    d="M55.5393 170.864C55.5295 170.838 55.4903 170.857 55.5001 170.88C56.1402 172.65 56.5027 174.444 56.9632 176.256C56.9828 176.338 57.1037 176.321 57.0939 176.233C56.9045 174.391 56.248 172.556 55.536 170.861L55.5393 170.864Z"
                    fill="#263238"
                />
                <path
                    d="M71.7876 178.277C71.3729 179.316 70.8568 180.322 70.3147 181.302C69.1618 183.395 67.9599 185.312 67.7411 187.749C67.6432 188.836 67.6105 189.93 67.2774 190.979C66.99 191.887 66.5262 192.723 65.9906 193.506C64.743 195.326 63.1819 196.893 62.0225 198.781C60.8173 200.744 59.929 202.903 59.3868 205.137C59.3574 205.257 59.1648 205.225 59.1942 205.104C59.1974 205.094 59.2007 205.088 59.2007 205.074C59.1974 205.068 59.1909 205.061 59.1876 205.048C59.0047 199.823 59.4228 194.558 61.0165 189.551C61.7122 187.363 62.5744 185.185 63.5477 183.104C64.4001 181.289 65.4648 179.545 66.9638 178.189C67.9371 177.314 70.0469 175.652 71.4284 176.53C72.0914 176.951 72.0424 177.621 71.7811 178.271L71.7876 178.277Z"
                    fill="#B9D9F9"
                />
                <path
                    d="M66.5391 190.125C65.6443 191.656 64.5796 193.084 63.5018 194.488C62.9956 195.148 62.4828 195.804 61.9766 196.464C61.7382 196.777 61.4704 197.088 61.2516 197.424C61.3887 196.875 61.5227 196.323 61.6631 195.775C62.3359 193.136 62.9858 190.471 63.9884 187.933C64.9715 185.451 66.2746 183.122 67.6169 180.82C67.6528 180.758 67.5549 180.715 67.5189 180.774C66.415 182.59 65.2948 184.403 64.3673 186.316C63.848 187.384 63.4136 188.485 63.0283 189.605C63.479 187.205 64.0211 184.902 64.8082 182.577C64.8147 182.557 64.7853 182.541 64.7755 182.564C63.8121 184.844 63.1197 187.404 62.927 189.873C62.927 189.876 62.927 189.883 62.9303 189.886C62.7049 190.565 62.4959 191.251 62.2999 191.94C61.4769 194.863 60.7552 197.816 60.0856 200.781C60.0987 198.825 60.2195 196.901 60.5886 194.971C60.5984 194.926 60.5298 194.916 60.52 194.958C60.0334 196.947 59.8146 199.041 60.017 201.082C59.7623 202.209 59.5174 203.339 59.2757 204.465C58.7629 206.872 58.1326 209.325 57.8321 211.771C57.8256 211.833 57.9138 211.846 57.9301 211.788C58.2894 210.468 58.5049 209.103 58.7989 207.767C59.0928 206.428 59.3965 205.092 59.7068 203.757C60.1608 201.813 60.6376 199.873 61.1209 197.937C61.4443 197.228 62.0713 196.598 62.5384 196C63.0511 195.347 63.5671 194.694 64.0668 194.027C64.9976 192.79 65.8533 191.506 66.5816 190.138C66.5979 190.108 66.5522 190.089 66.5359 190.115L66.5391 190.125Z"
                    fill="#263238"
                />
                <path
                    d="M66.7612 187.884C65.6279 189.337 64.5991 190.947 63.7892 192.606C63.7794 192.626 63.812 192.649 63.8251 192.629C64.8245 191.062 65.8075 189.484 66.8069 187.913C66.8232 187.887 66.7808 187.861 66.7612 187.884Z"
                    fill="#263238"
                />
                <path
                    d="M44.5201 183.228C45.1961 184.394 45.9701 185.795 45.6795 187.196C45.4966 188.081 44.8467 188.803 44.7291 189.708C44.4678 191.703 46.4568 193.023 47.8938 193.966C51.4798 196.321 56.4016 198.418 57.0548 203.225C57.0809 203.395 57.3748 203.353 57.352 203.186C57.352 203.176 57.3487 203.167 57.3454 203.154C57.352 203.144 57.3618 203.141 57.3618 203.124C57.8255 200.688 57.8157 198.206 57.2932 195.782C56.7576 193.303 55.6765 191.079 54.3995 188.901C52.2408 185.217 49.9546 181.445 46.6886 178.623C45.784 177.842 43.9355 176.438 43.2137 178.202C42.5148 179.913 43.684 181.807 44.5135 183.228H44.5201Z"
                    fill="#B9D9F9"
                />
                <path
                    d="M47.8024 191.045C50.18 193.621 53.9619 195.062 56.0097 197.949C54.7948 193.527 52.7895 189.552 49.6999 186.1C49.6215 186.012 49.7456 185.884 49.8305 185.969C52.0906 188.141 53.7627 190.682 54.9842 193.435C54.4355 191.469 53.668 189.608 52.7633 187.759C52.7307 187.691 52.8287 187.628 52.8679 187.697C54.0175 189.657 54.7817 191.858 55.2618 194.079C57.9595 200.611 58.2109 208.269 57.6427 215.154C57.6361 215.252 57.4859 215.255 57.4892 215.154C57.6427 209.729 57.5577 204.21 56.2546 198.909C56.2285 198.798 56.1958 198.69 56.1697 198.579C53.9423 195.473 50.1767 194.036 47.7566 191.087C47.7338 191.058 47.773 191.019 47.7991 191.045H47.8024Z"
                    fill="#263238"
                />
                <path
                    d="M47.2504 188.827C48.498 189.973 49.7293 191.146 50.9801 192.289C50.9932 192.302 50.9768 192.321 50.9605 192.312C49.5986 191.391 48.2171 190.172 47.1753 188.899C47.1328 188.846 47.1981 188.778 47.2504 188.824V188.827Z"
                    fill="#263238"
                />
                <path
                    d="M47.0545 183.863C48.1878 185.682 49.3766 187.387 50.7907 189.004C50.8103 189.027 50.7809 189.056 50.7581 189.037C49.2427 187.577 47.8938 185.81 46.9859 183.906C46.963 183.86 47.0251 183.824 47.0545 183.867V183.863Z"
                    fill="#263238"
                />
                <path
                    d="M52.6753 219.998H63.6129L62.14 205.281H54.3605L52.6753 219.998Z"
                    fill="#455A64"
                />
                <path
                    d="M54.0859 207.672H62.3814L62.1398 205.281H54.3603L54.0859 207.672Z"
                    fill="#263238"
                />
                <path d="M53.018 206H63.2698V203.652H53.018V206Z" fill="#455A64" />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_d_5949_104214"
                x={-116.942}
                y={-9.06168}
                width={546.696}
                height={488.312}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={106.155} />
                <feGaussianBlur stdDeviation={79.616} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.442014 0 0 0 0 0.641815 0 0 0 0 0.791667 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_5949_104214"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_5949_104214"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_5949_104214">
                <rect width={292} height={242} fill="white" />
            </clipPath>
            <clipPath id="clip1_5949_104214">
                <rect width={231} height={198} fill="white" transform="translate(38 44)" />
            </clipPath>
        </defs>
    </svg>
);
