import {useState} from 'react';
import {useMutation} from 'react-query';
import {RegistrationTitle} from './RegistrationTitle';
import {useBusinessRegistration} from './RegistrationContent';
import {Alert, Button, CustomInputBox, CustomSelect, FormValidator} from 'utils';
import {useApp} from 'components/App/AppContext';
import API from 'api';
import {nameCheck} from 'api/routes/business';
import {getError, isAvailable} from 'utils/helpers';
import {businessLineOptions, businessRegistrationSteps} from '../data';

export const RegistrationNameCheck = () => {
    const {params} = useApp();
    const {result, setCurrentStep, handleStep} = useBusinessRegistration();
    const [formValues, setFormValues] = useState({
        business_name: result?.business_name,
        business_line: result?.business_line,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isNameValid, setIsNameValid] = useState();
    const [errorMsg, setErrorMsg] = useState();

    const {clientid, appname, apikey} = params || {};

    const {data, mutate, reset, isLoading} = useMutation({
        mutationFn: async payload => {
            setErrorMsg();
            const response = API.post(nameCheck, payload, {
                headers: {
                    clientid,
                    appname,
                    apikey,
                },
            });

            return response;
        },
        onSuccess: ({data, status}) => {
            if (
                (status === 200 || status === 201) &&
                (data?.status_code === 200 || data?.status_code === 201)
            )
                setIsNameValid(true);
            else setErrorMsg(data?.message);
        },
        onError: error => setErrorMsg(getError(error)),
    });

    const response = data?.data?.data;

    const goBack = () => setCurrentStep(businessRegistrationSteps.LANDING.step);
    const retry = () => {
        setIsNameValid(false);
        reset();
    };

    const handleSelect = (name, value) => {
        setIsNameValid(false);
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const handleSubmit = e => {
        e.preventDefault();

        const {isValid, formErrors} = FormValidator(formValues);

        if (!isValid) return setFormErrors(formErrors);

        if (!isNameValid)
            return mutate({
                proposedName: formValues?.business_name,
                lineOfBusiness: formValues?.business_line?.value,
            });

        const result = {
            step: businessRegistrationSteps.PERSONAL_INFORMATION.step,
            data: {...formValues, name_check_result: response},
        };
        handleStep(result);
    };

    const getCompColor = comp => {
        const similarity_score = parseInt(comp?.similarity_score);
        const compliance_score = parseInt(comp?.compliance_score);
        if (compliance_score >= 75 && similarity_score < 50) {
            return 'success';
        }
        if (
            compliance_score >= 50 &&
            compliance_score <= 75 &&
            similarity_score >= 50 &&
            similarity_score <= 75
        ) {
            return 'warning';
        }
        if (compliance_score <= 50 && similarity_score <= 75) {
            return 'error';
        }
        return '';
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <RegistrationTitle title="Name Check" subtitle="Tell us the name of your company." />
            {errorMsg && (
                <div className="pb-4">
                    <Alert message={errorMsg} onClose={() => setErrorMsg()} />
                </div>
            )}
            <CustomInputBox
                label="Company Name"
                name="business_name"
                placeholder="Enter your company name"
                onChange={handleChange}
                value={formValues?.business_name}
                error={formErrors?.business_name}
            />
            <CustomSelect
                label="Line of Business"
                placeholder="Select Line of Business"
                name="business_line"
                onChange={value => handleSelect('business_line', value)}
                value={formValues?.business_line}
                error={formErrors?.business_line}
                options={businessLineOptions}
            />
            {isNameValid ? (
                <div
                    className={`${getCompColor(response?.compliance_data)} space-15 info-section`}
                    style={{}}
                >
                    <p>{response?.compliance_data?.message}</p>
                    <div className="d-flex justify-content-between">
                        <p className="key">Suggested Name</p>
                        <p className="value">
                            {isAvailable(response?.compliance_data?.suggested_name)}
                        </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="key">Most Similar Name</p>
                        <p className="value">
                            {isAvailable(response?.compliance_data?.most_similar_name)}
                        </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="key">Similarity Score</p>
                        <p className="value">{response?.compliance_data?.similarity_score}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="key">Compliance score</p>
                        <p className="value">{response?.compliance_data?.compliance_score}</p>
                    </div>
                </div>
            ) : (
                <div className="warning space-15">Each check costs ₦500</div>
            )}
            <div className="d-flex gap-3 mt-5">
                <Button
                    cls="btn w-100 btn-lg btn--gray"
                    handleClick={isNameValid ? retry : goBack}
                    name={isNameValid ? 'Retry' : 'Back'}
                />
                <Button
                    cls="btn w-100 btn-lg"
                    type="submit"
                    name={isNameValid ? 'Proceed' : 'Check'}
                    disabled={isLoading}
                />
            </div>
        </form>
    );
};
