import React from 'react';

export const FileIcon = ({width = '24', height = '24', color = '#0E1B28', className = ''}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                opacity="0.4"
                d="M9.1237 10.0833C10.1822 10.0833 11.0404 9.22521 11.0404 8.16667C11.0404 7.10812 10.1822 6.25 9.1237 6.25C8.06515 6.25 7.20703 7.10812 7.20703 8.16667C7.20703 9.22521 8.06515 10.0833 9.1237 10.0833Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.9596 2.41406H9.1263C4.33464 2.41406 2.41797 4.33073 2.41797 9.1224V14.8724C2.41797 19.6641 4.33464 21.5807 9.1263 21.5807H14.8763C19.668 21.5807 21.5846 19.6641 21.5846 14.8724V10.0807"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g opacity="0.4">
                <path
                    d="M15.5938 5.28906H20.8646"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M18.2305 7.92708V2.65625"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </g>
            <path
                opacity="0.4"
                d="M3.05859 18.6628L7.78318 15.4907C8.54026 14.9828 9.63276 15.0403 10.3132 15.6249L10.6294 15.9028C11.3769 16.5449 12.5844 16.5449 13.3319 15.9028L17.3186 12.4816C18.0661 11.8395 19.2736 11.8395 20.0211 12.4816L21.5832 13.8232"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
