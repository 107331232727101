import {useState} from 'react';
import {toUpper} from 'lodash';
import {useMutation} from 'react-query';
import {Modal, ModalBody} from 'reactstrap';
import {AddressSuccessIcon, DocumentIconTwo, EditIcon, EyeOnIcon} from 'assets/icons';
import {Alert, Button, RingSpinner} from 'utils';
import {RegistrationTitle} from './RegistrationTitle';
import {useBusinessRegistration} from './RegistrationContent';
import {useApp} from 'components/App/AppContext';
import API from 'api';
import {
    companyAddressLabels,
    proprietorAddressLabels,
    businessInformationLabels,
    proprietorInformationLabels,
} from '../enums';
import {DONE} from 'components/constants';
import {businessRegistrationSteps} from '../data';
import {registerBusiness} from 'api/routes/business';
import {getError, getFormData, getLocalPhoneFormat, timeDateFormat} from 'utils/helpers';

export const RegistrationReview = () => {
    const {params} = useApp();
    const {setCurrentStep, result} = useBusinessRegistration();
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();

    const {clientid, appname, apikey} = params || {};

    const sendEvent = data => window.parent.postMessage(data, '*');

    const goBack = () => setCurrentStep(businessRegistrationSteps.COMPANY_ADDRESS.step);

    const {mutate, isLoading} = useMutation({
        mutationKey: ['register-business'],
        mutationFn: async payload => {
            setErrorMsg();
            const response = API.post(registerBusiness, payload, {
                headers: {
                    clientid,
                    appname,
                    apikey,
                },
            });

            return response;
        },
        onSuccess: ({data, status}) => {
            if (
                (status === 200 || status === 201) &&
                (data?.status_code === 200 || data?.status_code === 201)
            ) {
                setSuccessMsg(data?.message);
            } else setErrorMsg(data?.message);
        },
        onError: error => setErrorMsg(getError(error)),
    });

    const handleSubmit = e => {
        e.preventDefault();

        const payload = {
            proprietorFirstname: result?.first_name,
            proprietorSurname: result?.last_name,
            proprietorGender: toUpper(result?.gender?.value),
            proprietorPhonenumber: getLocalPhoneFormat(result?.phone_number),
            proprietorEmail: result?.email,
            proprietorDob: timeDateFormat(result?.dob).date,
            proprietorNationality: result?.nationality?.label,
            proprietorStreetNumber: result?.proprietor_street_number,
            proprietorServiceAddress: result?.proprietor_street_name,
            proprietorState: result?.proprietor_state?.label,
            proprietorCity: result?.proprietor_city,
            proprietorPostcode: result?.proprietor_postcode,
            signature: result?.signature,
            meansOfId: result?.means_of_id,
            passport: result?.passport_pic,
            proposedOption1: result?.business_name,
            lineOfBusiness: result?.business_line?.value,
            companyEmail: result?.business_email,
            businessCommencementDate: timeDateFormat(result?.business_start_date).date,
            companyStreetNumber: result?.business_street_number,
            companyAddress: result?.business_street_name,
            companyState: result?.business_state?.label,
            companyCity: result?.proprietor_city,
            proprietorLga: result?.proprietor_lga,
        };

        const formData = getFormData(payload);

        mutate(formData);
    };
    return (
        <div className="max-w-md mx-auto">
            {!successMsg ? (
                <form onSubmit={handleSubmit}>
                    {!isLoading ? (
                        <>
                            <RegistrationTitle
                                title="Review and Submit"
                                subtitle="Please make sure the following information is correct. We'll use it to generate your documents."
                            />
                            {errorMsg && (
                                <div className="pb-4">
                                    <Alert message={errorMsg} onClose={() => setErrorMsg()} />
                                </div>
                            )}
                            <div
                                className="overflow-auto d-flex flex-column gap-5"
                                style={{maxHeight: '300px'}}
                            >
                                <Preview
                                    title="Proprietor Information"
                                    labels={proprietorInformationLabels}
                                    onClick={() =>
                                        setCurrentStep(
                                            businessRegistrationSteps.PERSONAL_INFORMATION.step
                                        )
                                    }
                                    extra={
                                        <div className="d-flex gap-4 mt-4 flex-column">
                                            <FilePreview
                                                title="Means of ID"
                                                imageUrl={URL.createObjectURL(result?.means_of_id)}
                                            />
                                            <FilePreview
                                                title="Passport Photo"
                                                imageUrl={URL.createObjectURL(result?.passport_pic)}
                                            />
                                            <FilePreview
                                                title="Signature"
                                                imageUrl={URL.createObjectURL(result?.signature)}
                                            />
                                        </div>
                                    }
                                />
                                <Preview
                                    title="Proprietor Address"
                                    labels={proprietorAddressLabels}
                                    onClick={() =>
                                        setCurrentStep(
                                            businessRegistrationSteps.PROPRIETORS_ADDRESS.step
                                        )
                                    }
                                />
                                <Preview
                                    title="Business Information"
                                    labels={businessInformationLabels}
                                    onClick={() =>
                                        setCurrentStep(
                                            businessRegistrationSteps.BUSINESS_INFORMATION.step
                                        )
                                    }
                                />
                                <Preview
                                    title="Company Address"
                                    labels={companyAddressLabels}
                                    onClick={() =>
                                        setCurrentStep(
                                            businessRegistrationSteps.COMPANY_ADDRESS.step
                                        )
                                    }
                                />
                            </div>
                            <div className="d-flex gap-3 mt-5">
                                <Button
                                    cls="btn w-100 btn-lg btn--gray"
                                    name="Back"
                                    handleClick={goBack}
                                    disabled={isLoading}
                                />
                                <Button
                                    cls="btn w-100 btn-lg"
                                    type="submit"
                                    name="Proceed"
                                    disabled={isLoading}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="flex__center min-height">
                            <RingSpinner text="Submitting" />
                        </div>
                    )}
                </form>
            ) : (
                <div className="d-flex flex-column align-items-center mt-5 pt-5 px-5 mx-5">
                    <AddressSuccessIcon className="mb-5" />
                    <p className="text-center pb-5">
                        You've successfully Submitted your CAC Registration
                    </p>
                    <Button
                        cls="w-100 btn btn-lg rounded-0 mt-2"
                        name="Close"
                        handleClick={() => {
                            sendEvent({name: DONE, result});
                        }}
                        disabled={isLoading}
                    />
                </div>
            )}
        </div>
    );
};

const Preview = ({title, onClick, labels, extra}) => {
    const {result} = useBusinessRegistration();

    return (
        <div className="preview">
            <div className="d-flex gap-2 mb-3">
                <p className="title text-bold text-primary d-flex align-items-center mb-0 text-nowrap gap-2 flex-grow-1">
                    {title}
                </p>
                {onClick && <EditIcon className="pointer" onClick={onClick} />}
            </div>
            <div className="info-section d-flex flex-column gap-4">
                {labels.map(({label, key, fn}) => (
                    <div key={key} className="d-flex">
                        <p className="key w-50 mb-0">{label}</p>
                        <div className="value text-left mb-0 w-50">
                            {fn ? fn(result?.[key]) : result?.[key]}
                        </div>
                    </div>
                ))}

                {extra}
            </div>
        </div>
    );
};

const FilePreview = ({title, imageUrl}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(prev => !prev);

    return (
        <>
            <div className="file-preview d-flex justify-content-between">
                <div className="d-flex align-items-center gap-3">
                    <DocumentIconTwo />
                    {title}
                </div>
                <button type="button" className="d-flex gap-3 align-items-center" onClick={toggle}>
                    <EyeOnIcon fill="var(--blackFull)" fill2="var(--blackFull)" />
                    View
                </button>
            </div>
            <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
                <ModalBody className="p-0">
                    <img src={imageUrl} style={{height: '80vh'}} className="w-100" />
                </ModalBody>
            </Modal>
        </>
    );
};
