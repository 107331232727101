import * as React from 'react';
import {Alert, CustomInputBox, CustomPassword} from 'utils';
import {RecordIcon} from 'assets/icons';
import {useMutation} from 'react-query';
import API from 'api';
import {merchantLogin} from 'api/routes/compliance';
import {ComplianceLayout} from './ComplianceLayout';
import {STEPS} from './utils';
import {getError} from 'utils/helpers';
import {useCompliance} from './ComplianceContent';

const texts = [
    'Search “Verification suite” in your mail',
    'Type in the password in the mail',
    'Check spam if you can’t find your mail, or contact support',
];

export const ComplianceLogin = () => {
    const {data, updateData, setError} = useCompliance();

    const {username, password, errorMsg} = data;
    const disableBtn = !!username && !!password;

    const handleChange = ({target: {name, value}}) => updateData({[name]: value});

    const {mutate} = useMutation(
        async () => {
            updateData({step: STEPS.VERIFY_LOGIN, errorMsg: ''});
            const response = await API.post(merchantLogin, {username, password});
            return response;
        },
        {
            onSuccess: ({data, status}) => {
                if (
                    (status === 200 || status === 201) &&
                    (data?.status_code === 200 || data?.status_code === 201)
                ) {
                    updateData({step: STEPS.GET_ACCOUNT_INFO, ...data?.results});
                } else setError(data?.message);
            },
            onError: error => setError(getError(error)),
        }
    );

    return (
        <ComplianceLayout disableBtn={!disableBtn} showBtn handleClick={mutate}>
            {errorMsg && <Alert message={errorMsg} onClose={() => updateData({errorMsg: ''})} />}

            <div className="my-4">
                <CustomInputBox
                    type="email"
                    label="Email"
                    name="username"
                    placeholder="Enter Email"
                    value={username}
                    onChange={handleChange}
                />

                <CustomPassword
                    label="Password"
                    name="password"
                    placeholder="Check your mail for password"
                    value={password}
                    onChange={handleChange}
                />
            </div>
            <div className="pb-3">
                <h4 className="mb-3">
                    <b>How to get your password</b>
                </h4>

                {texts.map((text, index) => (
                    <p className="" key={`text-${index}`}>
                        <RecordIcon className="me-2" />
                        {text}
                    </p>
                ))}
            </div>
        </ComplianceLayout>
    );
};
