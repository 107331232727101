export const STEPS = {
    START: 'START',
    LOGIN: 'LOGIN',
    VERIFY_LOGIN: 'VERIFY_LOGIN',
    GET_ACCOUNT_INFO: 'GET_ACCOUNT_INFO',
    APP_SELECT: 'APP_SELECT',
    ADD_SERVICES: 'ADD_SERVICES',
    APP_INFO: 'APP_INFO',
    FINALIZE_INFO: 'FINALIZE_INFO',
    COMPLETE: 'COMPLETE',
};

export const servicesUrl = [
    '/CustomerInformation/verifyMobileCarrier',
    '/IdentityVerification/NIP',
    '/BusinessInformation/CAC/Basic',
    '/IdentityVerification/Ghana-PID',
    '/BusinessInformation/TIN',
    '/IdentityVerification/PVC',
    '/IdentityVerification/Ghana-VID',
    '/IdentityVerification/DriverLicence',
    '/IdentityVerification/NIN',
    '/IdentityVerification/BVN',
    '/IdentityVerification/Ghana-COC',
    '/IdentityVerification/LivenessCheckAdvanced',
];

export const prodVerificationsServices = [
    {
        id: 17,
        service_label: 'Phone Number',
        service_url: '/CustomerInformation/verifyMobileCarrier',
        payload: 'phone_number,country_code',
    },
    {
        id: 31,
        service_label: 'Certificate of Incorporation (CAC) - Basic enquiry',
        service_url: '/BusinessInformation/CAC/Basic',
        payload: 'registration_number,country_code',
    },
    {
        id: 5,
        service_label: 'NGN International Passport',
        service_url: '/IdentityVerification/NIP',
        payload: 'phone_number,passport_number,first_name,last_name,dob',
    },
    {
        id: 22,
        service_label: 'Ghana International Passport',
        service_url: '/IdentityVerification/Ghana-PID',
        payload: 'phone_number,passportno,include_pic',
    },
    {
        id: 3,
        service_label: `Permanent Voter's Card`,
        service_url: '/IdentityVerification/PVC',
        payload: 'phone_number,last_name,state,pvc_number',
    },
    {
        id: 20,
        service_label: `Ghana Voter's Card`,
        service_url: '/IdentityVerification/Ghana-VID',
        payload: 'phone_number,voter_id,include_pic',
    },
    {
        id: 4,
        service_label: 'Drivers Licence',
        service_url: '/IdentityVerification/DriverLicence',
        payload: 'phone_number,license_number,first_name,last_name,dob',
    },
    {
        id: 2,
        service_label: 'National Identity Number (NIN)',
        service_url: '/IdentityVerification/NIN',
        payload: 'phone_number,nin_number',
    },
    {
        id: 1,
        service_label: 'Bank Verification Number (BVN)',
        service_url: '/IdentityVerification/BVN',
        payload: 'phone_number,bvn_number',
    },
    {
        id: 21,
        service_label: 'Ghana Drivers License',
        service_url: '/IdentityVerification/Ghana-COC',
        payload: 'phone_number,certificate_of_competence,include_pic',
    },
    {
        id: 32,
        service_label: 'Tax Identification Number (TIN)',
        service_url: '/BusinessInformation/TIN',
        payload: 'criteria,criteriaValue',
    },
    {
        id: 36,
        service_label: 'Liveness Check (Advanced)',
        service_url: '/IdentityVerification/LivenessCheckAdvanced',
        payload: 'phone_number,video,image',
    },
];

export const stagingVerificationsServices = [
    {
        id: 62,
        service_label: 'Phone Number',
        service_url: '/CustomerInformation/verifyMobileCarrier',
        payload: 'phone_number,country_code',
    },
    {
        id: 30,
        service_label: 'Certificate of Incorporation (CAC) - Basic enquiry',
        service_url: '/BusinessInformation/CAC/Basic',
        payload: 'registration_number,country_code',
    },
    {
        id: 5,
        service_label: 'NGN International Passport',
        service_url: '/IdentityVerification/NIP',
        payload: 'phone_number,passport_number,first_name,last_name,dob',
    },
    {
        id: 22,
        service_label: 'Ghana International Passport',
        service_url: '/IdentityVerification/Ghana-PID',
        payload: 'phone_number,passportno,include_pic',
    },
    {
        id: 3,
        service_label: `Permanent Voter's Card`,
        service_url: '/IdentityVerification/PVC',
        payload: 'phone_number,last_name,state,pvc_number',
    },
    {
        id: 20,
        service_label: `Ghana Voter's Card`,
        service_url: '/IdentityVerification/Ghana-VID',
        payload: 'phone_number,voter_id,include_pic',
    },
    {
        id: 4,
        service_label: 'Drivers Licence',
        service_url: '/IdentityVerification/DriverLicence',
        payload: 'phone_number,license_number,first_name,last_name,dob',
    },
    {
        id: 2,
        service_label: 'National Identity Number (NIN)',
        service_url: '/IdentityVerification/NIN',
        payload: 'phone_number,nin_number',
    },
    {
        id: 1,
        service_label: 'Bank Verification Number (BVN)',
        service_url: '/IdentityVerification/BVN',
        payload: 'phone_number,bvn_number',
    },
    {
        id: 21,
        service_label: 'Ghana Drivers License',
        service_url: '/IdentityVerification/Ghana-COC',
        payload: 'phone_number,certificate_of_competence,include_pic',
    },
    {
        id: 31,
        service_label: 'Tax Identification Number (TIN)',
        service_url: '/BusinessInformation/TIN',
        payload: 'criteria,criteriaValue',
    },
    {
        id: 34,
        service_label: 'Liveness Check (Advanced)',
        service_url: '/IdentityVerification/LivenessCheckAdvanced',
        payload: 'phone_number,video,image',
    },
    {
        id: 41,
        service_label: 'Aml',
        service_url: '/Aml/Sanctions',
        payload: 'phone_number,video,image',
    },
];

export const devVerificationsServices = [
    {
        id: 25,
        service_label: 'Phone Number',
        service_url: '/CustomerInformation/verifyMobileCarrier',
        payload: 'phone_number,country_code',
    },
    {
        id: 48,
        service_label: 'Certificate of Incorporation (CAC) - Basic enquiry',
        service_url: '/BusinessInformation/CAC/Basic',
        payload: 'registration_number,country_code',
    },
    {
        id: 5,
        service_label: 'NGN International Passport',
        service_url: '/IdentityVerification/NIP',
        payload: 'phone_number,passport_number,first_name,last_name,dob',
    },
    {
        id: 44,
        service_label: 'Ghana International Passport',
        service_url: '/IdentityVerification/Ghana-PID',
        payload: 'phone_number,passportno,include_pic',
    },
    {
        id: 3,
        service_label: `Permanent Voter's Card`,
        service_url: '/IdentityVerification/PVC',
        payload: 'phone_number,last_name,state,pvc_number',
    },
    {
        id: 43,
        service_label: `Ghana Voter's Card`,
        service_url: '/IdentityVerification/Ghana-VID',
        payload: 'phone_number,voter_id,include_pic',
    },
    {
        id: 4,
        service_label: 'Drivers Licence',
        service_url: '/IdentityVerification/DriverLicence',
        payload: 'phone_number,license_number,first_name,last_name,dob',
    },
    {
        id: 2,
        service_label: 'National Identity Number (NIN)',
        service_url: '/IdentityVerification/NIN',
        payload: 'phone_number,nin_number',
    },
    {
        id: 1,
        service_label: 'Bank Verification Number (BVN)',
        service_url: '/IdentityVerification/BVN',
        payload: 'phone_number,bvn_number',
    },
    {
        id: 47,
        service_label: 'Ghana Drivers License',
        service_url: '/IdentityVerification/Ghana-COC',
        payload: 'phone_number,certificate_of_competence,include_pic',
    },
    {
        id: 38,
        service_label: 'Tax Identification Number (TIN)',
        service_url: '/BusinessInformation/TIN',
        payload: 'criteria,criteriaValue',
    },
    {
        id: 27,
        service_label: 'Liveness Check (Advanced)',
        service_url: '/IdentityVerification/LivenessCheckAdvanced',
        payload: 'phone_number,video,image',
    },
    {
        id: 51,
        service_label: 'Aml',
        service_url: '/Aml/Sanctions',
        payload: 'phone_number,video,image',
    },
];

export const getVerificationServices = () => {
    const env = process.env.REACT_APP_ENV;
    if (env === 'dev') return devVerificationsServices;
    else if (env === 'staging') return stagingVerificationsServices;
    else return prodVerificationsServices;
};

export const dummy = {
    id: 123,
    user_id: 17,
    organization_name: 'BluIntouch',
    client_id: 'GXF8NSSQIKIF',
    address: '13B, Bishop Street1',
    base_currency_type: null,
    total_api_calls: 9700,
    total_credit_remaining: 427524,
    is_verified: 1,
    is_active: 1,
    activated_by: null,
    min_threshold: 21376,
    is_notified_for_min: 0,
    mid_threshold: 42752,
    is_notified_for_mid: 0,
    max_threshold: 85505,
    is_notified_for_max: 0,
    total_credit_loaded: 429473,
    created_at: '2021-03-26 06:34:55',
    updated_at: '2024-01-31 09:43:46',
    created_by: 1,
    credit_for_apps_and_sec_customers: 0,
    CAC: null,
    cac_certificate: null,
    email: null,
    phone_number: null,
    sector: null,
    id_type: null,
    id_number: null,
    tin: null,
    facial_verification: 0,
    bvn_verification: 0,
    tin_verification: 0,
    approval: 'approved',
    facial_verification_image: null,
    approval_date: null,
    customerServices: [
        {
            id: 1,
            service_label: '(BVN) Bank Verification Number',
            service_url: '/IdentityVerification/BVN',
            price_per_call: 120,
            fail_over_price: 100,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-07-30 14:46:53',
            slug: 'bvn',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 1,
                api_customer_id: 123,
                price_per_call: 121,
                has_fail_over: 0,
            },
        },
        {
            id: 2,
            service_label: '(NIN) National Identity Number',
            service_url: '/IdentityVerification/NIN',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-05 21:19:44',
            slug: 'nin',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 2,
                api_customer_id: 123,
                price_per_call: 151,
                has_fail_over: 0,
            },
        },
        {
            id: 3,
            service_label: '(PVC) Permanent Voters Card',
            service_url: '/IdentityVerification/PVC',
            price_per_call: 150,
            fail_over_price: 151,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-03-28 08:36:45',
            slug: 'voters_card',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 3,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 4,
            service_label: '(DL) Drivers Licence',
            service_url: '/IdentityVerification/DriverLicence',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-05 21:19:00',
            slug: 'driver_licence',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 4,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 5,
            service_label: '(NIP) Nigerian International Passport',
            service_url: '/IdentityVerification/NIP',
            price_per_call: 100,
            fail_over_price: 100,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-09-30 07:43:27',
            slug: 'internation_passport',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 5,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 7,
            service_label: 'OCR Validation',
            service_url: '/IdentityVerification/OCRValidation',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 3,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-02-02 15:02:29',
            slug: 'ocr',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 7,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 6,
            service_label: 'Address Verification',
            service_url: '/IdentityVerification/Address',
            price_per_call: 1000,
            fail_over_price: 1000,
            api_service_category_id: 2,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-18 05:09:57',
            slug: 'address_verification',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 6,
                api_customer_id: 123,
                price_per_call: 1000,
                has_fail_over: 0,
            },
        },
        {
            id: 15,
            service_label: '(OTP) One Time Password',
            service_url: '/Otp/send',
            price_per_call: 5,
            fail_over_price: 5,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-06-09 06:30:05',
            slug: 'mail',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 15,
                api_customer_id: 123,
                price_per_call: 5,
                has_fail_over: 0,
            },
        },
        {
            id: 11,
            service_label: '(Num2Bank) Number To Bank Listing',
            service_url: '/IdentityVerification/NumberToBankListing',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'num_to_bank',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: null,
            commission: null,
            metadata: null,
            pivot: {
                service_id: 11,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 8,
            service_label: 'Liveness Check',
            service_url: '/IdentityVerification/LivenessCheck',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 4,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-03 20:30:44',
            slug: 'liveness_check',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 8,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 12,
            service_label: 'Bank Account Verification',
            service_url: '/IdentityVerification/BankAccountVerify',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-05 21:19:23',
            slug: 'bank_account_verification',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 12,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 16,
            service_label: 'SMS',
            service_url: '/Messaging/Sms',
            price_per_call: 4,
            fail_over_price: 4,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-15 07:28:17',
            slug: 'sms',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 16,
                api_customer_id: 123,
                price_per_call: 4,
                has_fail_over: 0,
            },
        },
        {
            id: 10,
            service_label: '(iBVN) Bank Verification Number with Image',
            service_url: '/IdentityVerification/iBVN',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-09-29 20:50:50',
            slug: 'ibvn',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 10,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 21,
            service_label: '(pNIN) NIN Verification with Msisdn',
            service_url: '/IdentityVerification/pNIN',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-05 21:20:29',
            slug: '(pnin)_nin_verification_with_msisdn',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 21,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 26,
            service_label: 'Phone Number Verification (Standard)',
            service_url: '/CustomerInformation/verifyMobileCarrierStandard',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 6,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-09 20:09:58',
            slug: 'phone_number_verification_(standard)',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 26,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 20,
            service_label: 'Bulk(NIN) National Identity Number',
            service_url: '/V2/IdentityVerification/NIN-Bulk/',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-12 04:27:56',
            slug: 'bulk(nin)_national_identity_number',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 20,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 19,
            service_label: 'Bulk (BVN) Bank verification Number',
            service_url: '/v2/IdentityVerification/BVN-Upload',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-05 21:19:59',
            slug: 'bulk_bvn',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 19,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 27,
            service_label: 'Liveness Check (Advanced)',
            service_url: '/IdentityVerification/LivenessCheckAdvanced',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 4,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-16 17:44:15',
            slug: 'liveness_check_advanced',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 27,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 17,
            service_label: 'Kenya SMS',
            service_url: '/Messaging/SMS',
            price_per_call: 75,
            fail_over_price: 75,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-18 07:13:39',
            slug: 'kenya_sms',
            has_subcategory: 0,
            country_code: '254',
            country_name: 'Kenya',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 17,
                api_customer_id: 123,
                price_per_call: 75,
                has_fail_over: 0,
            },
        },
        {
            id: 25,
            service_label: 'Phone Number Verification',
            service_url: '/CustomerInformation/verifyMobileCarrier',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 6,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-09-30 15:09:08',
            slug: 'phone_number_verification',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 25,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 18,
            service_label: 'Document Capture',
            service_url: '/IdentityVerification/DocumentCapture',
            price_per_call: 100,
            fail_over_price: 100,
            api_service_category_id: 3,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-09 10:13:53',
            slug: 'ocr_capture',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 18,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 28,
            service_label: 'DsTv',
            service_url: '/V2/Billers/pay',
            price_per_call: 1.6,
            fail_over_price: 1.6,
            api_service_category_id: 9,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-10 08:14:22',
            slug: 'dstv',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 28,
                api_customer_id: 123,
                price_per_call: 1.6,
                has_fail_over: 0,
            },
        },
        {
            id: 30,
            service_label: 'Airtime',
            service_url: '/BillPayment/AirtimeTopup',
            price_per_call: 2,
            fail_over_price: 2,
            api_service_category_id: 12,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'airtime',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 30,
                api_customer_id: 123,
                price_per_call: 2,
                has_fail_over: 0,
            },
        },
        {
            id: 32,
            service_label: 'Phone Number Verification - Bulk',
            service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
            price_per_call: 25,
            fail_over_price: 25,
            api_service_category_id: 6,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-10 00:18:24',
            slug: 'phone_number_verification_(bulk)',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 32,
                api_customer_id: 123,
                price_per_call: 25,
                has_fail_over: 0,
            },
        },
        {
            id: 31,
            service_label: 'Data',
            service_url: '/BillPayment/DataTopup',
            price_per_call: 3,
            fail_over_price: 3,
            api_service_category_id: 12,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'data',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 31,
                api_customer_id: 123,
                price_per_call: 3,
                has_fail_over: 0,
            },
        },
        {
            id: 34,
            service_label: 'BIN-Verification',
            service_url: '/Onboarding/Kyc/Bin-lookUp',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 6,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'bin-verification',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 34,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 35,
            service_label: 'BVN Matching',
            service_url: '/Onboarding/Kyc/verifySingleBVN',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 6,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'bvn_matching',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 35,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 36,
            service_label: 'Know-Your-Customer (Advance)',
            service_url: '/Onboarding/Kyc/NubanVerify',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 6,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'know-your-customer_(advance)',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 36,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 38,
            service_label: 'TIN Verification',
            service_url: '/BusinessInformation/TIN',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 8,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'tin_verification',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 38,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 33,
            service_label: 'Know Your Customer (Basic)',
            service_url: '/Onboarding/Kyc/VerifyMobileNumber',
            price_per_call: 70,
            fail_over_price: 70,
            api_service_category_id: 6,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-10-27 06:04:42',
            slug: 'phone_number_verification_(advance)',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 33,
                api_customer_id: 123,
                price_per_call: 70,
                has_fail_over: 0,
            },
        },
        {
            id: 29,
            service_label: 'Gotv',
            service_url: '/Billers/pay',
            price_per_call: 1.5,
            fail_over_price: 1.5,
            api_service_category_id: 9,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-18 13:45:35',
            slug: 'gotv',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 29,
                api_customer_id: 123,
                price_per_call: 1.5,
                has_fail_over: 0,
            },
        },
        {
            id: 42,
            service_label: 'Spectranet',
            service_url: '/Billers/Pay',
            price_per_call: 2,
            fail_over_price: 2,
            api_service_category_id: 10,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'spectranet',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 42,
                api_customer_id: 123,
                price_per_call: 2,
                has_fail_over: 0,
            },
        },
        {
            id: 43,
            service_label: '(GVC) Ghana Voters Card',
            service_url: '/IdentityVerification/Ghana-VID',
            price_per_call: 35,
            fail_over_price: 35,
            api_service_category_id: 14,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-02-26 18:14:31',
            slug: 'ghana_voters_card',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 43,
                api_customer_id: 123,
                price_per_call: 35,
                has_fail_over: 0,
            },
        },
        {
            id: 44,
            service_label: '(GIP) Ghana Int Passport',
            service_url: '/IdentityVerification/Ghana-PID',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 14,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-02-24 07:49:47',
            slug: 'ghana_passport',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 44,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 40,
            service_label: 'Cabletv Plans',
            service_url: '/Authorize/cabletv-plans',
            price_per_call: 0,
            fail_over_price: 0,
            api_service_category_id: 13,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'cabletv_plans',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 40,
                api_customer_id: 123,
                price_per_call: 0,
                has_fail_over: 0,
            },
        },
        {
            id: 46,
            service_label: '(GSSNIT) Ghana SNNIT',
            service_url: '/IdentityVerification/Ghana-FSSNO',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 14,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: '(gssnit)_ghana_snnit',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 46,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 47,
            service_label: '(GDL) Ghana Drivers License',
            service_url: '/IdentityVerification/Ghana-COC',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 14,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: '(gdl)_ghana_drivers_license',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 47,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 48,
            service_label: 'CAC Basic Enquiry',
            service_url: '/BusinessInformation/CAC/Basic',
            price_per_call: 100,
            fail_over_price: 100,
            api_service_category_id: 8,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-04 10:08:20',
            slug: 'cac_basic_enquiry',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 48,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 49,
            service_label: 'CAC Advanced Enquiry',
            service_url: '/BusinessInformation/CAC/Advance',
            price_per_call: 120,
            fail_over_price: 120,
            api_service_category_id: 8,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-04 10:08:41',
            slug: 'cac_advanced_enquiry',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 49,
                api_customer_id: 123,
                price_per_call: 120,
                has_fail_over: 0,
            },
        },
        {
            id: 41,
            service_label: 'Smile',
            service_url: '/Billers/Pay',
            price_per_call: 2,
            fail_over_price: 2,
            api_service_category_id: 10,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'smile',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 41,
                api_customer_id: 123,
                price_per_call: 2,
                has_fail_over: 0,
            },
        },
        {
            id: 51,
            service_label: 'Aml',
            service_url: '/Aml/Sanctions',
            price_per_call: 100,
            fail_over_price: 100,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-29 07:03:35',
            slug: 'aml',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 51,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 52,
            service_label: 'Abuja Disco',
            service_url: '/v2/Billers/pay',
            price_per_call: 23,
            fail_over_price: 32,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-27 18:40:12',
            slug: 'abuja_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 52,
                api_customer_id: 123,
                price_per_call: 23,
                has_fail_over: 0,
            },
        },
        {
            id: 53,
            service_label: 'Auto-Detect Operators',
            service_url: '/api/AutoDetectOperators',
            price_per_call: 0,
            fail_over_price: 0,
            api_service_category_id: 13,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'auto-detect_operators',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 53,
                api_customer_id: 123,
                price_per_call: 0,
                has_fail_over: 0,
            },
        },
        {
            id: 54,
            service_label: 'Airtime International',
            service_url: '/AirtimeInternational',
            price_per_call: 4,
            fail_over_price: 4,
            api_service_category_id: 12,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-11-08 14:53:21',
            slug: 'airtime_international',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 54,
                api_customer_id: 123,
                price_per_call: 4,
                has_fail_over: 0,
            },
        },
        {
            id: 45,
            service_label: 'SSNITID',
            service_url: '/IdentityVerification/Ghana-FSSNO',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 14,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'ssnitid',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 45,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 55,
            service_label: 'Data International',
            service_url: '/DataInternational',
            price_per_call: 5,
            fail_over_price: 5,
            api_service_category_id: 12,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-10 09:28:13',
            slug: 'data_international',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 55,
                api_customer_id: 123,
                price_per_call: 5,
                has_fail_over: 0,
            },
        },
        {
            id: 56,
            service_label: 'Enugu Disco',
            service_url: '/v2/Billers/pay',
            price_per_call: 10,
            fail_over_price: 10,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-27 18:40:23',
            slug: 'enugu_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 56,
                api_customer_id: 123,
                price_per_call: 10,
                has_fail_over: 0,
            },
        },
        {
            id: 57,
            service_label: 'Ibadan Disco',
            service_url: '/v2/Billers/pay',
            price_per_call: 2,
            fail_over_price: 2,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-27 18:40:47',
            slug: 'ibadan_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 57,
                api_customer_id: 123,
                price_per_call: 2,
                has_fail_over: 0,
            },
        },
        {
            id: 50,
            service_label: 'Ikeja Disco',
            service_url: '/v2/Billers/pay',
            price_per_call: 23,
            fail_over_price: 32,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-02 12:02:48',
            slug: 'ikeja_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 50,
                api_customer_id: 123,
                price_per_call: 23,
                has_fail_over: 0,
            },
        },
        {
            id: 59,
            service_label: 'Kaduna Disco',
            service_url: '/v2/Billers/pay',
            price_per_call: 5,
            fail_over_price: 5,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-27 18:41:23',
            slug: 'kaduna_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 59,
                api_customer_id: 123,
                price_per_call: 5,
                has_fail_over: 0,
            },
        },
        {
            id: 60,
            service_label: 'Kano Disco',
            service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
            price_per_call: 5,
            fail_over_price: 5,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-08 09:28:37',
            slug: 'kano_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": false, "isElectricity": true}',
            pivot: {
                service_id: 60,
                api_customer_id: 123,
                price_per_call: 5,
                has_fail_over: 0,
            },
        },
        {
            id: 61,
            service_label: 'Eko Disco',
            service_url: '/v2/Billers/pay',
            price_per_call: 5,
            fail_over_price: 5,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-27 18:41:47',
            slug: 'eko_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 61,
                api_customer_id: 123,
                price_per_call: 5,
                has_fail_over: 0,
            },
        },
        {
            id: 64,
            service_label: '(vNIN) Virtual National Identity Number',
            service_url: '/IdentityVerification/vNIN',
            price_per_call: 5000,
            fail_over_price: 5000,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-28 11:18:42',
            slug: '(vnin)_virtual_national_identity_number',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 64,
                api_customer_id: 123,
                price_per_call: 5000,
                has_fail_over: 0,
            },
        },
        {
            id: 62,
            service_label: 'Port-Harcourt Disco',
            service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
            price_per_call: 100,
            fail_over_price: 100,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-15 16:33:55',
            slug: 'portharcourt_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat_commission_exclusive',
            commission: 2,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 62,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 66,
            service_label: '(pNIN-Basic) NIN Verification with Msisdn',
            service_url: '/IdentityVerification/pNIN-Basic',
            price_per_call: 70,
            fail_over_price: 70,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2022-12-08 08:16:31',
            slug: '(pnin-basic)_nin_verification_with_msisdn',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 66,
                api_customer_id: 123,
                price_per_call: 70,
                has_fail_over: 0,
            },
        },
        {
            id: 68,
            service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
            service_url: '/IdentityVerification/pNIN-Basic-Bulk',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-01-25 06:17:32',
            slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 68,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 69,
            service_label: 'Body Outlining',
            service_url: '/IdentityVerification/BodyOutline',
            price_per_call: 50,
            fail_over_price: 50,
            api_service_category_id: 4,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'body_outlining',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 69,
                api_customer_id: 123,
                price_per_call: 50,
                has_fail_over: 0,
            },
        },
        {
            id: 58,
            service_label: 'Jos Disco',
            service_url: '/v2/Billers/pay',
            price_per_call: 5,
            fail_over_price: 5,
            api_service_category_id: 11,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-27 18:41:01',
            slug: 'jos_disco',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
            pivot: {
                service_id: 58,
                api_customer_id: 123,
                price_per_call: 5,
                has_fail_over: 0,
            },
        },
        {
            id: 72,
            service_label: 'Ghana SMS',
            service_url: '/Messaging/Sms',
            price_per_call: 15,
            fail_over_price: 16,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-15 07:31:00',
            slug: 'ghana_sms',
            has_subcategory: 0,
            country_code: '233',
            country_name: 'Ghana',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 72,
                api_customer_id: 123,
                price_per_call: 15,
                has_fail_over: 0,
            },
        },
        {
            id: 74,
            service_label: 'South Africa SMS',
            service_url: '/Messaging/Sms',
            price_per_call: 75,
            fail_over_price: 75,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-11 07:41:40',
            slug: 'south_africa_sms',
            has_subcategory: 0,
            country_code: '27',
            country_name: 'South Africa',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 74,
                api_customer_id: 123,
                price_per_call: 75,
                has_fail_over: 0,
            },
        },
        {
            id: 81,
            service_label: 'International SMS',
            service_url: '/Messaging/Sms',
            price_per_call: 30,
            fail_over_price: 30,
            api_service_category_id: 16,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-12 17:10:40',
            slug: 'default_sms',
            has_subcategory: 0,
            country_code: '1',
            country_name: 'United States',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 81,
                api_customer_id: 123,
                price_per_call: 30,
                has_fail_over: 0,
            },
        },
        {
            id: 315,
            service_label: 'OTP Verification',
            service_url: '/Otp/verify',
            price_per_call: 0.1,
            fail_over_price: 0.1,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-06-06 15:27:11',
            slug: 'otp_verification',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 315,
                api_customer_id: 123,
                price_per_call: 0.1,
                has_fail_over: 0,
            },
        },
        {
            id: 65,
            service_label: 'Betting',
            service_url: '/BillPayment/BettingTopup',
            price_per_call: 10,
            fail_over_price: 10,
            api_service_category_id: 15,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-04-14 15:09:45',
            slug: 'betting',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 65,
                api_customer_id: 123,
                price_per_call: 10,
                has_fail_over: 0,
            },
        },
        {
            id: 316,
            service_label: 'Kenya National ID',
            service_url: '/IdentityVerification/KenyaNationalID',
            price_per_call: 100,
            fail_over_price: 110,
            api_service_category_id: 19,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'kenya_national_id',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 316,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 39,
            service_label: 'Startimes',
            service_url: '/Billers/Pay',
            price_per_call: 1.5,
            fail_over_price: 1.5,
            api_service_category_id: 9,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-18 13:45:43',
            slug: 'startimes',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission_inclusive',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 39,
                api_customer_id: 123,
                price_per_call: 1.5,
                has_fail_over: 0,
            },
        },
        {
            id: 9,
            service_label: 'Facial Comparison',
            service_url: '/IdentityVerification/FacialComparison',
            price_per_call: 150,
            fail_over_price: 150,
            api_service_category_id: 4,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-02-02 15:05:16',
            slug: 'facial_comparison',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 9,
                api_customer_id: 123,
                price_per_call: 150,
                has_fail_over: 0,
            },
        },
        {
            id: 75,
            service_label: 'Uganda SMS',
            service_url: '/Messaging/Sms',
            price_per_call: 75,
            fail_over_price: 75,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-05-11 07:42:15',
            slug: 'uganda_sms',
            has_subcategory: 0,
            country_code: '256',
            country_name: 'Uganda',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 75,
                api_customer_id: 123,
                price_per_call: 75,
                has_fail_over: 0,
            },
        },
        {
            id: 70,
            service_label: '(cBVN) Bank Verification Number with Consent',
            service_url: '/IdentityVerification/cBVN',
            price_per_call: 100,
            fail_over_price: 100,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: '(cbvn)_bank_verification_number_with_consent',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 70,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 303,
            service_label: 'United States SMS',
            service_url: '/Messaging/Sms',
            price_per_call: 75,
            fail_over_price: 75,
            api_service_category_id: 5,
            is_deleted: 0,
            created_at: '2023-05-11 07:44:42',
            updated_at: '2023-05-11 07:44:42',
            slug: null,
            has_subcategory: 0,
            country_code: '1',
            country_name: 'United States',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 303,
                api_customer_id: 123,
                price_per_call: 75,
                has_fail_over: 0,
            },
        },
        {
            id: 78,
            service_label: 'Internet',
            service_url: '/v2/Billers/pay',
            price_per_call: 2,
            fail_over_price: 2,
            api_service_category_id: 16,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'internet',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'commission',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 78,
                api_customer_id: 123,
                price_per_call: 2,
                has_fail_over: 0,
            },
        },
        {
            id: 317,
            service_label: 'Kenya Passport ID',
            service_url: '/IdentityVerification/KenyaPassportID',
            price_per_call: 100,
            fail_over_price: 110,
            api_service_category_id: 19,
            is_deleted: 0,
            created_at: null,
            updated_at: null,
            slug: 'kenya_passport_id',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 317,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
        {
            id: 328,
            service_label: '(pBVN) Bank Verification Number with Phone',
            service_url: '/IdentityVerification/pBVN',
            price_per_call: 100,
            fail_over_price: 100,
            api_service_category_id: 1,
            is_deleted: 0,
            created_at: null,
            updated_at: '2023-08-17 14:39:35',
            slug: '(pbvn)_bank_verification_number_with_phone',
            has_subcategory: 0,
            country_code: '234',
            country_name: 'Nigeria',
            charge_type: 'flat',
            commission: null,
            metadata: null,
            pivot: {
                service_id: 328,
                api_customer_id: 123,
                price_per_call: 100,
                has_fail_over: 0,
            },
        },
    ],
    applications: [
        {
            id: 121,
            app_name: 'coreapp',
            api_customer_id: 123,
            api_key: 'PZ5OQ8GAWUE4EBL11KMVHT69GVLFVMCO',
            total_api_calls: 5282,
            used_credit_on_app: 549873.875,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 121,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 121,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 121,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 121,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 121,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 121,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 121,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 121,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 121,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 121,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 121,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 121,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 121,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 121,
                    },
                },
                {
                    id: 7,
                    service_label: 'OCR Validation',
                    service_url: '/IdentityVerification/OCRValidation',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:02:29',
                    slug: 'ocr',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 7,
                        app_access_id: 121,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 121,
                    },
                },
                {
                    id: 15,
                    service_label: '(OTP) One Time Password',
                    service_url: '/Otp/send',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-09 06:30:05',
                    slug: 'mail',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 15,
                        app_access_id: 121,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 121,
                    },
                },
                {
                    id: 29,
                    service_label: 'Gotv',
                    service_url: '/Billers/pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:35',
                    slug: 'gotv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 29,
                        app_access_id: 121,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 121,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 121,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 121,
                    },
                },
                {
                    id: 34,
                    service_label: 'BIN-Verification',
                    service_url: '/Onboarding/Kyc/Bin-lookUp',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bin-verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 34,
                        app_access_id: 121,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 121,
                    },
                },
                {
                    id: 36,
                    service_label: 'Know-Your-Customer (Advance)',
                    service_url: '/Onboarding/Kyc/NubanVerify',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'know-your-customer_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 36,
                        app_access_id: 121,
                    },
                },
                {
                    id: 38,
                    service_label: 'TIN Verification',
                    service_url: '/BusinessInformation/TIN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'tin_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 38,
                        app_access_id: 121,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 121,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 121,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 121,
                    },
                },
                {
                    id: 44,
                    service_label: '(GIP) Ghana Int Passport',
                    service_url: '/IdentityVerification/Ghana-PID',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-24 07:49:47',
                    slug: 'ghana_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 44,
                        app_access_id: 121,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 121,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 121,
                    },
                },
                {
                    id: 48,
                    service_label: 'CAC Basic Enquiry',
                    service_url: '/BusinessInformation/CAC/Basic',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:20',
                    slug: 'cac_basic_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 48,
                        app_access_id: 121,
                    },
                },
                {
                    id: 49,
                    service_label: 'CAC Advanced Enquiry',
                    service_url: '/BusinessInformation/CAC/Advance',
                    price_per_call: 120,
                    fail_over_price: 120,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:41',
                    slug: 'cac_advanced_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 49,
                        app_access_id: 121,
                    },
                },
                {
                    id: 51,
                    service_label: 'Aml',
                    service_url: '/Aml/Sanctions',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-29 07:03:35',
                    slug: 'aml',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 51,
                        app_access_id: 121,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 121,
                    },
                },
                {
                    id: 52,
                    service_label: 'Abuja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:12',
                    slug: 'abuja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 52,
                        app_access_id: 121,
                    },
                },
                {
                    id: 50,
                    service_label: 'Ikeja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-02 12:02:48',
                    slug: 'ikeja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 50,
                        app_access_id: 121,
                    },
                },
                {
                    id: 54,
                    service_label: 'Airtime International',
                    service_url: '/AirtimeInternational',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-11-08 14:53:21',
                    slug: 'airtime_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 54,
                        app_access_id: 121,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 121,
                    },
                },
                {
                    id: 65,
                    service_label: 'Betting',
                    service_url: '/BillPayment/BettingTopup',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 15,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-14 15:09:45',
                    slug: 'betting',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 65,
                        app_access_id: 121,
                    },
                },
                {
                    id: 66,
                    service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-12-08 08:16:31',
                    slug: '(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 66,
                        app_access_id: 121,
                    },
                },
                {
                    id: 68,
                    service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic-Bulk',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-01-25 06:17:32',
                    slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 68,
                        app_access_id: 121,
                    },
                },
                {
                    id: 55,
                    service_label: 'Data International',
                    service_url: '/DataInternational',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 09:28:13',
                    slug: 'data_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 55,
                        app_access_id: 121,
                    },
                },
                {
                    id: 70,
                    service_label: '(cBVN) Bank Verification Number with Consent',
                    service_url: '/IdentityVerification/cBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(cbvn)_bank_verification_number_with_consent',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 70,
                        app_access_id: 121,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 121,
                    },
                },
                {
                    id: 64,
                    service_label: '(vNIN) Virtual National Identity Number',
                    service_url: '/IdentityVerification/vNIN',
                    price_per_call: 5000,
                    fail_over_price: 5000,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-28 11:18:42',
                    slug: '(vnin)_virtual_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 64,
                        app_access_id: 121,
                    },
                },
                {
                    id: 19,
                    service_label: 'Bulk (BVN) Bank verification Number',
                    service_url: '/v2/IdentityVerification/BVN-Upload',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:59',
                    slug: 'bulk_bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 19,
                        app_access_id: 121,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 121,
                    },
                },
                {
                    id: 57,
                    service_label: 'Ibadan Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:47',
                    slug: 'ibadan_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 57,
                        app_access_id: 121,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 121,
                    },
                },
                {
                    id: 56,
                    service_label: 'Enugu Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:23',
                    slug: 'enugu_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 56,
                        app_access_id: 121,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 121,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 121,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 121,
                    },
                },
                {
                    id: 316,
                    service_label: 'Kenya National ID',
                    service_url: '/IdentityVerification/KenyaNationalID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_national_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 316,
                        app_access_id: 121,
                    },
                },
                {
                    id: 74,
                    service_label: 'South Africa SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:41:40',
                    slug: 'south_africa_sms',
                    has_subcategory: 0,
                    country_code: '27',
                    country_name: 'South Africa',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 74,
                        app_access_id: 121,
                    },
                },
                {
                    id: 72,
                    service_label: 'Ghana SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 15,
                    fail_over_price: 16,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:31:00',
                    slug: 'ghana_sms',
                    has_subcategory: 0,
                    country_code: '233',
                    country_name: 'Ghana',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 72,
                        app_access_id: 121,
                    },
                },
                {
                    id: 317,
                    service_label: 'Kenya Passport ID',
                    service_url: '/IdentityVerification/KenyaPassportID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_passport_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 317,
                        app_access_id: 121,
                    },
                },
                {
                    id: 328,
                    service_label: '(pBVN) Bank Verification Number with Phone',
                    service_url: '/IdentityVerification/pBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-17 14:39:35',
                    slug: '(pbvn)_bank_verification_number_with_phone',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 328,
                        app_access_id: 121,
                    },
                },
                {
                    id: 53,
                    service_label: 'Auto-Detect Operators',
                    service_url: '/api/AutoDetectOperators',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'auto-detect_operators',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 53,
                        app_access_id: 121,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 125,
            app_name: 'test-otpapp',
            api_customer_id: 123,
            api_key: 'OY4M7QJNTUWT5RCICVNUV467NWBSN3PX',
            total_api_calls: 112,
            used_credit_on_app: 1272.5,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 125,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 125,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 125,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 125,
                    },
                },
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 125,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 125,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 125,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 126,
            app_name: 'olas_service',
            api_customer_id: 123,
            api_key: 'HH3MQWBQ73N0TBKBULH96VA5QR9WSARF',
            total_api_calls: 133,
            used_credit_on_app: 20016,
            api_credit_limit: 1000,
            app_balance: 100,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 126,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 126,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 126,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 126,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 126,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 126,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 126,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 126,
                    },
                },
                {
                    id: 44,
                    service_label: '(GIP) Ghana Int Passport',
                    service_url: '/IdentityVerification/Ghana-PID',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-24 07:49:47',
                    slug: 'ghana_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 44,
                        app_access_id: 126,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 126,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 126,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 126,
                    },
                },
                {
                    id: 34,
                    service_label: 'BIN-Verification',
                    service_url: '/Onboarding/Kyc/Bin-lookUp',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bin-verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 34,
                        app_access_id: 126,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 127,
            app_name: 'bluintouchapp',
            api_customer_id: 123,
            api_key: 'KMUWNB8B8US24R4C7T9WDLNLECSWBBQY',
            total_api_calls: 3610,
            used_credit_on_app: 388257.125,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 127,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 127,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 127,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 127,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 127,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 127,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 127,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 127,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 127,
                    },
                },
                {
                    id: 15,
                    service_label: '(OTP) One Time Password',
                    service_url: '/Otp/send',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-09 06:30:05',
                    slug: 'mail',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 15,
                        app_access_id: 127,
                    },
                },
                {
                    id: 53,
                    service_label: 'Auto-Detect Operators',
                    service_url: '/api/AutoDetectOperators',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'auto-detect_operators',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 53,
                        app_access_id: 127,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 127,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 127,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 127,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 127,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 127,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 127,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 127,
                    },
                },
                {
                    id: 52,
                    service_label: 'Abuja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:12',
                    slug: 'abuja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 52,
                        app_access_id: 127,
                    },
                },
                {
                    id: 50,
                    service_label: 'Ikeja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-02 12:02:48',
                    slug: 'ikeja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 50,
                        app_access_id: 127,
                    },
                },
                {
                    id: 57,
                    service_label: 'Ibadan Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:47',
                    slug: 'ibadan_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 57,
                        app_access_id: 127,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 127,
                    },
                },
                {
                    id: 64,
                    service_label: '(vNIN) Virtual National Identity Number',
                    service_url: '/IdentityVerification/vNIN',
                    price_per_call: 5000,
                    fail_over_price: 5000,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-28 11:18:42',
                    slug: '(vnin)_virtual_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 64,
                        app_access_id: 127,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 127,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 127,
                    },
                },
                {
                    id: 74,
                    service_label: 'South Africa SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:41:40',
                    slug: 'south_africa_sms',
                    has_subcategory: 0,
                    country_code: '27',
                    country_name: 'South Africa',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 74,
                        app_access_id: 127,
                    },
                },
                {
                    id: 56,
                    service_label: 'Enugu Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:23',
                    slug: 'enugu_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 56,
                        app_access_id: 127,
                    },
                },
                {
                    id: 58,
                    service_label: 'Jos Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:01',
                    slug: 'jos_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 58,
                        app_access_id: 127,
                    },
                },
                {
                    id: 59,
                    service_label: 'Kaduna Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:23',
                    slug: 'kaduna_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 59,
                        app_access_id: 127,
                    },
                },
                {
                    id: 60,
                    service_label: 'Kano Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-08 09:28:37',
                    slug: 'kano_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": false, "isElectricity": true}',
                    pivot: {
                        service_id: 60,
                        app_access_id: 127,
                    },
                },
                {
                    id: 62,
                    service_label: 'Port-Harcourt Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-15 16:33:55',
                    slug: 'portharcourt_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat_commission_exclusive',
                    commission: 2,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 62,
                        app_access_id: 127,
                    },
                },
                {
                    id: 54,
                    service_label: 'Airtime International',
                    service_url: '/AirtimeInternational',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-11-08 14:53:21',
                    slug: 'airtime_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 54,
                        app_access_id: 127,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 127,
                    },
                },
                {
                    id: 315,
                    service_label: 'OTP Verification',
                    service_url: '/Otp/verify',
                    price_per_call: 0.1,
                    fail_over_price: 0.1,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-06 15:27:11',
                    slug: 'otp_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 315,
                        app_access_id: 127,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 127,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 127,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 127,
                    },
                },
                {
                    id: 55,
                    service_label: 'Data International',
                    service_url: '/DataInternational',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 09:28:13',
                    slug: 'data_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 55,
                        app_access_id: 127,
                    },
                },
                {
                    id: 317,
                    service_label: 'Kenya Passport ID',
                    service_url: '/IdentityVerification/KenyaPassportID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_passport_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 317,
                        app_access_id: 127,
                    },
                },
                {
                    id: 316,
                    service_label: 'Kenya National ID',
                    service_url: '/IdentityVerification/KenyaNationalID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_national_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 316,
                        app_access_id: 127,
                    },
                },
                {
                    id: 39,
                    service_label: 'Startimes',
                    service_url: '/Billers/Pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:43',
                    slug: 'startimes',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 39,
                        app_access_id: 127,
                    },
                },
                {
                    id: 29,
                    service_label: 'Gotv',
                    service_url: '/Billers/pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:35',
                    slug: 'gotv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 29,
                        app_access_id: 127,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 128,
            app_name: 'olasapp6',
            api_customer_id: 123,
            api_key: 'SN0ET1YLW1CJ9ZC3JK78LB4Z5BUB8LK7',
            total_api_calls: 66,
            used_credit_on_app: 7055,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 128,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 128,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 128,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 128,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 128,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 128,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 128,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 128,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 128,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 128,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 128,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 128,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 129,
            app_name: 'bluintouch-notification-app',
            api_customer_id: 123,
            api_key: '9F4MQQ82QAW16X73CXGTSDUZI3VSLTWX',
            total_api_calls: 130,
            used_credit_on_app: 645,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 129,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 129,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 129,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 129,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 129,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 129,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 129,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 131,
            app_name: 'tayotestapp',
            api_customer_id: 123,
            api_key: 'QIDG71W1HT33B2BMVSO7DROZBEQPRJ2I',
            total_api_calls: 25,
            used_credit_on_app: 100,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 0,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 131,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 134,
            app_name: 'olawaletest',
            api_customer_id: 123,
            api_key: '19IECDOGSZW7TZ38XT1QMGD7LDQYY4D9',
            total_api_calls: 12,
            used_credit_on_app: 1216,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 134,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 134,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 135,
            app_name: 'identity',
            api_customer_id: 123,
            api_key: '3AKOM9DVMQ65NGND9EKIL84KTX9M26N7',
            total_api_calls: 9,
            used_credit_on_app: 374,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 135,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 135,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 135,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 135,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 135,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 135,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 135,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 135,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 135,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 135,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 135,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 135,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 137,
            app_name: 'olasapp2',
            api_customer_id: 123,
            api_key: 'DZPVR17PIH7M4NNQUTTWRMQEWXWQ3EJM',
            total_api_calls: 78,
            used_credit_on_app: 10410,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 137,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 137,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 137,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 137,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 137,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 137,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 137,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 137,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 137,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 137,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 137,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 137,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 137,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 137,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 137,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 137,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 137,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 137,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 137,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 137,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 137,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 137,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 137,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 138,
            app_name: 'address-verification',
            api_customer_id: 123,
            api_key: 'DCX3FX54LCZJFY7LFD4YC8921FZWSAX6',
            total_api_calls: 2,
            used_credit_on_app: 2000,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 138,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 139,
            app_name: 'allidentityapp',
            api_customer_id: 123,
            api_key: 'PMBLWKRTNYY2XOI9X6A7ZYP6PJ6NIWXP',
            total_api_calls: 18,
            used_credit_on_app: 541,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 139,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 139,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 139,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 139,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 139,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 139,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 139,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 139,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 139,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 139,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 139,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 139,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 139,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 139,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 139,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 139,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 139,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 140,
            app_name: 'olasapp4',
            api_customer_id: 123,
            api_key: 'HBVNUFHB6SXRIC3KK304C4EUQYYAPDDH',
            total_api_calls: 204,
            used_credit_on_app: 15638.6006,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 140,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 140,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 140,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 140,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 140,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 140,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 140,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 140,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 140,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 140,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 140,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 140,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 140,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 140,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 140,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 140,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 140,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 140,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 140,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 140,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 140,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 140,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 140,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 140,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 141,
            app_name: 'ghanaidentity',
            api_customer_id: 123,
            api_key: 'KBO0X4LR039AYRRLQF6DQIX2CVY4A1AE',
            total_api_calls: 4,
            used_credit_on_app: 300,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 141,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 141,
                    },
                },
                {
                    id: 83,
                    service_label: 'Albania SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: '2023-05-11 07:44:40',
                    updated_at: '2023-05-11 07:44:40',
                    slug: null,
                    has_subcategory: 0,
                    country_code: '355',
                    country_name: 'Albania',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 83,
                        app_access_id: 141,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 143,
            app_name: 'ghana-verification2',
            api_customer_id: 123,
            api_key: 'PNJN3DJBKPBOCF0NX0FNBBMNA3WCPWBR',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 143,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 144,
            app_name: 'horizonpay_verificaiton',
            api_customer_id: 123,
            api_key: 'B9NQA73C0PXL2P3W2YB4GV9O9J3OZB8C',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 144,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 144,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 144,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 145,
            app_name: 'test-elect_one',
            api_customer_id: 123,
            api_key: 'P3LUYBYACPHM125AXIE0M1DPS4CDN1UP',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 145,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 145,
                    },
                },
                {
                    id: 89,
                    service_label: 'Antarctica SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: '2023-05-11 07:44:40',
                    updated_at: '2023-05-11 07:44:40',
                    slug: null,
                    has_subcategory: 0,
                    country_code: '672',
                    country_name: 'Antarctica',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 89,
                        app_access_id: 145,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 146,
            app_name: 'abayomi',
            api_customer_id: 123,
            api_key: '6XME2BW7QBRFM829URICFKZOHYH843GI',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 2000,
            app_balance: 2000,
            is_active: 1,
            billing_type_id: 2,
            is_notified_for_min: 0,
            min_threshold: 100,
            is_notified_for_mid: 0,
            mid_threshold: 200,
            is_notified_for_max: 0,
            max_threshold: 400,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 146,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 146,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 146,
                    },
                },
            ],
            billing_type: {
                id: 2,
                billing_type_label: 'Bundle Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 147,
            app_name: 'utimumbank-cac',
            api_customer_id: 123,
            api_key: '3M52LZQ3BEHT59INNF8HYS46MPJMLONJ',
            total_api_calls: 1,
            used_credit_on_app: 150,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 147,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 147,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 147,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 147,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 147,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 147,
                    },
                },
                {
                    id: 7,
                    service_label: 'OCR Validation',
                    service_url: '/IdentityVerification/OCRValidation',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:02:29',
                    slug: 'ocr',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 7,
                        app_access_id: 147,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 147,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 147,
                    },
                },
                {
                    id: 15,
                    service_label: '(OTP) One Time Password',
                    service_url: '/Otp/send',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-09 06:30:05',
                    slug: 'mail',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 15,
                        app_access_id: 147,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 147,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 147,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 147,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 147,
                    },
                },
                {
                    id: 19,
                    service_label: 'Bulk (BVN) Bank verification Number',
                    service_url: '/v2/IdentityVerification/BVN-Upload',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:59',
                    slug: 'bulk_bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 19,
                        app_access_id: 147,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 147,
                    },
                },
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 147,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 147,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 147,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 147,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 147,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 147,
                    },
                },
                {
                    id: 29,
                    service_label: 'Gotv',
                    service_url: '/Billers/pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:35',
                    slug: 'gotv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 29,
                        app_access_id: 147,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 147,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 147,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 147,
                    },
                },
                {
                    id: 34,
                    service_label: 'BIN-Verification',
                    service_url: '/Onboarding/Kyc/Bin-lookUp',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bin-verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 34,
                        app_access_id: 147,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 147,
                    },
                },
                {
                    id: 36,
                    service_label: 'Know-Your-Customer (Advance)',
                    service_url: '/Onboarding/Kyc/NubanVerify',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'know-your-customer_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 36,
                        app_access_id: 147,
                    },
                },
                {
                    id: 38,
                    service_label: 'TIN Verification',
                    service_url: '/BusinessInformation/TIN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'tin_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 38,
                        app_access_id: 147,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 147,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 147,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 147,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 147,
                    },
                },
                {
                    id: 44,
                    service_label: '(GIP) Ghana Int Passport',
                    service_url: '/IdentityVerification/Ghana-PID',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-24 07:49:47',
                    slug: 'ghana_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 44,
                        app_access_id: 147,
                    },
                },
                {
                    id: 40,
                    service_label: 'Cabletv Plans',
                    service_url: '/Authorize/cabletv-plans',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'cabletv_plans',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 40,
                        app_access_id: 147,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 147,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 147,
                    },
                },
                {
                    id: 48,
                    service_label: 'CAC Basic Enquiry',
                    service_url: '/BusinessInformation/CAC/Basic',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:20',
                    slug: 'cac_basic_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 48,
                        app_access_id: 147,
                    },
                },
                {
                    id: 49,
                    service_label: 'CAC Advanced Enquiry',
                    service_url: '/BusinessInformation/CAC/Advance',
                    price_per_call: 120,
                    fail_over_price: 120,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:41',
                    slug: 'cac_advanced_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 49,
                        app_access_id: 147,
                    },
                },
                {
                    id: 50,
                    service_label: 'Ikeja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-02 12:02:48',
                    slug: 'ikeja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 50,
                        app_access_id: 147,
                    },
                },
                {
                    id: 51,
                    service_label: 'Aml',
                    service_url: '/Aml/Sanctions',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-29 07:03:35',
                    slug: 'aml',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 51,
                        app_access_id: 147,
                    },
                },
                {
                    id: 52,
                    service_label: 'Abuja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:12',
                    slug: 'abuja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 52,
                        app_access_id: 147,
                    },
                },
                {
                    id: 53,
                    service_label: 'Auto-Detect Operators',
                    service_url: '/api/AutoDetectOperators',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'auto-detect_operators',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 53,
                        app_access_id: 147,
                    },
                },
                {
                    id: 54,
                    service_label: 'Airtime International',
                    service_url: '/AirtimeInternational',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-11-08 14:53:21',
                    slug: 'airtime_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 54,
                        app_access_id: 147,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 147,
                    },
                },
                {
                    id: 55,
                    service_label: 'Data International',
                    service_url: '/DataInternational',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 09:28:13',
                    slug: 'data_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 55,
                        app_access_id: 147,
                    },
                },
                {
                    id: 56,
                    service_label: 'Enugu Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:23',
                    slug: 'enugu_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 56,
                        app_access_id: 147,
                    },
                },
                {
                    id: 57,
                    service_label: 'Ibadan Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:47',
                    slug: 'ibadan_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 57,
                        app_access_id: 147,
                    },
                },
                {
                    id: 58,
                    service_label: 'Jos Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:01',
                    slug: 'jos_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 58,
                        app_access_id: 147,
                    },
                },
                {
                    id: 59,
                    service_label: 'Kaduna Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:23',
                    slug: 'kaduna_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 59,
                        app_access_id: 147,
                    },
                },
                {
                    id: 60,
                    service_label: 'Kano Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-08 09:28:37',
                    slug: 'kano_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": false, "isElectricity": true}',
                    pivot: {
                        service_id: 60,
                        app_access_id: 147,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 147,
                    },
                },
                {
                    id: 62,
                    service_label: 'Port-Harcourt Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-15 16:33:55',
                    slug: 'portharcourt_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat_commission_exclusive',
                    commission: 2,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 62,
                        app_access_id: 147,
                    },
                },
                {
                    id: 64,
                    service_label: '(vNIN) Virtual National Identity Number',
                    service_url: '/IdentityVerification/vNIN',
                    price_per_call: 5000,
                    fail_over_price: 5000,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-28 11:18:42',
                    slug: '(vnin)_virtual_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 64,
                        app_access_id: 147,
                    },
                },
                {
                    id: 66,
                    service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-12-08 08:16:31',
                    slug: '(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 66,
                        app_access_id: 147,
                    },
                },
                {
                    id: 68,
                    service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic-Bulk',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-01-25 06:17:32',
                    slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 68,
                        app_access_id: 147,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 147,
                    },
                },
                {
                    id: 70,
                    service_label: '(cBVN) Bank Verification Number with Consent',
                    service_url: '/IdentityVerification/cBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(cbvn)_bank_verification_number_with_consent',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 70,
                        app_access_id: 147,
                    },
                },
                {
                    id: 72,
                    service_label: 'Ghana SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 15,
                    fail_over_price: 16,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:31:00',
                    slug: 'ghana_sms',
                    has_subcategory: 0,
                    country_code: '233',
                    country_name: 'Ghana',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 72,
                        app_access_id: 147,
                    },
                },
                {
                    id: 74,
                    service_label: 'South Africa SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:41:40',
                    slug: 'south_africa_sms',
                    has_subcategory: 0,
                    country_code: '27',
                    country_name: 'South Africa',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 74,
                        app_access_id: 147,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 147,
                    },
                },
                {
                    id: 315,
                    service_label: 'OTP Verification',
                    service_url: '/Otp/verify',
                    price_per_call: 0.1,
                    fail_over_price: 0.1,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-06 15:27:11',
                    slug: 'otp_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 315,
                        app_access_id: 147,
                    },
                },
                {
                    id: 65,
                    service_label: 'Betting',
                    service_url: '/BillPayment/BettingTopup',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 15,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-14 15:09:45',
                    slug: 'betting',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 65,
                        app_access_id: 147,
                    },
                },
                {
                    id: 316,
                    service_label: 'Kenya National ID',
                    service_url: '/IdentityVerification/KenyaNationalID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_national_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 316,
                        app_access_id: 147,
                    },
                },
                {
                    id: 39,
                    service_label: 'Startimes',
                    service_url: '/Billers/Pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:43',
                    slug: 'startimes',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 39,
                        app_access_id: 147,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 147,
                    },
                },
                {
                    id: 75,
                    service_label: 'Uganda SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:42:15',
                    slug: 'uganda_sms',
                    has_subcategory: 0,
                    country_code: '256',
                    country_name: 'Uganda',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 75,
                        app_access_id: 147,
                    },
                },
                {
                    id: 303,
                    service_label: 'United States SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: '2023-05-11 07:44:42',
                    updated_at: '2023-05-11 07:44:42',
                    slug: null,
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 303,
                        app_access_id: 147,
                    },
                },
                {
                    id: 78,
                    service_label: 'Internet',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'internet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 78,
                        app_access_id: 147,
                    },
                },
                {
                    id: 317,
                    service_label: 'Kenya Passport ID',
                    service_url: '/IdentityVerification/KenyaPassportID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_passport_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 317,
                        app_access_id: 147,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 152,
            app_name: 'soft-app-test',
            api_customer_id: 123,
            api_key: 'ADAFBSMGDWD1YT6WX8OQUUFW1WWQ01PT',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 152,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 152,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 152,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 152,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 153,
            app_name: 'testauditapp',
            api_customer_id: 123,
            api_key: 'B3L9LYBBHGTPR8D34C9WV36KFNHBQA4T',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 153,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 153,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 153,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 153,
                    },
                },
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 153,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 153,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 154,
            app_name: 'testauditappnew',
            api_customer_id: 123,
            api_key: 'VJ5YPJY9S6DSLJJUTYRNT1OZ5U3Q9RAL',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 154,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 154,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 154,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 154,
                    },
                },
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 154,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 154,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 155,
            app_name: 'testing',
            api_customer_id: 123,
            api_key: '6LJVIUDTLWQC2N5J7MQ3LAFG8TCOGR9L',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 100,
            app_balance: 100,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 155,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 156,
            app_name: 'new-service',
            api_customer_id: 123,
            api_key: 'ZTOOHXKVOF6DGJT1SKUNJ7KYBE6J76Y4',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 67,
                    service_label: 'self',
                    service_url: '/weklna',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 17,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-15 06:00:43',
                    slug: 'self',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 67,
                        app_access_id: 156,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 157,
            app_name: 'meritrade',
            api_customer_id: 123,
            api_key: 'PR7DZB82317D0H757UAALGWO9WF36N61',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 1000,
            app_balance: 1000,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 157,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 157,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 157,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 157,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 157,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 157,
                    },
                },
                {
                    id: 7,
                    service_label: 'OCR Validation',
                    service_url: '/IdentityVerification/OCRValidation',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:02:29',
                    slug: 'ocr',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 7,
                        app_access_id: 157,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 157,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 157,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 159,
            app_name: 'testing-biz-aml-verification-',
            api_customer_id: 123,
            api_key: 'QXUKOB34ZRAIK382IARTYK30PFBMZ3FK',
            total_api_calls: 2,
            used_credit_on_app: 150,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 38,
                    service_label: 'TIN Verification',
                    service_url: '/BusinessInformation/TIN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'tin_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 38,
                        app_access_id: 159,
                    },
                },
                {
                    id: 48,
                    service_label: 'CAC Basic Enquiry',
                    service_url: '/BusinessInformation/CAC/Basic',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:20',
                    slug: 'cac_basic_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 48,
                        app_access_id: 159,
                    },
                },
                {
                    id: 49,
                    service_label: 'CAC Advanced Enquiry',
                    service_url: '/BusinessInformation/CAC/Advance',
                    price_per_call: 120,
                    fail_over_price: 120,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:41',
                    slug: 'cac_advanced_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 49,
                        app_access_id: 159,
                    },
                },
                {
                    id: 51,
                    service_label: 'Aml',
                    service_url: '/Aml/Sanctions',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-29 07:03:35',
                    slug: 'aml',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 51,
                        app_access_id: 159,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 160,
            app_name: 'cba-app',
            api_customer_id: 123,
            api_key: '9BWFMD096VXXQFKOMRY5SV8QADZCT4JU',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 160,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 160,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 160,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 160,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 160,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 160,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 160,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 160,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 160,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 160,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 160,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 160,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 160,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 160,
                    },
                },
                {
                    id: 7,
                    service_label: 'OCR Validation',
                    service_url: '/IdentityVerification/OCRValidation',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:02:29',
                    slug: 'ocr',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 7,
                        app_access_id: 160,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 160,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 160,
                    },
                },
                {
                    id: 15,
                    service_label: '(OTP) One Time Password',
                    service_url: '/Otp/send',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-09 06:30:05',
                    slug: 'mail',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 15,
                        app_access_id: 160,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 160,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 160,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 160,
                    },
                },
                {
                    id: 19,
                    service_label: 'Bulk (BVN) Bank verification Number',
                    service_url: '/v2/IdentityVerification/BVN-Upload',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:59',
                    slug: 'bulk_bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 19,
                        app_access_id: 160,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 160,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 160,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 160,
                    },
                },
                {
                    id: 34,
                    service_label: 'BIN-Verification',
                    service_url: '/Onboarding/Kyc/Bin-lookUp',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bin-verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 34,
                        app_access_id: 160,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 160,
                    },
                },
                {
                    id: 36,
                    service_label: 'Know-Your-Customer (Advance)',
                    service_url: '/Onboarding/Kyc/NubanVerify',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'know-your-customer_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 36,
                        app_access_id: 160,
                    },
                },
                {
                    id: 38,
                    service_label: 'TIN Verification',
                    service_url: '/BusinessInformation/TIN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'tin_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 38,
                        app_access_id: 160,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 160,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 160,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 160,
                    },
                },
                {
                    id: 44,
                    service_label: '(GIP) Ghana Int Passport',
                    service_url: '/IdentityVerification/Ghana-PID',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-24 07:49:47',
                    slug: 'ghana_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 44,
                        app_access_id: 160,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 160,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 160,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 160,
                    },
                },
                {
                    id: 48,
                    service_label: 'CAC Basic Enquiry',
                    service_url: '/BusinessInformation/CAC/Basic',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:20',
                    slug: 'cac_basic_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 48,
                        app_access_id: 160,
                    },
                },
                {
                    id: 49,
                    service_label: 'CAC Advanced Enquiry',
                    service_url: '/BusinessInformation/CAC/Advance',
                    price_per_call: 120,
                    fail_over_price: 120,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:41',
                    slug: 'cac_advanced_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 49,
                        app_access_id: 160,
                    },
                },
                {
                    id: 51,
                    service_label: 'Aml',
                    service_url: '/Aml/Sanctions',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-29 07:03:35',
                    slug: 'aml',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 51,
                        app_access_id: 160,
                    },
                },
                {
                    id: 53,
                    service_label: 'Auto-Detect Operators',
                    service_url: '/api/AutoDetectOperators',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'auto-detect_operators',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 53,
                        app_access_id: 160,
                    },
                },
                {
                    id: 40,
                    service_label: 'Cabletv Plans',
                    service_url: '/Authorize/cabletv-plans',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'cabletv_plans',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 40,
                        app_access_id: 160,
                    },
                },
                {
                    id: 52,
                    service_label: 'Abuja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:12',
                    slug: 'abuja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 52,
                        app_access_id: 160,
                    },
                },
                {
                    id: 50,
                    service_label: 'Ikeja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-02 12:02:48',
                    slug: 'ikeja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 50,
                        app_access_id: 160,
                    },
                },
                {
                    id: 54,
                    service_label: 'Airtime International',
                    service_url: '/AirtimeInternational',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-11-08 14:53:21',
                    slug: 'airtime_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 54,
                        app_access_id: 160,
                    },
                },
                {
                    id: 64,
                    service_label: '(vNIN) Virtual National Identity Number',
                    service_url: '/IdentityVerification/vNIN',
                    price_per_call: 5000,
                    fail_over_price: 5000,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-28 11:18:42',
                    slug: '(vnin)_virtual_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 64,
                        app_access_id: 160,
                    },
                },
                {
                    id: 65,
                    service_label: 'Betting',
                    service_url: '/BillPayment/BettingTopup',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 15,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-14 15:09:45',
                    slug: 'betting',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 65,
                        app_access_id: 160,
                    },
                },
                {
                    id: 66,
                    service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-12-08 08:16:31',
                    slug: '(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 66,
                        app_access_id: 160,
                    },
                },
                {
                    id: 57,
                    service_label: 'Ibadan Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:47',
                    slug: 'ibadan_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 57,
                        app_access_id: 160,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 160,
                    },
                },
                {
                    id: 59,
                    service_label: 'Kaduna Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:23',
                    slug: 'kaduna_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 59,
                        app_access_id: 160,
                    },
                },
                {
                    id: 56,
                    service_label: 'Enugu Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:23',
                    slug: 'enugu_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 56,
                        app_access_id: 160,
                    },
                },
                {
                    id: 68,
                    service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic-Bulk',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-01-25 06:17:32',
                    slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 68,
                        app_access_id: 160,
                    },
                },
                {
                    id: 29,
                    service_label: 'Gotv',
                    service_url: '/Billers/pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:35',
                    slug: 'gotv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 29,
                        app_access_id: 160,
                    },
                },
                {
                    id: 55,
                    service_label: 'Data International',
                    service_url: '/DataInternational',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 09:28:13',
                    slug: 'data_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 55,
                        app_access_id: 160,
                    },
                },
                {
                    id: 70,
                    service_label: '(cBVN) Bank Verification Number with Consent',
                    service_url: '/IdentityVerification/cBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(cbvn)_bank_verification_number_with_consent',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 70,
                        app_access_id: 160,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 161,
            app_name: 'bundle_test_bfs',
            api_customer_id: 123,
            api_key: 'LGME3ITGZ798FHL2BU4ND1ATXT0DCEEU',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 161,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 161,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 161,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 162,
            app_name: 'qa-team',
            api_customer_id: 123,
            api_key: 'HUOE82EISHS7PVO3O58AFI3H1R0VP0YE',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 162,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 162,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 162,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 162,
                    },
                },
                {
                    id: 66,
                    service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-12-08 08:16:31',
                    slug: '(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 66,
                        app_access_id: 162,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 162,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 162,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 163,
            app_name: 'me43u3',
            api_customer_id: 123,
            api_key: 'TSRQDANGR62O0LC5NHL4HMMGNL9KRSC4',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 163,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 163,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 163,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 165,
            app_name: 'name01',
            api_customer_id: 123,
            api_key: '83N40FQ6RPEGIVQFO1UFWHX00A5ZIF2X',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 165,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 167,
            app_name: 'testfgg',
            api_customer_id: 123,
            api_key: 'CJAULHTLVVL26CBQ44ZMFPQ29NN56JP0',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: 0,
            is_notified_for_mid: 0,
            mid_threshold: 0,
            is_notified_for_max: 0,
            max_threshold: 0,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 167,
                    },
                },
                {
                    id: 29,
                    service_label: 'Gotv',
                    service_url: '/Billers/pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:35',
                    slug: 'gotv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 29,
                        app_access_id: 167,
                    },
                },
                {
                    id: 36,
                    service_label: 'Know-Your-Customer (Advance)',
                    service_url: '/Onboarding/Kyc/NubanVerify',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'know-your-customer_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 36,
                        app_access_id: 167,
                    },
                },
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 167,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 167,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 167,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 167,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 167,
                    },
                },
                {
                    id: 7,
                    service_label: 'OCR Validation',
                    service_url: '/IdentityVerification/OCRValidation',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:02:29',
                    slug: 'ocr',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 7,
                        app_access_id: 167,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 167,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 167,
                    },
                },
                {
                    id: 15,
                    service_label: '(OTP) One Time Password',
                    service_url: '/Otp/send',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-09 06:30:05',
                    slug: 'mail',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 15,
                        app_access_id: 167,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 167,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 167,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 167,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 167,
                    },
                },
                {
                    id: 19,
                    service_label: 'Bulk (BVN) Bank verification Number',
                    service_url: '/v2/IdentityVerification/BVN-Upload',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:59',
                    slug: 'bulk_bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 19,
                        app_access_id: 167,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 167,
                    },
                },
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 167,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 167,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 167,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 167,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 167,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 167,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 167,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 167,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 167,
                    },
                },
                {
                    id: 34,
                    service_label: 'BIN-Verification',
                    service_url: '/Onboarding/Kyc/Bin-lookUp',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bin-verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 34,
                        app_access_id: 167,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 167,
                    },
                },
                {
                    id: 38,
                    service_label: 'TIN Verification',
                    service_url: '/BusinessInformation/TIN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'tin_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 38,
                        app_access_id: 167,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 167,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 167,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 167,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 167,
                    },
                },
                {
                    id: 44,
                    service_label: '(GIP) Ghana Int Passport',
                    service_url: '/IdentityVerification/Ghana-PID',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-24 07:49:47',
                    slug: 'ghana_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 44,
                        app_access_id: 167,
                    },
                },
                {
                    id: 40,
                    service_label: 'Cabletv Plans',
                    service_url: '/Authorize/cabletv-plans',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'cabletv_plans',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 40,
                        app_access_id: 167,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 167,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 167,
                    },
                },
                {
                    id: 48,
                    service_label: 'CAC Basic Enquiry',
                    service_url: '/BusinessInformation/CAC/Basic',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:20',
                    slug: 'cac_basic_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 48,
                        app_access_id: 167,
                    },
                },
                {
                    id: 49,
                    service_label: 'CAC Advanced Enquiry',
                    service_url: '/BusinessInformation/CAC/Advance',
                    price_per_call: 120,
                    fail_over_price: 120,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:41',
                    slug: 'cac_advanced_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 49,
                        app_access_id: 167,
                    },
                },
                {
                    id: 50,
                    service_label: 'Ikeja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-02 12:02:48',
                    slug: 'ikeja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 50,
                        app_access_id: 167,
                    },
                },
                {
                    id: 51,
                    service_label: 'Aml',
                    service_url: '/Aml/Sanctions',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-29 07:03:35',
                    slug: 'aml',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 51,
                        app_access_id: 167,
                    },
                },
                {
                    id: 52,
                    service_label: 'Abuja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:12',
                    slug: 'abuja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 52,
                        app_access_id: 167,
                    },
                },
                {
                    id: 53,
                    service_label: 'Auto-Detect Operators',
                    service_url: '/api/AutoDetectOperators',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'auto-detect_operators',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 53,
                        app_access_id: 167,
                    },
                },
                {
                    id: 54,
                    service_label: 'Airtime International',
                    service_url: '/AirtimeInternational',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-11-08 14:53:21',
                    slug: 'airtime_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 54,
                        app_access_id: 167,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 167,
                    },
                },
                {
                    id: 55,
                    service_label: 'Data International',
                    service_url: '/DataInternational',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 09:28:13',
                    slug: 'data_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 55,
                        app_access_id: 167,
                    },
                },
                {
                    id: 56,
                    service_label: 'Enugu Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:23',
                    slug: 'enugu_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 56,
                        app_access_id: 167,
                    },
                },
                {
                    id: 57,
                    service_label: 'Ibadan Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:47',
                    slug: 'ibadan_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 57,
                        app_access_id: 167,
                    },
                },
                {
                    id: 58,
                    service_label: 'Jos Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:01',
                    slug: 'jos_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 58,
                        app_access_id: 167,
                    },
                },
                {
                    id: 59,
                    service_label: 'Kaduna Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:23',
                    slug: 'kaduna_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 59,
                        app_access_id: 167,
                    },
                },
                {
                    id: 60,
                    service_label: 'Kano Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-08 09:28:37',
                    slug: 'kano_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": false, "isElectricity": true}',
                    pivot: {
                        service_id: 60,
                        app_access_id: 167,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 167,
                    },
                },
                {
                    id: 62,
                    service_label: 'Port-Harcourt Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-15 16:33:55',
                    slug: 'portharcourt_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat_commission_exclusive',
                    commission: 2,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 62,
                        app_access_id: 167,
                    },
                },
                {
                    id: 64,
                    service_label: '(vNIN) Virtual National Identity Number',
                    service_url: '/IdentityVerification/vNIN',
                    price_per_call: 5000,
                    fail_over_price: 5000,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-28 11:18:42',
                    slug: '(vnin)_virtual_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 64,
                        app_access_id: 167,
                    },
                },
                {
                    id: 66,
                    service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-12-08 08:16:31',
                    slug: '(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 66,
                        app_access_id: 167,
                    },
                },
                {
                    id: 68,
                    service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic-Bulk',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-01-25 06:17:32',
                    slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 68,
                        app_access_id: 167,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 167,
                    },
                },
                {
                    id: 70,
                    service_label: '(cBVN) Bank Verification Number with Consent',
                    service_url: '/IdentityVerification/cBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(cbvn)_bank_verification_number_with_consent',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 70,
                        app_access_id: 167,
                    },
                },
                {
                    id: 72,
                    service_label: 'Ghana SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 15,
                    fail_over_price: 16,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:31:00',
                    slug: 'ghana_sms',
                    has_subcategory: 0,
                    country_code: '233',
                    country_name: 'Ghana',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 72,
                        app_access_id: 167,
                    },
                },
                {
                    id: 74,
                    service_label: 'South Africa SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:41:40',
                    slug: 'south_africa_sms',
                    has_subcategory: 0,
                    country_code: '27',
                    country_name: 'South Africa',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 74,
                        app_access_id: 167,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 167,
                    },
                },
                {
                    id: 315,
                    service_label: 'OTP Verification',
                    service_url: '/Otp/verify',
                    price_per_call: 0.1,
                    fail_over_price: 0.1,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-06 15:27:11',
                    slug: 'otp_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 315,
                        app_access_id: 167,
                    },
                },
                {
                    id: 65,
                    service_label: 'Betting',
                    service_url: '/BillPayment/BettingTopup',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 15,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-14 15:09:45',
                    slug: 'betting',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 65,
                        app_access_id: 167,
                    },
                },
                {
                    id: 316,
                    service_label: 'Kenya National ID',
                    service_url: '/IdentityVerification/KenyaNationalID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_national_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 316,
                        app_access_id: 167,
                    },
                },
                {
                    id: 39,
                    service_label: 'Startimes',
                    service_url: '/Billers/Pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:43',
                    slug: 'startimes',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 39,
                        app_access_id: 167,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 167,
                    },
                },
                {
                    id: 75,
                    service_label: 'Uganda SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:42:15',
                    slug: 'uganda_sms',
                    has_subcategory: 0,
                    country_code: '256',
                    country_name: 'Uganda',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 75,
                        app_access_id: 167,
                    },
                },
                {
                    id: 303,
                    service_label: 'United States SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: '2023-05-11 07:44:42',
                    updated_at: '2023-05-11 07:44:42',
                    slug: null,
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 303,
                        app_access_id: 167,
                    },
                },
                {
                    id: 78,
                    service_label: 'Internet',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'internet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 78,
                        app_access_id: 167,
                    },
                },
                {
                    id: 317,
                    service_label: 'Kenya Passport ID',
                    service_url: '/IdentityVerification/KenyaPassportID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_passport_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 317,
                        app_access_id: 167,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 169,
            app_name: 'testing345',
            api_customer_id: 123,
            api_key: '5FEE5826KC9OLTQ6JRYJKSFC60K25WWW',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 2,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 169,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 169,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 169,
                    },
                },
            ],
            billing_type: {
                id: 2,
                billing_type_label: 'Bundle Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 170,
            app_name: 'demo-app',
            api_customer_id: 123,
            api_key: 'LM1NY4AY5ECWHAQOSMYTOHCL2NVVA31X',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 0,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 170,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 171,
            app_name: 'testttt',
            api_customer_id: 123,
            api_key: '7XYFS2K7WMX2H2VVCWC8KJGIO5X0R2R5',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 0,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 171,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 171,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 171,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 171,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 171,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 171,
                    },
                },
                {
                    id: 7,
                    service_label: 'OCR Validation',
                    service_url: '/IdentityVerification/OCRValidation',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:02:29',
                    slug: 'ocr',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 7,
                        app_access_id: 171,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 171,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 171,
                    },
                },
                {
                    id: 15,
                    service_label: '(OTP) One Time Password',
                    service_url: '/Otp/send',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-09 06:30:05',
                    slug: 'mail',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 15,
                        app_access_id: 171,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 171,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 171,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 171,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 171,
                    },
                },
                {
                    id: 19,
                    service_label: 'Bulk (BVN) Bank verification Number',
                    service_url: '/v2/IdentityVerification/BVN-Upload',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:59',
                    slug: 'bulk_bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 19,
                        app_access_id: 171,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 171,
                    },
                },
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 171,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 171,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 171,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 171,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 171,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 171,
                    },
                },
                {
                    id: 29,
                    service_label: 'Gotv',
                    service_url: '/Billers/pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:35',
                    slug: 'gotv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 29,
                        app_access_id: 171,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 171,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 171,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 171,
                    },
                },
                {
                    id: 34,
                    service_label: 'BIN-Verification',
                    service_url: '/Onboarding/Kyc/Bin-lookUp',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bin-verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 34,
                        app_access_id: 171,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 171,
                    },
                },
                {
                    id: 36,
                    service_label: 'Know-Your-Customer (Advance)',
                    service_url: '/Onboarding/Kyc/NubanVerify',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'know-your-customer_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 36,
                        app_access_id: 171,
                    },
                },
                {
                    id: 38,
                    service_label: 'TIN Verification',
                    service_url: '/BusinessInformation/TIN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'tin_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 38,
                        app_access_id: 171,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 171,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 171,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 171,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 171,
                    },
                },
                {
                    id: 44,
                    service_label: '(GIP) Ghana Int Passport',
                    service_url: '/IdentityVerification/Ghana-PID',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-24 07:49:47',
                    slug: 'ghana_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 44,
                        app_access_id: 171,
                    },
                },
                {
                    id: 40,
                    service_label: 'Cabletv Plans',
                    service_url: '/Authorize/cabletv-plans',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'cabletv_plans',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 40,
                        app_access_id: 171,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 171,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 171,
                    },
                },
                {
                    id: 48,
                    service_label: 'CAC Basic Enquiry',
                    service_url: '/BusinessInformation/CAC/Basic',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:20',
                    slug: 'cac_basic_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 48,
                        app_access_id: 171,
                    },
                },
                {
                    id: 49,
                    service_label: 'CAC Advanced Enquiry',
                    service_url: '/BusinessInformation/CAC/Advance',
                    price_per_call: 120,
                    fail_over_price: 120,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:41',
                    slug: 'cac_advanced_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 49,
                        app_access_id: 171,
                    },
                },
                {
                    id: 50,
                    service_label: 'Ikeja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-02 12:02:48',
                    slug: 'ikeja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 50,
                        app_access_id: 171,
                    },
                },
                {
                    id: 51,
                    service_label: 'Aml',
                    service_url: '/Aml/Sanctions',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-29 07:03:35',
                    slug: 'aml',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 51,
                        app_access_id: 171,
                    },
                },
                {
                    id: 52,
                    service_label: 'Abuja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:12',
                    slug: 'abuja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 52,
                        app_access_id: 171,
                    },
                },
                {
                    id: 53,
                    service_label: 'Auto-Detect Operators',
                    service_url: '/api/AutoDetectOperators',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'auto-detect_operators',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 53,
                        app_access_id: 171,
                    },
                },
                {
                    id: 54,
                    service_label: 'Airtime International',
                    service_url: '/AirtimeInternational',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-11-08 14:53:21',
                    slug: 'airtime_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 54,
                        app_access_id: 171,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 171,
                    },
                },
                {
                    id: 55,
                    service_label: 'Data International',
                    service_url: '/DataInternational',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 09:28:13',
                    slug: 'data_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 55,
                        app_access_id: 171,
                    },
                },
                {
                    id: 56,
                    service_label: 'Enugu Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:23',
                    slug: 'enugu_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 56,
                        app_access_id: 171,
                    },
                },
                {
                    id: 57,
                    service_label: 'Ibadan Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:47',
                    slug: 'ibadan_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 57,
                        app_access_id: 171,
                    },
                },
                {
                    id: 58,
                    service_label: 'Jos Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:01',
                    slug: 'jos_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 58,
                        app_access_id: 171,
                    },
                },
                {
                    id: 59,
                    service_label: 'Kaduna Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:23',
                    slug: 'kaduna_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 59,
                        app_access_id: 171,
                    },
                },
                {
                    id: 60,
                    service_label: 'Kano Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-08 09:28:37',
                    slug: 'kano_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": false, "isElectricity": true}',
                    pivot: {
                        service_id: 60,
                        app_access_id: 171,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 171,
                    },
                },
                {
                    id: 62,
                    service_label: 'Port-Harcourt Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-15 16:33:55',
                    slug: 'portharcourt_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat_commission_exclusive',
                    commission: 2,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 62,
                        app_access_id: 171,
                    },
                },
                {
                    id: 64,
                    service_label: '(vNIN) Virtual National Identity Number',
                    service_url: '/IdentityVerification/vNIN',
                    price_per_call: 5000,
                    fail_over_price: 5000,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-28 11:18:42',
                    slug: '(vnin)_virtual_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 64,
                        app_access_id: 171,
                    },
                },
                {
                    id: 66,
                    service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-12-08 08:16:31',
                    slug: '(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 66,
                        app_access_id: 171,
                    },
                },
                {
                    id: 68,
                    service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic-Bulk',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-01-25 06:17:32',
                    slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 68,
                        app_access_id: 171,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 171,
                    },
                },
                {
                    id: 70,
                    service_label: '(cBVN) Bank Verification Number with Consent',
                    service_url: '/IdentityVerification/cBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(cbvn)_bank_verification_number_with_consent',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 70,
                        app_access_id: 171,
                    },
                },
                {
                    id: 72,
                    service_label: 'Ghana SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 15,
                    fail_over_price: 16,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:31:00',
                    slug: 'ghana_sms',
                    has_subcategory: 0,
                    country_code: '233',
                    country_name: 'Ghana',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 72,
                        app_access_id: 171,
                    },
                },
                {
                    id: 74,
                    service_label: 'South Africa SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:41:40',
                    slug: 'south_africa_sms',
                    has_subcategory: 0,
                    country_code: '27',
                    country_name: 'South Africa',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 74,
                        app_access_id: 171,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 171,
                    },
                },
                {
                    id: 315,
                    service_label: 'OTP Verification',
                    service_url: '/Otp/verify',
                    price_per_call: 0.1,
                    fail_over_price: 0.1,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-06 15:27:11',
                    slug: 'otp_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 315,
                        app_access_id: 171,
                    },
                },
                {
                    id: 65,
                    service_label: 'Betting',
                    service_url: '/BillPayment/BettingTopup',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 15,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-14 15:09:45',
                    slug: 'betting',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 65,
                        app_access_id: 171,
                    },
                },
                {
                    id: 316,
                    service_label: 'Kenya National ID',
                    service_url: '/IdentityVerification/KenyaNationalID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_national_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 316,
                        app_access_id: 171,
                    },
                },
                {
                    id: 39,
                    service_label: 'Startimes',
                    service_url: '/Billers/Pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:43',
                    slug: 'startimes',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 39,
                        app_access_id: 171,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 171,
                    },
                },
                {
                    id: 75,
                    service_label: 'Uganda SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:42:15',
                    slug: 'uganda_sms',
                    has_subcategory: 0,
                    country_code: '256',
                    country_name: 'Uganda',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 75,
                        app_access_id: 171,
                    },
                },
                {
                    id: 303,
                    service_label: 'United States SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: '2023-05-11 07:44:42',
                    updated_at: '2023-05-11 07:44:42',
                    slug: null,
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 303,
                        app_access_id: 171,
                    },
                },
                {
                    id: 78,
                    service_label: 'Internet',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'internet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 78,
                        app_access_id: 171,
                    },
                },
                {
                    id: 317,
                    service_label: 'Kenya Passport ID',
                    service_url: '/IdentityVerification/KenyaPassportID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_passport_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 317,
                        app_access_id: 171,
                    },
                },
                {
                    id: 328,
                    service_label: '(pBVN) Bank Verification Number with Phone',
                    service_url: '/IdentityVerification/pBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-17 14:39:35',
                    slug: '(pbvn)_bank_verification_number_with_phone',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 328,
                        app_access_id: 171,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 172,
            app_name: 'testt',
            api_customer_id: 123,
            api_key: 'ULZV998Y2CUSBXWT96KT4BV69VLITW45',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 2,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 172,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 172,
                    },
                },
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 172,
                    },
                },
                {
                    id: 4,
                    service_label: '(DL) Drivers Licence',
                    service_url: '/IdentityVerification/DriverLicence',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:00',
                    slug: 'driver_licence',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 4,
                        app_access_id: 172,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 172,
                    },
                },
                {
                    id: 6,
                    service_label: 'Address Verification',
                    service_url: '/IdentityVerification/Address',
                    price_per_call: 1000,
                    fail_over_price: 1000,
                    api_service_category_id: 2,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-18 05:09:57',
                    slug: 'address_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 6,
                        app_access_id: 172,
                    },
                },
                {
                    id: 7,
                    service_label: 'OCR Validation',
                    service_url: '/IdentityVerification/OCRValidation',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:02:29',
                    slug: 'ocr',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 7,
                        app_access_id: 172,
                    },
                },
                {
                    id: 8,
                    service_label: 'Liveness Check',
                    service_url: '/IdentityVerification/LivenessCheck',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-03 20:30:44',
                    slug: 'liveness_check',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 8,
                        app_access_id: 172,
                    },
                },
                {
                    id: 12,
                    service_label: 'Bank Account Verification',
                    service_url: '/IdentityVerification/BankAccountVerify',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:23',
                    slug: 'bank_account_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 12,
                        app_access_id: 172,
                    },
                },
                {
                    id: 15,
                    service_label: '(OTP) One Time Password',
                    service_url: '/Otp/send',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-09 06:30:05',
                    slug: 'mail',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 15,
                        app_access_id: 172,
                    },
                },
                {
                    id: 11,
                    service_label: '(Num2Bank) Number To Bank Listing',
                    service_url: '/IdentityVerification/NumberToBankListing',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'num_to_bank',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: null,
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 11,
                        app_access_id: 172,
                    },
                },
                {
                    id: 16,
                    service_label: 'SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:28:17',
                    slug: 'sms',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 16,
                        app_access_id: 172,
                    },
                },
                {
                    id: 10,
                    service_label: '(iBVN) Bank Verification Number with Image',
                    service_url: '/IdentityVerification/iBVN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-29 20:50:50',
                    slug: 'ibvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 10,
                        app_access_id: 172,
                    },
                },
                {
                    id: 18,
                    service_label: 'Document Capture',
                    service_url: '/IdentityVerification/DocumentCapture',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 3,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-09 10:13:53',
                    slug: 'ocr_capture',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 18,
                        app_access_id: 172,
                    },
                },
                {
                    id: 19,
                    service_label: 'Bulk (BVN) Bank verification Number',
                    service_url: '/v2/IdentityVerification/BVN-Upload',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:59',
                    slug: 'bulk_bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 19,
                        app_access_id: 172,
                    },
                },
                {
                    id: 20,
                    service_label: 'Bulk(NIN) National Identity Number',
                    service_url: '/V2/IdentityVerification/NIN-Bulk/',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-12 04:27:56',
                    slug: 'bulk(nin)_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 20,
                        app_access_id: 172,
                    },
                },
                {
                    id: 17,
                    service_label: 'Kenya SMS',
                    service_url: '/Messaging/SMS',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 07:13:39',
                    slug: 'kenya_sms',
                    has_subcategory: 0,
                    country_code: '254',
                    country_name: 'Kenya',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 17,
                        app_access_id: 172,
                    },
                },
                {
                    id: 25,
                    service_label: 'Phone Number Verification',
                    service_url: '/CustomerInformation/verifyMobileCarrier',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 15:09:08',
                    slug: 'phone_number_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 25,
                        app_access_id: 172,
                    },
                },
                {
                    id: 26,
                    service_label: 'Phone Number Verification (Standard)',
                    service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-09 20:09:58',
                    slug: 'phone_number_verification_(standard)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 26,
                        app_access_id: 172,
                    },
                },
                {
                    id: 27,
                    service_label: 'Liveness Check (Advanced)',
                    service_url: '/IdentityVerification/LivenessCheckAdvanced',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-16 17:44:15',
                    slug: 'liveness_check_advanced',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 27,
                        app_access_id: 172,
                    },
                },
                {
                    id: 28,
                    service_label: 'DsTv',
                    service_url: '/V2/Billers/pay',
                    price_per_call: 1.6,
                    fail_over_price: 1.6,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 08:14:22',
                    slug: 'dstv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 28,
                        app_access_id: 172,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 172,
                    },
                },
                {
                    id: 29,
                    service_label: 'Gotv',
                    service_url: '/Billers/pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:35',
                    slug: 'gotv',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 29,
                        app_access_id: 172,
                    },
                },
                {
                    id: 30,
                    service_label: 'Airtime',
                    service_url: '/BillPayment/AirtimeTopup',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'airtime',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 30,
                        app_access_id: 172,
                    },
                },
                {
                    id: 32,
                    service_label: 'Phone Number Verification - Bulk',
                    service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                    price_per_call: 25,
                    fail_over_price: 25,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-10 00:18:24',
                    slug: 'phone_number_verification_(bulk)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 32,
                        app_access_id: 172,
                    },
                },
                {
                    id: 31,
                    service_label: 'Data',
                    service_url: '/BillPayment/DataTopup',
                    price_per_call: 3,
                    fail_over_price: 3,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'data',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 31,
                        app_access_id: 172,
                    },
                },
                {
                    id: 34,
                    service_label: 'BIN-Verification',
                    service_url: '/Onboarding/Kyc/Bin-lookUp',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bin-verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 34,
                        app_access_id: 172,
                    },
                },
                {
                    id: 35,
                    service_label: 'BVN Matching',
                    service_url: '/Onboarding/Kyc/verifySingleBVN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'bvn_matching',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 35,
                        app_access_id: 172,
                    },
                },
                {
                    id: 36,
                    service_label: 'Know-Your-Customer (Advance)',
                    service_url: '/Onboarding/Kyc/NubanVerify',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'know-your-customer_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 36,
                        app_access_id: 172,
                    },
                },
                {
                    id: 38,
                    service_label: 'TIN Verification',
                    service_url: '/BusinessInformation/TIN',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'tin_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 38,
                        app_access_id: 172,
                    },
                },
                {
                    id: 33,
                    service_label: 'Know Your Customer (Basic)',
                    service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 6,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-27 06:04:42',
                    slug: 'phone_number_verification_(advance)',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 33,
                        app_access_id: 172,
                    },
                },
                {
                    id: 41,
                    service_label: 'Smile',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'smile',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 41,
                        app_access_id: 172,
                    },
                },
                {
                    id: 42,
                    service_label: 'Spectranet',
                    service_url: '/Billers/Pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 10,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'spectranet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 42,
                        app_access_id: 172,
                    },
                },
                {
                    id: 43,
                    service_label: '(GVC) Ghana Voters Card',
                    service_url: '/IdentityVerification/Ghana-VID',
                    price_per_call: 35,
                    fail_over_price: 35,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-26 18:14:31',
                    slug: 'ghana_voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 43,
                        app_access_id: 172,
                    },
                },
                {
                    id: 44,
                    service_label: '(GIP) Ghana Int Passport',
                    service_url: '/IdentityVerification/Ghana-PID',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-24 07:49:47',
                    slug: 'ghana_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 44,
                        app_access_id: 172,
                    },
                },
                {
                    id: 40,
                    service_label: 'Cabletv Plans',
                    service_url: '/Authorize/cabletv-plans',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'cabletv_plans',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 40,
                        app_access_id: 172,
                    },
                },
                {
                    id: 46,
                    service_label: '(GSSNIT) Ghana SNNIT',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gssnit)_ghana_snnit',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 46,
                        app_access_id: 172,
                    },
                },
                {
                    id: 47,
                    service_label: '(GDL) Ghana Drivers License',
                    service_url: '/IdentityVerification/Ghana-COC',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(gdl)_ghana_drivers_license',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 47,
                        app_access_id: 172,
                    },
                },
                {
                    id: 48,
                    service_label: 'CAC Basic Enquiry',
                    service_url: '/BusinessInformation/CAC/Basic',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:20',
                    slug: 'cac_basic_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 48,
                        app_access_id: 172,
                    },
                },
                {
                    id: 49,
                    service_label: 'CAC Advanced Enquiry',
                    service_url: '/BusinessInformation/CAC/Advance',
                    price_per_call: 120,
                    fail_over_price: 120,
                    api_service_category_id: 8,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-04 10:08:41',
                    slug: 'cac_advanced_enquiry',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 49,
                        app_access_id: 172,
                    },
                },
                {
                    id: 50,
                    service_label: 'Ikeja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-02 12:02:48',
                    slug: 'ikeja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 50,
                        app_access_id: 172,
                    },
                },
                {
                    id: 51,
                    service_label: 'Aml',
                    service_url: '/Aml/Sanctions',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-29 07:03:35',
                    slug: 'aml',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 51,
                        app_access_id: 172,
                    },
                },
                {
                    id: 52,
                    service_label: 'Abuja Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 23,
                    fail_over_price: 32,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:12',
                    slug: 'abuja_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 52,
                        app_access_id: 172,
                    },
                },
                {
                    id: 53,
                    service_label: 'Auto-Detect Operators',
                    service_url: '/api/AutoDetectOperators',
                    price_per_call: 0,
                    fail_over_price: 0,
                    api_service_category_id: 13,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'auto-detect_operators',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 53,
                        app_access_id: 172,
                    },
                },
                {
                    id: 54,
                    service_label: 'Airtime International',
                    service_url: '/AirtimeInternational',
                    price_per_call: 4,
                    fail_over_price: 4,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-11-08 14:53:21',
                    slug: 'airtime_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 54,
                        app_access_id: 172,
                    },
                },
                {
                    id: 45,
                    service_label: 'SSNITID',
                    service_url: '/IdentityVerification/Ghana-FSSNO',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 14,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'ssnitid',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 45,
                        app_access_id: 172,
                    },
                },
                {
                    id: 55,
                    service_label: 'Data International',
                    service_url: '/DataInternational',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 12,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-10 09:28:13',
                    slug: 'data_international',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 55,
                        app_access_id: 172,
                    },
                },
                {
                    id: 56,
                    service_label: 'Enugu Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:23',
                    slug: 'enugu_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 56,
                        app_access_id: 172,
                    },
                },
                {
                    id: 57,
                    service_label: 'Ibadan Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:40:47',
                    slug: 'ibadan_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 57,
                        app_access_id: 172,
                    },
                },
                {
                    id: 58,
                    service_label: 'Jos Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:01',
                    slug: 'jos_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 58,
                        app_access_id: 172,
                    },
                },
                {
                    id: 59,
                    service_label: 'Kaduna Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:23',
                    slug: 'kaduna_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 59,
                        app_access_id: 172,
                    },
                },
                {
                    id: 60,
                    service_label: 'Kano Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-08 09:28:37',
                    slug: 'kano_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": false, "isElectricity": true}',
                    pivot: {
                        service_id: 60,
                        app_access_id: 172,
                    },
                },
                {
                    id: 61,
                    service_label: 'Eko Disco',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 5,
                    fail_over_price: 5,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-27 18:41:47',
                    slug: 'eko_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 61,
                        app_access_id: 172,
                    },
                },
                {
                    id: 62,
                    service_label: 'Port-Harcourt Disco',
                    service_url: '/https://dev-billers.blusalt.net/api/v1/electricity/purchase',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 11,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-15 16:33:55',
                    slug: 'portharcourt_disco',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat_commission_exclusive',
                    commission: 2,
                    metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                    pivot: {
                        service_id: 62,
                        app_access_id: 172,
                    },
                },
                {
                    id: 64,
                    service_label: '(vNIN) Virtual National Identity Number',
                    service_url: '/IdentityVerification/vNIN',
                    price_per_call: 5000,
                    fail_over_price: 5000,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-28 11:18:42',
                    slug: '(vnin)_virtual_national_identity_number',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 64,
                        app_access_id: 172,
                    },
                },
                {
                    id: 66,
                    service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic',
                    price_per_call: 70,
                    fail_over_price: 70,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-12-08 08:16:31',
                    slug: '(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 66,
                        app_access_id: 172,
                    },
                },
                {
                    id: 68,
                    service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN-Basic-Bulk',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-01-25 06:17:32',
                    slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 68,
                        app_access_id: 172,
                    },
                },
                {
                    id: 69,
                    service_label: 'Body Outlining',
                    service_url: '/IdentityVerification/BodyOutline',
                    price_per_call: 50,
                    fail_over_price: 50,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'body_outlining',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 69,
                        app_access_id: 172,
                    },
                },
                {
                    id: 70,
                    service_label: '(cBVN) Bank Verification Number with Consent',
                    service_url: '/IdentityVerification/cBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: '(cbvn)_bank_verification_number_with_consent',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 70,
                        app_access_id: 172,
                    },
                },
                {
                    id: 72,
                    service_label: 'Ghana SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 15,
                    fail_over_price: 16,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-15 07:31:00',
                    slug: 'ghana_sms',
                    has_subcategory: 0,
                    country_code: '233',
                    country_name: 'Ghana',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 72,
                        app_access_id: 172,
                    },
                },
                {
                    id: 74,
                    service_label: 'South Africa SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:41:40',
                    slug: 'south_africa_sms',
                    has_subcategory: 0,
                    country_code: '27',
                    country_name: 'South Africa',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 74,
                        app_access_id: 172,
                    },
                },
                {
                    id: 81,
                    service_label: 'International SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 30,
                    fail_over_price: 30,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-12 17:10:40',
                    slug: 'default_sms',
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 81,
                        app_access_id: 172,
                    },
                },
                {
                    id: 315,
                    service_label: 'OTP Verification',
                    service_url: '/Otp/verify',
                    price_per_call: 0.1,
                    fail_over_price: 0.1,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-06-06 15:27:11',
                    slug: 'otp_verification',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 315,
                        app_access_id: 172,
                    },
                },
                {
                    id: 65,
                    service_label: 'Betting',
                    service_url: '/BillPayment/BettingTopup',
                    price_per_call: 10,
                    fail_over_price: 10,
                    api_service_category_id: 15,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-04-14 15:09:45',
                    slug: 'betting',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 65,
                        app_access_id: 172,
                    },
                },
                {
                    id: 316,
                    service_label: 'Kenya National ID',
                    service_url: '/IdentityVerification/KenyaNationalID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_national_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 316,
                        app_access_id: 172,
                    },
                },
                {
                    id: 39,
                    service_label: 'Startimes',
                    service_url: '/Billers/Pay',
                    price_per_call: 1.5,
                    fail_over_price: 1.5,
                    api_service_category_id: 9,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-18 13:45:43',
                    slug: 'startimes',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission_inclusive',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 39,
                        app_access_id: 172,
                    },
                },
                {
                    id: 9,
                    service_label: 'Facial Comparison',
                    service_url: '/IdentityVerification/FacialComparison',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 4,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-02-02 15:05:16',
                    slug: 'facial_comparison',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 9,
                        app_access_id: 172,
                    },
                },
                {
                    id: 75,
                    service_label: 'Uganda SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-05-11 07:42:15',
                    slug: 'uganda_sms',
                    has_subcategory: 0,
                    country_code: '256',
                    country_name: 'Uganda',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 75,
                        app_access_id: 172,
                    },
                },
                {
                    id: 303,
                    service_label: 'United States SMS',
                    service_url: '/Messaging/Sms',
                    price_per_call: 75,
                    fail_over_price: 75,
                    api_service_category_id: 5,
                    is_deleted: 0,
                    created_at: '2023-05-11 07:44:42',
                    updated_at: '2023-05-11 07:44:42',
                    slug: null,
                    has_subcategory: 0,
                    country_code: '1',
                    country_name: 'United States',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 303,
                        app_access_id: 172,
                    },
                },
                {
                    id: 78,
                    service_label: 'Internet',
                    service_url: '/v2/Billers/pay',
                    price_per_call: 2,
                    fail_over_price: 2,
                    api_service_category_id: 16,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'internet',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'commission',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 78,
                        app_access_id: 172,
                    },
                },
                {
                    id: 317,
                    service_label: 'Kenya Passport ID',
                    service_url: '/IdentityVerification/KenyaPassportID',
                    price_per_call: 100,
                    fail_over_price: 110,
                    api_service_category_id: 19,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: null,
                    slug: 'kenya_passport_id',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 317,
                        app_access_id: 172,
                    },
                },
                {
                    id: 328,
                    service_label: '(pBVN) Bank Verification Number with Phone',
                    service_url: '/IdentityVerification/pBVN',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-08-17 14:39:35',
                    slug: '(pbvn)_bank_verification_number_with_phone',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 328,
                        app_access_id: 172,
                    },
                },
            ],
            billing_type: {
                id: 2,
                billing_type_label: 'Bundle Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 173,
            app_name: 'test_app_app',
            api_customer_id: 123,
            api_key: 'J79IAB6Y3MCNFEKQ4VNH2X0IWK4KTQAB',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 11000,
            app_balance: 11000,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: 550,
            is_notified_for_mid: 0,
            mid_threshold: 1100,
            is_notified_for_max: 0,
            max_threshold: 2200,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 173,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 173,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 174,
            app_name: 'test-limit-app',
            api_customer_id: 123,
            api_key: 'DZJT3QMYMAN3O92VP2ILK9YTVTFF60OR',
            total_api_calls: 10,
            used_credit_on_app: 1080,
            api_credit_limit: 1100,
            app_balance: 20,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: 9,
            is_notified_for_mid: 0,
            mid_threshold: 17,
            is_notified_for_max: 1,
            max_threshold: 34,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 174,
                    },
                },
                {
                    id: 21,
                    service_label: '(pNIN) NIN Verification with Msisdn',
                    service_url: '/IdentityVerification/pNIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:20:29',
                    slug: '(pnin)_nin_verification_with_msisdn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 21,
                        app_access_id: 174,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 175,
            app_name: 'test-limit-2',
            api_customer_id: 123,
            api_key: 'HFKJQB1ZM93BUV5PVRCGLJI0ML8Z3PNN',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 10000,
            app_balance: 10000,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 1,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 175,
                    },
                },
                {
                    id: 2,
                    service_label: '(NIN) National Identity Number',
                    service_url: '/IdentityVerification/NIN',
                    price_per_call: 150,
                    fail_over_price: 150,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-10-05 21:19:44',
                    slug: 'nin',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 2,
                        app_access_id: 175,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 176,
            app_name: 'tetsing-mic',
            api_customer_id: 123,
            api_key: 'FPLDVH7Z2Q2EMO5G5KE5158A9PBC4WFU',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 176,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 189,
            app_name: 'h-h-h-h-hvvvvv',
            api_customer_id: 123,
            api_key: 'TQBV0QN278O3N0JO20SBB603YFIVVNX9',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 3,
                    service_label: '(PVC) Permanent Voters Card',
                    service_url: '/IdentityVerification/PVC',
                    price_per_call: 150,
                    fail_over_price: 151,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-03-28 08:36:45',
                    slug: 'voters_card',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 3,
                        app_access_id: 189,
                    },
                },
                {
                    id: 5,
                    service_label: '(NIP) Nigerian International Passport',
                    service_url: '/IdentityVerification/NIP',
                    price_per_call: 100,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2022-09-30 07:43:27',
                    slug: 'internation_passport',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 5,
                        app_access_id: 189,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
        {
            id: 205,
            app_name: 'kya-palmpay',
            api_customer_id: 123,
            api_key: 'TFUF7GY3RDXRZYC0DL3XL92R0R8PIIKQ',
            total_api_calls: 0,
            used_credit_on_app: 0,
            api_credit_limit: 0,
            app_balance: 0,
            is_active: 1,
            billing_type_id: 1,
            is_notified_for_min: 0,
            min_threshold: null,
            is_notified_for_mid: 0,
            mid_threshold: null,
            is_notified_for_max: 0,
            max_threshold: null,
            is_app_credit_limited: 0,
            app_services: [
                {
                    id: 1,
                    service_label: '(BVN) Bank Verification Number',
                    service_url: '/IdentityVerification/BVN',
                    price_per_call: 120,
                    fail_over_price: 100,
                    api_service_category_id: 1,
                    is_deleted: 0,
                    created_at: null,
                    updated_at: '2023-07-30 14:46:53',
                    slug: 'bvn',
                    has_subcategory: 0,
                    country_code: '234',
                    country_name: 'Nigeria',
                    charge_type: 'flat',
                    commission: null,
                    metadata: null,
                    pivot: {
                        service_id: 1,
                        app_access_id: 205,
                    },
                },
            ],
            billing_type: {
                id: 1,
                billing_type_label: 'Single Service Billing',
                created_at: null,
                updated_at: null,
            },
        },
    ],
    app: {
        id: 121,
        app_name: 'coreapp',
        api_customer_id: 123,
        api_key: 'PZ5OQ8GAWUE4EBL11KMVHT69GVLFVMCO',
        total_api_calls: 5282,
        used_credit_on_app: 549873.875,
        api_credit_limit: 0,
        app_balance: 0,
        is_active: 1,
        billing_type_id: 1,
        is_notified_for_min: 0,
        min_threshold: null,
        is_notified_for_mid: 0,
        mid_threshold: null,
        is_notified_for_max: 0,
        max_threshold: null,
        is_app_credit_limited: 0,
        app_services: [
            {
                id: 17,
                service_label: 'Kenya SMS',
                service_url: '/Messaging/SMS',
                price_per_call: 75,
                fail_over_price: 75,
                api_service_category_id: 5,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-05-18 07:13:39',
                slug: 'kenya_sms',
                has_subcategory: 0,
                country_code: '254',
                country_name: 'Kenya',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 17,
                    app_access_id: 121,
                },
            },
            {
                id: 18,
                service_label: 'Document Capture',
                service_url: '/IdentityVerification/DocumentCapture',
                price_per_call: 100,
                fail_over_price: 100,
                api_service_category_id: 3,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-08-09 10:13:53',
                slug: 'ocr_capture',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 18,
                    app_access_id: 121,
                },
            },
            {
                id: 16,
                service_label: 'SMS',
                service_url: '/Messaging/Sms',
                price_per_call: 4,
                fail_over_price: 4,
                api_service_category_id: 5,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-05-15 07:28:17',
                slug: 'sms',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 16,
                    app_access_id: 121,
                },
            },
            {
                id: 1,
                service_label: '(BVN) Bank Verification Number',
                service_url: '/IdentityVerification/BVN',
                price_per_call: 120,
                fail_over_price: 100,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-07-30 14:46:53',
                slug: 'bvn',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 1,
                    app_access_id: 121,
                },
            },
            {
                id: 2,
                service_label: '(NIN) National Identity Number',
                service_url: '/IdentityVerification/NIN',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-05 21:19:44',
                slug: 'nin',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 2,
                    app_access_id: 121,
                },
            },
            {
                id: 12,
                service_label: 'Bank Account Verification',
                service_url: '/IdentityVerification/BankAccountVerify',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-05 21:19:23',
                slug: 'bank_account_verification',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 12,
                    app_access_id: 121,
                },
            },
            {
                id: 11,
                service_label: '(Num2Bank) Number To Bank Listing',
                service_url: '/IdentityVerification/NumberToBankListing',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'num_to_bank',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: null,
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 11,
                    app_access_id: 121,
                },
            },
            {
                id: 26,
                service_label: 'Phone Number Verification (Standard)',
                service_url: '/CustomerInformation/verifyMobileCarrierStandard',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 6,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-09 20:09:58',
                slug: 'phone_number_verification_(standard)',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 26,
                    app_access_id: 121,
                },
            },
            {
                id: 10,
                service_label: '(iBVN) Bank Verification Number with Image',
                service_url: '/IdentityVerification/iBVN',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-09-29 20:50:50',
                slug: 'ibvn',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 10,
                    app_access_id: 121,
                },
            },
            {
                id: 5,
                service_label: '(NIP) Nigerian International Passport',
                service_url: '/IdentityVerification/NIP',
                price_per_call: 100,
                fail_over_price: 100,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-09-30 07:43:27',
                slug: 'internation_passport',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 5,
                    app_access_id: 121,
                },
            },
            {
                id: 3,
                service_label: '(PVC) Permanent Voters Card',
                service_url: '/IdentityVerification/PVC',
                price_per_call: 150,
                fail_over_price: 151,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-03-28 08:36:45',
                slug: 'voters_card',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 3,
                    app_access_id: 121,
                },
            },
            {
                id: 21,
                service_label: '(pNIN) NIN Verification with Msisdn',
                service_url: '/IdentityVerification/pNIN',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-05 21:20:29',
                slug: '(pnin)_nin_verification_with_msisdn',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 21,
                    app_access_id: 121,
                },
            },
            {
                id: 4,
                service_label: '(DL) Drivers Licence',
                service_url: '/IdentityVerification/DriverLicence',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-05 21:19:00',
                slug: 'driver_licence',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 4,
                    app_access_id: 121,
                },
            },
            {
                id: 9,
                service_label: 'Facial Comparison',
                service_url: '/IdentityVerification/FacialComparison',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 4,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-02-02 15:05:16',
                slug: 'facial_comparison',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 9,
                    app_access_id: 121,
                },
            },
            {
                id: 7,
                service_label: 'OCR Validation',
                service_url: '/IdentityVerification/OCRValidation',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 3,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-02-02 15:02:29',
                slug: 'ocr',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 7,
                    app_access_id: 121,
                },
            },
            {
                id: 8,
                service_label: 'Liveness Check',
                service_url: '/IdentityVerification/LivenessCheck',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 4,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-03 20:30:44',
                slug: 'liveness_check',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 8,
                    app_access_id: 121,
                },
            },
            {
                id: 15,
                service_label: '(OTP) One Time Password',
                service_url: '/Otp/send',
                price_per_call: 5,
                fail_over_price: 5,
                api_service_category_id: 5,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-06-09 06:30:05',
                slug: 'mail',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 15,
                    app_access_id: 121,
                },
            },
            {
                id: 28,
                service_label: 'DsTv',
                service_url: '/V2/Billers/pay',
                price_per_call: 1.6,
                fail_over_price: 1.6,
                api_service_category_id: 9,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-08-10 08:14:22',
                slug: 'dstv',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 28,
                    app_access_id: 121,
                },
            },
            {
                id: 29,
                service_label: 'Gotv',
                service_url: '/Billers/pay',
                price_per_call: 1.5,
                fail_over_price: 1.5,
                api_service_category_id: 9,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-05-18 13:45:35',
                slug: 'gotv',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 29,
                    app_access_id: 121,
                },
            },
            {
                id: 30,
                service_label: 'Airtime',
                service_url: '/BillPayment/AirtimeTopup',
                price_per_call: 2,
                fail_over_price: 2,
                api_service_category_id: 12,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'airtime',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 30,
                    app_access_id: 121,
                },
            },
            {
                id: 31,
                service_label: 'Data',
                service_url: '/BillPayment/DataTopup',
                price_per_call: 3,
                fail_over_price: 3,
                api_service_category_id: 12,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'data',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 31,
                    app_access_id: 121,
                },
            },
            {
                id: 32,
                service_label: 'Phone Number Verification - Bulk',
                service_url: '/CustomerInformationBulk/verifyMobileCarrierBulk',
                price_per_call: 25,
                fail_over_price: 25,
                api_service_category_id: 6,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-10 00:18:24',
                slug: 'phone_number_verification_(bulk)',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 32,
                    app_access_id: 121,
                },
            },
            {
                id: 34,
                service_label: 'BIN-Verification',
                service_url: '/Onboarding/Kyc/Bin-lookUp',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 6,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'bin-verification',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 34,
                    app_access_id: 121,
                },
            },
            {
                id: 35,
                service_label: 'BVN Matching',
                service_url: '/Onboarding/Kyc/verifySingleBVN',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 6,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'bvn_matching',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 35,
                    app_access_id: 121,
                },
            },
            {
                id: 36,
                service_label: 'Know-Your-Customer (Advance)',
                service_url: '/Onboarding/Kyc/NubanVerify',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 6,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'know-your-customer_(advance)',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 36,
                    app_access_id: 121,
                },
            },
            {
                id: 38,
                service_label: 'TIN Verification',
                service_url: '/BusinessInformation/TIN',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 8,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'tin_verification',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 38,
                    app_access_id: 121,
                },
            },
            {
                id: 41,
                service_label: 'Smile',
                service_url: '/Billers/Pay',
                price_per_call: 2,
                fail_over_price: 2,
                api_service_category_id: 10,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'smile',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 41,
                    app_access_id: 121,
                },
            },
            {
                id: 42,
                service_label: 'Spectranet',
                service_url: '/Billers/Pay',
                price_per_call: 2,
                fail_over_price: 2,
                api_service_category_id: 10,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'spectranet',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 42,
                    app_access_id: 121,
                },
            },
            {
                id: 43,
                service_label: '(GVC) Ghana Voters Card',
                service_url: '/IdentityVerification/Ghana-VID',
                price_per_call: 35,
                fail_over_price: 35,
                api_service_category_id: 14,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-02-26 18:14:31',
                slug: 'ghana_voters_card',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 43,
                    app_access_id: 121,
                },
            },
            {
                id: 44,
                service_label: '(GIP) Ghana Int Passport',
                service_url: '/IdentityVerification/Ghana-PID',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 14,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-02-24 07:49:47',
                slug: 'ghana_passport',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 44,
                    app_access_id: 121,
                },
            },
            {
                id: 45,
                service_label: 'SSNITID',
                service_url: '/IdentityVerification/Ghana-FSSNO',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 14,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'ssnitid',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 45,
                    app_access_id: 121,
                },
            },
            {
                id: 46,
                service_label: '(GSSNIT) Ghana SNNIT',
                service_url: '/IdentityVerification/Ghana-FSSNO',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 14,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: '(gssnit)_ghana_snnit',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 46,
                    app_access_id: 121,
                },
            },
            {
                id: 48,
                service_label: 'CAC Basic Enquiry',
                service_url: '/BusinessInformation/CAC/Basic',
                price_per_call: 100,
                fail_over_price: 100,
                api_service_category_id: 8,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-04 10:08:20',
                slug: 'cac_basic_enquiry',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 48,
                    app_access_id: 121,
                },
            },
            {
                id: 49,
                service_label: 'CAC Advanced Enquiry',
                service_url: '/BusinessInformation/CAC/Advance',
                price_per_call: 120,
                fail_over_price: 120,
                api_service_category_id: 8,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-04 10:08:41',
                slug: 'cac_advanced_enquiry',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 49,
                    app_access_id: 121,
                },
            },
            {
                id: 51,
                service_label: 'Aml',
                service_url: '/Aml/Sanctions',
                price_per_call: 100,
                fail_over_price: 100,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-29 07:03:35',
                slug: 'aml',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 51,
                    app_access_id: 121,
                },
            },
            {
                id: 27,
                service_label: 'Liveness Check (Advanced)',
                service_url: '/IdentityVerification/LivenessCheckAdvanced',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 4,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-16 17:44:15',
                slug: 'liveness_check_advanced',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 27,
                    app_access_id: 121,
                },
            },
            {
                id: 52,
                service_label: 'Abuja Disco',
                service_url: '/v2/Billers/pay',
                price_per_call: 23,
                fail_over_price: 32,
                api_service_category_id: 11,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-27 18:40:12',
                slug: 'abuja_disco',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission',
                commission: null,
                metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                pivot: {
                    service_id: 52,
                    app_access_id: 121,
                },
            },
            {
                id: 50,
                service_label: 'Ikeja Disco',
                service_url: '/v2/Billers/pay',
                price_per_call: 23,
                fail_over_price: 32,
                api_service_category_id: 11,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-08-02 12:02:48',
                slug: 'ikeja_disco',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission',
                commission: null,
                metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                pivot: {
                    service_id: 50,
                    app_access_id: 121,
                },
            },
            {
                id: 54,
                service_label: 'Airtime International',
                service_url: '/AirtimeInternational',
                price_per_call: 4,
                fail_over_price: 4,
                api_service_category_id: 12,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-11-08 14:53:21',
                slug: 'airtime_international',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 54,
                    app_access_id: 121,
                },
            },
            {
                id: 47,
                service_label: '(GDL) Ghana Drivers License',
                service_url: '/IdentityVerification/Ghana-COC',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 14,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: '(gdl)_ghana_drivers_license',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 47,
                    app_access_id: 121,
                },
            },
            {
                id: 65,
                service_label: 'Betting',
                service_url: '/BillPayment/BettingTopup',
                price_per_call: 10,
                fail_over_price: 10,
                api_service_category_id: 15,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-14 15:09:45',
                slug: 'betting',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 65,
                    app_access_id: 121,
                },
            },
            {
                id: 66,
                service_label: '(pNIN-Basic) NIN Verification with Msisdn',
                service_url: '/IdentityVerification/pNIN-Basic',
                price_per_call: 70,
                fail_over_price: 70,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-12-08 08:16:31',
                slug: '(pnin-basic)_nin_verification_with_msisdn',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 66,
                    app_access_id: 121,
                },
            },
            {
                id: 68,
                service_label: 'Bulk (pNIN-Basic) NIN Verification with Msisdn',
                service_url: '/IdentityVerification/pNIN-Basic-Bulk',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-01-25 06:17:32',
                slug: 'bulk_(pnin-basic)_nin_verification_with_msisdn',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 68,
                    app_access_id: 121,
                },
            },
            {
                id: 55,
                service_label: 'Data International',
                service_url: '/DataInternational',
                price_per_call: 5,
                fail_over_price: 5,
                api_service_category_id: 12,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-08-10 09:28:13',
                slug: 'data_international',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission_inclusive',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 55,
                    app_access_id: 121,
                },
            },
            {
                id: 70,
                service_label: '(cBVN) Bank Verification Number with Consent',
                service_url: '/IdentityVerification/cBVN',
                price_per_call: 100,
                fail_over_price: 100,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: '(cbvn)_bank_verification_number_with_consent',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 70,
                    app_access_id: 121,
                },
            },
            {
                id: 6,
                service_label: 'Address Verification',
                service_url: '/IdentityVerification/Address',
                price_per_call: 1000,
                fail_over_price: 1000,
                api_service_category_id: 2,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-18 05:09:57',
                slug: 'address_verification',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 6,
                    app_access_id: 121,
                },
            },
            {
                id: 64,
                service_label: '(vNIN) Virtual National Identity Number',
                service_url: '/IdentityVerification/vNIN',
                price_per_call: 5000,
                fail_over_price: 5000,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-08-28 11:18:42',
                slug: '(vnin)_virtual_national_identity_number',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 64,
                    app_access_id: 121,
                },
            },
            {
                id: 19,
                service_label: 'Bulk (BVN) Bank verification Number',
                service_url: '/v2/IdentityVerification/BVN-Upload',
                price_per_call: 150,
                fail_over_price: 150,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-05 21:19:59',
                slug: 'bulk_bvn',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 19,
                    app_access_id: 121,
                },
            },
            {
                id: 20,
                service_label: 'Bulk(NIN) National Identity Number',
                service_url: '/V2/IdentityVerification/NIN-Bulk/',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-12 04:27:56',
                slug: 'bulk(nin)_national_identity_number',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 20,
                    app_access_id: 121,
                },
            },
            {
                id: 57,
                service_label: 'Ibadan Disco',
                service_url: '/v2/Billers/pay',
                price_per_call: 2,
                fail_over_price: 2,
                api_service_category_id: 11,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-27 18:40:47',
                slug: 'ibadan_disco',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission',
                commission: null,
                metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                pivot: {
                    service_id: 57,
                    app_access_id: 121,
                },
            },
            {
                id: 61,
                service_label: 'Eko Disco',
                service_url: '/v2/Billers/pay',
                price_per_call: 5,
                fail_over_price: 5,
                api_service_category_id: 11,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-27 18:41:47',
                slug: 'eko_disco',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission',
                commission: null,
                metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                pivot: {
                    service_id: 61,
                    app_access_id: 121,
                },
            },
            {
                id: 56,
                service_label: 'Enugu Disco',
                service_url: '/v2/Billers/pay',
                price_per_call: 10,
                fail_over_price: 10,
                api_service_category_id: 11,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-04-27 18:40:23',
                slug: 'enugu_disco',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission',
                commission: null,
                metadata: '{"prepaid": true, "postpaid": true, "isElectricity": true}',
                pivot: {
                    service_id: 56,
                    app_access_id: 121,
                },
            },
            {
                id: 69,
                service_label: 'Body Outlining',
                service_url: '/IdentityVerification/BodyOutline',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 4,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'body_outlining',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 69,
                    app_access_id: 121,
                },
            },
            {
                id: 25,
                service_label: 'Phone Number Verification',
                service_url: '/CustomerInformation/verifyMobileCarrier',
                price_per_call: 50,
                fail_over_price: 50,
                api_service_category_id: 6,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-09-30 15:09:08',
                slug: 'phone_number_verification',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 25,
                    app_access_id: 121,
                },
            },
            {
                id: 33,
                service_label: 'Know Your Customer (Basic)',
                service_url: '/Onboarding/Kyc/VerifyMobileNumber',
                price_per_call: 70,
                fail_over_price: 70,
                api_service_category_id: 6,
                is_deleted: 0,
                created_at: null,
                updated_at: '2022-10-27 06:04:42',
                slug: 'phone_number_verification_(advance)',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 33,
                    app_access_id: 121,
                },
            },
            {
                id: 316,
                service_label: 'Kenya National ID',
                service_url: '/IdentityVerification/KenyaNationalID',
                price_per_call: 100,
                fail_over_price: 110,
                api_service_category_id: 19,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'kenya_national_id',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 316,
                    app_access_id: 121,
                },
            },
            {
                id: 74,
                service_label: 'South Africa SMS',
                service_url: '/Messaging/Sms',
                price_per_call: 75,
                fail_over_price: 75,
                api_service_category_id: 5,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-05-11 07:41:40',
                slug: 'south_africa_sms',
                has_subcategory: 0,
                country_code: '27',
                country_name: 'South Africa',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 74,
                    app_access_id: 121,
                },
            },
            {
                id: 72,
                service_label: 'Ghana SMS',
                service_url: '/Messaging/Sms',
                price_per_call: 15,
                fail_over_price: 16,
                api_service_category_id: 5,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-05-15 07:31:00',
                slug: 'ghana_sms',
                has_subcategory: 0,
                country_code: '233',
                country_name: 'Ghana',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 72,
                    app_access_id: 121,
                },
            },
            {
                id: 317,
                service_label: 'Kenya Passport ID',
                service_url: '/IdentityVerification/KenyaPassportID',
                price_per_call: 100,
                fail_over_price: 110,
                api_service_category_id: 19,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'kenya_passport_id',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 317,
                    app_access_id: 121,
                },
            },
            {
                id: 328,
                service_label: '(pBVN) Bank Verification Number with Phone',
                service_url: '/IdentityVerification/pBVN',
                price_per_call: 100,
                fail_over_price: 100,
                api_service_category_id: 1,
                is_deleted: 0,
                created_at: null,
                updated_at: '2023-08-17 14:39:35',
                slug: '(pbvn)_bank_verification_number_with_phone',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'flat',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 328,
                    app_access_id: 121,
                },
            },
            {
                id: 53,
                service_label: 'Auto-Detect Operators',
                service_url: '/api/AutoDetectOperators',
                price_per_call: 0,
                fail_over_price: 0,
                api_service_category_id: 13,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
                slug: 'auto-detect_operators',
                has_subcategory: 0,
                country_code: '234',
                country_name: 'Nigeria',
                charge_type: 'commission',
                commission: null,
                metadata: null,
                pivot: {
                    service_id: 53,
                    app_access_id: 121,
                },
            },
        ],
    },
};
