import styled from 'styled-components';

export const SpinnerContainer = styled.div`
    position: relative;
    .ring {
        width: 160px;
        padding: 16px;
        background: var(--primaryFull);
        aspect-ratio: 1;
        border-radius: 50%;
        -webkit-mask: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        box-sizing: border-box;
        animation: r 2s linear infinite;

        &.medium {
            width: 100px;
            padding: 20px;
        }

        &.secondary {
            background: var(--secondaryFull);
        }
    }
    .ring-text {
        position: absolute;
        top: 75px;
        left: 50px;
        font-weight: 600;
        font-size: 13px;
    }

    @keyframes r {
        to {
            transform: rotate(1turn);
        }
    }
`;
