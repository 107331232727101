import {useState} from 'react';
import moment from 'moment';
import {CloseIcon, InfoCircleErrorIcon} from 'assets/icons';
import {
    Alert,
    Button,
    CustomSelect,
    FormValidator,
    CountrySelect,
    CustomInputBox,
    CustomDatePicker,
    CustomPhoneInput,
    CustomFileDropzone,
} from 'utils';
import {RegistrationTitle} from './RegistrationTitle';
import {useBusinessRegistration} from './RegistrationContent';
import {CustomInputBoxStyles} from 'utils/CustomInputBox/CustomInputBoxStyles';
import {useRegisterBusiness} from 'hooks';
import {
    getError,
    fetchValues,
    getFormData,
    timeDateFormat,
    appendPlusToNumber,
} from 'utils/helpers';
import {businessRegistrationSteps, genderOptions, idTypesOptions} from '../data';

const defaultValues = {
    first_name: '',
    last_name: '',
    gender: '',
    nationality: '',
    dob: '',
    phone_number: '',
    bvn: '',
    email: '',
    signature: '',
    means_of_id: '',
    passport_pic: '',
    id_type: '',
    id_issued_date: '',
    id_expiry_date: '',
    id_number: '',
};

export const RegistrationPersonalInformation = () => {
    const {setCurrentStep, result, handleStep} = useBusinessRegistration();
    const [formValues, setFormValues] = useState({
        ...defaultValues,
        ...fetchValues(defaultValues, result),
    });
    const [formErrors, setFormErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState();

    const {mutate, isLoading} = useRegisterBusiness({
        onMutate: () => setErrorMsg(),
        onSuccess: ({data, status}) => {
            if (
                (status === 200 || status === 201) &&
                (data?.status_code === 200 || data?.status_code === 201)
            ) {
                handleStep({
                    step: businessRegistrationSteps.PROPRIETORS_ADDRESS.step,
                    data: {
                        ...formValues,
                        completed_step: Math.max(
                            result?.completed_step || 0,
                            parseInt(data?.data?.completed_step)
                        ),
                        request_id: data?.data?.request_id,
                        personal_information_result: data?.data,
                    },
                });
            } else setErrorMsg(data?.message);
        },
        onError: error => setErrorMsg(getError(error)),
    });

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    const handleFile = (name, value) => {
        const file = value instanceof File ? value : value.target.files[0];
        handleSelect(name, file);
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const goBack = () => setCurrentStep(businessRegistrationSteps.NAME_CHECK.step);

    const handleSubmit = e => {
        e.preventDefault();

        const payload = {
            ...formValues,
        };

        if (payload?.id_type?.value !== 'national_id') {
            payload.id_issued_date = timeDateFormat(formValues?.id_issued_date).date;
            payload.id_expiry_date = timeDateFormat(formValues?.id_expiry_date).date;
        } else {
            delete payload?.id_issued_date;
            delete payload?.id_expiry_date;
        }

        const {isValid, formErrors} = FormValidator(payload, 2);

        if (!isValid) return setFormErrors(formErrors);

        const age = moment().diff(moment(formValues.dob, 'YYYY/MM/DD'), 'years');

        if (age < 18) return setFormErrors({dob: 'You must be at least 18 years old'});

        const data = {
            ...payload,
            step: '2',
            id_type: payload?.id_type?.value,
            gender: formValues?.gender?.value,
            business_name: result?.business_name,
            nationality: formValues?.nationality?.label,
            dob: timeDateFormat(formValues?.dob, 'YYYY/MM/DD').date,
            phone_number: appendPlusToNumber(formValues?.phone_number),
        };

        if ((result?.completed_step || 0) >= 2) {
            data.request_id = result?.request_id;
            data.backStep = '2';
            delete data?.step;
            delete data?.business_name;
        }

        const formData = getFormData(data);

        mutate(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <RegistrationTitle title="Personal Information" subtitle="Tell us about yourself" />
            {errorMsg && (
                <div className="pb-4">
                    <Alert message={errorMsg} onClose={() => setErrorMsg()} />
                </div>
            )}
            <div className="overflow-auto" style={{maxHeight: '300px'}}>
                <div className="row mt-4">
                    <div className="col-lg-6 col-12">
                        <CustomInputBox
                            label="First Name"
                            placeholder="First Name"
                            name="first_name"
                            onChange={handleChange}
                            value={formValues?.first_name}
                            error={formErrors?.first_name}
                        />
                    </div>
                    <div className="col-lg-6 col-12">
                        <CustomInputBox
                            label="Last Name"
                            placeholder="Last Name"
                            name="last_name"
                            onChange={handleChange}
                            value={formValues?.last_name}
                            error={formErrors?.last_name}
                        />
                    </div>
                    <div className="col-12">
                        <CustomSelect
                            label="Gender"
                            placeholder="Gender"
                            name="gender"
                            onChange={value => handleSelect('gender', value)}
                            value={formValues?.gender}
                            error={formErrors?.gender}
                            options={genderOptions}
                        />
                    </div>
                    <div className="col-12">
                        <CustomDatePicker
                            dateInputBordered
                            error={formErrors?.dob}
                            label="Date of Birth"
                            name="dob"
                            onChange={value => handleSelect('dob', value)}
                            placeholder="DD MM YYYY"
                            value={formValues?.dob}
                            maxDate={new Date(moment().subtract(18, 'years').format())}
                        />
                    </div>
                    <div className="col-12">
                        <CountrySelect
                            label="Nationality"
                            placeholder="Select Country"
                            name="nationality"
                            onChange={value => handleSelect('nationality', value)}
                            value={formValues?.nationality}
                            error={formErrors?.nationality}
                        />
                    </div>
                    <div className="col-12">
                        <CustomPhoneInput
                            name="phone_number"
                            label="Phone Number"
                            onChange={value => handleSelect('phone_number', value)}
                            value={formValues?.phone_number}
                            error={formErrors.phone_number}
                        />
                    </div>
                    <div className="col-12">
                        <CustomInputBox
                            type="email"
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            placeholder="Enter Email"
                            value={formValues?.email}
                            error={formErrors.email}
                        />
                    </div>
                    <div className="col-12">
                        <CustomInputBox
                            label="BVN"
                            name="bvn"
                            onChange={handleChange}
                            placeholder="Enter BVN Number"
                            value={formValues?.bvn}
                            error={formErrors.bvn}
                            type="number"
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <CustomSelect
                            label="ID Type"
                            name="id_type"
                            placeholder="ID Type"
                            value={formValues?.id_type}
                            error={formErrors?.id_type}
                            onChange={value => handleSelect('id_type', value)}
                            options={idTypesOptions}
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <CustomInputBox
                            label="ID Number"
                            name="id_number"
                            placeholder="ID Number"
                            value={formValues?.id_number}
                            onChange={handleChange}
                            error={formErrors?.id_number}
                        />
                    </div>
                    {formValues?.id_type?.value !== 'national_id' && (
                        <>
                            <div className="col-12 col-lg-6">
                                <CustomDatePicker
                                    label="Date Issued"
                                    placeholder="Date Issued"
                                    name="id_issued_date"
                                    value={formValues?.id_issued_date}
                                    dateInputBordered={true}
                                    dateIconColored={true}
                                    onChange={value => handleSelect('id_issued_date', value)}
                                    error={formErrors?.id_issued_date}
                                    max={new Date()}
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <CustomDatePicker
                                    label="Expiry Date"
                                    placeholder="Expiry Date"
                                    name="id_expiry_date"
                                    value={formValues?.id_expiry_date}
                                    dateInputBordered={true}
                                    dateIconColored={true}
                                    error={formErrors?.id_expiry_date}
                                    onChange={value => handleSelect('id_expiry_date', value)}
                                    min={new Date()}
                                />
                            </div>
                        </>
                    )}
                    <div className="col-12">
                        {formValues?.means_of_id ? (
                            <CustomInputBoxStyles className="mb-4">
                                <div className="position-relative w-100">
                                    <img
                                        src={URL.createObjectURL(formValues?.means_of_id)}
                                        alt=""
                                        className="w-100 object-fit-cover"
                                        height="155px"
                                    />
                                    <CloseIcon
                                        color="var(--errorFull)"
                                        onClick={() => handleSelect('means_of_id')}
                                        className="close position-absolute end-0 top-0 pointer"
                                    />
                                </div>
                                {typeof formErrors?.means_of_id === 'string' &&
                                    formErrors?.means_of_id && (
                                        <small className="input-text-error">
                                            <InfoCircleErrorIcon className="me-2" />
                                            {formErrors?.means_of_id}
                                        </small>
                                    )}
                            </CustomInputBoxStyles>
                        ) : (
                            <CustomFileDropzone
                                label="Means of ID"
                                error={formErrors?.means_of_id}
                                accept={['image/*']}
                                onChange={value => handleFile('means_of_id', value)}
                            />
                        )}
                    </div>
                    <div className="col-12">
                        {formValues?.passport_pic ? (
                            <CustomInputBoxStyles className="mb-4">
                                <div className="position-relative w-100">
                                    <img
                                        src={URL.createObjectURL(formValues?.passport_pic)}
                                        alt=""
                                        className="w-100 object-fit-cover"
                                        height="155px"
                                    />
                                    <CloseIcon
                                        color="var(--errorFull)"
                                        onClick={() => handleSelect('passport_pic')}
                                        className="close position-absolute end-0 top-0 pointer"
                                    />
                                </div>
                                {typeof formErrors?.passport_pic === 'string' &&
                                    formErrors?.passport_pic && (
                                        <small className="input-text-error">
                                            <InfoCircleErrorIcon className="me-2" />
                                            {formErrors?.passport_pic}
                                        </small>
                                    )}
                            </CustomInputBoxStyles>
                        ) : (
                            <CustomFileDropzone
                                label="Passport"
                                error={formErrors?.passport_pic}
                                accept={['image/*']}
                                onChange={value => handleFile('passport_pic', value)}
                            />
                        )}
                    </div>
                    <div className="col-12">
                        {formValues?.signature ? (
                            <CustomInputBoxStyles className="mb-4">
                                <div className="position-relative w-100">
                                    <img
                                        src={URL.createObjectURL(formValues?.signature)}
                                        alt=""
                                        className="w-100 object-fit-cover"
                                        height="155px"
                                    />
                                    <CloseIcon
                                        onClick={() => handleSelect('signature')}
                                        color="var(--errorFull)"
                                        className="close position-absolute end-0 top-0 pointer"
                                    />
                                </div>
                                {typeof formErrors?.signature === 'string' &&
                                    formErrors?.signature && (
                                        <small className="input-text-error">
                                            <InfoCircleErrorIcon className="me-2" />
                                            {formErrors?.signature}
                                        </small>
                                    )}
                            </CustomInputBoxStyles>
                        ) : (
                            <CustomFileDropzone
                                label="Signature"
                                error={formErrors?.signature}
                                accept={['image/*']}
                                onChange={value => handleFile('signature', value)}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex gap-3 mt-5">
                {(result?.completed_step || 0) <= 2 && (
                    <Button
                        cls="btn w-100 btn-lg btn--gray"
                        name="Back"
                        handleClick={goBack}
                        disabled={isLoading}
                    />
                )}
                <Button cls="btn w-100 btn-lg" type="submit" name="Proceed" disabled={isLoading} />
            </div>
        </form>
    );
};
