export const InfoCircleErrorIcon = ({color = '#E31E18', width = '13', height = '13', ...rest}) => (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...rest}>
        <path
            d="M6.50004 12.3226C3.28796 12.3226 0.677124 9.71176 0.677124 6.49967C0.677124 3.28759 3.28796 0.676758 6.50004 0.676758C9.71212 0.676758 12.323 3.28759 12.323 6.49967C12.323 9.71176 9.71212 12.3226 6.50004 12.3226ZM6.50004 1.48926C3.73754 1.48926 1.48962 3.73717 1.48962 6.49967C1.48962 9.26217 3.73754 11.5101 6.50004 11.5101C9.26254 11.5101 11.5105 9.26217 11.5105 6.49967C11.5105 3.73717 9.26254 1.48926 6.50004 1.48926Z"
            fill={color}
        />
        <path
            d="M6.5 7.44759C6.27792 7.44759 6.09375 7.26342 6.09375 7.04134V4.33301C6.09375 4.11092 6.27792 3.92676 6.5 3.92676C6.72208 3.92676 6.90625 4.11092 6.90625 4.33301V7.04134C6.90625 7.26342 6.72208 7.44759 6.5 7.44759Z"
            fill={color}
        />
        <path
            d="M6.50004 9.20855C6.42962 9.20855 6.35921 9.1923 6.29421 9.16522C6.22921 9.13814 6.16962 9.10022 6.11546 9.05147C6.06671 8.9973 6.02879 8.94314 6.00171 8.87272C5.97462 8.80772 5.95837 8.7373 5.95837 8.66689C5.95837 8.59647 5.97462 8.52605 6.00171 8.46105C6.02879 8.39605 6.06671 8.33647 6.11546 8.2823C6.16962 8.23355 6.22921 8.19564 6.29421 8.16855C6.42421 8.11439 6.57587 8.11439 6.70587 8.16855C6.77087 8.19564 6.83046 8.23355 6.88462 8.2823C6.93337 8.33647 6.97129 8.39605 6.99837 8.46105C7.02546 8.52605 7.04171 8.59647 7.04171 8.66689C7.04171 8.7373 7.02546 8.80772 6.99837 8.87272C6.97129 8.94314 6.93337 8.9973 6.88462 9.05147C6.83046 9.10022 6.77087 9.13814 6.70587 9.16522C6.64087 9.1923 6.57046 9.20855 6.50004 9.20855Z"
            fill={color}
        />
    </svg>
);
