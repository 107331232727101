export const CameraIcon = ({width = 16, height = 16, ...rest}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            opacity={0.4}
            d="M4.50662 14.6654H11.4933C13.3333 14.6654 14.0666 13.5387 14.1533 12.1654L14.4999 6.6587C14.5933 5.2187 13.4466 3.9987 11.9999 3.9987C11.5933 3.9987 11.2199 3.76536 11.0333 3.40536L10.5533 2.4387C10.2466 1.83203 9.44662 1.33203 8.76662 1.33203H7.23995C6.55328 1.33203 5.75328 1.83203 5.44662 2.4387L4.96662 3.40536C4.77995 3.76536 4.40662 3.9987 3.99995 3.9987C2.55328 3.9987 1.40662 5.2187 1.49995 6.6587L1.84662 12.1654C1.92662 13.5387 2.66662 14.6654 4.50662 14.6654Z"
            fill="#17568B"
        />
        <path
            d="M9 5.83203H7C6.72667 5.83203 6.5 5.60536 6.5 5.33203C6.5 5.0587 6.72667 4.83203 7 4.83203H9C9.27333 4.83203 9.5 5.0587 9.5 5.33203C9.5 5.60536 9.27333 5.83203 9 5.83203Z"
            fill="#17568B"
        />
        <path
            d="M7.99992 12.0848C9.2444 12.0848 10.2532 11.0759 10.2532 9.83146C10.2532 8.58698 9.2444 7.57812 7.99992 7.57812C6.75543 7.57812 5.74658 8.58698 5.74658 9.83146C5.74658 11.0759 6.75543 12.0848 7.99992 12.0848Z"
            fill="#17568B"
        />
    </svg>
);
