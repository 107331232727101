export const testLogic = (result = {}) => {
    const {liveness, face_attributes} = result;
    const {mouth_status, glass, eyes_status, image_quality} = face_attributes || {};

    const procedureValidationLogic = liveness?.procedure_validation !== 'PASSED';
    const faceGenuinenessLogic = liveness?.face_genuineness !== 'PASSED';
    const screenPlayLogic = liveness?.face_genuineness === 'SCREEN_REPLAY';
    const glassLogic = glass !== 'No';
    const leftEyesStatusLogic = eyes_status?.left_eye_status?.no_glass_eye_close > 5;
    const rightEyesStatusLogic = eyes_status?.right_eye_status?.no_glass_eye_close > 5;
    const mouthSurgicalMaskLogic = mouth_status?.surgical_mask_or_respirator > 5;
    const mouthOcclusionLogic = mouth_status?.other_occlusion > 2;
    const mouthOpenLogic = mouth_status?.open > 50;
    const mouthCloseLogic = mouth_status?.close < 50;
    const imageQualityLogic = image_quality?.value < 70;

    const logicFailed =
        screenPlayLogic ||
        procedureValidationLogic ||
        faceGenuinenessLogic ||
        glassLogic ||
        leftEyesStatusLogic ||
        rightEyesStatusLogic ||
        mouthSurgicalMaskLogic ||
        mouthOcclusionLogic ||
        mouthOpenLogic ||
        mouthCloseLogic ||
        imageQualityLogic;

    return {
        logicFailed,
        screenPlayLogic,
        procedureValidationLogic,
        faceGenuinenessLogic,
        glassLogic,
        leftEyesStatusLogic,
        rightEyesStatusLogic,
        mouthSurgicalMaskLogic,
        mouthOcclusionLogic,
        mouthOpenLogic,
        mouthCloseLogic,
        imageQualityLogic,
    };
};
