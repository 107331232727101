import {useContext, useEffect, useRef, useState} from 'react';
import {useMutation} from 'react-query';

import {BackArrowIcon} from 'assets/icons';

import {Button} from 'utils';
import {StepIndicator} from './StepIndicator';
import {ProgressBar} from 'utils/ProgressBar/ProgressBar';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';
import {LivenessStyles} from 'components/SmartKYC/Liveness/LivenessStyles';

import {useCamera} from 'hooks';

import API from 'api';
import {getError} from 'utils/helpers';
import {livenessCheck} from 'api/routes/address';
import AppContext from 'components/App/AppContext';

export const AddressLiveness = () => {
    const videoRef = useRef({});
    const {colors, params} = useContext(AppContext);
    const {currentStep, result, handleStep} = useContext(AddressContext);

    const {clientid, appname, apikey} = params || {};

    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [countdown, setCountdown] = useState(3);
    const [recording, setRecording] = useState(false);
    const [mediaRecorded, setMediaRecorded] = useState(null);
    const [errorMsg, setErrorMsg] = useState();
    const [currentBlobs, setCurrentBlobs] = useState([]);
    const [startFile, setStartFile] = useState('');
    const [progress, setProgress] = useState(0);
    const loadingDuration = 4000;

    const {stream, startStream, stopStream} = useCamera({
        constraints: {
            video: {
                facingMode: 'user',
            },
        },
        onSuccess: stream => {
            setLoading(false);
            setTimeout(() => setCountdown(prev => prev - 1), 1000);

            if ('srcObject' in videoRef.current) {
                videoRef.current.srcObject = stream;
            } else {
                videoRef.current.src = URL.createObjectURL(stream);
            }

            videoRef.current.play();
        },
        onError: () => setErrorMsg('Camera not found'),
    });

    const startRecording = () => {
        setRecording(true);
        mediaRecorded?.start(1000);

        setTimeout(() => {
            setTimeout(() => {
                stopStream();
                setRecording(false);
            }, 1000);
        }, 4000);
    };

    useEffect(() => {
        let [timeout, progressTimeout] = ['', ''];
        if (countdown > 0 && countdown !== 0 && loading === false) {
            progressTimeout = setTimeout(() => setCountdown(prev => prev - 1), 1000);
        }
        if (progress < 100 && recording)
            progressTimeout = setTimeout(() => setProgress(progress + 1), loadingDuration / 100);
        return () => {
            clearTimeout(timeout);
            clearTimeout(progressTimeout);
        };
    }, [countdown, progress, recording]);

    useEffect(() => {
        startStream();
        return () => stopStream();
    }, []);

    useEffect(() => {
        const options = {videoBitsPerSecond: 2009000};
        let mediaRecorder;
        if (stream) {
            if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,vorbis')) {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                    mimeType: 'video/webm; codecs=vp8,vorbis',
                });
            } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                    mimeType: 'video/webm;codecs=vp8,opus',
                });
            } else if (MediaRecorder.isTypeSupported('video/mp4')) {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                    mimeType: 'video/mp4',
                });
            } else {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                });
            }
            setMediaRecorded(mediaRecorder);
        }
    }, [stream]);

    useEffect(() => {
        if (mediaRecorded && mediaRecorded?.addEventListener) {
            setCurrentBlobs([]);
            setStartFile(false);
            mediaRecorded.addEventListener('dataavailable', e => {
                setCurrentBlobs(prev => [...prev, e.data]);
            });
            mediaRecorded.addEventListener('stop', async () => setStartFile(true));
        }
    }, [mediaRecorded]);

    useEffect(() => {
        if (startFile && currentBlobs) {
            const blob = new Blob(currentBlobs, {type: 'video/mp4'});
            const file = new File([blob], 'liveness.mp4', {
                type: 'video/mp4',
            });

            // if (file) {
            //     const url = URL.createObjectURL(file);

            //     // Create an anchor (link) element
            //     const a = document.createElement('a');
            //     a.href = url;
            //     a.download = file.name || 'downloaded_file'; // Provide a default name if the file object doesn't have one

            //     // Append the anchor to the document to trigger the download
            //     document.body.appendChild(a);
            //     a.click();
            // }

            if (file) handleVerification(file);
            else {
                setErrorMsg('Liveness Verification Failed!');
            }
        }
    }, [currentBlobs, startFile]);

    const {mutate} = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(livenessCheck, payload, {
                headers: {
                    clientid,
                    appname,
                    apikey,
                },
            });
            return response;
        },
        {
            onSuccess: ({data, status}) => {
                if (
                    (status === 200 || status === 201) &&
                    (data?.status_code === 200 || data?.status_code === 201)
                )
                    handleStep({
                        step: STEPS.LIVENESS_RESULT,
                        data: {liveness_result: data?.data || {}},
                    });
                else setErrorMsg(data?.message);
            },
            onError: error => setErrorMsg(getError(error)),
        }
    );

    const handleVerification = file => {
        setStep(2);
        setErrorMsg();

        const formData = new FormData();
        formData.append('video', file, 'liveness.mp4');
        formData.append('reference', result?.id_result?.reference);

        mutate(formData);
    };

    const reset = () => {
        setErrorMsg();
        setCountdown(3);
        setLoading(true);
        setRecording(false);
        setMediaRecorded(null);
        setStep(1);
        setProgress(0);
        startStream();
    };

    const renderByStep = () => {
        if (step === 1)
            return (
                <ProgressBar progress={progress}>
                    {loading && (
                        <div className="video-frame-loading">
                            <div className="h-100 flex__center">
                                {errorMsg ? (
                                    <h4 className="text-danger">{errorMsg}</h4>
                                ) : (
                                    <h4>Loading... Please Wait</h4>
                                )}
                            </div>
                        </div>
                    )}
                    <video
                        ref={videoRef}
                        id="video"
                        style={{
                            display: loading ? 'none' : 'block',
                        }}
                        autoPlay
                        muted
                        playsInline
                    >
                        Video stream not available.
                    </video>
                </ProgressBar>
            );

        if (step === 2) {
            return (
                <div className="verification-status">
                    <div className="text-center">
                        {errorMsg ? (
                            <>
                                <h4 className="text-danger py-4">{errorMsg}</h4>
                                <div className="d-flex justify-content-center w-100">
                                    <Button
                                        name="Retry"
                                        cls="btn--sm btn--primary--bordered px-5"
                                        handleClick={reset}
                                    />
                                </div>
                            </>
                        ) : (
                            <h4 className="heading3">Verification in Progress. Please Wait...</h4>
                        )}
                    </div>
                </div>
            );
        }
    };

    return (
        <LivenessStyles colors={colors}>
            <div className="d-flex flex-column align-items-center">
                <div className="w-100">
                    {/* <BackArrowIcon
                        className="pointer back-arrow"
                        onClick={() => {
                            stopStream();
                            handleStep({step: STEPS.ID});
                        }}
                    /> */}
                </div>
                <StepIndicator currentStep={currentStep} />
                <AddressHeader
                    title="Liveness Check"
                    text="Align your face with the frame"
                    cls="text-center mb-4"
                />
                <div className="W-100 video-frame py-5 mt-4">
                    <div className="frame-edge" />
                    <div className="frame-edge right" />
                    <div className="frame-edge bottom-left" />
                    <div className="frame-edge bottom-right" />
                    <div className="flex__center">{renderByStep()}</div>
                </div>
                {step === 1 && (
                    <Button
                        name={recording ? 'Liveness check in progress' : 'Continue'}
                        cls="btn--lg btn--primary"
                        disabled={countdown !== 0 || progress > 1}
                        handleClick={startRecording}
                    />
                )}
            </div>
        </LivenessStyles>
    );
};
