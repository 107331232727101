import {useState, useContext} from 'react';
import {Header} from 'components/Layouts/Header';
import AppContext, {useApp} from 'components/App/AppContext';
import {LivenessPhoneInput} from './LivenessPhoneInput';
import {LivenessCheck} from './LivenessCheck';
import {LivenessStyles} from './LivenessStyles';
import {LivenessCheckResult} from './LivenessCheckResult';

export const STEPS = {
    PHONE: 'PHONE',
    CHECK: 'CHECK',
    RESULT: 'RESULT',
};

export const LivenessContent = () => {
    const {params, colors} = useApp();
    const [currentStep, setCurrentStep] = useState(STEPS.PHONE);
    const [result, setResult] = useState({phone: params?.otherParams?.phone_number});

    const handleStep = ({step, data = {}}) => {
        setResult(prev => ({...prev, ...data}));
        setCurrentStep(step);
    };

    const renderSteps = () => {
        switch (currentStep) {
            case STEPS.PHONE:
            default:
                return <LivenessPhoneInput result={result} handleStep={handleStep} />;

            case STEPS.CHECK:
                return <LivenessCheck result={result} handleStep={handleStep} />;

            case STEPS.RESULT:
                return <LivenessCheckResult result={result} handleStep={handleStep} />;
        }
    };
    return (
        <LivenessStyles colors={colors}>
            <Header title="Liveness Check" />
            <div className="content">{renderSteps()}</div>
        </LivenessStyles>
    );
};

export const LivenessHeader = ({title, text}) => {
    return (
        <div className="content-header">
            <h4>{title}</h4>
            <span>{text}</span>
        </div>
    );
};
