import styled from 'styled-components';

export const AppContainer = styled.div`
    .overflow-x {
        -ms-overflow-style: none;
        flex-wrap: nowrap;
        scrollbar-width: none;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .overflow-y {
        -ms-overflow-style: none;
        flex-wrap: nowrap;
        scrollbar-width: none;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .container {
        position: relative;
        background: var(--whiteFull);
        min-width: 400px;
        max-width: ${({isLarge}) => (isLarge ? undefined : '450px')};
        min-height: 300px;
        margin: 30px auto;
        border-radius: 10px;
        padding: 0px;
        box-shadow: 0 8px 29px rgb(0, 0, 0, 0.15);
        font-family: gilroy-regular;

        @media (max-width: 600px) {
            max-width: 90%;
        }

        @media (max-width: 420px) {
            min-width: 100%;
            min-height: 100vh;
            margin: auto;
            box-shadow: none;
        }
        .close__widget {
            position: absolute;
            top: -14px;
            right: -20px;
            height: 20px;
            width: 20px;
            z-index: 3;
            cursor: pointer;
            display: flex;
            align-items: center;
            border-radius: 50%;
            transform: rotate(45deg);
            background: var(--primaryFull);

            &.inside {
                border-radius: 3px;
                background: transparent;
                transform: rotate(0deg);
                border: 1px solid var(--grayThree);
                top: 14px;
                right: 14px;

                svg {
                    transform: rotate(45deg);
                    path {
                        stroke: var(--grayOne);
                    }
                }

                &:hover {
                    svg path {
                        stroke: var(--grayOne);
                    }
                }
            }

            @media (max-width: 420px) {
                top: 15px;
                right: 30px;
            }
            svg path {
                stroke: var(--whiteFull);
            }

            &:hover {
                svg path {
                    stroke: var(--whiteFull);
                }
            }
        }
    }
    .content {
        padding: 30px;
    }
    .widget__footer {
        opacity: 0.7;
        text-align: center;
        padding-bottom: 10px;

        p {
            font-size: 1.2em;
            span {
                font-family: gilroy-bold;
            }
        }
    }
    .widget__header {
        font-size: 16px;
        color: var(--primaryFull);
        font-weight: 600;
        border-bottom: 1px solid var(--grayFour);
        padding: 15px 30px;
        font-family: gilroy-bold;
    }
    .content-header {
        margin-bottom: 16px;

        h4 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        span {
            color: var(--grayOne);
            line-height: 150%;
            text-align: justify;
        }
    }
    .min-height {
        min-height: 300px;
    }
    .font-13 {
        font-size: 13px;
    }
`;
