import {useContext} from 'react';
import AppContext from 'components/App/AppContext';
import {ProgressBarStyles} from './ProgressBarStyles';

export const ProgressBar = props => {
    const {colors} = useContext(AppContext);
    let {
        size = 300,
        progress = 0,
        trackWidth = 8,
        trackColor = colors.primaryThree,
        indicatorWidth = 8,
        indicatorColor = colors.primaryFull,
        indicatorCap = `round`,
        spinnerMode = false,
        spinnerSpeed = 1,
    } = props;

    const center = size / 2,
        radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
        dashArray = 2 * Math.PI * radius,
        dashOffset = dashArray * ((100 - progress) / 100);

    return (
        <>
            <ProgressBarStyles style={{width: size, height: size}}>
                <svg className="svg-pi" style={{width: size, height: size}}>
                    <circle
                        className="svg-pi-track"
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={trackColor}
                        strokeWidth={trackWidth}
                    />
                    <circle
                        className={`svg-pi-indicator ${
                            spinnerMode ? 'svg-pi-indicator--spinner' : ''
                        }`}
                        style={{animationDuration: spinnerSpeed * 1000}}
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={indicatorColor}
                        strokeWidth={indicatorWidth}
                        strokeDasharray={dashArray}
                        strokeDashoffset={dashOffset}
                        strokeLinecap={indicatorCap}
                    />
                </svg>
                {props.children}
            </ProgressBarStyles>
        </>
    );
};
