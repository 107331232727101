import {useContext} from 'react';
import {AddressContext} from './AddressContent';

const steps = {
    COUNTRY: 0,
    ID: 1,
    LIVENESS: 2,
    LIVENESS_RESULT: 2,
    ADDRESS_STANDARD: 3,
    BILL: 4,
    HOUSE: 5,
    CONFIRM: 6,
};

const miniSteps = {
    ID: 0,
    ADDRESS_STANDARD: 1,
    BILL: 2,
    HOUSE: 3,
    CONFIRM: 4,
};

export const StepIndicator = ({currentStep}) => {
    const {isMini} = useContext(AddressContext);
    const arr = [...Array(isMini ? 5 : 7).keys()];

    return (
        <div className="steps-container">
            {arr.map((_, i) => (
                <div
                    key={i}
                    className={`step ${
                        i <= (isMini ? miniSteps : steps)[currentStep] ? 'active' : ''
                    }`}
                ></div>
            ))}
        </div>
    );
};
