import {useContext, useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {useQuery} from 'react-query';
import API from 'api';
import {getServices} from 'api/routes/main';
import routes from 'components/routes';
import {WidgetLayout} from './WidgetLayout';
import {WidgetWrapper} from './WidgetWrapper';
import AppContext from './AppContext';
import {FailedState} from 'utils';

export const MainPage = () => {
    const {params, colors} = useContext(AppContext);
    const [errorMsg, setErrorMsg] = useState('');
    const {replace} = useHistory();
    const {clientid, appname, apikey, serviceId} = params || {};

    const getAppQuery = useQuery(
        ['get_app_query'],
        async () => {
            setErrorMsg();
            const headers = {clientid, appname, apikey};
            const response = await API.get(getServices, {headers});
            return response.data;
        },
        {
            onSuccess: ({results}) => {
                const service = results?.find(({id}) => id === parseInt(serviceId || 0));
                if (service) replace(service?.service_url);
                else setErrorMsg('Invalid Service ID Provided');
            },
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    return (
        <WidgetWrapper params={params}>
            <WidgetLayout currentQuery={getAppQuery} colors={colors} errorMsg={errorMsg}>
                <Switch>
                    {routes.map(({name, path, component}) => (
                        <Route key={name} path={path} component={component} />
                    ))}

                    <Route
                        path="*"
                        component={() => (
                            <div className="min-height flex__center">
                                <FailedState
                                    btnName=""
                                    errorMsg="Service not found. Please check the service ID passed and try again"
                                />
                            </div>
                        )}
                    />
                </Switch>
            </WidgetLayout>
        </WidgetWrapper>
    );
};
