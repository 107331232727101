export const apiVersion = {
    version: `/api/v1`,
    get default() {
        return this.version;
    },
    get v2() {
        return 'v2';
    },
    get base() {
        return '/api';
    },
};
