import {createGlobalStyle} from 'styled-components';

export const getColors = (colors = {}) => {
    const {primary = 'rgba(23, 86, 139, 1)', secondary = 'rgba(80, 159, 239, 1) '} = colors;

    const updateOpacity = (color = primary, opacity = 1) => {
        const splitValue = color.split('(')[1].split(')')[0].split(',');
        return `rgba(${splitValue[0]}, ${splitValue[1]}, ${splitValue[2]}, ${opacity})`;
    };

    const getGradient = (color = primary) => {
        const splitValue = color.split('(')[1].split(')')[0].split(',');
        return `rgba(${splitValue[0] - 9}, ${splitValue[1] - 12}, ${splitValue[2] - 17}, 1)`;
    };

    const whiteFull = 'rgba(255, 255, 255, 1)';
    const blackFull = 'rgba(14, 27, 40, 1)';
    const cautionFull = 'rgba(228, 155, 12, 1)';
    const errorFull = 'rgba(223, 55, 75, 1)';
    const successFull = 'rgba(12, 185, 91, 1)';

    const gradientOne = primary;
    const gradientTwo = getGradient(gradientOne);
    const gradientThree = getGradient(gradientTwo);
    const gradientFour = getGradient(gradientThree);
    const gradientFive = getGradient(gradientFour);
    const gradientSix = getGradient(gradientFive);
    const gradientSeven = getGradient(gradientSix);

    return {
        whiteFull,
        blackFull,
        cautionFull,
        errorFull,

        primaryFull: primary,
        primaryOne: updateOpacity(primary, 0.52),
        primaryTwo: updateOpacity(primary, 0.24),
        primaryThree: updateOpacity(primary, 0.08),
        primaryFour: updateOpacity(primary, 0.04),

        secondaryFull: secondary,
        secondaryMid: updateOpacity(secondary, 0.52),
        secondaryDisabled: updateOpacity(secondary, 0.24),
        secondaryOutline: updateOpacity(secondary, 0.08),
        secondaryHover: updateOpacity(secondary, 0.04),

        surfaceFocus: 'rgba(235, 235, 245, 1)',
        surfaceBackground: 'rgba(247, 247, 250, 1)',

        whiteMid: updateOpacity(whiteFull, 0.52),
        whiteDisabled: updateOpacity(whiteFull, 0.32),
        whiteOutline: updateOpacity(whiteFull, 0.12),

        textTwo: updateOpacity(blackFull, 0.82),
        grayOne: updateOpacity(blackFull, 0.52),
        grayTwo: updateOpacity(blackFull, 0.32),
        grayThree: updateOpacity(blackFull, 0.12),
        grayFour: updateOpacity(blackFull, 0.04),

        cautionMid: updateOpacity(cautionFull, 0.52),
        cautionDisabled: updateOpacity(cautionFull, 0.24),
        cautionOutline: updateOpacity(cautionFull, 0.08),

        errorMidFull: updateOpacity(errorFull, 0.76),
        errorMid: updateOpacity(errorFull, 0.52),
        errorDisabled: updateOpacity(errorFull, 0.24),
        errorOutline: updateOpacity(errorFull, 0.08),

        successSec: 'rgba(44, 199, 162, 1)',
        successSecTwo: 'rgba(60, 211, 130, 1)',
        successMidFull: updateOpacity(successFull, 0.76),
        successMid: updateOpacity(successFull, 0.52),
        successDisabled: updateOpacity(successFull, 0.24),
        successOutline: updateOpacity(successFull, 0.08),

        blanket: 'rgba(0, 0, 0, 0.4)',

        gradientOne,
        gradientTwo,
        gradientThree,
        gradientFour,
        gradientFive,
        gradientSix,
        gradientSeven,
    };
};

export const GlobalStyle = createGlobalStyle`
  ${({theme}) => `
    :root {
        --whiteFull: ${theme.whiteFull};
        --blackFull: ${theme.blackFull};
        --cautionFull: ${theme.cautionFull};
        --errorFull: ${theme.errorFull};
        --successFull: ${theme.successFull};

        --primaryFull: ${theme.primaryFull};
        --primaryOne: ${theme.primaryOne};
        --primaryTwo: ${theme.primaryTwo};
        --primaryThree: ${theme.primaryThree};
        --primaryFour: ${theme.primaryFour};

        --secondaryFull: ${theme.secondaryFull};
        --secondaryMid: ${theme.secondaryMid};
        --secondaryDisabled: ${theme.secondaryDisabled};
        --secondaryOutline: ${theme.secondaryOutline};
        --secondaryHover: ${theme.secondaryHover};

        --surfaceFocus: ${theme.surfaceFocus};
        --surfaceBackground: ${theme.surfaceBackground};

        --whiteMid: ${theme.whiteMid};
        --whiteDisabled: ${theme.whiteDisabled};
        --whiteOutline: ${theme.whiteOutline};

        --textTwo: ${theme.textTwo};
        --grayOne: ${theme.grayOne};
        --whiteOutline: ${theme.whiteOutline};
        --grayTwo: ${theme.grayTwo};
        --grayThree: ${theme.grayThree};
        --grayFour: ${theme.grayFour};

        --cautionMid: ${theme.cautionMid};
        --cautionDisabled: ${theme.cautionDisabled};
        --cautionOutline: ${theme.cautionOutline};

        --errorMidFull: ${theme.errorMidFull};
        --errorMid: ${theme.errorMid};
        --errorDisabled: ${theme.errorDisabled};
        --errorOutline: ${theme.errorOutline};

        --successSec: ${theme.successSec};
        --successSecTwo: ${theme.successSecTwo};
        --successMidFull: ${theme.successMidFull};
        --successMid: ${theme.successMid};
        --successDisabled: ${theme.successDisabled};
        --successOutline: ${theme.successOutline};

        --blanket: ${theme.blanket};

        --gradientOne: ${theme.gradientOne};
        --gradientTwo: ${theme.gradientTwo};
        --gradientThree: ${theme.gradientThree};
        --gradientFour: ${theme.gradientFour};
        --gradientFive: ${theme.gradientFive};
        --gradientSix: ${theme.gradientSix};
        --gradientSeven: ${theme.gradientSeven};

      }
  `}
`;
