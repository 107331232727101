import styled from 'styled-components';

export const LivenessStyles = styled.div`
    ${({colors}) => `
        min-height: 600px;
        .video-frame {
            position: relative;
            margin: 0 30px;
            min-height: 300px;

            .frame-edge {
                position: absolute;
                border-width: 2px 0 0 2px;
                border-style: solid;
                border-radius: 5px 0;
                width: 50px;
                height: 50px;
                background: none;
                border-color: ${colors.primaryTwo};

                &.right {
                    right: 0;
                    transform: rotate(90deg);
                }
                &.bottom-left {
                    bottom: 30px;
                    transform: rotate(270deg);
                }
                &.bottom-right {
                    right: 0;
                    bottom: 30px;
                    transform: rotate(180deg);
                }
            }
            .video-frame-circle,
            .video-frame-loading,
            video {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 270px;
                height: 270px;
                border-radius: 50%;
                margin: auto;
                background: ${colors.primaryTwo};
                overflow: hidden;
                object-fit: cover;    
            }
            .video-frame-circle,
            video {
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
            .verification-status {
                display: flex;
                align-items: center;
                justify-content-center: center;
                padding: 25px;
                min-height: 250px;
            }
        }
        .verification_result {
            position: relative;

            img, svg {
                border-radius: 50%;
                width: 150px;
                height: 150px;
                margin: 10px 0 20px 0;
                box-shadow: 0 8px 29px rgb(0 0 0 / 10%);
            }
            ul {
                
                li {
                    padding: 5px 0;
                    width: 50%;
                    display: inline-block;

                    span {
                        font-size: 14px;
                        color: ${colors.textTwo};
                    }
                    p {
                        font-size: 14px;
                        color: ${colors.blackFull};
                        font-weight: 600;
                        padding-top: 5px;
                    }
                }

                
            }
            ol {
                padding: 10px 0 20px 0;
               
                li {
                    list-style: none;
                    width: 100%
                    padding: 5px 0;
                    color: ${colors.errorFull};
                    margin-bottom: 10px;
                    font-size: 13px;
                    padding-left: 20px;
                    position: relative;

                    p {
                        &::before {
                            content: "*";
                            position: absolute;
                            left: 0px;
                            top: 1.5px;
                            font-size: 22px;
                        }
                    }
                }  
                &.warning {
                    li {
                        color: ${colors.cautionFull} !important;
                    }
                } 
            }

            

            
        }
   `}
`;
