import {useState} from 'react';
import {EyeOnIcon, EyeOffIcon, InfoCircleErrorIcon} from 'assets/icons';
import {CustomInputBoxStyles} from './CustomInputBoxStyles';

export const CustomPassword = ({
    id,
    name = 'password',
    cls,
    value,
    error,
    label = 'Password',
    onChange,
    placeholder = 'Password',
    showPasswordStrength,
    required,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({});

    const isError = error ? 'error' : '';
    const isRequired = required ? 'required' : '';

    return (
        <CustomInputBoxStyles>
            <div className={`form-group ${cls}`}>
                {label && (
                    <label htmlFor={name} className={`form-group-label ${isRequired}`}>
                        {label}
                    </label>
                )}

                <div className="input-container-wrapper">
                    <div className={`input-wrapper ${isError} d-flex align-items-center`}>
                        <input
                            className="w-100"
                            type={showPassword ? 'text' : 'password'}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            value={value}
                            autoComplete="off"
                            onChange={onChange}
                            {...rest}
                        />

                        <div className={`px-2 pointer ${showPassword ? 'show__password' : ''}`}>
                            {showPassword ? (
                                <EyeOffIcon
                                    width="30px"
                                    onClick={() => setShowPassword(prev => !prev)}
                                />
                            ) : (
                                <EyeOnIcon
                                    width="30px"
                                    onClick={() => setShowPassword(prev => !prev)}
                                />
                            )}
                        </div>
                    </div>

                    {typeof error === 'string' && error && (
                        <small className="input-text-error">
                            <InfoCircleErrorIcon className="me-2" />
                            {error}
                        </small>
                    )}
                </div>
            </div>
        </CustomInputBoxStyles>
    );
};

export default CustomPassword;
