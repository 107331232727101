import {apiVersion} from '.';

const baseURL = `${apiVersion.v2}/Authorize`;

export const {merchantLogin, GetApps, editApplications, automateCompliance} = {
    merchantLogin: `${baseURL}/login`,
    GetApps: `${baseURL}/GetApps`,
    editApplications: appId => `${baseURL}/EditApplicationService/${appId}`,
    automateCompliance: `${apiVersion.default}/aggregator/verification/gateway`,
};
