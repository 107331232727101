import {useContext} from 'react';
import AppContext from 'components/App/AppContext';
import {ButtonContainer} from './ButtonStyles';

export const Button = ({name, disabled, type = 'button', icon, cls = 'btn--sm', handleClick}) => {
    const classes = `btn btn--primary ${cls}`;
    const {colors} = useContext(AppContext);

    return (
        <ButtonContainer
            colors={colors}
            className={classes}
            disabled={disabled}
            type={type}
            onClick={handleClick}
        >
            {icon && <span className="px-3">{icon}</span>}
            {name}
        </ButtonContainer>
    );
};
