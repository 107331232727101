import {piexif} from 'piexifjs';
import {useContext, useLayoutEffect, useRef, useState} from 'react';

import {BackArrowIcon, InfoCircleErrorIcon} from 'assets/icons';

import {StepIndicator} from './StepIndicator';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';
import {CustomInputBoxStyles} from 'utils/CustomInputBox/CustomInputBoxStyles';

import {useCamera, useGPS} from 'hooks';

import {Button, FormValidator} from 'utils';
import {injectExifData} from 'utils/helpers';
import AppContext from 'components/App/AppContext';

export const AddressHouse = () => {
    const canvas = useRef({});
    const videoRef = useRef({});
    const {colors} = useContext(AppContext);
    const {currentStep, result, handleStep} = useContext(AddressContext);

    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(true);

    const [formValues, setFormValues] = useState({
        home: result?.home,
    });
    const [formErrors, setFormErrors] = useState();

    const handleRetry = () => {
        if (!errorMsg || !loading) return;

        setErrorMsg();
        if (errorMsg == 'GPS is Required') {
            getLocation();
        } else if (errorMsg == 'Camera not found') {
            startStream();
        }
    };

    const {startStream, stopStream} = useCamera({
        constraints: {
            video: {
                facingMode: {exact: 'environment'},
                width: {ideal: 1920},
                height: {ideal: 1080},
            },
        },
        onSuccess: stream => {
            setLoading(false);

            if ('srcObject' in videoRef.current) {
                videoRef.current.srcObject = stream;
            } else {
                videoRef.current.src = URL.createObjectURL(stream);
            }
        },
        onError: () => {
            setLoading(true);
            setErrorMsg('Camera not found');
        },
    });

    const {location, getLocation} = useGPS({
        onSuccess: () => startStream(),
        onError: () => {
            setLoading(true);
            setErrorMsg('GPS is Required');
        },
    });

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    useLayoutEffect(() => {
        getLocation();
    }, []);

    const takepicture = () => {
        const context = canvas.current.getContext('2d');
        context.imageSmoothingEnabled = false;

        const {videoWidth, videoHeight} = videoRef.current;
        const {latitude, longitude} = location;

        canvas.current.width = videoWidth;
        canvas.current.height = videoHeight;
        context.drawImage(video, 0, 0, videoWidth, videoHeight);

        const data = canvas.current.toDataURL('image/jpeg');

        const exifObj = {
            GPS: {
                [piexif.GPSIFD.GPSLatitudeRef]: latitude >= 0 ? 'N' : 'S',
                [piexif.GPSIFD.GPSLatitude]: piexif.GPSHelper.degToDmsRational(latitude),
                [piexif.GPSIFD.GPSLongitudeRef]: longitude >= 0 ? 'E' : 'W',
                [piexif.GPSIFD.GPSLongitude]: piexif.GPSHelper.degToDmsRational(longitude),
            },
        };

        const formattedData = injectExifData(data, exifObj);

        handleSelect('home', formattedData);
        stopStream();
    };

    const retakePicture = () => {
        handleSelect('home');
        startStream();
    };

    const handleNext = e => {
        e.preventDefault();

        const {isValid, formErrors} = FormValidator(formValues);

        isValid ? handleStep({step: STEPS.CONFIRM, data: formValues}) : setFormErrors(formErrors);
    };

    const url = formValues?.home;

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="w-100">
                <BackArrowIcon
                    className="pointer back-arrow"
                    onClick={() => {
                        stopStream();
                        handleStep({step: STEPS.BILL});
                    }}
                />
            </div>
            <StepIndicator currentStep={currentStep} />
            <AddressHeader
                title="Picture of Building"
                text="Capture the whole building"
                cls="text-center mb-4"
            />
            <div className="mt-5 w-100">
                <div className="d-flex flex-column">
                    <div className="W-100 image-frame">
                        {loading && (
                            <div className="image-frame-loading">
                                <div className="h-100 flex__center">
                                    {errorMsg ? (
                                        <div className="d-flex flex-column">
                                            <h4 className="text-danger">{errorMsg}</h4>
                                            <div className="d-flex justify-content-center w-100">
                                                <Button
                                                    name="Retry"
                                                    cls="btn--sm btn--primary--bordered px-5"
                                                    handleClick={handleRetry}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <h4>Loading... Please Wait</h4>
                                    )}
                                </div>
                            </div>
                        )}
                        <video
                            className="reverse"
                            ref={videoRef}
                            id="video"
                            style={{
                                display: formValues?.home || loading ? 'none' : 'block',
                            }}
                            autoPlay
                            muted
                            playsInline
                        >
                            Video stream not available.
                        </video>
                        <canvas className="d-none" ref={canvas} id="canvas"></canvas>
                        {url && <img className="reverse" src={url} />}
                    </div>
                    <CustomInputBoxStyles colors={colors}>
                        {typeof formErrors?.home === 'string' && formErrors?.home && (
                            <small className="input-text-error">
                                <span className="me-2">
                                    <InfoCircleErrorIcon />
                                </span>
                                {formErrors?.home}
                            </small>
                        )}
                    </CustomInputBoxStyles>
                    <div className="d-flex justify-content-center mb-5">
                        {!loading && !errorMsg && (
                            <Button
                                name={formValues?.home ? 'Re-take Photo' : 'Take Photo'}
                                cls="btn--sm btn--primary--bordered px-5 mt-3 mb-5"
                                handleClick={formValues?.home ? retakePicture : takepicture}
                            />
                        )}
                    </div>
                    <Button
                        cls="w-100 btn btn-lg rounded-0"
                        name="Continue"
                        handleClick={handleNext}
                        disabled={!formValues?.home}
                    />
                    <Button
                        cls="w-100 btn btn-lg btn--transparent black rounded-0 underline"
                        name="Back"
                        handleClick={() => {
                            handleStep({step: STEPS.BILL});
                            stopStream();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
