import LivenessCheckContent from './LivenessCheckContent';
import {LivenessHeader} from './LivenessContent';

export const LivenessCheck = ({result, handleStep}) => {
    return (
        <>
            <LivenessHeader
                title="Fit your face in the oval frame to record"
                text="Move your face in circular motion to capture"
            />

            <LivenessCheckContent result={result} handleStep={handleStep} />
        </>
    );
};
