import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {GlobalStyle, getColors} from 'assets/colors';
import qs from 'qs';
import AppContext from './AppContext';
import {AppContainer} from './AppStyles';
import {MainPage} from './MainPage';
import {Compliance, ContinueAddress} from 'pages';

const App = () => {
    const params = qs.parse(window.location.search?.slice(1));
    const colors = getColors({primary: params.primaryColor});

    const isLarge = [335].includes(Number(params?.serviceId));

    return (
        <ThemeProvider theme={colors}>
            <GlobalStyle />
            <AppContext.Provider value={{colors, params}}>
                <AppContainer colors={colors} isLarge={isLarge}>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                path="/IdentityVerification/Address/:id"
                                component={ContinueAddress}
                            />
                            <Route path="/compliance" component={Compliance} />
                            <Route path="/" component={MainPage} />
                        </Switch>
                    </BrowserRouter>
                </AppContainer>
            </AppContext.Provider>
        </ThemeProvider>
    );
};

export default App;
