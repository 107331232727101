import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {Container} from './CustomFileDropzoneStyles';
import * as Icons from 'assets/icons';
import {CustomInputBoxStyles} from '../CustomInputBox/CustomInputBoxStyles';

export const CustomFileDropzone = ({
    name,
    onChange,
    error,
    multiple = false,
    maxFiles = 0,
    label,
    required,
    cls = '',
}) => {
    const onDrop = useCallback(files => {
        onChange(files[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} = useDropzone({
        accept: {'image/*': []},
        onDrop,
        multiple,
        maxFiles,
    });

    const isRequired = required ? ' required' : '';

    return (
        <CustomInputBoxStyles className={cls}>
            <div className="form-group h-100 w-100">
                {label && (
                    <label htmlFor={name} className={`form-group-label d-flex${isRequired}`}>
                        {label}
                    </label>
                )}
                <Container
                    {...getRootProps({
                        isFocused,
                        isDragAccept,
                        isDragReject: error ? true : isDragReject,
                    })}
                >
                    <input {...getInputProps()} onChange={onChange} />
                    <Icons.FileIcon className="mb-2" />
                    <p className="sub-text text-center pointer">
                        Click to add File or Drag and <br /> drop File here
                    </p>
                </Container>

                {typeof error === 'string' && error && (
                    <small className="input-text-error">
                        <Icons.InfoCircleErrorIcon className="me-2" />
                        {error}
                    </small>
                )}
            </div>
        </CustomInputBoxStyles>
    );
};
