import {useState} from 'react';
import {Header} from 'components/Layouts/Header';
import {useApp} from 'components/App/AppContext';
import {LivenessPhoneInput} from './LivenessPhoneInput';
import {LivenessStyles} from './LivenessStyles';
import {LivenessCheckResult} from './LivenessCheckResult';
import LivenessAdvancedCheckContent from './LivenessAdvancedCheckContent';
import {FailedState} from 'utils';

export const STEPS = {
    PHONE: 'PHONE',
    CHECK: 'CHECK',
    RESULT: 'RESULT',
};

export const LivenessAdvancedContent = () => {
    const {params, colors} = useApp();
    const [currentStep, setCurrentStep] = useState(STEPS.PHONE);
    const [result, setResult] = useState({
        phone: params?.otherParams?.phone_number,
        image_url: params?.otherParams?.imageUrl,
    });

    const handleStep = ({step, data = {}}) => {
        setResult(prev => ({...prev, ...data}));
        setCurrentStep(step);
    };

    const renderSteps = () => {
        switch (currentStep) {
            case STEPS.PHONE:
            default:
                return <LivenessPhoneInput result={result} handleStep={handleStep} />;

            case STEPS.CHECK:
                return <LivenessAdvancedCheckContent result={result} handleStep={handleStep} />;

            case STEPS.RESULT:
                return <LivenessCheckResult result={result} handleStep={handleStep} />;
        }
    };
    return (
        <LivenessStyles colors={colors}>
            <Header title="Liveness Check" />
            <div className="content">
                {params?.otherParams?.imageUrl ? (
                    renderSteps()
                ) : (
                    <div className="min-height flex__center">
                        <FailedState
                            btnName=""
                            errorMsg="Invalid Paremeter passed. Please check the required parameters passed and try again"
                        />
                    </div>
                )}
            </div>
        </LivenessStyles>
    );
};

export const LivenessHeader = ({title, text}) => {
    return (
        <div className="content-header">
            <h4>{title}</h4>
            <span>{text}</span>
        </div>
    );
};
