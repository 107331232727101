import styled from 'styled-components';

export const CustomCheckBoxStyles = styled.div`
    ${({colors}) => `
       .custom-check-box {
        margin-bottom: 20px;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        position: relative;
        width: 100%;
        input {
            background-color: ${colors?.secondaryHover};
            border: 0.5px solid ${colors?.grayTwo};
            border-radius: 2px;
            height: 16px;
            padding: 0;
            min-width: 16px;
            -moz-appearance: none;
            -o-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            box-sizing: border-box;
            cursor: pointer !important;
            outline: none;
            transition-duration: 0.3s;
            &:checked {
                background-color: ${colors?.primaryFull};
            }
        }
        input:hover {
            border: 0.5px solid ${colors?.primaryFull};
        }
        label {
            color: inherit;
            line-height: 17px;
            font-size: 13px;
            cursor: pointer;
            font-weight: 500;
            margin-left: 10px;
            color: ${colors?.grayOne};
        }
        label:hover {
            color: ${colors?.primaryFull};
        }

        &.secondary {
            input {
                background-color: ${colors?.whiteFull};
                border: 0.5px solid ${colors?.secondaryFull};

                &:checked {
                    background-color: ${colors?.secondaryFull};
                }
            }

            label {
                color: ${colors?.blackFull};
            }

            &:hover {
                label {
                    color: ${colors?.secondaryFull};
                }
            }
        }

        &.auth {
            input {
                background-color: ${colors?.whiteFull};
                border: 0.5px solid ${colors?.grayOne} !important;

                &:checked {
                    background-color: ${colors?.secondaryFull} !important;
                }
            }

            label:hover {
                color: ${colors?.grayOne};
            }
        }
    }
   `}
`;
