export const AddressSuccessIcon = ({width = 118, height = 79, ...rest}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 118 79"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <circle cx={49.4209} cy={45.5} r={28.5} fill="#EBFAF5" />
        <path
            d="M49.4207 25.918C38.6303 25.918 29.8374 34.7109 29.8374 45.5013C29.8374 56.2917 38.6303 65.0846 49.4207 65.0846C60.2112 65.0846 69.0041 56.2917 69.0041 45.5013C69.0041 34.7109 60.2112 25.918 49.4207 25.918ZM58.7816 40.9971L47.6778 52.1009C47.4037 52.3751 47.0316 52.5317 46.6399 52.5317C46.2482 52.5317 45.8762 52.3751 45.602 52.1009L40.0599 46.5588C39.492 45.9909 39.492 45.0509 40.0599 44.483C40.6278 43.9151 41.5678 43.9151 42.1357 44.483L46.6399 48.9871L56.7057 38.9213C57.2737 38.3534 58.2137 38.3534 58.7816 38.9213C59.3495 39.4892 59.3495 40.4096 58.7816 40.9971Z"
            fill="#0CB95B"
        />
        <path
            d="M102.053 63.1464C101.06 71.2633 94.3548 78.2307 94.3548 78.2307C94.3548 78.2307 84.7622 76.9961 78.9087 71.2916C74.0272 66.5308 73.6441 59.6698 77.667 56.7183C83.4354 52.4967 89.0476 63.1535 89.0476 63.1535C89.0476 63.1535 86.7488 51.326 93.8936 51.0067C98.8744 50.7796 102.876 56.3777 102.053 63.1393V63.1464Z"
            fill="#EBFAF5"
        />
        <path
            d="M115.072 39.0818C111.453 43.8923 104.621 45.8719 104.621 45.8719C104.621 45.8719 98.9586 41.558 97.3196 35.7755C95.9574 30.9437 98.2349 26.4312 101.882 26.0268C107.111 25.4521 106.777 34.3139 106.777 34.3139C106.777 34.3139 109.658 25.9275 114.334 28.3469C117.59 30.0355 118.087 35.0731 115.079 39.0818H115.072Z"
            fill="#EBFAF5"
        />
        <path
            d="M20.2261 51.8104C18.6345 54.0302 15.5513 55 15.5513 55C15.5513 55 12.9352 53.1024 12.133 50.4895C11.4628 48.3116 12.4519 46.2367 14.1015 46.0176C16.4695 45.7019 16.4083 49.7291 16.4083 49.7291C16.4083 49.7291 17.6293 45.892 19.7751 46.9423C21.2732 47.6736 21.5503 49.9547 20.2229 51.8072L20.2261 51.8104Z"
            fill="#EBFAF5"
        />
        <path
            d="M19.2109 12.7959C15.7059 17.6844 8.91586 19.8201 8.91586 19.8201C8.91586 19.8201 3.15462 15.641 1.38793 9.88688C-0.0878544 5.09058 2.09035 0.521317 5.72306 0.038848C10.938 -0.656474 10.8032 8.21244 10.8032 8.21244C10.8032 8.21244 13.4922 -0.23786 18.2176 2.07515C21.5168 3.68575 22.127 8.70909 19.2038 12.7888L19.2109 12.7959Z"
            fill="#EBFAF5"
        />
    </svg>
);
