import {useContext, useLayoutEffect, useRef, useState} from 'react';

import {ArrowRightIcon, DocumentIcon, InfoCircleErrorIcon} from 'assets/icons';

import {Button, FormValidator} from 'utils';
import {StepIndicator} from './StepIndicator';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';
import {CustomInputBoxStyles} from 'utils/CustomInputBox/CustomInputBoxStyles';

import {useCamera} from 'hooks';

import {utilityTypeOptions} from '../data';
import AppContext from 'components/App/AppContext';

export const AddressBill = () => {
    const canvas = useRef({});
    const videoRef = useRef({});
    const {colors} = useContext(AppContext);
    const {currentStep, result, handleStep} = useContext(AddressContext);

    const [step, setStep] = useState(1);
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({
        utilityType: result?.utilityType,
        utility: result?.utility,
    });
    const [formErrors, setFormErrors] = useState();

    const {startStream, stopStream} = useCamera({
        constraints: {
            video: {
                facingMode: {exact: 'environment'},
                width: {ideal: 1920},
                height: {ideal: 1080},
            },
        },
        onSuccess: stream => {
            setLoading(false);

            if ('srcObject' in videoRef.current) {
                videoRef.current.srcObject = stream;
            } else {
                videoRef.current.src = URL.createObjectURL(stream);
            }

            // videoRef.current.play();
        },
        onError: () => {
            setLoading(true);
            setErrorMsg('Camera not found');
        },
    });

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    useLayoutEffect(() => {
        if (step === 2) {
            startStream();
        }
    }, [step]);

    const takepicture = () => {
        const context = canvas.current.getContext('2d');
        context.imageSmoothingEnabled = false;

        const {videoWidth, videoHeight} = videoRef.current;

        canvas.current.width = videoWidth;
        canvas.current.height = videoHeight;
        context.drawImage(video, 0, 0, videoWidth, videoHeight);

        const data = canvas.current.toDataURL('image/jpeg');

        handleSelect('utility', data);
        stopStream();
    };

    const retakePicture = () => {
        handleSelect('utility');
        startStream();
    };

    const handleNext = e => {
        e.preventDefault();

        const {isValid, formErrors} = FormValidator(formValues);

        isValid ? handleStep({step: STEPS.HOUSE, data: formValues}) : setFormErrors(formErrors);
    };

    const url = formValues?.utility;

    return (
        <div className="d-flex flex-column align-items-center">
            {/* <div className="w-100">
                <BackArrowIcon
                    className="pointer back-arrow"
                    onClick={() => {
                        stopStream();
                        handleStep({step: STEPS.ADDRESS_STANDARD});
                    }}
                />
            </div> */}
            <StepIndicator currentStep={currentStep} />
            <AddressHeader
                title="Picture of Utility Bill"
                text="It must be a valid bill"
                cls="text-center mb-4"
            />
            <div className="mt-5 w-100">
                {step === 1 && (
                    <>
                        <p>Select a utility bill to upload</p>

                        <div className="d-flex flex-column gap-3">
                            {utilityTypeOptions.map(type => (
                                <div
                                    key={type?.value}
                                    className="bill d-flex align-items-center justify-content-between pointer"
                                    onClick={() => {
                                        handleSelect('utilityType', type);
                                        setStep(2);
                                    }}
                                >
                                    <div className="d-flex align-items-center">
                                        <DocumentIcon className="me-3" />
                                        {type?.label}
                                    </div>
                                    <ArrowRightIcon />
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {step === 2 && (
                    <div className="d-flex flex-column bill-payment">
                        <div className="W-100 image-frame">
                            {loading && (
                                <div className="image-frame-loading">
                                    <div className="h-100 flex__center">
                                        {errorMsg ? (
                                            <div className="d-flex flex-column">
                                                <h4 className="text-danger py-4">{errorMsg}</h4>
                                                <div className="d-flex justify-content-center w-100">
                                                    <Button
                                                        name="Retry"
                                                        cls="btn--sm btn--primary--bordered px-5"
                                                        handleClick={() => startStream}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <h4>Loading... Please Wait</h4>
                                        )}
                                    </div>
                                </div>
                            )}
                            <video
                                ref={videoRef}
                                className="reverse"
                                id="video"
                                style={{
                                    display: formValues?.utility || loading ? 'none' : 'block',
                                }}
                                autoPlay
                                muted
                                playsInline
                            >
                                Video stream not available.
                            </video>
                            <canvas className="d-none" ref={canvas} id="canvas"></canvas>
                            {url && <img className="reverse" src={url} />}
                        </div>
                        <CustomInputBoxStyles colors={colors}>
                            {typeof formErrors?.utility === 'string' && formErrors?.utility && (
                                <small className="input-text-error">
                                    <span className="me-2">
                                        <InfoCircleErrorIcon />
                                    </span>
                                    {formErrors?.utility}
                                </small>
                            )}
                        </CustomInputBoxStyles>
                        <div className="d-flex justify-content-center mb-5">
                            {!loading && !errorMsg && (
                                <Button
                                    name={formValues?.utility ? 'Re-scan Bill' : 'Scan Bill'}
                                    cls="btn--sm btn--primary--bordered px-5 mt-3 mb-5"
                                    handleClick={formValues?.utility ? retakePicture : takepicture}
                                />
                            )}
                        </div>
                        <Button
                            cls="w-100 btn btn-lg rounded-0"
                            name="Continue"
                            handleClick={handleNext}
                            disabled={!formValues?.utility}
                        />
                        <Button
                            cls="w-100 btn btn-lg btn--transparent black rounded-0 underline"
                            name="Back"
                            handleClick={() => {
                                setStep(1);
                                stopStream();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddressBill;
