import {Button, RingSpinner} from 'utils';

export const ComplianceLayout = ({
    children,
    showBtn,
    btnName = 'Continue',
    title,
    subText,
    handleClick,
    disableBtn,
}) => {
    const currentTitle = title ?? 'Integrate your verification suite';
    const subTitle =
        subText ??
        'We have created a verification suite for your account, but we need you to connect it with this account, Enter the password sent to your mail.';

    return (
        <div className="p-5">
            <h1 className="mt-5 mb-4">{currentTitle}</h1>

            <span className="text-secondary">{subTitle}</span>

            <div className="py-4">{children}</div>

            {showBtn && (
                <Button
                    name={btnName}
                    disabled={disableBtn}
                    cls="btn--lg btn--secondary my-4"
                    handleClick={handleClick}
                />
            )}
        </div>
    );
};

export const ComplianceLoading = ({text}) => {
    return (
        <ComplianceLayout>
            <div className="loader_wrapper">
                <div>
                    <RingSpinner cls="medium secondary mx-auto" text={' '} />
                    <h4 className="mt-5">
                        <b>{text}</b>
                    </h4>
                </div>
            </div>
        </ComplianceLayout>
    );
};
