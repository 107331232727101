import styled from 'styled-components';
import BusinessImage from 'assets/icons/business-bg.svg';

export const RegistrationStyles = styled.div`
    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 30%;
        height: 40%;
        background: url(${BusinessImage}) no-repeat;
        background-size: contain;
        z-index: 0;
        opacity: 0.5;
        background-position: bottom right;
    }

    &::before {
        left: 0;
        transform: rotateY(180deg);
    }

    &::after {
        right: 0;
    }

    &.index {
        .registration-wrapper {
            background-color: transparent;
            border: 0 !important;
        }
    }

    .text-primary {
        color: var(--primaryFull) !important;
    }
    .text-bold {
        font-family: 'gilroy-bold';
    }
    .text-left {
        text-align: left !important;
    }
    .max-w-lg {
        max-width: 900px;
    }
    .max-w-md {
        max-width: 576px;
    }
    .registration-wrapper {
        margin: 15px;
        padding: 30px;
        background-color: var(--whiteFull);
        position: relative;
        z-index: 2;
    }
    .registration-stepper {
        display: flex;
        gap: 12px;
        overflow-x: auto;

        .step {
            padding: 12px 8px;
            background-color: var(--grayFour);
            display: flex;
            gap: 12px;
            align-items: center;
            transition: 0.2s ease-in-out all;

            .circle {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                border-radius: 999999px;
                color: var(--grayOne);
                background-color: var(--grayThree);
            }

            &.active {
                flex-grow: 1;
                color: var(--primaryFull);
                background-color: #ecf1f6;

                .circle {
                    color: var(--whiteFull);
                    background-color: var(--primaryFull);
                }
            }

            &.completed {
                background-color: var(--successSec);
                .circle {
                    background-color: var(--whiteFull);
                }
            }
        }
    }

    .registration-landing {
        max-width: 408.37px;
        margin: 0 auto;
        h4 {
            font-family: 'gilroy-bold';
            font-size: 26px;
            font-weight: 700;
        }

        .instructions {
            margin-bottom: 24px;

            .icon {
                width: 5%;
            }

            p {
                width: 95%;
            }
        }
    }

    .structure-option {
        p {
            font-weight: 500;
            font-size: 14px;
        }
        li {
            font-size: 12px;
            list-style-position: inside;
            color: var(--grayOne);
            text-indent: -15px;
        }
    }

    .btn--gray {
        background-color: var(--grayFour) !important;
        color: var(--blackFull) !important;
    }

    .space-15 {
        padding: 15px;
    }

    .warning {
        background-color: var(--cautionOutline);
        color: var(--cautionFull);
    }

    .success {
        background-color: var(--successOutline);
        color: var(--successSec);
    }

    .error {
        background-color: var(--errorOutline);
        color: var(--errorMidFull);
    }

    .info-section {
        .key {
            font-weight: 500;
            color: var(--blackFull);
        }

        .value {
            color: var(--grayOne);
            text-align: right;
            font-weight: 400;
        }
    }

    .accordion {
        background-color: #ecf1f6;
        font-size: 14px;
    }

    .preview {
        background-color: #f7f7fa;
        padding: 16px;

        .title {
            font-size: 14px;
            font-weight: 500;
            &::after {
                content: '';
                /* display: block; */
                flex-grow: 1;
                width: 100%;
                height: 2px;
                background-color: var(--primaryFull);
            }
        }
    }

    .file-preview {
        padding: 10px 15px;
        background-color: var(--whiteFull);

        button {
            padding: 10px;
            height: 36px;
            border: 1px solid var(--grayThree);
            background-color: var(--grayFour);
            line-height: 0%;
        }
    }
`;
