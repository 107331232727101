export const BackArrowIcon = ({width = 41, height = 41, ...rest}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M15.3751 37.5846H25.6251C34.1667 37.5846 37.5834 34.168 37.5834 25.6263V15.3763C37.5834 6.83464 34.1667 3.41797 25.6251 3.41797H15.3751C6.83341 3.41797 3.41675 6.83464 3.41675 15.3763V25.6263C3.41675 34.168 6.83341 37.5846 15.3751 37.5846Z"
            stroke="#F4F4F4"
            strokeWidth={0.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.6525 26.5296L16.6392 20.4992L22.6525 14.4688"
            stroke="#7C7C7C"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
