import React from 'react';

export const ArrowDownIcon = ({color = '#82898F', width = '16', height = '16', className, id}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            id={id}
        >
            <path
                d="M11.9466 5.45312H7.79329H4.05329C3.41329 5.45312 3.09329 6.22646 3.54662 6.67979L6.99995 10.1331C7.55329 10.6865 8.45329 10.6865 9.00662 10.1331L10.32 8.81979L12.46 6.67979C12.9066 6.22646 12.5866 5.45312 11.9466 5.45312Z"
                fill={color}
            />
        </svg>
    );
};
