import {useMutation} from 'react-query';
import API from 'api';
import {businessRegistration} from 'api/routes/business';
import {useApp} from 'components/App/AppContext';

export const useRegisterBusiness = options => {
    const {params} = useApp();
    const {onMutate = () => {}, onSuccess = () => {}, onError = () => {}} = options || {};

    const {clientid, appname, apikey} = params || {};

    return useMutation({
        mutationFn: async payload => {
            const response = API.post(businessRegistration, payload, {
                headers: {clientid, appname, apikey},
            });

            return response;
        },
        onMutate,
        onSuccess,
        onError,
    });
};
