export const AddIcon = ({color = '#292D32', width = '24', height = '24', onClick = () => {}}) => {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onClick={onClick}>
            <path
                d="M6 12H18"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 18V6"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
