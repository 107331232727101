import {useContext, useState} from 'react';

import {BackArrowIcon} from 'assets/icons';

import {countryOptions} from '../data';
import {StepIndicator} from './StepIndicator';
import {Button, CustomSelect, FormValidator} from 'utils';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';

export const AddressCountry = () => {
    const {currentStep, result, handleStep} = useContext(AddressContext);

    const [formValues, setFormValues] = useState({country: result?.country});
    const [formErrors, setFormErrors] = useState({});

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    const handleNext = e => {
        e.preventDefault();

        const payload = {...formValues};

        const {formErrors, isValid} = FormValidator(payload);

        isValid ? handleStep({step: STEPS.ID, data: formValues}) : setFormErrors(formErrors);
    };
    return (
        <div className="d-flex flex-column align-items-center">
            <div className="w-100">
                <BackArrowIcon
                    className="pointer back-arrow"
                    onClick={() => handleStep({step: STEPS.HOME})}
                />
            </div>
            <StepIndicator currentStep={currentStep} />
            <AddressHeader
                title="Select Country"
                text="Enter your country"
                cls="text-center mb-4"
            />
            <div className="w-100 mb-5">
                <CustomSelect
                    formGroupCls="mb-5"
                    label="Country"
                    placeholder="Select your country"
                    options={countryOptions}
                    value={formValues?.country}
                    onChange={value => handleSelect('country', value)}
                    error={formErrors?.country}
                />
            </div>
            <Button cls="w-100 btn btn-lg rounded-0 mt-5" name="Next" handleClick={handleNext} />
        </div>
    );
};
