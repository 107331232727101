import * as React from 'react';
import {Alert, CustomInputBox} from 'utils';
import {useCompliance} from './ComplianceContent';
import {ComplianceLayout} from './ComplianceLayout';
import {maskText} from 'utils/helpers';

export const ComplianceAppInfo = () => {
    const {data, updateData, submitCompliance} = useCompliance();
    const {client_id, app, errorMsg} = data;
    const clientID = maskText(client_id, 3);
    const disableBtn = client_id && app;

    return (
        <ComplianceLayout disableBtn={!disableBtn} showBtn handleClick={submitCompliance}>
            {errorMsg && <Alert message={errorMsg} onClose={() => updateData({errorMsg: ''})} />}

            <div className="mt-4">
                <CustomInputBox
                    label="App Name"
                    name="app_name"
                    placeholder="Your app name"
                    value={app.app_name}
                    disabled={true}
                />

                <CustomInputBox
                    label="App ID"
                    name="id"
                    placeholder="Your app id"
                    value="***************"
                    disabled={true}
                />

                <CustomInputBox
                    label="Client ID"
                    name="client_id"
                    placeholder="Your client id"
                    value={clientID}
                    disabled={true}
                />
            </div>
            <div className="pt-4">
                <h4 className="mb-3">
                    <b>What your verification suite is for</b>
                </h4>

                <p className="text-secondary">
                    Your Verification suite account allows you to authenticate your merchant
                    customers’ identity. You are being charged for each of your customer’s
                    verification.
                </p>
            </div>
        </ComplianceLayout>
    );
};
