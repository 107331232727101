import {useState, useLayoutEffect} from 'react';

export const useDeviceType = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [hasBackCamera, setHasBackCamera] = useState(false);

    useLayoutEffect(() => {
        const checkDeviceType = async () => {
            const userAgent = navigator.userAgent || window.opera;

            setIsMobile(/iPhone|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(userAgent));

            if ('mediaDevices' in navigator && 'enumerateDevices' in navigator?.mediaDevices) {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const backCamera = devices.find(
                    device =>
                        device.kind === 'videoinput' && device.label.toLowerCase().includes('back')
                );
                setHasBackCamera(!!backCamera);
            }
        };

        checkDeviceType();
    }, []); // Empty dependency array to run the effect only on mount

    return {isMobile, hasBackCamera};
};
