import {createContext, useContext, useMemo, useState} from 'react';
import {useIsMutating} from 'react-query';
import {RegistrationStyles} from './RegistrationStyles';
import {businessRegistrationSteps} from '../data';
import {RegistrationHeader} from './RegistrationHeader';
import {RegistrationLanding} from './RegistrationLanding';
import {RegistrationNameCheck} from './RegistrationNameCheck';
import {RegistrationPersonalInformation} from './RegistrationPersonalInformation';
import {RegistrationPersonalAddress} from './RegistrationPersonalAddress';
import {RegistrationCompanyAddress} from './RegistrationCompanyAddress';
import {RegistrationBusinessInformation} from './RegistrationBusinessInformation';
import {RegistrationReview} from './RegistrationReview';

const BusinessRegistrationContext = createContext({
    currentStep: '',
    setCurrentStep: () => {},
    result: {},
    setResult: () => {},
    handleStep: () => {},
});

export const useBusinessRegistration = () => useContext(BusinessRegistrationContext);

export const BusinessRegistrationContent = () => {
    const [currentStep, setCurrentStep] = useState(businessRegistrationSteps.LANDING.step);
    const [result, setResult] = useState();

    const isMutating = useIsMutating({mutationKey: ['register-business']});

    const handleStep = ({step, data = {}}) => {
        setResult(prev => ({...prev, ...data}));
        setCurrentStep(step);
    };

    const value = useMemo(
        () => ({
            currentStep,
            setCurrentStep,
            result,
            setResult,
            handleStep,
        }),
        [currentStep, result]
    );

    const renderByStep = () => {
        switch (currentStep) {
            default:
            case businessRegistrationSteps.LANDING.step:
                return <RegistrationLanding />;
            case businessRegistrationSteps.NAME_CHECK.step:
                return <RegistrationNameCheck />;
            case businessRegistrationSteps.PERSONAL_INFORMATION.step:
                return <RegistrationPersonalInformation />;
            case businessRegistrationSteps.PROPRIETORS_ADDRESS.step:
                return <RegistrationPersonalAddress />;
            case businessRegistrationSteps.BUSINESS_INFORMATION.step:
                return <RegistrationBusinessInformation />;
            case businessRegistrationSteps.COMPANY_ADDRESS.step:
                return <RegistrationCompanyAddress />;
            case businessRegistrationSteps.REVIEW.step:
                return <RegistrationReview />;
        }
    };

    return (
        <BusinessRegistrationContext.Provider value={value}>
            <RegistrationStyles className={currentStep === businessRegistrationSteps.LANDING.step}>
                <div className="p-5 fw-bold fs-2 text-primary text-bold border-bottom">
                    Business Registration
                </div>
                <div className="registration-wrapper border">
                    {currentStep !== businessRegistrationSteps.LANDING.step && isMutating < 1 && (
                        <RegistrationHeader />
                    )}
                    <div
                        className={
                            currentStep !== businessRegistrationSteps.LANDING.step ? 'mt-5' : ''
                        }
                    >
                        {renderByStep()}
                    </div>
                </div>
            </RegistrationStyles>
        </BusinessRegistrationContext.Provider>
    );
};
