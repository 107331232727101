import {useState} from 'react';
import {CameraIcon, OfficeIcon} from 'assets/icons';
import {Button, CustomCheckBox} from 'utils';
import {useBusinessRegistration} from './RegistrationContent';
import {businessRegistrationSteps} from '../data';

export const RegistrationLanding = () => {
    const [accept, setAccept] = useState(false);

    const {handleStep} = useBusinessRegistration();

    const handleNext = e => {
        e.preventDefault();

        if (!accept) return;
        handleStep({step: businessRegistrationSteps.NAME_CHECK.step, data: {accept}});
    };

    return (
        <div className="registration-landing flex flex-column align-items-center">
            <h4 className="text-center" style={{color: '#071B2B'}}>
                Business Registration
            </h4>
            <OfficeIcon className="mb-5" />
            <div className="mx-4 px-5">
                <div className="instructions">
                    <div className="d-flex">
                        <CameraIcon className="me-3 icon" />
                        <p className="flex-grow-1">
                            Make sure you have your passport photograph handy.
                        </p>
                    </div>
                    <div className="d-flex">
                        <CameraIcon className="me-3 icon" />
                        <p className="flex-grow-1">
                            Make sure you have your means of identification handy.
                        </p>
                    </div>
                </div>
                <div className="w-100 d-flex flex-column align-items-center">
                    <CustomCheckBox
                        label="I agree to Blusalt terms of use and privacy Policy"
                        checked={accept}
                        onClick={() => setAccept(prev => !prev)}
                    />
                    <div className="w-100 px-4">
                        <Button
                            cls="btn w-100 btn-lg"
                            name="Continue"
                            disabled={accept === false}
                            handleClick={handleNext}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
