import styled from 'styled-components';

export const CustomInputBoxStyles = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1em;
    margin-bottom: 20px;
    justify-content: flex-start;

    @media (max-width: 767.9px) {
        width: 100%;
    }

    input {
        height: 40px;
    }

    input,
    textarea {
        padding: 11px 15px;
        line-height: inherit;
        font-style: normal;
        color: var(--blackFull);
        border-radius: 2px;
        // min-width: 75%;
        max-width: 100%;

        @media (max-width: 420px) {
            min-width: 70%;
        }

        &::placeholder {
            font: inherit;
            font-size: 14px;
            font-weight: 400;
            line-height: inherit;
            color: var(--grayOne);
        }

        &:disabled {
            background-color: var(--surfaceBackground);
            color: var(--grayOne);
        }
    }

    .form-group-label {
        color: var(--textTwo);
        padding-bottom: 4px;
        font-size: 12px;
        font-weight: 600;

        &.required {
            &::after {
                content: '*';
                color: red;
                margin-left: 3px;
            }
        }
    }

    .input-left {
        height: 40px;
        text-align: center;
        width: 150px;
    }
    .dropdown-bg {
        background-color: var(--secondaryOutline);
    }

    .input-wrapper {
        border-radius: 2px;
        border: 1px solid var(--surfaceFocus);

        @media (max-width: 579.5px) {
            width: 100%;
        }

        &.error {
            border: 1px solid var(--errorFull);
        }
        &.disabled {
            background-color: var(--surfaceBackground);
        }

        &.no-hover-color,
        &.no-hover-color:hover &.no-hover-color:focus-within {
            border: 1px solid var(--surfaceFocus) !important;

            &.error {
                border: 1px solid var(--errorFull) !important;
            }

            &.disabled {
                background-color: var(--surfaceBackground) !important;
            }
        }
    }

    .password-input-wrapper img {
        cursor: pointer;
    }

    .input-text-error {
        display: flex;
        align-items: center;
        padding-top: 7px;
        font-size: 0.9em;
        color: var(--errorFull);
        font-family: 'gilroy-regular';
    }

    &:hover,
    &:focus-within {
        .form-group-label,
        .info-text {
            color: var(--primaryFull);

            .question {
                color: var(--secondaryFull);
            }
        }
    }

    &:disabled {
        background-color: var(--surfaceBackground);
        border: 1px solid var(--surfaceBackground);
    }

    &.small-form-group {
        input {
            padding: 0.4rem 0.8rem;
            font-size: 1.1rem;
            background: unset !important;

            &::-webkit-calendar-picker-indicator {
                margin-left: 0px !important;
            }
        }
    }

    &.secondary-input {
        .input-wrapper {
            border: 1px solid var(--surfaceBackground) !important;

            &.no-hover-color,
            &.no-hover-color:hover,
            &.no-hover-color:focus-within {
                border: 1px solid var(--secondaryHover) !important;
            }

            &.error {
                border: 1px solid var(--errorFull);
            }
            &.disabled {
                background-color: var(--disabledGray);
            }
        }
    }

    &.small-input {
        input {
            min-width: 40%;
        }
    }

    &:hover,
    &:focus-within {
        .input-wrapper,
        .input-wrapper.error {
            border: 1px solid var(--primaryFull) !important;
        }
    }
    .show__password svg path {
        fill: var(--primaryFull) !important;
    }

    .react-tel-input {
        .form-control {
            width: 100%;
            padding-left: 68px;
            border: none;
            &:hover {
                border: none !important;
                outline: none !important;
            }
        }
        .flag-dropdown {
            border: none;
            background-color: var(--primaryThree) !important;
        }
        .selected-flag {
            width: 58px;
            padding: 0 0 0 18px;
        }
    }

    .date-icon-container {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: var(--primaryFull);
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .input-container-wrapper {
        .input-wrapper:hover,
        .input-wrapper:focus-within {
            border: 1px solid var(--primaryFull);
            input {
                border: none !important;
            }
        }
        .disabled {
            border: 1px solid var(--surfaceBackground);
            .date-icon-container {
                background-color: var(--surfaceBackground);
                svg mask {
                    fill: var(--surfaceBackground);
                }
            }
        }
        .react-datepicker {
            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
        }
        .react-datepicker__close-icon {
            background: none;
        }
        .react-datepicker__close-icon::after {
            background-color: var(--primaryFull);
        }
        &.date-input {
            background-color: var(--whiteFull);
        }
        &.date-input:hover,
        &.date-input:focus-within {
            cursor: pointer;
            svg path {
                fill: var(--primaryFull);
            }
        }
    }
    .date-input--bordered {
        .date-icon-container {
            background-color: var(--whiteFull);
        }
    }
    .inline-datepicker {
        .input-wrapper,
        .input-wrapper:hover {
            border: 1px solid transparent;
            .date-icon-container {
                display: none;
            }
        }
    }
    .date--select-date-picker {
        position: fixed;
        right: 15%;
        top: 27%;
        z-index: 10000;
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2rem !important;
        font-size: 1.5em;
    }
    .react-datepicker__current-month {
        padding: 0 14px 14px !important;
        font-size: 1.5em;
    }
    .react-datepicker__month-dropdown-container,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__year-dropdown-container,
    .react-datepicker__year-dropdown-container--select {
        padding: 14px !important;
        font-size: 1.5em !important;
    }
    .react-datepicker__day,
    .react-datepicker__day-name {
        padding: 3px 14px !important;
    }
    .react-datepicker,
    .react-datepicker * {
        border: 0px !important;
        background-color: var(--whiteFull) !important;
        color: var(--grayOne) !important;

        &:hover {
            background-color: var(--whiteFull) !important;
            z-index: 99999999 !important;
        }
        .react-datepicker__day--selected {
            color: var(--primaryFull) !important;
            font-family: 'gilroy-bold';
            font-size: 1.6rem;
        }

        .react-datepicker__header__dropdown {
            display: flex;
        }
    }
    .react-datepicker svg *,
    .react-datepicker__day-name {
        color: var(--grayOne) !important;
    }
    .react-datepicker__day--outside-month {
        color: transparent !important;
        pointer-events: none;
    }
    .react-datepicker__day--disabled {
        color: var(--surfaceBackground) !important;
    }
    .react-datepicker-popper {
        z-index: 100;
    }
    .custom__date {
        position: absolute;
        top: 40px;
        z-index: 9999;
        right: 0;
        padding: 20px 10px 0 0;
    }
`;
