import {AddIcon, PoweredIcon} from 'assets/icons';
import {CLOSE} from 'components/constants';

export const WidgetWrapper = ({children, closeStyle}) => {
    const sendEvent = data => window.parent.postMessage(data, '*');

    return (
        <>
            <div className="container">
                {children}

                <div
                    className={`close__widget ${closeStyle}`}
                    onClick={() => sendEvent({name: CLOSE})}
                >
                    <AddIcon />
                </div>

                <div className="widget__footer">
                    <p>
                        <PoweredIcon className="me-3" /> Powered by <span>Blusalt</span>
                    </p>
                </div>
            </div>
        </>
    );
};
