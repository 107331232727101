import * as React from 'react';
import QueryString from 'query-string';
import API, {paymentProAPI} from 'api';
import {apiVersion} from 'api/routes';
import {GetApps, automateCompliance, editApplications} from 'api/routes/compliance';
import {WidgetWrapper} from 'components/App/WidgetWrapper';
import {STEPS, getVerificationServices} from './utils';
import {checkSuccess, getError, getFormData} from 'utils/helpers';
import {ComplianceStart} from './ComplianceStart';
import {ComplianceLogin} from './ComplianceLogin';
import {ComplianceAppSelect} from './ComplianceAppSelect';
import {ComplianceAppInfo} from './ComplianceAppInfo';
import {ComplianceComplete} from './ComplianceComplete';
import {ComplianceWrapper} from './ComplianceStyles';
import {ComplianceLoading} from './ComplianceLayout';
import {useMutation, useQuery} from 'react-query';

export const ComplianceContext = React.createContext({});

export const useCompliance = () => React.useContext(ComplianceContext);

export const ComplianceContent = () => {
    const {compliance_token} = QueryString.parse(window.location.search);
    const [data, setData] = React.useState({step: STEPS.LOGIN});
    const {step, token, app, addService} = data || {};
    const allVerificationsServices = getVerificationServices();

    const serviceIds = React.useMemo(() => app?.app_services.map(({id}) => id), [app]) || [];
    const defaultServiceIds = React.useMemo(
        () => allVerificationsServices?.map(({id}) => id),
        [allVerificationsServices]
    );

    const allServicesAvailable = React.useMemo(
        () => defaultServiceIds?.every(serviceId => serviceIds?.includes(serviceId)),
        [defaultServiceIds, serviceIds]
    );

    const serviceNotAvailableIds =
        React.useMemo(
            () => defaultServiceIds?.filter(serviceId => !serviceIds?.includes(serviceId)),
            [defaultServiceIds, serviceIds]
        ) || [];
    const styleService = serviceId => {
        if (app) {
            return serviceIds?.includes(serviceId) ? 'success' : 'warning';
        }
        return '';
    };

    const updateData = (newData = {}) => {
        setData(prev => ({
            ...prev,
            ...newData,
        }));
    };

    const setError = (errorMsg, step = STEPS.LOGIN) => updateData({step, errorMsg});

    const getAppQuery = useQuery(
        ['get_app_query'],
        async () => {
            const response = await API.get(GetApps, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        },
        {
            onSuccess: ({results}) => {
                const currentApp = results?.applications?.find(({id}) => app?.id === id);
                updateData({...results, step: STEPS.APP_SELECT, app: currentApp ?? app});
            },
            onError: error => setError(getError(error)),
            retry: 0,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            enabled: !!token && step === STEPS.GET_ACCOUNT_INFO,
        }
    );

    const payloadServiceIds = [...serviceIds, ...serviceNotAvailableIds];

    const payload = getFormData(
        {service: payloadServiceIds},
        {noAttributesWithArrayNotation: true}
    );

    const corePayload = getFormData(
        {service: serviceNotAvailableIds},
        {noAttributesWithArrayNotation: true}
    );

    const {mutate: addServicesToApp} = useMutation(
        async () => {
            updateData({step: STEPS.ADD_SERVICES, errorMsg: ''});
            const response = await API.put(editApplications(app.id), corePayload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    clientid: data?.client_id,
                    appname: app?.app_name,
                    apikey: app?.api_key,
                },
            });
            return response;
        },
        {
            onSuccess: ({data, status}) => {
                const success = checkSuccess(status, data?.status);
                success
                    ? getAppQuery.refetch()
                    : updateData({step: STEPS.APP_SELECT, errorMsg: data?.message});
            },
            onError: error => {
                setError(getError(error), STEPS.APP_SELECT);
            },
        }
    );

    const getCompliancePayload = () => {
        const baseUrl = `${process.env.REACT_APP_BLUSALT_API}/${apiVersion.v2}`;
        const services = allVerificationsServices?.map(({service_label, service_url, payload}) => ({
            service_name: service_label,
            api_url: `${baseUrl}${service_url}`,
            payload,
        }));
        return {
            name: data?.organization_name,
            client_id: data?.client_id,
            api_key: app?.api_key,
            app_name: app?.app_name,
            services,
        };
    };

    const {mutate: submitCompliance} = useMutation(
        async () => {
            const payload = getCompliancePayload();
            updateData({step: STEPS.FINALIZE_INFO, errorMsg: ''});
            const response = await paymentProAPI.post(automateCompliance, payload, {
                headers: {
                    Authorization: `Bearer ${compliance_token}`,
                },
            });
            return response;
        },
        {
            onSuccess: ({data, status}) => {
                const success = checkSuccess(status, data?.success);
                success
                    ? updateData({step: STEPS.COMPLETE, ...data.data})
                    : updateData({step: STEPS.APP_INFO, errorMsg: data?.message});
            },
            onError: error => setError(getError(error), STEPS.APP_INFO),
        }
    );

    const proceedToAppInfo = () => {
        if (app) {
            if (allServicesAvailable) {
                updateData({step: STEPS.APP_INFO});
            } else if (addService) {
                addServicesToApp();
            }
        }
    };

    const renderBySteps = () => {
        switch (step) {
            case STEPS.START:
            default:
                return <ComplianceStart />;

            case STEPS.LOGIN:
                return <ComplianceLogin />;

            case STEPS.VERIFY_LOGIN:
                return <ComplianceLoading text="Verifying password..." />;

            case STEPS.GET_ACCOUNT_INFO:
                return <ComplianceLoading text="Getting account info..." />;

            case STEPS.ADD_SERVICES:
                return (
                    <ComplianceLoading
                        text={`Adding ${serviceNotAvailableIds.length} service(s) to ${app?.app_name}`}
                    />
                );

            case STEPS.FINALIZE_INFO:
                return <ComplianceLoading text="Finalizing info..." />;

            case STEPS.APP_SELECT:
                return <ComplianceAppSelect />;

            case STEPS.APP_INFO:
                return <ComplianceAppInfo />;

            case STEPS.COMPLETE:
                return <ComplianceComplete />;
        }
    };

    return (
        <ComplianceWrapper>
            <ComplianceContext.Provider
                value={{
                    data,
                    updateData,
                    setError,
                    compliance_token,
                    allServicesAvailable,
                    styleService,
                    proceedToAppInfo,
                    submitCompliance,
                    allVerificationsServices,
                }}
            >
                <WidgetWrapper closeStyle="inside">{renderBySteps()}</WidgetWrapper>
            </ComplianceContext.Provider>
        </ComplianceWrapper>
    );
};
