export const responseStatus = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    EMPTY: 'EMPTY',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
};

export const CLOSE = 'close';
export const DONE = 'done';
export const SUCCESS = 'SUCCESS';
export const SUCCESSFUL = 'SUCCESSFUL';
