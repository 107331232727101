import {apiVersion} from '.';

const baseURL = `${apiVersion.base}/Normal/SmartAddress`;

export const {verifyId, livenessCheck, updateAddress, validateAddress, getSavedAddress} = {
    verifyId: `${baseURL}/VerifyID`,
    livenessCheck: `${baseURL}/Liveness`,
    updateAddress: `${baseURL}/UpdateAddress`,
    validateAddress: `${baseURL}Validation`,
    getSavedAddress: `${baseURL}/VerificationDetails`,
};
