import {getError} from 'utils/helpers';
import {FailedState, RingSpinner} from 'utils';

export const WidgetLayout = ({
    errorTitle = 'Failed',
    currentQuery,
    children,
    errorMsg,
    colors = {},
}) => {
    const renderByStatus = () => {
        const {isLoading, isError, error, refetch} = currentQuery || {};

        if (isLoading) {
            return (
                <div className="flex__center min-height">
                    <RingSpinner colors={colors} />
                </div>
            );
        }

        if (isError || errorMsg)
            return (
                <div className="min-height flex__center">
                    <FailedState
                        onRetry={refetch}
                        title={errorTitle}
                        errorMsg={errorMsg || getError(error)}
                    />
                </div>
            );

        return <>{children}</>;
    };
    return <>{renderByStatus()}</>;
};
