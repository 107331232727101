import {useState, useEffect, useRef, useContext} from 'react';
import API from 'api';
import {Button} from 'utils';
import {STEPS} from './LivenessContent';
import AppContext from 'components/App/AppContext';
import {ProgressBar} from 'utils/ProgressBar/ProgressBar';
import {livenessVerification} from 'api/routes/smart_kyc';
import {getError} from 'utils/helpers';

const LivenessCheckContent = ({result, handleStep}) => {
    const videoRef = useRef({});
    const {params} = useContext(AppContext);
    const {clientid, appname, apikey} = params || {};

    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [countdown, setCountdown] = useState(3);
    const [recording, setRecording] = useState(false);
    const [cameraStream, setCameraStream] = useState(null);
    const [mediaRecorded, setMediaRecorded] = useState(null);
    const [errorMsg, setErrorMsg] = useState();
    const [currentBlobs, setCurrentBlobs] = useState([]);
    const [startFile, setStartFile] = useState('');
    const [progress, setProgress] = useState(0);
    const loadingDuration = 4000;

    const startRecording = () => {
        setRecording(true);
        mediaRecorded?.start();

        setTimeout(() => {
            setTimeout(() => {
                stopStream();
                setRecording(false);
            }, 1000);
        }, 4000);
    };

    useEffect(() => {
        let [timeout, progressTimeout] = ['', ''];
        if (countdown > 0 && countdown !== 0 && loading === false) {
            progressTimeout = setTimeout(() => setCountdown(prev => prev - 1), 1000);
        }
        if (progress < 100 && recording)
            progressTimeout = setTimeout(() => setProgress(progress + 1), loadingDuration / 100);
        return () => {
            clearTimeout(timeout);
            clearTimeout(progressTimeout);
        };
        // eslint-disable-next-line
    }, [countdown, progress, recording]);

    useEffect(() => {
        startCamera();
        return () => {
            stopStream();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cameraStream) {
            const mediaRecorder = new MediaRecorder(cameraStream, {
                videoBitsPerSecond: 2009000,
            });
            setMediaRecorded(mediaRecorder);
        }
    }, [cameraStream]);

    useEffect(() => {
        if (mediaRecorded) {
            mediaRecorded.addEventListener('dataavailable', e =>
                setCurrentBlobs(prev => [...prev, e.data])
            );
            mediaRecorded.addEventListener('stop', async () => setStartFile(true));
        }
    }, [mediaRecorded]);

    useEffect(() => {
        if (startFile && currentBlobs) {
            const blob = new Blob(currentBlobs, {type: 'video/mp4'});
            const file = new File([blob], 'liveness.mp4', {
                type: 'video/mp4',
            });
            if (file) handleVerification(file);
            else {
                setErrorMsg('Liveness Verification Failed!');
            }
        }
        // eslint-disable-next-line
    }, [currentBlobs, startFile]);

    const startCamera = async () => {
        videoRef.current.src = '';

        const currentCameraStream = await navigator.mediaDevices
            .getUserMedia({video: true, audio: true})
            .then(stream => {
                setLoading(false);
                setTimeout(() => setCountdown(prev => prev - 1), 1000);
                return stream;
            });
        setCameraStream(currentCameraStream);

        videoRef.current.srcObject = currentCameraStream;
        videoRef.current.play();
    };

    const stopStream = () => {
        const newStream = cameraStream
            ? cameraStream.getTracks().forEach(function (track) {
                  track.stop();
              })
            : null;
        const newRecording = recording ? mediaRecorded.stop() : null;

        setCameraStream(newStream);
        setMediaRecorded(newRecording);
    };

    const handleVerification = async file => {
        setStep(2);
        setErrorMsg();

        const headers = {clientid, appname, apikey};

        const formData = new FormData();
        formData.append('video', file, 'liveness.mp4');
        formData.append('phone_number', result.phone);

        await API.post(livenessVerification, formData, {headers})
            .then(result => {
                const {status, data} = result || {};
                if (status === 200 || status === 201) {
                    handleStep({step: STEPS.RESULT, data: {file, ...(data?.data || {})}});
                } else setErrorMsg(data?.message);
            })
            .catch(error => {
                setErrorMsg(getError(error));
            });
    };

    const reset = () => {
        setErrorMsg();
        setCountdown(3);
        setLoading(true);
        setRecording(false);
        setCameraStream(null);
        setMediaRecorded(null);
        setStep(1);
        setProgress(0);
        videoRef.current = {};
        startCamera();
    };

    const renderByStep = () => {
        if (step === 1)
            return (
                <ProgressBar progress={progress}>
                    {loading ? (
                        <div className="video-frame-loading">
                            <div className="h-100 flex__center">
                                <h4>Loading... Please Wait</h4>
                            </div>
                        </div>
                    ) : (
                        <video muted ref={videoRef} id="video">
                            Video stream not available.
                        </video>
                    )}
                </ProgressBar>
            );

        if (step === 2) {
            return (
                <div className="verification-status">
                    <div className="text-center">
                        {errorMsg ? (
                            <>
                                <h4 className="py-4">{errorMsg}</h4>
                                <div className="flex__between">
                                    <Button
                                        name="Back"
                                        cls="btn--sm btn--secondary--bordered me-4 px-5"
                                        handleClick={() => handleStep({step: STEPS.PHONE})}
                                    />
                                    <Button
                                        name="Retry"
                                        cls="btn--sm btn--primary--bordered me-4 px-5"
                                        handleClick={reset}
                                    />
                                </div>
                            </>
                        ) : (
                            <h4 className="heading3">Verification in Progress. Please Wait...</h4>
                        )}
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <div className="video-frame py-5 mt-4">
                <div className="frame-edge" />
                <div className="frame-edge right" />
                <div className="frame-edge bottom-left" />
                <div className="frame-edge bottom-right" />
                <div className="flex__center">{renderByStep()}</div>
            </div>

            {step === 1 && (
                <Button
                    name={recording ? 'Liveness check in progress' : 'Start Liveness Check'}
                    cls="btn--sm btn--primary--bordered ms-5 px-5"
                    disabled={countdown !== 0 || progress > 1}
                    handleClick={startRecording}
                />
            )}
        </>
    );
};

export default LivenessCheckContent;
