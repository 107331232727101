import QueryString from 'query-string';
import {Button} from 'utils';
import {DONE} from 'components/constants';
import {flattenObject} from 'utils/helpers';

export const LivenessCheckAcceptedResult = ({result = {}}) => {
    const {liveness = {}, time_used, face_attributes = {}, result_ref1} = result;

    const sendEvent = () =>
        window.parent.postMessage(
            {
                name: DONE,
                result,
                resultQuery: QueryString.stringify(flattenObject(result)),
            },
            '*'
        );

    return (
        <>
            <ul>
                <li>
                    <span>Procedure Validation</span>
                    <p
                        className={
                            liveness?.procedure_validation === 'PASSED'
                                ? 'text-success'
                                : 'text-warning'
                        }
                    >
                        {liveness?.procedure_validation || 'Information not available'}
                    </p>
                </li>
                <li>
                    <span>Face Genuineness</span>
                    <p
                        className={
                            liveness?.face_genuineness === 'PASSED'
                                ? 'text-success'
                                : 'text-warning'
                        }
                    >
                        {liveness?.procedure_validation || 'Information not available'}
                    </p>
                </li>
                <li>
                    <span>Image Quality</span>
                    <p>{face_attributes?.image_quality?.value || 'Information not available'}</p>
                </li>
                <li>
                    <span>Confidence</span>
                    <p>{result_ref1?.confidence || 'Information not available'}</p>
                </li>
                <li>
                    <span>Glasses</span>
                    <p>{face_attributes?.glass || 'Information not available'}</p>
                </li>

                <li>
                    <span>Time used</span>
                    <p>{time_used ? `${time_used / 100} Seconds` : 'Information not available'} </p>
                </li>
                <li>
                    <span>Phone Nuumber</span>
                    <p>{result?.phone || 'Information not available'}</p>
                </li>
            </ul>

            <div className="col-7 pt-3 ">
                <Button name="Done" cls="btn--lg" handleClick={sendEvent} />
            </div>
        </>
    );
};
