import {AsYouType, parsePhoneNumber} from 'libphonenumber-js';

import {appendPlusToNumber} from './helpers';

export const FormValidator = (formObject, maxFileSize = 5) => {
    const isObject = obj => {
        return Object.prototype.toString.call(obj) === '[object Object]';
    };

    const isEmpty = fieldValue => {
        let isEmpty;

        if (typeof fieldValue === 'string') {
            isEmpty = fieldValue.trim() === '' || fieldValue === undefined;
        }

        if (typeof fieldValue === 'undefined') {
            isEmpty = true;
        }

        if (fieldValue === null) {
            isEmpty = true;
        }

        if (Array.isArray(fieldValue)) {
            isEmpty = fieldValue.length === 0 || fieldValue === undefined;
        }

        if (isObject(fieldValue)) {
            isEmpty = !Object.values(fieldValue).some(val => val);
        }

        return isEmpty;
    };

    let formErrors = {};
    let isValid = true;

    Object.keys(formObject).forEach(field => {
        const value = formObject[field];
        let fileSize = value?.size || value?.[0]?.size;

        if (isEmpty(value)) {
            formErrors[field] = 'This field cannot be empty';
        }
        if (field === 'email' || field === 'companyEmail') {
            const isEmailValid =
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    formObject[field]
                );
            formErrors[field] = isEmailValid ? false : 'please enter a valid email';
        }
        if (field === 'password' || field === 'newPassword') {
            if (formObject[field].length < 8) {
                const isPasswordShort = formObject[field].length > 8;
                formErrors[field] = isPasswordShort
                    ? false
                    : 'Password must have at least 8 characters';
            } else {
                const isPasswordValid = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(
                    formObject[field]
                );
                formErrors[field] = isPasswordValid
                    ? false
                    : 'Password must have at least 1 uppercase, 1 number and 1 special character';
            }
        }
        if (field === 'phoneNumber' || field === 'phone_number') {
            const value = appendPlusToNumber(formObject[field]);

            if (!value) formErrors[field] = 'Please enter a valid phone number';
            else {
                const formatted = new AsYouType().input(value);
                const countryCode = formatted.split(' ')[0];

                try {
                    const isValid = parsePhoneNumber(formObject[field], {
                        defaultCallingCode: countryCode.replace(/\+/g, ''),
                    }).isValid();

                    if (!isValid) {
                        formErrors[field] = 'Please enter a valid phone number';
                    }
                } catch (e) {
                    formErrors[field] = 'Please enter a valid phone number';
                }
            }
        }
        if (field === 'bvn') {
            if (!formObject[field]) {
                formErrors[field] = `This field cannot be empty`;
            }
            if (formObject[field] && formObject[field].length !== 11) {
                formErrors[field] = `${field.toUpperCase()} should contain exactly 11 numbers`;
            }
        }

        if (
            (value instanceof File || value?.[0] instanceof File) &&
            fileSize / 1024 / 1024 > maxFileSize
        ) {
            formErrors[field] = `File too large. Max of ${maxFileSize}MB`;
        }
    });

    Object.values(formErrors).forEach(error => {
        if (error || error.length > 0) isValid = false;
    });

    return {formErrors, isValid};
};
