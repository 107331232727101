import styled from 'styled-components';

export const CustomSelectStyles = styled.div`
    ${({colors}) => `
         .form-group-select,
    .form-group-select_error,
    .form-group-select--is-multi,
    .form-group-select--is-multi_error {
        width: 100% !important;

        [class*='-control'] {
            color: inherit;
            margin-top: 0 !important;
            padding: 0 !important;
            border: none;
            border-radius: 2px !important;
            box-shadow: none !important;
            min-height: 42px;

            svg {
                @include rot-0;
            }

            &:hover,
            &:focus-within svg path {
                fill: ${colors?.primaryFull} !important;
            }

            &:focus-within svg {
                @include rot-180;
            }
        }

        [class*='__menu'] {
            overflow-x: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        [class*='-option'] {
            background-color: ${colors?.whiteFull} !important ;
            color: unset !important;
        }
        [class*='-option']:hover {
            background-color: ${colors?.primaryFull}  !important ;
            color:  ${colors?.whiteFull} !important;
        }
        [class*='-container']:focus {
            border: none !important;
        }
        [class*='-control'],
        [class*='__indicators'],
        [class*='__value-container'],
        [class*='__input-container'],
        input[class*='__input'] {
            height: 40px !important;
            margin: 0px !important;

            &::placeholder {
                font: inherit;
                font-size: 14px;
                font-weight: 400;
                line-height: inherit;
                color:  ${colors?.grayOne};
            }
        }

        span {
            display: none;
        }

        .form-group-select__indicator,
        .form-group-select--is-multi__indicator {
            padding: 0 10px !important;

            svg {
                height: 25px !important;
                width: 25px !important;

                path {
                    fill: ${colors?.surfaceBackground} !important;
                }
            }
        }

        .form-group-select__control--is-disabled {
            background-color: ${colors?.surfaceBackground};

            .form-group-select__single-value--is-disabled {
                 color:  ${colors?.grayOne};
            }

            svg {
                height: 25px !important;
                width: 25px !important;

                path {
                    fill:${colors?.grayOne}!important;
                }
            }
        }

        .form-group-select__placeholder,
        .form-group-select__single-value {
            margin-top: 3px;
            font-size: 14px;
        }

        .form-group-select__placeholder {
            color:  ${colors?.grayOne};
        }
    }

    .secondary-select {
        .input-wrapper {
            border-color: ${colors?.secondaryMid};

            .form-group-select,
            .form-group-select--is-multi {
                [class*='-control'] {
                    border: none !important;

                    svg path {
                        fill:${colors?.secondaryMid} !important;
                    }
                }

                &:hover,
                &:focus-within {
                    [class*='-control'] svg path {
                        fill: ${colors?.primaryFull} !important;
                    }
                }
            }
        }
    }

    .form-group-select_error,
    .form-group-select--is-multi_error {
        [class*='-control'] {
            border: none !important;
        }

        [class*='__indicators'] svg path {
            fill:  ${colors?.errorFull}  !important;
        }

        &:hover,
        &:focus-within {
            [class*='-control'] svg path {
                fill:  ${colors?.primaryFull} !important;
            }
        }
    }
    
   `}
`;
