import * as React from 'react';
import {ComplianceLayout} from './ComplianceLayout';
import {Alert, CustomCheckBox, CustomInputBox, CustomSelect} from 'utils';
import {useCompliance} from './ComplianceContent';

export const ComplianceAppSelect = () => {
    const {
        data,
        updateData,
        allServicesAvailable,
        styleService,
        proceedToAppInfo,
        allVerificationsServices,
    } = useCompliance();
    const {organization_name, addService, app, applications, errorMsg} = data || {};
    const showCheckBox = !allServicesAvailable && app;
    const disableBtn = !app || (!allServicesAvailable && !addService);

    return (
        <ComplianceLayout
            disableBtn={disableBtn}
            showBtn
            btnName={allServicesAvailable || !app ? 'Continue' : 'Add service(s) to application'}
            handleClick={proceedToAppInfo}
        >
            {errorMsg && <Alert message={errorMsg} onClose={() => updateData({errorMsg: ''})} />}

            <div className="pt-4">
                <CustomInputBox
                    label="Business Name"
                    name="organization_name"
                    placeholder="Your Business Name"
                    value={organization_name}
                    disabled={true}
                />

                <CustomSelect
                    label="Application"
                    placeholder="Choose an application"
                    options={applications}
                    value={app}
                    onChange={value => updateData({app: value})}
                    getOptionLabel={({app_name}) => app_name}
                    isClearable
                />

                <p className="text-secondary">
                    All needed verifications, Choose an application for all these verifications
                </p>

                {app && (
                    <div className="check">
                        <div>
                            <div className="box success" />
                            Included
                        </div>
                        <div>
                            <div className="box warning" />
                            Not Included
                        </div>
                    </div>
                )}

                <div className="services">
                    {allVerificationsServices?.map(({id, service_label}) => (
                        <div key={id} className={`service ${styleService(id)}`}>
                            {service_label}
                        </div>
                    ))}
                </div>

                {showCheckBox && (
                    <div className="add_service">
                        <CustomCheckBox
                            label="Add the other verifications not marked green"
                            checked={addService}
                            onClick={() => updateData({addService: !addService})}
                        />
                    </div>
                )}
            </div>
        </ComplianceLayout>
    );
};
