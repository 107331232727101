import {useContext, useState} from 'react';

import {CameraIcon, HouseIcon} from 'assets/icons';

import {Button, CustomCheckBox} from 'utils';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';

export const AddressLanding = () => {
    const [accept, setAccept] = useState(false);

    const {isMini, handleStep} = useContext(AddressContext);

    const handleNext = e => {
        e.preventDefault();

        if (!accept) return;
        handleStep({step: isMini ? STEPS.ID : STEPS.COUNTRY, data: {accept}});
    };
    return (
        <div className="d-flex flex-column align-items-center">
            <AddressHeader title="Address Verification" cls="landing-header" />
            <HouseIcon className="house" />
            <div className="mx-4">
                <div className="instructions">
                    <div className="d-flex">
                        <CameraIcon className="me-3 icon" />
                        <p className="flex-grow-1">
                            Get ready to take a selfie and a picture of the building you live in.
                        </p>
                    </div>
                    <div className="d-flex">
                        <CameraIcon className="me-3 icon" />
                        <p className="flex-grow-1">
                            Make sure you have either of these documents (Utility Bill, Waste Bill,
                            Bank Statement) handy.
                        </p>
                    </div>
                    <div className="d-flex">
                        <CameraIcon className="me-3 icon" />
                        <p className="flex-grow-1">Ensure you are using a mobile device</p>
                    </div>
                </div>
                <div className="w-100">
                    <CustomCheckBox
                        label="I agree to Blusalt terms of use and privacy Policy"
                        checked={accept}
                        onClick={() => setAccept(prev => !prev)}
                    />
                    <Button
                        cls="w-100 btn btn-lg rounded-0"
                        name="Start Verification"
                        disabled={accept === false}
                        handleClick={handleNext}
                    />
                </div>
            </div>
        </div>
    );
};
