import {useState} from 'react';
import {RegistrationTitle} from './RegistrationTitle';
import {useBusinessRegistration} from './RegistrationContent';
import {CustomInputBox, CountrySelect, Button, StateSelect, FormValidator, Alert} from 'utils';
import {useRegisterBusiness} from 'hooks';
import {businessRegistrationSteps} from '../data';
import {fetchValues, getError} from 'utils/helpers';

const defaultValues = {
    business_state: '',
    business_city: '',
    business_street_number: '',
    business_street_name: '',
    business_email: '',
};

export const RegistrationCompanyAddress = () => {
    const {setCurrentStep, result, handleStep} = useBusinessRegistration();
    const [formValues, setFormValues] = useState({
        ...defaultValues,
        ...fetchValues(defaultValues, result),
    });
    const [formErrors, setFormErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState();

    const {mutate, isLoading} = useRegisterBusiness({
        onMutate: () => setErrorMsg(),
        onSuccess: ({data, status}) => {
            if (
                (status === 200 || status === 201) &&
                (data?.status_code === 200 || data?.status_code === 201)
            ) {
                handleStep({
                    step: businessRegistrationSteps.REVIEW.step,
                    data: {
                        ...formValues,
                        completed_step: Math.max(
                            result?.completed_step || 0,
                            parseInt(data?.data?.completed_step)
                        ),
                        business_address_result: data?.data,
                    },
                });
            } else setErrorMsg(data?.message);
        },
        onError: error => setErrorMsg(getError(error)),
    });

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const goBack = () => setCurrentStep(businessRegistrationSteps.BUSINESS_INFORMATION.step);

    const handleSubmit = e => {
        e.preventDefault();

        const {isValid, formErrors} = FormValidator(formValues);

        if (!isValid) return setFormErrors(formErrors);

        const payload = {
            ...formValues,
            step: '5',
            business_country: 'Nigeria',
            request_id: result?.request_id,
            business_state: formValues?.business_state?.label,
        };

        if ((result?.completed_step || 0) >= 5) {
            payload.backStep = '5';
            delete payload?.step;
        }

        mutate(payload);
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <RegistrationTitle
                title="Company Address"
                subtitle="We never share this information for marketing purposes. Don't have an office? It's fine to use a home address."
            />
            {errorMsg && (
                <div className="pb-4">
                    <Alert message={errorMsg} onClose={() => setErrorMsg()} />
                </div>
            )}
            <div className="overflow-auto" style={{maxHeight: '300px'}}>
                <div className="row mt-4">
                    <div className="col-12">
                        <CountrySelect
                            label="Country"
                            placeholder="Select Country"
                            name="country"
                            value={{label: 'Nigeria'}}
                            disabled
                        />
                    </div>
                    <div className="col-12">
                        <StateSelect
                            countryCode="NG"
                            label="State"
                            placeholder="State"
                            name="business_state"
                            onChange={value => handleSelect('business_state', value)}
                            value={formValues?.business_state}
                            error={formErrors?.business_state}
                        />
                    </div>
                    <div className="col-lg-6 col-12">
                        <CustomInputBox
                            label="Company Street Number"
                            name="business_street_number"
                            onChange={handleChange}
                            placeholder="Enter Company Street Number"
                            value={formValues?.business_street_number}
                            error={formErrors.business_street_number}
                        />
                    </div>
                    <div className="col-lg-6 col-12">
                        <CustomInputBox
                            label="Company Street Name"
                            name="business_street_name"
                            onChange={handleChange}
                            placeholder="Enter Company Street Name"
                            value={formValues?.business_street_name}
                            error={formErrors.business_street_name}
                        />
                    </div>
                    <div className="col-12">
                        <CustomInputBox
                            label="City"
                            name="business_city"
                            onChange={handleChange}
                            placeholder="Enter Company City"
                            value={formValues?.business_city}
                            error={formErrors.business_city}
                        />
                    </div>
                    <div className="col-12">
                        <CustomInputBox
                            type="email"
                            label="Email"
                            name="business_email"
                            onChange={handleChange}
                            placeholder="Enter Company Email"
                            value={formValues?.business_email}
                            error={formErrors.business_email}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex gap-3 mt-5">
                <Button
                    cls="btn w-100 btn-lg btn--gray"
                    name="Back"
                    handleClick={goBack}
                    disabled={isLoading}
                />
                <Button cls="btn w-100 btn-lg" type="submit" name="Proceed" disabled={isLoading} />
            </div>
        </form>
    );
};
