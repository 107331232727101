export const businessRegistrationSteps = {
    LANDING: {step: 0, text: 'Landing'},
    NAME_CHECK: {step: 1, text: 'Name Check'},
    PERSONAL_INFORMATION: {step: 2, text: 'Personal Information'},
    PROPRIETORS_ADDRESS: {step: 3, text: "Proprietor's Address"},
    BUSINESS_INFORMATION: {step: 4, text: 'Business Information'},
    COMPANY_ADDRESS: {step: 5, text: 'Company Address'},
    REVIEW: {step: 6, text: 'Review and Submit'},
};

export const businessRegistrationTypes = [
    {
        name: 'Private Limited Company (LTD)',
        value: 'LTD',
        points: [
            'Limited to a maximum of 50 shareholders.',
            'Shares are not available to the public.',
            "Shareholders' liability is limited to the amount unpaid on their shares.",
        ],
    },
    {
        name: 'Public Limited Company (PLC)',
        value: 'PLC',
        points: [
            'Unlimited number of shareholders.',
            'Shares can be offered to the public and traded on the stock exchange.',
            "Shareholders' liability is limited to the amount unpaid on their shares.",
        ],
    },
    {
        name: 'Company Limited By Guarantee',
        value: 'CLG',
        points: [
            'Typically used for non-profit purposes, such as charities, clubs, and societies.',
            'Profits cannot be distributed to members.',
            "Members' liability is limited to the amount they agree to contribute to the company's assets in case of winding up.",
        ],
    },
    {
        name: 'Unlimited Company',
        value: 'UC',
        points: [
            "Shareholders have unlimited liability, meaning they are personally responsible for the company's debts and liabilities.",
            'Higher financial risk for shareholders.',
            'Financial statements are not required to be filed publicly, offering greater privacy.',
        ],
    },
    {
        name: 'Incorporated Trustees (NGO)',
        value: 'NGO',
        points: [
            'Established for religious, educational, literary, scientific, social, development, cultural, sporting, or charitable purposes.',
            'Managed by a board of trustees.',
            'Profits and income must be used for promoting the objectives of the organization, not distributed to members.',
        ],
    },
];

export const genderOptions = [
    {label: 'Male', value: 'male'},
    {label: 'Female', value: 'female'},
];

export const businessLineOptions = [
    {label: 'General merchandise', value: 'General merchandise'},
    {label: 'Trading', value: 'Trading'},
    {label: 'Fashion design/Tailoring', value: 'Fashion design/Tailoring'},
    {label: 'ICT service', value: 'ICT service'},
    {label: 'Data analysis', value: 'Data analysis'},
    {label: 'Poultry/Livestock farming', value: 'Poultry/Livestock farming'},
    {
        label: 'Crop production farming/Agro allied service',
        value: 'Crop production farming/Agro allied service',
    },
    {label: 'Hair stylist/salon', value: 'Hair stylist/salon'},
    {label: 'Solar panel installation', value: 'Solar panel installation'},
    {label: 'Digital marketing', value: 'Digital marketing'},
    {label: 'Graphic design', value: 'Graphic design'},
    {label: 'Content creation', value: 'Content creation'},
    {label: 'Web design', value: 'Web design'},
    {label: 'POS agent', value: 'POS agent'},
];

export const idTypesOptions = [
    {
        label: 'National ID',
        value: 'national_id',
    },
    {
        label: 'Passport',
        value: 'passport',
    },
    {label: 'Driver License', value: 'driver_license'},
];
