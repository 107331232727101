export const PoweredIcon = ({
    color = '#292D32',
    width = '16',
    height = '20',
    onClick = () => {},
    className,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            onClick={onClick}
            className={className}
        >
            <path
                d="M13.91 8.72002H10.82V1.52002C10.82 -0.15998 9.91001 -0.499981 8.80001 0.760019L8.00001 1.67002L1.23001 9.37002C0.300011 10.42 0.690012 11.28 2.09001 11.28H5.18001V18.48C5.18001 20.16 6.09001 20.5 7.20001 19.24L8.00001 18.33L14.77 10.63C15.7 9.58002 15.31 8.72002 13.91 8.72002Z"
                fill={color}
            />
        </svg>
    );
};
