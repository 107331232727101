import {Button} from 'utils';
import {STEPS} from './LivenessContent';
import {testLogic} from './logic';

export const LivenessCheckRejectedResult = ({result = {}, handleStep}) => {
    const logics = testLogic(result);

    return (
        <>
            <div className="pt-3 verif_not-accepted">
                <h5 className="fw-bold">
                    Your Liveness was rejected because of the following reason:
                </h5>

                <ol>
                    {logics?.imageQualityLogic && (
                        <li>
                            <p>
                                Your image quality is very low. Please do this in a very lighten
                                environment{' '}
                            </p>
                        </li>
                    )}
                    {logics?.procedureValidationLogic && (
                        <li>
                            <p> You failed the procedure validation.</p>
                        </li>
                    )}
                    {logics?.screenPlayLogic && (
                        <li>
                            <p>This check was done with a recorded video with screen play.</p>
                        </li>
                    )}
                    {!logics.screenPlayLogic && logics?.faceGenuinenessLogic && (
                        <li>
                            <p>
                                Your face is not clear enough. Please do this in a very lighten
                                environment
                            </p>
                        </li>
                    )}
                    {logics?.glassLogic && (
                        <li>
                            <p>Using of glasses is not allowed.</p>
                        </li>
                    )}
                    {(logics?.leftEyesStatusLogic || logics?.rightEyesStatusLogic) && (
                        <li>
                            <p>One of your eyes is closed. Please open your eyes and try again</p>
                        </li>
                    )}
                    {logics?.mouthSurgicalMaskLogic && (
                        <li>
                            <p>
                                Your mouth is being blocked/covered by an object (e.g Surgical
                                Mask).
                            </p>
                        </li>
                    )}
                    {logics?.mouthOcclusionLogic && (
                        <li>
                            <p>Your mouth is being blocked/covered by an object (e.g Face Mask)</p>
                        </li>
                    )}
                    {logics?.mouthCloseLogic && (
                        <li>
                            <p>You opened your mouth. Please close your mouth and try again</p>
                        </li>
                    )}
                </ol>

                <div className="col-7">
                    <Button
                        name="Redo Liveness Check"
                        cls="btn--lg"
                        handleClick={() => handleStep({step: STEPS.CHECK})}
                    />
                </div>
            </div>
        </>
    );
};
