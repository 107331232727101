export const TickIcon = props => (
    <svg
        width={11}
        height={9}
        viewBox="0 0 11 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.73762 6.60587L4.09048 6.95734L4.44333 6.60587L9.54305 1.52623C9.63567 1.43397 9.7964 1.43397 9.88903 1.52623C9.98057 1.6174 9.98057 1.77411 9.88903 1.86528L4.26347 7.4687L4.61632 7.82295L4.26347 7.4687C4.21742 7.51457 4.15543 7.54036 4.09048 7.54036C4.02552 7.54036 3.96354 7.51457 3.91749 7.4687L3.56463 7.82295L3.91749 7.4687L1.10967 4.67193C1.01813 4.58076 1.01813 4.42405 1.10967 4.33288C1.20229 4.24062 1.36302 4.24062 1.45565 4.33288L3.73762 6.60587Z"
            fill="#292D32"
            stroke="#3CD382"
        />
    </svg>
);
