import styled from 'styled-components';

export const RegistrationTitle = ({title, subtitle}) => {
    return (
        <RegistrationTitleStyles>
            <h4>{title}</h4>
            <p>{subtitle}</p>
        </RegistrationTitleStyles>
    );
};

const RegistrationTitleStyles = styled.div`
    margin-bottom: 31px;

    h4 {
        font-size: 18px;
        font-family: 'gilroy-bold';
        font-weight: 600;
        color: #071b2b;
        line-height: 22.05px;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 0;
        color: var(--grayOne);
        font-size: 14px;
        font-weight: 500;
        line-height: 16.98px;
    }
`;
