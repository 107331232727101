import {useContext} from 'react';
import {toLower} from 'lodash';

import {BackArrowIcon, ErrorIconTwo, SuccessIcon} from 'assets/icons';

import {Button} from 'utils';
import {StepIndicator} from './StepIndicator';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';

export const AddressLivenessResult = () => {
    const {currentStep, result, handleStep, isContinue} = useContext(AddressContext);

    const isFaceGenuine =
        toLower(result?.liveness_result?.liveness?.face_genuineness) === toLower('PASSED');
    const isProcedureValid =
        toLower(result?.liveness_result?.liveness?.procedure_validation) === toLower('PASSED');

    const handleNext = e => {
        e.preventDefault();

        if (isFaceGenuine && isProcedureValid) handleStep({step: STEPS.ADDRESS_STANDARD});
    };

    const handleBack = e => {
        e.preventDefault();
        handleStep({step: STEPS.LIVENESS});
    };
    return (
        <div className="d-flex flex-column align-items-center">
            {/* <div className="w-100">
                <BackArrowIcon
                    className="pointer back-arrow"
                    onClick={() => handleStep({step: STEPS.LIVENESS})}
                />
            </div> */}
            <StepIndicator currentStep={currentStep} />
            <AddressHeader
                title="Liveness Done"
                text="Preview of information gotten from facial comparison"
                cls="text-center mb-4"
            />
            <div className="d-flex gap-3 justify-content-between mb-5">
                <img
                    className="liveness-image"
                    src={result?.liveness_result?.id_image_url}
                    alt=""
                    srcset=""
                />
                <img
                    className="liveness-image"
                    src={result?.liveness_result?.liveness_image_url}
                    alt=""
                    srcset=""
                />
            </div>
            <div className="info-section w-100 px-5">
                <div className="d-flex justify-content-between">
                    <p className="key">Probability or Resemblance</p>
                    <p className="value">{result?.liveness_result?.percentage_resemblance}%</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Face Genuieness</p>
                    <p className="value">
                        {isFaceGenuine ? (
                            <SuccessIcon width={20} height={20} />
                        ) : (
                            <ErrorIconTwo width={20} height={20} />
                        )}
                    </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Procedure Validation</p>
                    <p className="value">
                        {isProcedureValid ? (
                            <SuccessIcon width={20} height={20} />
                        ) : (
                            <ErrorIconTwo width={20} height={20} />
                        )}
                    </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="key">Gender</p>
                    <p className="value">{result?.id_result?.gender}</p>
                </div>
            </div>
            <Button
                cls="w-100 btn btn-lg rounded-0 mt-5"
                name="Continue"
                handleClick={handleNext}
                disabled={!(isFaceGenuine && isProcedureValid)}
            />
            {!isContinue && (
                <Button
                    cls="w-100 btn btn-lg btn--transparent black rounded-0 underline"
                    name="Back"
                    handleClick={handleBack}
                />
            )}
        </div>
    );
};
