import {TickIcon} from 'assets/icons';
import {businessRegistrationSteps} from '../data';
import {useBusinessRegistration} from './RegistrationContent';

export const RegistrationHeader = () => {
    const {currentStep} = useBusinessRegistration();

    const values = Object.values(businessRegistrationSteps).slice(1);

    return (
        <div className="registration-stepper">
            {values.map(({step, text}) => (
                <div
                    key={step}
                    className={`step ${currentStep === step ? 'active' : ''} ${
                        currentStep > step ? 'completed' : ''
                    }`}
                >
                    <div className="circle">{currentStep > step ? <TickIcon /> : step}</div>
                    {currentStep === step && (
                        <p className="text-primary text-bold mb-0 text-nowrap">{text}</p>
                    )}
                </div>
            ))}
        </div>
    );
};
