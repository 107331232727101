export const OfficeIcon = props => (
    <svg
        width={409}
        height={306}
        viewBox="0 0 409 306"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_9915_139886)">
            <path d="M408.377 268.277H0.00195312V268.482H408.377V268.277Z" fill="#EBEBEB" />
            <path d="M367.457 281.418H340.406V281.622H367.457V281.418Z" fill="#EBEBEB" />
            <path d="M270.523 283.641H263.426V283.845H270.523V283.641Z" fill="#EBEBEB" />
            <path d="M339.591 273.84H323.918V274.044H339.591V273.84Z" fill="#EBEBEB" />
            <path d="M78.125 275.213H42.8496V275.417H78.125V275.213Z" fill="#EBEBEB" />
            <path d="M90.5724 275.213H85.4023V275.417H90.5724V275.213Z" fill="#EBEBEB" />
            <path d="M183.894 278.66H107.381V278.864H183.894V278.66Z" fill="#EBEBEB" />
            <path
                d="M193.579 231.852H35.8648C33.292 231.852 31.2012 229.761 31.2012 227.188V5.49762C31.2012 2.92486 33.292 0.833984 35.8648 0.833984H193.579C196.152 0.833984 198.243 2.92486 198.243 5.49762V227.188C198.243 229.761 196.152 231.852 193.579 231.852ZM35.8648 1.03817C33.4064 1.03817 31.4054 3.03921 31.4054 5.49762V227.188C31.4054 229.646 33.4064 231.647 35.8648 231.647H193.579C196.038 231.647 198.039 229.646 198.039 227.188V5.49762C198.039 3.03921 196.038 1.03817 193.579 1.03817H35.8648Z"
                fill="#EBEBEB"
            />
            <path
                d="M370.241 231.852H212.527C209.954 231.852 207.863 229.761 207.863 227.188V5.49762C207.863 2.92486 209.954 0.833984 212.527 0.833984H370.241C372.814 0.833984 374.905 2.92486 374.905 5.49762V227.188C374.905 229.761 372.814 231.852 370.241 231.852ZM212.527 1.03817C210.068 1.03817 208.067 3.03921 208.067 5.49762V227.188C208.067 229.646 210.068 231.647 212.527 231.647H370.241C372.7 231.647 374.701 229.646 374.701 227.188V5.49762C374.701 3.03921 372.7 1.03817 370.241 1.03817H212.527Z"
                fill="#EBEBEB"
            />
            <path d="M346.231 54.5449H207.775V209.662H346.231V54.5449Z" fill="#EBEBEB" />
            <path d="M343.511 57.0449H207.775V206.91H343.511V57.0449Z" fill="#FAFAFA" />
            <path
                d="M338.496 136.587V126.059H335.482V119.043H331.472V108.516H330.476V119.043H326.457V126.059H323.452V136.587H317.939V139.601H315.431V151.134H314.925V134.586H313.422V145.114H311.919V120.048H310.914V130.576H306.904V127.064H305.401V112.526H302.396V97.9795H301.391V99.9887H280.834V93.9693H277.322V70.9043H275.819V93.9693H270.804V99.9887H268.795V130.07H258.267V121.045H261.779V119.542H258.267V117.034H248.237V93.9693H245.73V90.4654H233.699V93.9693H232.196V126.059H226.683V140.606H223.669V129.073H221.162V126.059H215.649V115.531H212.643V108.516H209.131V97.9795H207.775V206.909H343.511V136.587H338.496Z"
                fill="#F0F0F0"
            />
            <path d="M289.933 101.561H288.512V102.982H289.933V101.561Z" fill="#FAFAFA" />
            <path d="M289.933 103.945H288.512V105.366H289.933V103.945Z" fill="#FAFAFA" />
            <path d="M289.933 113.469H288.512V114.89H289.933V113.469Z" fill="#FAFAFA" />
            <path d="M289.933 115.852H288.512V117.273H289.933V115.852Z" fill="#FAFAFA" />
            <path d="M289.933 118.238H288.512V119.659H289.933V118.238Z" fill="#FAFAFA" />
            <path d="M289.933 125.385H288.512V126.806H289.933V125.385Z" fill="#FAFAFA" />
            <path d="M289.933 127.762H288.512V129.183H289.933V127.762Z" fill="#FAFAFA" />
            <path d="M287.679 111.09H286.258V112.511H287.679V111.09Z" fill="#FAFAFA" />
            <path d="M287.679 113.469H286.258V114.89H287.679V113.469Z" fill="#FAFAFA" />
            <path d="M287.679 115.852H286.258V117.273H287.679V115.852Z" fill="#FAFAFA" />
            <path d="M287.679 118.238H286.258V119.659H287.679V118.238Z" fill="#FAFAFA" />
            <path d="M287.679 120.613H286.258V122.034H287.679V120.613Z" fill="#FAFAFA" />
            <path d="M287.679 125.385H286.258V126.806H287.679V125.385Z" fill="#FAFAFA" />
            <path d="M287.679 127.762H286.258V129.183H287.679V127.762Z" fill="#FAFAFA" />
            <path d="M285.417 101.561H283.996V102.982H285.417V101.561Z" fill="#FAFAFA" />
            <path d="M285.417 103.945H283.996V105.366H285.417V103.945Z" fill="#FAFAFA" />
            <path d="M285.417 106.328H283.996V107.749H285.417V106.328Z" fill="#FAFAFA" />
            <path d="M285.417 108.707H283.996V110.128H285.417V108.707Z" fill="#FAFAFA" />
            <path d="M285.417 111.09H283.996V112.511H285.417V111.09Z" fill="#FAFAFA" />
            <path d="M285.417 113.469H283.996V114.89H285.417V113.469Z" fill="#FAFAFA" />
            <path d="M285.417 115.852H283.996V117.273H285.417V115.852Z" fill="#FAFAFA" />
            <path d="M285.417 118.238H283.996V119.659H285.417V118.238Z" fill="#FAFAFA" />
            <path d="M285.417 120.613H283.996V122.034H285.417V120.613Z" fill="#FAFAFA" />
            <path d="M285.417 123H283.996V124.421H285.417V123Z" fill="#FAFAFA" />
            <path d="M285.417 127.762H283.996V129.183H285.417V127.762Z" fill="#FAFAFA" />
            <path d="M283.163 101.561H281.742V102.982H283.163V101.561Z" fill="#FAFAFA" />
            <path d="M283.163 103.945H281.742V105.366H283.163V103.945Z" fill="#FAFAFA" />
            <path d="M283.163 106.328H281.742V107.749H283.163V106.328Z" fill="#FAFAFA" />
            <path d="M283.163 108.707H281.742V110.128H283.163V108.707Z" fill="#FAFAFA" />
            <path d="M283.163 111.09H281.742V112.511H283.163V111.09Z" fill="#FAFAFA" />
            <path d="M283.163 113.469H281.742V114.89H283.163V113.469Z" fill="#FAFAFA" />
            <path d="M283.163 115.852H281.742V117.273H283.163V115.852Z" fill="#FAFAFA" />
            <path d="M283.163 137.293H281.742V138.714H283.163V137.293Z" fill="#FAFAFA" />
            <path d="M280.907 101.561H279.486V102.982H280.907V101.561Z" fill="#FAFAFA" />
            <path d="M280.907 103.945H279.486V105.366H280.907V103.945Z" fill="#FAFAFA" />
            <path d="M280.907 106.328H279.486V107.749H280.907V106.328Z" fill="#FAFAFA" />
            <path d="M280.907 108.707H279.486V110.128H280.907V108.707Z" fill="#FAFAFA" />
            <path d="M280.907 111.09H279.486V112.511H280.907V111.09Z" fill="#FAFAFA" />
            <path d="M280.907 113.469H279.486V114.89H280.907V113.469Z" fill="#FAFAFA" />
            <path d="M280.907 115.852H279.486V117.273H280.907V115.852Z" fill="#FAFAFA" />
            <path d="M280.907 118.238H279.486V119.659H280.907V118.238Z" fill="#FAFAFA" />
            <path d="M280.907 132.521H279.486V133.943H280.907V132.521Z" fill="#FAFAFA" />
            <path d="M280.907 134.908H279.486V136.329H280.907V134.908Z" fill="#FAFAFA" />
            <path d="M280.907 137.293H279.486V138.714H280.907V137.293Z" fill="#FAFAFA" />
            <path d="M280.907 139.67H279.486V141.091H280.907V139.67Z" fill="#FAFAFA" />
            <path d="M280.907 149.201H279.486V150.622H280.907V149.201Z" fill="#FAFAFA" />
            <path d="M278.654 101.561H277.232V102.982H278.654V101.561Z" fill="#FAFAFA" />
            <path d="M278.654 103.945H277.232V105.366H278.654V103.945Z" fill="#FAFAFA" />
            <path d="M278.654 106.328H277.232V107.749H278.654V106.328Z" fill="#FAFAFA" />
            <path d="M278.654 108.707H277.232V110.128H278.654V108.707Z" fill="#FAFAFA" />
            <path d="M278.654 111.09H277.232V112.511H278.654V111.09Z" fill="#FAFAFA" />
            <path d="M278.654 113.469H277.232V114.89H278.654V113.469Z" fill="#FAFAFA" />
            <path d="M278.654 115.852H277.232V117.273H278.654V115.852Z" fill="#FAFAFA" />
            <path d="M278.654 118.238H277.232V119.659H278.654V118.238Z" fill="#FAFAFA" />
            <path d="M278.654 120.613H277.232V122.034H278.654V120.613Z" fill="#FAFAFA" />
            <path d="M278.654 123H277.232V124.421H278.654V123Z" fill="#FAFAFA" />
            <path d="M278.654 130.146H277.232V131.568H278.654V130.146Z" fill="#FAFAFA" />
            <path d="M278.654 132.521H277.232V133.943H278.654V132.521Z" fill="#FAFAFA" />
            <path d="M278.654 134.908H277.232V136.329H278.654V134.908Z" fill="#FAFAFA" />
            <path d="M278.654 137.293H277.232V138.714H278.654V137.293Z" fill="#FAFAFA" />
            <path d="M278.654 139.67H277.232V141.091H278.654V139.67Z" fill="#FAFAFA" />
            <path d="M278.654 149.201H277.232V150.622H278.654V149.201Z" fill="#FAFAFA" />
            <path d="M276.39 101.561H274.969V102.982H276.39V101.561Z" fill="#FAFAFA" />
            <path d="M276.39 103.945H274.969V105.366H276.39V103.945Z" fill="#FAFAFA" />
            <path d="M276.39 106.328H274.969V107.749H276.39V106.328Z" fill="#FAFAFA" />
            <path d="M276.39 108.707H274.969V110.128H276.39V108.707Z" fill="#FAFAFA" />
            <path d="M276.39 111.09H274.969V112.511H276.39V111.09Z" fill="#FAFAFA" />
            <path d="M276.39 113.469H274.969V114.89H276.39V113.469Z" fill="#FAFAFA" />
            <path d="M276.39 115.852H274.969V117.273H276.39V115.852Z" fill="#FAFAFA" />
            <path d="M276.39 118.238H274.969V119.659H276.39V118.238Z" fill="#FAFAFA" />
            <path d="M276.39 120.613H274.969V122.034H276.39V120.613Z" fill="#FAFAFA" />
            <path d="M276.39 123H274.969V124.421H276.39V123Z" fill="#FAFAFA" />
            <path d="M276.39 125.385H274.969V126.806H276.39V125.385Z" fill="#FAFAFA" />
            <path d="M276.39 127.762H274.969V129.183H276.39V127.762Z" fill="#FAFAFA" />
            <path d="M276.39 130.146H274.969V131.568H276.39V130.146Z" fill="#FAFAFA" />
            <path d="M276.39 132.521H274.969V133.943H276.39V132.521Z" fill="#FAFAFA" />
            <path d="M276.39 134.908H274.969V136.329H276.39V134.908Z" fill="#FAFAFA" />
            <path d="M276.39 137.293H274.969V138.714H276.39V137.293Z" fill="#FAFAFA" />
            <path d="M276.39 139.67H274.969V141.091H276.39V139.67Z" fill="#FAFAFA" />
            <path d="M276.39 142.055H274.969V143.476H276.39V142.055Z" fill="#FAFAFA" />
            <path d="M274.136 101.561H272.715V102.982H274.136V101.561Z" fill="#FAFAFA" />
            <path d="M274.136 103.945H272.715V105.366H274.136V103.945Z" fill="#FAFAFA" />
            <path d="M274.136 106.328H272.715V107.749H274.136V106.328Z" fill="#FAFAFA" />
            <path d="M274.136 108.707H272.715V110.128H274.136V108.707Z" fill="#FAFAFA" />
            <path d="M274.136 111.09H272.715V112.511H274.136V111.09Z" fill="#FAFAFA" />
            <path d="M274.136 113.469H272.715V114.89H274.136V113.469Z" fill="#FAFAFA" />
            <path d="M274.136 115.852H272.715V117.273H274.136V115.852Z" fill="#FAFAFA" />
            <path d="M274.136 118.238H272.715V119.659H274.136V118.238Z" fill="#FAFAFA" />
            <path d="M274.136 120.613H272.715V122.034H274.136V120.613Z" fill="#FAFAFA" />
            <path d="M274.136 123H272.715V124.421H274.136V123Z" fill="#FAFAFA" />
            <path d="M274.136 130.146H272.715V131.568H274.136V130.146Z" fill="#FAFAFA" />
            <path d="M274.136 132.521H272.715V133.943H274.136V132.521Z" fill="#FAFAFA" />
            <path d="M274.136 134.908H272.715V136.329H274.136V134.908Z" fill="#FAFAFA" />
            <path d="M274.136 137.293H272.715V138.714H274.136V137.293Z" fill="#FAFAFA" />
            <path d="M271.882 101.561H270.461V102.982H271.882V101.561Z" fill="#FAFAFA" />
            <path d="M271.882 103.945H270.461V105.366H271.882V103.945Z" fill="#FAFAFA" />
            <path d="M271.882 106.328H270.461V107.749H271.882V106.328Z" fill="#FAFAFA" />
            <path d="M271.882 108.707H270.461V110.128H271.882V108.707Z" fill="#FAFAFA" />
            <path d="M271.882 111.09H270.461V112.511H271.882V111.09Z" fill="#FAFAFA" />
            <path d="M271.882 113.469H270.461V114.89H271.882V113.469Z" fill="#FAFAFA" />
            <path d="M271.882 115.852H270.461V117.273H271.882V115.852Z" fill="#FAFAFA" />
            <path d="M271.882 118.238H270.461V119.659H271.882V118.238Z" fill="#FAFAFA" />
            <path d="M271.882 120.613H270.461V122.034H271.882V120.613Z" fill="#FAFAFA" />
            <path d="M271.882 123H270.461V124.421H271.882V123Z" fill="#FAFAFA" />
            <path d="M271.882 130.146H270.461V131.568H271.882V130.146Z" fill="#FAFAFA" />
            <path d="M271.882 132.521H270.461V133.943H271.882V132.521Z" fill="#FAFAFA" />
            <path d="M271.882 134.908H270.461V136.329H271.882V134.908Z" fill="#FAFAFA" />
            <path d="M246.474 95.377H245.053V96.7981H246.474V95.377Z" fill="#FAFAFA" />
            <path d="M246.474 97.7617H245.053V99.1829H246.474V97.7617Z" fill="#FAFAFA" />
            <path d="M246.474 100.148H245.053V101.57H246.474V100.148Z" fill="#FAFAFA" />
            <path d="M246.474 102.523H245.053V103.945H246.474V102.523Z" fill="#FAFAFA" />
            <path d="M246.474 104.91H245.053V106.331H246.474V104.91Z" fill="#FAFAFA" />
            <path d="M246.474 107.285H245.053V108.706H246.474V107.285Z" fill="#FAFAFA" />
            <path d="M246.474 109.67H245.053V111.091H246.474V109.67Z" fill="#FAFAFA" />
            <path d="M246.474 112.055H245.053V113.476H246.474V112.055Z" fill="#FAFAFA" />
            <path d="M246.474 119.193H245.053V120.614H246.474V119.193Z" fill="#FAFAFA" />
            <path d="M246.474 121.578H245.053V122.999H246.474V121.578Z" fill="#FAFAFA" />
            <path d="M244.22 95.377H242.799V96.7981H244.22V95.377Z" fill="#FAFAFA" />
            <path d="M244.22 97.7617H242.799V99.1829H244.22V97.7617Z" fill="#FAFAFA" />
            <path d="M244.22 100.148H242.799V101.57H244.22V100.148Z" fill="#FAFAFA" />
            <path d="M244.22 102.523H242.799V103.945H244.22V102.523Z" fill="#FAFAFA" />
            <path d="M244.22 104.91H242.799V106.331H244.22V104.91Z" fill="#FAFAFA" />
            <path d="M244.22 107.285H242.799V108.706H244.22V107.285Z" fill="#FAFAFA" />
            <path d="M244.22 109.67H242.799V111.091H244.22V109.67Z" fill="#FAFAFA" />
            <path d="M244.22 112.055H242.799V113.476H244.22V112.055Z" fill="#FAFAFA" />
            <path d="M244.22 121.578H242.799V122.999H244.22V121.578Z" fill="#FAFAFA" />
            <path d="M241.966 95.377H240.545V96.7981H241.966V95.377Z" fill="#FAFAFA" />
            <path d="M241.966 97.7617H240.545V99.1829H241.966V97.7617Z" fill="#FAFAFA" />
            <path d="M241.966 100.148H240.545V101.57H241.966V100.148Z" fill="#FAFAFA" />
            <path d="M241.966 102.523H240.545V103.945H241.966V102.523Z" fill="#FAFAFA" />
            <path d="M241.966 104.91H240.545V106.331H241.966V104.91Z" fill="#FAFAFA" />
            <path d="M241.966 107.285H240.545V108.706H241.966V107.285Z" fill="#FAFAFA" />
            <path d="M241.966 109.67H240.545V111.091H241.966V109.67Z" fill="#FAFAFA" />
            <path d="M241.966 112.055H240.545V113.476H241.966V112.055Z" fill="#FAFAFA" />
            <path d="M239.712 95.377H238.291V96.7981H239.712V95.377Z" fill="#FAFAFA" />
            <path d="M239.712 97.7617H238.291V99.1829H239.712V97.7617Z" fill="#FAFAFA" />
            <path d="M239.712 100.148H238.291V101.57H239.712V100.148Z" fill="#FAFAFA" />
            <path d="M239.712 102.523H238.291V103.945H239.712V102.523Z" fill="#FAFAFA" />
            <path d="M239.712 104.91H238.291V106.331H239.712V104.91Z" fill="#FAFAFA" />
            <path d="M239.712 107.285H238.291V108.706H239.712V107.285Z" fill="#FAFAFA" />
            <path d="M239.712 112.055H238.291V113.476H239.712V112.055Z" fill="#FAFAFA" />
            <path d="M239.712 114.432H238.291V115.853H239.712V114.432Z" fill="#FAFAFA" />
            <path d="M239.712 121.578H238.291V122.999H239.712V121.578Z" fill="#FAFAFA" />
            <path d="M239.712 123.963H238.291V125.384H239.712V123.963Z" fill="#FAFAFA" />
            <path d="M239.712 126.34H238.291V127.761H239.712V126.34Z" fill="#FAFAFA" />
            <path d="M239.712 128.725H238.291V130.146H239.712V128.725Z" fill="#FAFAFA" />
            <path d="M239.712 131.104H238.291V132.525H239.712V131.104Z" fill="#FAFAFA" />
            <path d="M239.712 133.486H238.291V134.907H239.712V133.486Z" fill="#FAFAFA" />
            <path d="M239.712 135.871H238.291V137.292H239.712V135.871Z" fill="#FAFAFA" />
            <path d="M237.448 95.377H236.027V96.7981H237.448V95.377Z" fill="#FAFAFA" />
            <path d="M237.448 97.7617H236.027V99.1829H237.448V97.7617Z" fill="#FAFAFA" />
            <path d="M237.448 100.148H236.027V101.57H237.448V100.148Z" fill="#FAFAFA" />
            <path d="M237.448 102.523H236.027V103.945H237.448V102.523Z" fill="#FAFAFA" />
            <path d="M237.448 104.91H236.027V106.331H237.448V104.91Z" fill="#FAFAFA" />
            <path d="M237.448 107.285H236.027V108.706H237.448V107.285Z" fill="#FAFAFA" />
            <path d="M237.448 114.432H236.027V115.853H237.448V114.432Z" fill="#FAFAFA" />
            <path d="M237.448 116.816H236.027V118.238H237.448V116.816Z" fill="#FAFAFA" />
            <path d="M237.448 119.193H236.027V120.614H237.448V119.193Z" fill="#FAFAFA" />
            <path d="M237.448 121.578H236.027V122.999H237.448V121.578Z" fill="#FAFAFA" />
            <path d="M237.448 123.963H236.027V125.384H237.448V123.963Z" fill="#FAFAFA" />
            <path d="M237.448 126.34H236.027V127.761H237.448V126.34Z" fill="#FAFAFA" />
            <path d="M237.448 128.725H236.027V130.146H237.448V128.725Z" fill="#FAFAFA" />
            <path d="M237.448 131.104H236.027V132.525H237.448V131.104Z" fill="#FAFAFA" />
            <path d="M237.448 133.486H236.027V134.907H237.448V133.486Z" fill="#FAFAFA" />
            <path d="M237.448 135.871H236.027V137.292H237.448V135.871Z" fill="#FAFAFA" />
            <path d="M237.448 138.248H236.027V139.669H237.448V138.248Z" fill="#FAFAFA" />
            <path d="M237.448 143.01H236.027V144.431H237.448V143.01Z" fill="#FAFAFA" />
            <path d="M235.195 95.377H233.773V96.7981H235.195V95.377Z" fill="#FAFAFA" />
            <path d="M235.195 97.7617H233.773V99.1829H235.195V97.7617Z" fill="#FAFAFA" />
            <path d="M235.195 100.148H233.773V101.57H235.195V100.148Z" fill="#FAFAFA" />
            <path d="M235.195 102.523H233.773V103.945H235.195V102.523Z" fill="#FAFAFA" />
            <path d="M235.195 104.91H233.773V106.331H235.195V104.91Z" fill="#FAFAFA" />
            <path d="M235.195 107.285H233.773V108.706H235.195V107.285Z" fill="#FAFAFA" />
            <path d="M235.195 114.432H233.773V115.853H235.195V114.432Z" fill="#FAFAFA" />
            <path d="M235.195 116.816H233.773V118.238H235.195V116.816Z" fill="#FAFAFA" />
            <path d="M235.195 119.193H233.773V120.614H235.195V119.193Z" fill="#FAFAFA" />
            <path d="M235.195 121.578H233.773V122.999H235.195V121.578Z" fill="#FAFAFA" />
            <path d="M235.195 128.725H233.773V130.146H235.195V128.725Z" fill="#FAFAFA" />
            <path d="M235.195 131.104H233.773V132.525H235.195V131.104Z" fill="#FAFAFA" />
            <path d="M235.195 133.486H233.773V134.907H235.195V133.486Z" fill="#FAFAFA" />
            <path d="M235.195 135.871H233.773V137.292H235.195V135.871Z" fill="#FAFAFA" />
            <path d="M235.195 138.248H233.773V139.669H235.195V138.248Z" fill="#FAFAFA" />
            <path d="M235.195 143.01H233.773V144.431H235.195V143.01Z" fill="#FAFAFA" />
            <path d="M235.195 145.395H233.773V146.816H235.195V145.395Z" fill="#FAFAFA" />
            <path d="M214.716 117.527H213.295V118.948H214.716V117.527Z" fill="#FAFAFA" />
            <path d="M214.716 119.902H213.295V121.323H214.716V119.902Z" fill="#FAFAFA" />
            <path d="M214.716 122.289H213.295V123.71H214.716V122.289Z" fill="#FAFAFA" />
            <path d="M214.716 124.674H213.295V126.095H214.716V124.674Z" fill="#FAFAFA" />
            <path d="M214.716 127.051H213.295V128.472H214.716V127.051Z" fill="#FAFAFA" />
            <path d="M214.716 129.436H213.295V130.857H214.716V129.436Z" fill="#FAFAFA" />
            <path d="M214.716 131.812H213.295V133.234H214.716V131.812Z" fill="#FAFAFA" />
            <path d="M214.716 138.959H213.295V140.38H214.716V138.959Z" fill="#FAFAFA" />
            <path d="M214.716 141.342H213.295V142.763H214.716V141.342Z" fill="#FAFAFA" />
            <path d="M214.716 143.721H213.295V145.142H214.716V143.721Z" fill="#FAFAFA" />
            <path d="M212.464 117.527H211.043V118.948H212.464V117.527Z" fill="#FAFAFA" />
            <path d="M212.464 119.902H211.043V121.323H212.464V119.902Z" fill="#FAFAFA" />
            <path d="M212.464 122.289H211.043V123.71H212.464V122.289Z" fill="#FAFAFA" />
            <path d="M212.464 124.674H211.043V126.095H212.464V124.674Z" fill="#FAFAFA" />
            <path d="M212.464 127.051H211.043V128.472H212.464V127.051Z" fill="#FAFAFA" />
            <path d="M212.464 129.436H211.043V130.857H212.464V129.436Z" fill="#FAFAFA" />
            <path d="M212.464 131.812H211.043V133.234H212.464V131.812Z" fill="#FAFAFA" />
            <path d="M210.21 117.527H208.168V118.948H210.21V117.527Z" fill="#FAFAFA" />
            <path d="M210.21 119.902H208.168V121.323H210.21V119.902Z" fill="#FAFAFA" />
            <path d="M210.21 122.289H208.168V123.71H210.21V122.289Z" fill="#FAFAFA" />
            <path d="M210.21 124.674H208.168V126.095H210.21V124.674Z" fill="#FAFAFA" />
            <path d="M210.21 127.051H208.168V128.472H210.21V127.051Z" fill="#FAFAFA" />
            <path d="M210.21 129.436H208.168V130.857H210.21V129.436Z" fill="#FAFAFA" />
            <path d="M210.21 131.812H208.168V133.234H210.21V131.812Z" fill="#FAFAFA" />
            <path d="M334.306 120.613H332.885V122.034H334.306V120.613Z" fill="#FAFAFA" />
            <path d="M334.306 123H332.885V124.421H334.306V123Z" fill="#FAFAFA" />
            <path d="M334.306 125.385H332.885V126.806H334.306V125.385Z" fill="#FAFAFA" />
            <path d="M334.306 127.762H332.885V129.183H334.306V127.762Z" fill="#FAFAFA" />
            <path d="M334.306 130.146H332.885V131.568H334.306V130.146Z" fill="#FAFAFA" />
            <path d="M334.306 132.521H332.885V133.943H334.306V132.521Z" fill="#FAFAFA" />
            <path d="M334.306 134.908H332.885V136.329H334.306V134.908Z" fill="#FAFAFA" />
            <path d="M334.306 137.293H332.885V138.714H334.306V137.293Z" fill="#FAFAFA" />
            <path d="M334.306 139.67H332.885V141.091H334.306V139.67Z" fill="#FAFAFA" />
            <path d="M334.306 142.055H332.885V143.476H334.306V142.055Z" fill="#FAFAFA" />
            <path d="M334.306 144.432H332.885V145.853H334.306V144.432Z" fill="#FAFAFA" />
            <path d="M334.306 146.816H332.885V148.238H334.306V146.816Z" fill="#FAFAFA" />
            <path d="M334.306 149.201H332.885V150.622H334.306V149.201Z" fill="#FAFAFA" />
            <path d="M334.306 151.578H332.885V152.999H334.306V151.578Z" fill="#FAFAFA" />
            <path d="M334.306 153.963H332.885V155.384H334.306V153.963Z" fill="#FAFAFA" />
            <path d="M334.306 156.34H332.885V157.761H334.306V156.34Z" fill="#FAFAFA" />
            <path d="M334.306 158.725H332.885V160.146H334.306V158.725Z" fill="#FAFAFA" />
            <path d="M334.306 161.107H332.885V162.529H334.306V161.107Z" fill="#FAFAFA" />
            <path d="M334.306 163.486H332.885V164.907H334.306V163.486Z" fill="#FAFAFA" />
            <path d="M334.306 165.871H332.885V167.292H334.306V165.871Z" fill="#FAFAFA" />
            <path d="M332.052 120.613H330.631V122.034H332.052V120.613Z" fill="#FAFAFA" />
            <path d="M332.052 123H330.631V124.421H332.052V123Z" fill="#FAFAFA" />
            <path d="M332.052 125.385H330.631V126.806H332.052V125.385Z" fill="#FAFAFA" />
            <path d="M332.052 127.762H330.631V129.183H332.052V127.762Z" fill="#FAFAFA" />
            <path d="M332.052 130.146H330.631V131.568H332.052V130.146Z" fill="#FAFAFA" />
            <path d="M332.052 132.521H330.631V133.943H332.052V132.521Z" fill="#FAFAFA" />
            <path d="M332.052 134.908H330.631V136.329H332.052V134.908Z" fill="#FAFAFA" />
            <path d="M332.052 137.293H330.631V138.714H332.052V137.293Z" fill="#FAFAFA" />
            <path d="M332.052 139.67H330.631V141.091H332.052V139.67Z" fill="#FAFAFA" />
            <path d="M332.052 142.055H330.631V143.476H332.052V142.055Z" fill="#FAFAFA" />
            <path d="M332.052 144.432H330.631V145.853H332.052V144.432Z" fill="#FAFAFA" />
            <path d="M332.052 146.816H330.631V148.238H332.052V146.816Z" fill="#FAFAFA" />
            <path d="M332.052 149.201H330.631V150.622H332.052V149.201Z" fill="#FAFAFA" />
            <path d="M332.052 151.578H330.631V152.999H332.052V151.578Z" fill="#FAFAFA" />
            <path d="M332.052 153.963H330.631V155.384H332.052V153.963Z" fill="#FAFAFA" />
            <path d="M332.052 156.34H330.631V157.761H332.052V156.34Z" fill="#FAFAFA" />
            <path d="M332.052 158.725H330.631V160.146H332.052V158.725Z" fill="#FAFAFA" />
            <path d="M332.052 161.107H330.631V162.529H332.052V161.107Z" fill="#FAFAFA" />
            <path d="M329.79 120.613H328.369V122.034H329.79V120.613Z" fill="#FAFAFA" />
            <path d="M329.79 123H328.369V124.421H329.79V123Z" fill="#FAFAFA" />
            <path d="M329.79 125.385H328.369V126.806H329.79V125.385Z" fill="#FAFAFA" />
            <path d="M329.79 127.762H328.369V129.183H329.79V127.762Z" fill="#FAFAFA" />
            <path d="M329.79 130.146H328.369V131.568H329.79V130.146Z" fill="#FAFAFA" />
            <path d="M329.79 132.521H328.369V133.943H329.79V132.521Z" fill="#FAFAFA" />
            <path d="M329.79 134.908H328.369V136.329H329.79V134.908Z" fill="#FAFAFA" />
            <path d="M329.79 137.293H328.369V138.714H329.79V137.293Z" fill="#FAFAFA" />
            <path d="M329.79 139.67H328.369V141.091H329.79V139.67Z" fill="#FAFAFA" />
            <path d="M329.79 142.055H328.369V143.476H329.79V142.055Z" fill="#FAFAFA" />
            <path d="M329.79 144.432H328.369V145.853H329.79V144.432Z" fill="#FAFAFA" />
            <path d="M329.79 146.816H328.369V148.238H329.79V146.816Z" fill="#FAFAFA" />
            <path d="M329.79 149.201H328.369V150.622H329.79V149.201Z" fill="#FAFAFA" />
            <path d="M329.79 151.578H328.369V152.999H329.79V151.578Z" fill="#FAFAFA" />
            <path d="M329.79 153.963H328.369V155.384H329.79V153.963Z" fill="#FAFAFA" />
            <path d="M329.79 156.34H328.369V157.761H329.79V156.34Z" fill="#FAFAFA" />
            <path d="M329.79 158.725H328.369V160.146H329.79V158.725Z" fill="#FAFAFA" />
            <path d="M329.79 161.107H328.369V162.529H329.79V161.107Z" fill="#FAFAFA" />
            <path d="M342.743 138.002H341.322V139.423H342.743V138.002Z" fill="#FAFAFA" />
            <path d="M342.743 140.381H341.322V141.802H342.743V140.381Z" fill="#FAFAFA" />
            <path d="M342.743 142.764H341.322V144.185H342.743V142.764Z" fill="#FAFAFA" />
            <path d="M342.743 145.143H341.322V146.564H342.743V145.143Z" fill="#FAFAFA" />
            <path d="M342.743 147.525H341.322V148.947H342.743V147.525Z" fill="#FAFAFA" />
            <path d="M342.743 149.91H341.322V151.331H342.743V149.91Z" fill="#FAFAFA" />
            <path d="M342.743 152.287H341.322V153.708H342.743V152.287Z" fill="#FAFAFA" />
            <path d="M342.743 154.672H341.322V156.093H342.743V154.672Z" fill="#FAFAFA" />
            <path d="M342.743 157.049H341.322V158.47H342.743V157.049Z" fill="#FAFAFA" />
            <path d="M342.743 159.434H341.322V160.855H342.743V159.434Z" fill="#FAFAFA" />
            <path d="M342.743 161.82H341.322V163.241H342.743V161.82Z" fill="#FAFAFA" />
            <path d="M342.743 164.195H341.322V165.616H342.743V164.195Z" fill="#FAFAFA" />
            <path d="M342.743 166.58H341.322V168.001H342.743V166.58Z" fill="#FAFAFA" />
            <path d="M340.488 138.002H339.066V139.423H340.488V138.002Z" fill="#FAFAFA" />
            <path d="M340.488 140.381H339.066V141.802H340.488V140.381Z" fill="#FAFAFA" />
            <path d="M340.488 142.764H339.066V144.185H340.488V142.764Z" fill="#FAFAFA" />
            <path d="M340.488 145.143H339.066V146.564H340.488V145.143Z" fill="#FAFAFA" />
            <path d="M340.488 147.525H339.066V148.947H340.488V147.525Z" fill="#FAFAFA" />
            <path d="M340.488 149.91H339.066V151.331H340.488V149.91Z" fill="#FAFAFA" />
            <path d="M340.488 152.287H339.066V153.708H340.488V152.287Z" fill="#FAFAFA" />
            <path d="M340.488 154.672H339.066V156.093H340.488V154.672Z" fill="#FAFAFA" />
            <path d="M340.488 157.049H339.066V158.47H340.488V157.049Z" fill="#FAFAFA" />
            <path d="M340.488 159.434H339.066V160.855H340.488V159.434Z" fill="#FAFAFA" />
            <path d="M340.488 161.82H339.066V163.241H340.488V161.82Z" fill="#FAFAFA" />
            <path d="M340.488 164.195H339.066V165.616H340.488V164.195Z" fill="#FAFAFA" />
            <path d="M340.488 166.58H339.066V168.001H340.488V166.58Z" fill="#FAFAFA" />
            <path d="M338.236 138.002H336.814V139.423H338.236V138.002Z" fill="#FAFAFA" />
            <path d="M338.236 140.381H336.814V141.802H338.236V140.381Z" fill="#FAFAFA" />
            <path d="M338.236 142.764H336.814V144.185H338.236V142.764Z" fill="#FAFAFA" />
            <path d="M338.236 145.143H336.814V146.564H338.236V145.143Z" fill="#FAFAFA" />
            <path d="M338.236 147.525H336.814V148.947H338.236V147.525Z" fill="#FAFAFA" />
            <path d="M338.236 149.91H336.814V151.331H338.236V149.91Z" fill="#FAFAFA" />
            <path d="M338.236 152.287H336.814V153.708H338.236V152.287Z" fill="#FAFAFA" />
            <path d="M338.236 154.672H336.814V156.093H338.236V154.672Z" fill="#FAFAFA" />
            <path d="M338.236 157.049H336.814V158.47H338.236V157.049Z" fill="#FAFAFA" />
            <path d="M338.236 159.434H336.814V160.855H338.236V159.434Z" fill="#FAFAFA" />
            <path d="M338.236 161.82H336.814V163.241H338.236V161.82Z" fill="#FAFAFA" />
            <path d="M338.236 164.195H336.814V165.616H338.236V164.195Z" fill="#FAFAFA" />
            <path d="M338.236 166.58H336.814V168.001H338.236V166.58Z" fill="#FAFAFA" />
            <path d="M338.236 168.957H336.814V170.378H338.236V168.957Z" fill="#FAFAFA" />
            <path d="M325.697 138.705H324.275V140.126H325.697V138.705Z" fill="#FAFAFA" />
            <path d="M325.697 141.092H324.275V142.513H325.697V141.092Z" fill="#FAFAFA" />
            <path d="M325.697 143.477H324.275V144.898H325.697V143.477Z" fill="#FAFAFA" />
            <path d="M325.697 145.854H324.275V147.275H325.697V145.854Z" fill="#FAFAFA" />
            <path d="M325.697 148.238H324.275V149.659H325.697V148.238Z" fill="#FAFAFA" />
            <path d="M325.697 150.623H324.275V152.044H325.697V150.623Z" fill="#FAFAFA" />
            <path d="M325.697 153H324.275V154.421H325.697V153Z" fill="#FAFAFA" />
            <path d="M325.697 155.385H324.275V156.806H325.697V155.385Z" fill="#FAFAFA" />
            <path d="M325.697 157.762H324.275V159.183H325.697V157.762Z" fill="#FAFAFA" />
            <path d="M325.697 160.146H324.275V161.568H325.697V160.146Z" fill="#FAFAFA" />
            <path d="M325.697 167.291H324.275V168.712H325.697V167.291Z" fill="#FAFAFA" />
            <path d="M323.445 138.705H322.023V140.126H323.445V138.705Z" fill="#FAFAFA" />
            <path d="M323.445 141.092H322.023V142.513H323.445V141.092Z" fill="#FAFAFA" />
            <path d="M323.445 143.477H322.023V144.898H323.445V143.477Z" fill="#FAFAFA" />
            <path d="M323.445 145.854H322.023V147.275H323.445V145.854Z" fill="#FAFAFA" />
            <path d="M323.445 148.238H322.023V149.659H323.445V148.238Z" fill="#FAFAFA" />
            <path d="M323.445 150.623H322.023V152.044H323.445V150.623Z" fill="#FAFAFA" />
            <path d="M323.445 153H322.023V154.421H323.445V153Z" fill="#FAFAFA" />
            <path d="M323.445 155.385H322.023V156.806H323.445V155.385Z" fill="#FAFAFA" />
            <path d="M323.445 157.762H322.023V159.183H323.445V157.762Z" fill="#FAFAFA" />
            <path d="M323.445 164.908H322.023V166.329H323.445V164.908Z" fill="#FAFAFA" />
            <path d="M323.445 167.291H322.023V168.712H323.445V167.291Z" fill="#FAFAFA" />
            <path d="M321.191 138.705H319.77V140.126H321.191V138.705Z" fill="#FAFAFA" />
            <path d="M321.191 141.092H319.77V142.513H321.191V141.092Z" fill="#FAFAFA" />
            <path d="M321.191 143.477H319.77V144.898H321.191V143.477Z" fill="#FAFAFA" />
            <path d="M321.191 145.854H319.77V147.275H321.191V145.854Z" fill="#FAFAFA" />
            <path d="M321.191 148.238H319.77V149.659H321.191V148.238Z" fill="#FAFAFA" />
            <path d="M321.191 150.623H319.77V152.044H321.191V150.623Z" fill="#FAFAFA" />
            <path d="M321.191 153H319.77V154.421H321.191V153Z" fill="#FAFAFA" />
            <path d="M321.191 155.385H319.77V156.806H321.191V155.385Z" fill="#FAFAFA" />
            <path d="M321.191 162.529H319.77V163.95H321.191V162.529Z" fill="#FAFAFA" />
            <path d="M321.191 164.908H319.77V166.329H321.191V164.908Z" fill="#FAFAFA" />
            <path d="M321.191 167.291H319.77V168.712H321.191V167.291Z" fill="#FAFAFA" />
            <path d="M300.208 102.523H298.787V103.945H300.208V102.523Z" fill="#FAFAFA" />
            <path d="M300.208 104.91H298.787V106.331H300.208V104.91Z" fill="#FAFAFA" />
            <path d="M300.208 107.285H298.787V108.706H300.208V107.285Z" fill="#FAFAFA" />
            <path d="M300.208 109.67H298.787V111.091H300.208V109.67Z" fill="#FAFAFA" />
            <path d="M300.208 112.055H298.787V113.476H300.208V112.055Z" fill="#FAFAFA" />
            <path d="M300.208 114.432H298.787V115.853H300.208V114.432Z" fill="#FAFAFA" />
            <path d="M300.208 116.816H298.787V118.238H300.208V116.816Z" fill="#FAFAFA" />
            <path d="M300.208 119.193H298.787V120.614H300.208V119.193Z" fill="#FAFAFA" />
            <path d="M300.208 121.578H298.787V122.999H300.208V121.578Z" fill="#FAFAFA" />
            <path d="M300.208 123.963H298.787V125.384H300.208V123.963Z" fill="#FAFAFA" />
            <path d="M300.208 126.34H298.787V127.761H300.208V126.34Z" fill="#FAFAFA" />
            <path d="M300.208 128.725H298.787V130.146H300.208V128.725Z" fill="#FAFAFA" />
            <path d="M300.208 131.104H298.787V132.525H300.208V131.104Z" fill="#FAFAFA" />
            <path d="M300.208 133.486H298.787V134.907H300.208V133.486Z" fill="#FAFAFA" />
            <path d="M300.208 135.871H298.787V137.292H300.208V135.871Z" fill="#FAFAFA" />
            <path d="M300.208 138.248H298.787V139.669H300.208V138.248Z" fill="#FAFAFA" />
            <path d="M300.208 140.633H298.787V142.054H300.208V140.633Z" fill="#FAFAFA" />
            <path d="M300.208 145.395H298.787V146.816H300.208V145.395Z" fill="#FAFAFA" />
            <path d="M300.208 147.779H298.787V149.2H300.208V147.779Z" fill="#FAFAFA" />
            <path d="M300.208 150.156H298.787V151.577H300.208V150.156Z" fill="#FAFAFA" />
            <path d="M300.208 152.541H298.787V153.962H300.208V152.541Z" fill="#FAFAFA" />
            <path d="M300.208 154.918H298.787V156.339H300.208V154.918Z" fill="#FAFAFA" />
            <path d="M297.952 102.523H296.531V103.945H297.952V102.523Z" fill="#FAFAFA" />
            <path d="M297.952 104.91H296.531V106.331H297.952V104.91Z" fill="#FAFAFA" />
            <path d="M297.952 107.285H296.531V108.706H297.952V107.285Z" fill="#FAFAFA" />
            <path d="M297.952 109.67H296.531V111.091H297.952V109.67Z" fill="#FAFAFA" />
            <path d="M297.952 112.055H296.531V113.476H297.952V112.055Z" fill="#FAFAFA" />
            <path d="M297.952 114.432H296.531V115.853H297.952V114.432Z" fill="#FAFAFA" />
            <path d="M297.952 116.816H296.531V118.238H297.952V116.816Z" fill="#FAFAFA" />
            <path d="M297.952 119.193H296.531V120.614H297.952V119.193Z" fill="#FAFAFA" />
            <path d="M297.952 121.578H296.531V122.999H297.952V121.578Z" fill="#FAFAFA" />
            <path d="M297.952 123.963H296.531V125.384H297.952V123.963Z" fill="#FAFAFA" />
            <path d="M297.952 126.34H296.531V127.761H297.952V126.34Z" fill="#FAFAFA" />
            <path d="M297.952 128.725H296.531V130.146H297.952V128.725Z" fill="#FAFAFA" />
            <path d="M297.952 131.104H296.531V132.525H297.952V131.104Z" fill="#FAFAFA" />
            <path d="M297.952 133.486H296.531V134.907H297.952V133.486Z" fill="#FAFAFA" />
            <path d="M297.952 135.871H296.531V137.292H297.952V135.871Z" fill="#FAFAFA" />
            <path d="M297.952 138.248H296.531V139.669H297.952V138.248Z" fill="#FAFAFA" />
            <path d="M297.952 152.541H296.531V153.962H297.952V152.541Z" fill="#FAFAFA" />
            <path d="M297.952 154.918H296.531V156.339H297.952V154.918Z" fill="#FAFAFA" />
            <path d="M295.699 102.523H294.277V103.945H295.699V102.523Z" fill="#FAFAFA" />
            <path d="M295.699 104.91H294.277V106.331H295.699V104.91Z" fill="#FAFAFA" />
            <path d="M295.699 107.285H294.277V108.706H295.699V107.285Z" fill="#FAFAFA" />
            <path d="M295.699 109.67H294.277V111.091H295.699V109.67Z" fill="#FAFAFA" />
            <path d="M295.699 112.055H294.277V113.476H295.699V112.055Z" fill="#FAFAFA" />
            <path d="M295.699 114.432H294.277V115.853H295.699V114.432Z" fill="#FAFAFA" />
            <path d="M295.699 116.816H294.277V118.238H295.699V116.816Z" fill="#FAFAFA" />
            <path d="M295.699 119.193H294.277V120.614H295.699V119.193Z" fill="#FAFAFA" />
            <path d="M295.699 121.578H294.277V122.999H295.699V121.578Z" fill="#FAFAFA" />
            <path d="M295.699 123.963H294.277V125.384H295.699V123.963Z" fill="#FAFAFA" />
            <path d="M295.699 126.34H294.277V127.761H295.699V126.34Z" fill="#FAFAFA" />
            <path d="M295.699 128.725H294.277V130.146H295.699V128.725Z" fill="#FAFAFA" />
            <path d="M295.699 131.104H294.277V132.525H295.699V131.104Z" fill="#FAFAFA" />
            <path d="M295.699 133.486H294.277V134.907H295.699V133.486Z" fill="#FAFAFA" />
            <path d="M295.699 135.871H294.277V137.292H295.699V135.871Z" fill="#FAFAFA" />
            <path d="M295.699 138.248H294.277V139.669H295.699V138.248Z" fill="#FAFAFA" />
            <path d="M295.699 143.01H294.277V144.431H295.699V143.01Z" fill="#FAFAFA" />
            <path d="M295.699 145.395H294.277V146.816H295.699V145.395Z" fill="#FAFAFA" />
            <path d="M295.699 147.779H294.277V149.2H295.699V147.779Z" fill="#FAFAFA" />
            <path d="M295.699 150.156H294.277V151.577H295.699V150.156Z" fill="#FAFAFA" />
            <path d="M295.699 154.918H294.277V156.339H295.699V154.918Z" fill="#FAFAFA" />
            <path d="M308.4 132.521H306.979V133.943H308.4V132.521Z" fill="#FAFAFA" />
            <path d="M308.4 134.908H306.979V136.329H308.4V134.908Z" fill="#FAFAFA" />
            <path d="M308.4 137.293H306.979V138.714H308.4V137.293Z" fill="#FAFAFA" />
            <path d="M308.4 139.67H306.979V141.091H308.4V139.67Z" fill="#FAFAFA" />
            <path d="M308.4 142.055H306.979V143.476H308.4V142.055Z" fill="#FAFAFA" />
            <path d="M308.4 144.432H306.979V145.853H308.4V144.432Z" fill="#FAFAFA" />
            <path d="M308.4 146.816H306.979V148.238H308.4V146.816Z" fill="#FAFAFA" />
            <path d="M308.4 151.578H306.979V152.999H308.4V151.578Z" fill="#FAFAFA" />
            <path d="M308.4 153.963H306.979V155.384H308.4V153.963Z" fill="#FAFAFA" />
            <path d="M308.4 156.34H306.979V157.761H308.4V156.34Z" fill="#FAFAFA" />
            <path d="M308.4 158.725H306.979V160.146H308.4V158.725Z" fill="#FAFAFA" />
            <path d="M308.4 161.107H306.979V162.529H308.4V161.107Z" fill="#FAFAFA" />
            <path d="M308.4 163.486H306.979V164.907H308.4V163.486Z" fill="#FAFAFA" />
            <path d="M308.4 165.871H306.979V167.292H308.4V165.871Z" fill="#FAFAFA" />
            <path d="M308.4 168.248H306.979V169.669H308.4V168.248Z" fill="#FAFAFA" />
            <path d="M306.148 132.521H304.727V133.943H306.148V132.521Z" fill="#FAFAFA" />
            <path d="M306.148 134.908H304.727V136.329H306.148V134.908Z" fill="#FAFAFA" />
            <path d="M306.148 137.293H304.727V138.714H306.148V137.293Z" fill="#FAFAFA" />
            <path d="M306.148 139.67H304.727V141.091H306.148V139.67Z" fill="#FAFAFA" />
            <path d="M306.148 142.055H304.727V143.476H306.148V142.055Z" fill="#FAFAFA" />
            <path d="M306.148 144.432H304.727V145.853H306.148V144.432Z" fill="#FAFAFA" />
            <path d="M306.148 151.578H304.727V152.999H306.148V151.578Z" fill="#FAFAFA" />
            <path d="M306.148 153.963H304.727V155.384H306.148V153.963Z" fill="#FAFAFA" />
            <path d="M306.148 156.34H304.727V157.761H306.148V156.34Z" fill="#FAFAFA" />
            <path d="M306.148 158.725H304.727V160.146H306.148V158.725Z" fill="#FAFAFA" />
            <path d="M306.148 161.107H304.727V162.529H306.148V161.107Z" fill="#FAFAFA" />
            <path d="M306.148 163.486H304.727V164.907H306.148V163.486Z" fill="#FAFAFA" />
            <path d="M306.148 165.871H304.727V167.292H306.148V165.871Z" fill="#FAFAFA" />
            <path d="M306.148 168.248H304.727V169.669H306.148V168.248Z" fill="#FAFAFA" />
            <path d="M303.892 132.521H302.471V133.943H303.892V132.521Z" fill="#FAFAFA" />
            <path d="M303.892 134.908H302.471V136.329H303.892V134.908Z" fill="#FAFAFA" />
            <path d="M303.892 137.293H302.471V138.714H303.892V137.293Z" fill="#FAFAFA" />
            <path d="M303.892 139.67H302.471V141.091H303.892V139.67Z" fill="#FAFAFA" />
            <path d="M303.892 142.055H302.471V143.476H303.892V142.055Z" fill="#FAFAFA" />
            <path d="M303.892 144.432H302.471V145.853H303.892V144.432Z" fill="#FAFAFA" />
            <path d="M303.892 149.201H302.471V150.622H303.892V149.201Z" fill="#FAFAFA" />
            <path d="M303.892 151.578H302.471V152.999H303.892V151.578Z" fill="#FAFAFA" />
            <path d="M303.892 153.963H302.471V155.384H303.892V153.963Z" fill="#FAFAFA" />
            <path d="M303.892 156.34H302.471V157.761H303.892V156.34Z" fill="#FAFAFA" />
            <path d="M303.892 158.725H302.471V160.146H303.892V158.725Z" fill="#FAFAFA" />
            <path d="M303.892 161.107H302.471V162.529H303.892V161.107Z" fill="#FAFAFA" />
            <path d="M303.892 163.486H302.471V164.907H303.892V163.486Z" fill="#FAFAFA" />
            <path d="M303.892 165.871H302.471V167.292H303.892V165.871Z" fill="#FAFAFA" />
            <path d="M303.892 168.248H302.471V169.669H303.892V168.248Z" fill="#FAFAFA" />
            <path d="M256.339 119.193H254.918V120.614H256.339V119.193Z" fill="#FAFAFA" />
            <path d="M256.339 121.578H254.918V122.999H256.339V121.578Z" fill="#FAFAFA" />
            <path d="M256.339 123.963H254.918V125.384H256.339V123.963Z" fill="#FAFAFA" />
            <path d="M256.339 126.34H254.918V127.761H256.339V126.34Z" fill="#FAFAFA" />
            <path d="M256.339 128.725H254.918V130.146H256.339V128.725Z" fill="#FAFAFA" />
            <path d="M256.339 131.104H254.918V132.525H256.339V131.104Z" fill="#FAFAFA" />
            <path d="M256.339 133.486H254.918V134.907H256.339V133.486Z" fill="#FAFAFA" />
            <path d="M256.339 135.871H254.918V137.292H256.339V135.871Z" fill="#FAFAFA" />
            <path d="M256.339 138.248H254.918V139.669H256.339V138.248Z" fill="#FAFAFA" />
            <path d="M256.339 140.633H254.918V142.054H256.339V140.633Z" fill="#FAFAFA" />
            <path d="M256.339 143.01H254.918V144.431H256.339V143.01Z" fill="#FAFAFA" />
            <path d="M256.339 145.395H254.918V146.816H256.339V145.395Z" fill="#FAFAFA" />
            <path d="M256.339 147.779H254.918V149.2H256.339V147.779Z" fill="#FAFAFA" />
            <path d="M256.339 152.541H254.918V153.962H256.339V152.541Z" fill="#FAFAFA" />
            <path d="M256.339 154.918H254.918V156.339H256.339V154.918Z" fill="#FAFAFA" />
            <path d="M256.339 157.303H254.918V158.724H256.339V157.303Z" fill="#FAFAFA" />
            <path d="M256.339 159.689H254.918V161.111H256.339V159.689Z" fill="#FAFAFA" />
            <path d="M256.339 164.451H254.918V165.872H256.339V164.451Z" fill="#FAFAFA" />
            <path d="M256.339 166.826H254.918V168.247H256.339V166.826Z" fill="#FAFAFA" />
            <path d="M256.339 169.213H254.918V170.634H256.339V169.213Z" fill="#FAFAFA" />
            <path d="M254.077 119.193H252.656V120.614H254.077V119.193Z" fill="#FAFAFA" />
            <path d="M254.077 121.578H252.656V122.999H254.077V121.578Z" fill="#FAFAFA" />
            <path d="M254.077 123.963H252.656V125.384H254.077V123.963Z" fill="#FAFAFA" />
            <path d="M254.077 126.34H252.656V127.761H254.077V126.34Z" fill="#FAFAFA" />
            <path d="M254.077 128.725H252.656V130.146H254.077V128.725Z" fill="#FAFAFA" />
            <path d="M254.077 131.104H252.656V132.525H254.077V131.104Z" fill="#FAFAFA" />
            <path d="M254.077 133.486H252.656V134.907H254.077V133.486Z" fill="#FAFAFA" />
            <path d="M254.077 135.871H252.656V137.292H254.077V135.871Z" fill="#FAFAFA" />
            <path d="M254.077 138.248H252.656V139.669H254.077V138.248Z" fill="#FAFAFA" />
            <path d="M254.077 140.633H252.656V142.054H254.077V140.633Z" fill="#FAFAFA" />
            <path d="M254.077 143.01H252.656V144.431H254.077V143.01Z" fill="#FAFAFA" />
            <path d="M254.077 145.395H252.656V146.816H254.077V145.395Z" fill="#FAFAFA" />
            <path d="M254.077 147.779H252.656V149.2H254.077V147.779Z" fill="#FAFAFA" />
            <path d="M254.077 154.918H252.656V156.339H254.077V154.918Z" fill="#FAFAFA" />
            <path d="M254.077 157.303H252.656V158.724H254.077V157.303Z" fill="#FAFAFA" />
            <path d="M254.077 162.064H252.656V163.486H254.077V162.064Z" fill="#FAFAFA" />
            <path d="M254.077 164.451H252.656V165.872H254.077V164.451Z" fill="#FAFAFA" />
            <path d="M254.077 166.826H252.656V168.247H254.077V166.826Z" fill="#FAFAFA" />
            <path d="M254.077 169.213H252.656V170.634H254.077V169.213Z" fill="#FAFAFA" />
            <path d="M251.824 119.193H250.402V120.614H251.824V119.193Z" fill="#FAFAFA" />
            <path d="M251.824 121.578H250.402V122.999H251.824V121.578Z" fill="#FAFAFA" />
            <path d="M251.824 123.963H250.402V125.384H251.824V123.963Z" fill="#FAFAFA" />
            <path d="M251.824 126.34H250.402V127.761H251.824V126.34Z" fill="#FAFAFA" />
            <path d="M251.824 128.725H250.402V130.146H251.824V128.725Z" fill="#FAFAFA" />
            <path d="M251.824 131.104H250.402V132.525H251.824V131.104Z" fill="#FAFAFA" />
            <path d="M251.824 133.486H250.402V134.907H251.824V133.486Z" fill="#FAFAFA" />
            <path d="M251.824 135.871H250.402V137.292H251.824V135.871Z" fill="#FAFAFA" />
            <path d="M251.824 138.248H250.402V139.669H251.824V138.248Z" fill="#FAFAFA" />
            <path d="M251.824 140.633H250.402V142.054H251.824V140.633Z" fill="#FAFAFA" />
            <path d="M251.824 143.01H250.402V144.431H251.824V143.01Z" fill="#FAFAFA" />
            <path d="M251.824 145.395H250.402V146.816H251.824V145.395Z" fill="#FAFAFA" />
            <path d="M251.824 147.779H250.402V149.2H251.824V147.779Z" fill="#FAFAFA" />
            <path d="M251.824 150.156H250.402V151.577H251.824V150.156Z" fill="#FAFAFA" />
            <path d="M251.824 154.918H250.402V156.339H251.824V154.918Z" fill="#FAFAFA" />
            <path d="M251.824 159.689H250.402V161.111H251.824V159.689Z" fill="#FAFAFA" />
            <path d="M251.824 162.064H250.402V163.486H251.824V162.064Z" fill="#FAFAFA" />
            <path d="M251.824 164.451H250.402V165.872H251.824V164.451Z" fill="#FAFAFA" />
            <path d="M251.824 166.826H250.402V168.247H251.824V166.826Z" fill="#FAFAFA" />
            <path d="M251.824 169.213H250.402V170.634H251.824V169.213Z" fill="#FAFAFA" />
            <path d="M265.529 132.775H264.107V134.197H265.529V132.775Z" fill="#FAFAFA" />
            <path d="M265.529 135.162H264.107V136.583H265.529V135.162Z" fill="#FAFAFA" />
            <path d="M265.529 137.537H264.107V138.958H265.529V137.537Z" fill="#FAFAFA" />
            <path d="M265.529 139.922H264.107V141.343H265.529V139.922Z" fill="#FAFAFA" />
            <path d="M265.529 142.299H264.107V143.72H265.529V142.299Z" fill="#FAFAFA" />
            <path d="M265.529 144.684H264.107V146.105H265.529V144.684Z" fill="#FAFAFA" />
            <path d="M265.529 151.83H264.107V153.251H265.529V151.83Z" fill="#FAFAFA" />
            <path d="M265.529 154.207H264.107V155.628H265.529V154.207Z" fill="#FAFAFA" />
            <path d="M265.529 156.592H264.107V158.013H265.529V156.592Z" fill="#FAFAFA" />
            <path d="M265.529 158.977H264.107V160.398H265.529V158.977Z" fill="#FAFAFA" />
            <path d="M265.529 161.352H264.107V162.773H265.529V161.352Z" fill="#FAFAFA" />
            <path d="M265.529 163.738H264.107V165.159H265.529V163.738Z" fill="#FAFAFA" />
            <path d="M265.529 166.123H264.107V167.544H265.529V166.123Z" fill="#FAFAFA" />
            <path d="M263.275 132.775H261.854V134.197H263.275V132.775Z" fill="#FAFAFA" />
            <path d="M263.275 135.162H261.854V136.583H263.275V135.162Z" fill="#FAFAFA" />
            <path d="M263.275 137.537H261.854V138.958H263.275V137.537Z" fill="#FAFAFA" />
            <path d="M263.275 139.922H261.854V141.343H263.275V139.922Z" fill="#FAFAFA" />
            <path d="M263.275 142.299H261.854V143.72H263.275V142.299Z" fill="#FAFAFA" />
            <path d="M263.275 144.684H261.854V146.105H263.275V144.684Z" fill="#FAFAFA" />
            <path d="M263.275 151.83H261.854V153.251H263.275V151.83Z" fill="#FAFAFA" />
            <path d="M263.275 154.207H261.854V155.628H263.275V154.207Z" fill="#FAFAFA" />
            <path d="M263.275 156.592H261.854V158.013H263.275V156.592Z" fill="#FAFAFA" />
            <path d="M263.275 158.977H261.854V160.398H263.275V158.977Z" fill="#FAFAFA" />
            <path d="M263.275 161.352H261.854V162.773H263.275V161.352Z" fill="#FAFAFA" />
            <path d="M263.275 163.738H261.854V165.159H263.275V163.738Z" fill="#FAFAFA" />
            <path d="M263.275 168.5H261.854V169.921H263.275V168.5Z" fill="#FAFAFA" />
            <path d="M210.593 54.8984H207.775V209.664H210.593V54.8984Z" fill="#EBEBEB" />
            <path d="M255.449 54.8984H253.064V209.664H255.449V54.8984Z" fill="#EBEBEB" />
            <path d="M300.305 54.8984H297.92V209.664H300.305V54.8984Z" fill="#EBEBEB" />
            <path
                d="M64.3793 248.931C64.3793 248.931 60.7366 240.984 64.902 233.143C69.0675 225.303 70.2762 223.457 67.6218 221.856C64.9674 220.247 63.7504 228.128 63.7504 228.128C63.7504 228.128 61.5125 222.713 63.3584 217.037C65.2042 211.369 66.364 202.131 63.8403 201.143C61.3165 200.155 63.3584 212.667 61.1695 216.931C61.1695 216.931 59.3318 212.186 59.0296 207.628C58.7274 203.071 59.2011 195.802 57.2083 193.939C55.2154 192.077 53.9413 193.368 55.9015 203.414C57.8617 213.468 57.8453 221.635 57.8453 221.635C57.8453 221.635 53.5247 208.453 50.6171 208.069C47.7176 207.685 47.7176 211.957 50.2006 215.657C52.6835 219.357 57.4778 225.997 58.2619 229.411C58.2619 229.411 53.5166 219.487 49.245 222.534C44.9734 225.572 52.9612 231.567 56.5222 233.731C60.0832 235.896 62.5498 249.47 62.5498 249.47L64.3793 248.947V248.931Z"
                fill="#E0E0E0"
            />
            <path
                d="M61.4957 252.198C61.4957 252.198 65.0404 244.194 60.7851 236.419C56.5299 228.643 55.2966 226.814 57.9347 225.172C60.5728 223.531 61.8796 231.388 61.8796 231.388C61.8796 231.388 64.0521 225.94 62.1328 220.296C60.2216 214.652 58.9475 205.44 61.463 204.41C63.9705 203.381 62.0838 215.927 64.3217 220.157C64.3217 220.157 66.1022 215.379 66.3472 210.822C66.5922 206.264 66.0368 198.995 68.0052 197.101C69.9736 195.206 71.264 196.48 69.4263 206.558C67.5887 216.637 67.703 224.805 67.703 224.805C67.703 224.805 71.8684 211.557 74.7679 211.132C77.6673 210.708 77.7164 214.971 75.2743 218.712C72.8322 222.452 68.1277 229.158 67.3763 232.58C67.3763 232.58 72.0073 222.591 76.3115 225.564C80.6158 228.537 72.7015 234.655 69.1732 236.868C65.6366 239.09 63.3334 252.697 63.3334 252.697L61.4957 252.198Z"
                fill="#EBEBEB"
            />
            <path
                d="M77.8659 246.49H48.5527L50.9295 267.464H75.4973L77.8659 246.49Z"
                fill="#EBEBEB"
            />
            <path
                d="M344.555 244.992C344.661 244.281 347.095 227.513 342.219 221.722C341.141 220.44 339.777 219.77 338.176 219.729C328.35 219.501 327.003 227.391 326.986 227.472L328.146 227.652C328.195 227.366 329.355 220.685 338.143 220.897C339.417 220.93 340.455 221.445 341.32 222.474C345.853 227.864 343.419 244.649 343.387 244.82L344.546 244.992H344.555Z"
                fill="#EBEBEB"
            />
            <path
                d="M345.152 244.234L346.328 244.193C346.328 244.038 345.936 228.74 353.475 225.579C360.858 222.484 364.182 228.846 364.321 229.124L365.367 228.601C365.326 228.528 361.43 220.981 353.017 224.509C344.735 227.98 345.127 243.58 345.152 244.242V244.234Z"
                fill="#EBEBEB"
            />
            <path
                d="M345.119 245.433C345.119 245.188 345.193 220.996 349.906 213.915C354.439 207.119 361.74 210.321 362.051 210.46L362.533 209.39C362.451 209.349 354.014 205.633 348.926 213.261C344.009 220.628 343.943 244.42 343.943 245.425H345.119V245.433Z"
                fill="#EBEBEB"
            />
            <path
                d="M336.773 220.309C336.773 220.309 341.06 221.894 339.786 225.626C338.512 229.359 324.938 235.509 324.938 235.509C324.938 235.509 319.253 230.298 324.472 221.828C329.691 213.359 336.65 217.638 336.764 220.309H336.773Z"
                fill="#E0E0E0"
            />
            <path
                d="M350.118 212.641C350.118 212.641 347.072 212.314 347.317 210.46C347.562 208.606 359.16 203.828 366.004 211.554C366.004 211.554 361.626 219.085 355.288 219.183C348.95 219.281 350.118 212.649 350.118 212.649V212.641Z"
                fill="#E0E0E0"
            />
            <path
                d="M353.247 225.039C353.247 225.039 351.148 223.34 353.41 222.515C355.681 221.69 366.813 221.919 366.927 232.953C366.927 232.953 353.957 235.109 352.356 230.903C350.747 226.705 353.247 225.039 353.247 225.039Z"
                fill="#E0E0E0"
            />
            <path
                d="M334.469 267.461H354.594L356.759 245.434H332.297L334.469 267.461Z"
                fill="#EBEBEB"
            />
            <path d="M331.218 247.859H357.836V243H331.218V247.859Z" fill="#E0E0E0" />
            <path
                d="M130.079 64.926C136.471 64.926 141.653 59.7444 141.653 53.3526C141.653 46.9609 136.471 41.7793 130.079 41.7793C123.687 41.7793 118.506 46.9609 118.506 53.3526C118.506 59.7444 123.687 64.926 130.079 64.926Z"
                fill="#E0E0E0"
            />
            <path
                d="M130.078 63.1934C135.514 63.1934 139.92 58.7871 139.92 53.3516C139.92 47.9161 135.514 43.5098 130.078 43.5098C124.643 43.5098 120.236 47.9161 120.236 53.3516C120.236 58.7871 124.643 63.1934 130.078 63.1934Z"
                fill="#F0F0F0"
            />
            <path d="M130.545 46.6055V53.4825L135.102 50.9016" fill="#F0F0F0" />
            <path
                d="M130.545 46.6055V53.4825L135.102 50.9016"
                stroke="#E0E0E0"
                strokeWidth={1.6335}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M204.186 305.165C291.645 305.165 362.545 301.026 362.545 295.919C362.545 290.813 291.645 286.674 204.186 286.674C116.726 286.674 45.8262 290.813 45.8262 295.919C45.8262 301.026 116.726 305.165 204.186 305.165Z"
                fill="#F5F5F5"
            />
            <path d="M189.921 93.6309H65.0723V177.413H189.921V93.6309Z" fill="#263238" />
            <path
                d="M93.2733 139.828H89.4672V285.781L81.1445 287.554V289.024H90.6923H92.0481H101.588V287.554L93.2733 285.781V139.828Z"
                fill="#263238"
            />
            <path
                d="M165.523 285.781V139.828H161.717V285.781L153.402 287.554V289.024H162.942H164.298H173.846V287.554L165.523 285.781Z"
                fill="#263238"
            />
            <g opacity={0.6}>
                <path d="M189.921 93.6309H65.0723V177.413H189.921V93.6309Z" fill="white" />
                <path
                    d="M93.2733 139.828H89.4672V285.781L81.1445 287.554V289.024H90.6923H92.0481H101.588V287.554L93.2733 285.781V139.828Z"
                    fill="white"
                />
                <path
                    d="M165.523 285.781V139.828H161.717V285.781L153.402 287.554V289.024H162.942H164.298H173.846V287.554L165.523 285.781Z"
                    fill="white"
                />
            </g>
            <path d="M186.311 173.796V97.2422L68.6823 97.2422V173.796H186.311Z" fill="white" />
            <g opacity={0.2}>
                <path
                    d="M177.376 168.371H75.8379V111.443H76.6546V167.554H177.376V168.371Z"
                    fill="black"
                />
            </g>
            <path
                opacity={0.2}
                d="M85.5285 158.922H81.1426V167.147H85.5285V158.922Z"
                fill="black"
            />
            <path d="M85.5285 158.922H81.1426V161.315H85.5285V158.922Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M94.4895 145.762H90.1035V167.136H94.4895V145.762Z"
                fill="black"
            />
            <path d="M94.4895 145.762H90.1035V151.985H94.4895V145.762Z" fill="#407BFF" />
            <path opacity={0.2} d="M103.45 150.697H99.0645V167.147H103.45V150.697Z" fill="black" />
            <path d="M103.45 150.697H99.0645V155.483H103.45V150.697Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M112.409 143.135H108.023V167.139H112.409V143.135Z"
                fill="black"
            />
            <path d="M112.409 143.135H108.023V150.126H112.409V143.135Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M121.368 136.158H116.982V167.146H121.368V136.158Z"
                fill="black"
            />
            <path d="M121.368 136.158H116.982V145.183H121.368V136.158Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M130.337 130.883H125.951V167.146H130.337V130.883Z"
                fill="black"
            />
            <path d="M130.337 130.883H125.951V141.443H130.337V130.883Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M139.298 136.984H134.912V167.147H139.298V136.984Z"
                fill="black"
            />
            <path d="M139.298 136.984H134.912V145.764H139.298V136.984Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M148.257 135.666H143.871V167.144H148.257V135.666Z"
                fill="black"
            />
            <path d="M148.257 135.666H143.871V144.83H148.257V135.666Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M157.214 127.631H152.828V167.137H157.214V127.631Z"
                fill="black"
            />
            <path d="M157.214 127.631H152.828V139.131H157.214V127.631Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M166.173 119.535H161.787V167.135H166.173V119.535Z"
                fill="black"
            />
            <path d="M166.173 119.535H161.787V133.395H166.173V119.535Z" fill="#407BFF" />
            <path opacity={0.2} d="M175.136 112.465H170.75V167.146H175.136V112.465Z" fill="black" />
            <path d="M175.136 112.465H170.75V128.383H175.136V112.465Z" fill="#407BFF" />
            <path
                opacity={0.2}
                d="M153.395 102.842H99.0645V105.104H153.395V102.842Z"
                fill="black"
            />
            <path
                d="M184.846 158.588L181.049 156.726C181.049 156.726 179.921 154.227 179.84 154.267C179.758 154.308 172.571 152.789 172.571 152.789C172.571 152.789 172.489 157.036 173.298 157.935C174.106 158.833 179.031 159.258 179.276 159.413C179.521 159.568 180.085 159.062 180.085 159.062L184.12 161.716L184.846 158.604V158.588Z"
                fill="#EBB376"
            />
            <path
                d="M226.338 126.635C226.338 126.635 224.761 123 219.673 123C214.584 123 210.346 126.03 210.582 129.91C210.819 133.789 207.92 133.422 207.43 137.179C206.94 140.936 208.761 141.908 208.884 143.476C209.006 145.052 206.948 144.57 205.608 147.233C204.277 149.896 202.578 153.653 208.271 158.618C213.964 163.584 230.078 162.735 233.59 159.713C237.102 156.683 236.98 150.867 233.59 149.291C230.201 147.715 233.345 147.96 233.713 144.448C234.072 140.936 230.928 139.605 231.164 136.811C231.401 134.018 232.259 126.88 226.321 126.635H226.338Z"
                fill="#263238"
            />
            <path
                d="M206.564 150.215V160.204C206.564 160.204 205.706 161.274 204.424 162.646C203.215 163.945 201.606 165.513 199.997 166.681C198.462 167.792 196.926 168.551 195.669 168.388C191.716 167.881 181.049 161.748 181.049 161.748L182.821 157.035C182.821 157.035 194.934 163.267 195.669 162.622C196.396 161.976 204.359 150.779 206.556 150.207L206.564 150.215Z"
                fill="#407BFF"
            />
            <path
                opacity={0.2}
                d="M204.424 162.646C203.215 163.945 201.606 165.513 199.997 166.681C199.417 162.221 204.538 156.725 204.538 156.725C204.538 156.725 204.612 159.583 204.424 162.646Z"
                fill="black"
            />
            <path
                d="M207.969 186.396C207.969 186.396 201.329 221.778 199.557 236.308C197.245 255.257 187.779 282.985 187.779 282.985L191.561 283.965C191.561 283.965 204.907 260.402 207.504 239.207C218.105 217.539 218.955 190.864 218.955 190.864L207.961 186.405L207.969 186.396Z"
                fill="#EBB376"
            />
            <path
                d="M180.731 291.563C181.343 291.285 182.005 291.114 182.675 291.073C182.675 291.073 185.002 289.701 187.306 284.384L189.895 284.367C190.793 284.375 191.585 284.947 191.887 285.796C191.928 285.911 191.953 286.033 191.961 286.156C191.936 286.785 191.871 287.405 191.765 288.026C191.528 289.962 190.246 294.127 190.246 294.127L189.805 294.013L190.605 290.142C190.875 288.827 189.486 290.101 188.849 291.424C188.212 292.747 187.224 294.087 184.365 293.425C181.025 292.641 179.685 292.167 180.731 291.563Z"
                fill="#263238"
            />
            <path
                d="M214.861 185.832C214.861 185.832 215.686 221.826 216.96 236.405C218.618 255.427 215.09 284.512 215.09 284.512L218.994 284.683C218.994 284.683 227.178 258.874 225.332 237.598C231.221 214.206 226.541 187.931 226.541 187.931L214.861 185.84V185.832Z"
                fill="#EBB376"
            />
            <path
                d="M209.004 292.744C209.584 292.401 210.221 292.156 210.883 292.041C210.883 292.041 213.047 290.432 214.763 284.887L217.335 284.593C218.226 284.503 219.083 284.985 219.467 285.793C219.524 285.9 219.557 286.022 219.581 286.145C219.622 286.773 219.622 287.394 219.581 288.023C219.557 289.975 218.732 294.255 218.732 294.255L218.283 294.19L218.658 290.253C218.781 288.913 217.548 290.326 217.049 291.715C216.559 293.103 215.726 294.533 212.802 294.19C209.405 293.773 208.008 293.438 208.988 292.736L209.004 292.744Z"
                fill="#263238"
            />
            <path
                d="M187.779 282.984C187.779 282.984 184.544 289.763 182.674 291.07C182.674 291.07 185.173 292.556 187.779 289.24C191.144 284.952 191.968 286.145 191.968 286.145C191.968 286.145 191.83 284.675 191.56 283.956C191.054 282.576 187.779 282.976 187.779 282.976V282.984Z"
                fill="#EBB376"
            />
            <path
                d="M215.481 282.928C215.481 282.928 214.566 285.435 213.341 288.196C212.116 290.956 210.891 292.051 210.891 292.051C210.891 292.051 213.022 292.745 214.166 291.406C215.309 290.066 217.278 285.255 219.483 285.803C219.483 285.803 219.319 285.231 218.993 284.684C218.666 284.137 215.489 282.928 215.489 282.928H215.481Z"
                fill="#EBB376"
            />
            <path
                d="M205.69 180.493C205.69 180.493 203.06 206.686 199.156 232.944C195.603 256.85 188.652 277.049 188.652 277.049L196.403 277.694C196.403 277.694 211.268 240.858 215.793 219.533C215.793 219.533 217.41 257.332 214.821 278.339L221.608 278.012C221.608 278.012 238.164 206.874 225.806 181.244L205.682 180.484L205.69 180.493Z"
                fill="#407BFF"
            />
            <path
                opacity={0.4}
                d="M221.618 278.021L214.831 278.339C217.412 257.34 215.803 219.533 215.803 219.533C214.659 224.916 212.854 231.278 210.804 237.796C204.719 257.095 196.413 277.694 196.413 277.694L188.662 277.04C188.662 277.04 195.604 256.85 199.157 232.936C203.061 206.678 205.691 180.484 205.691 180.484L225.816 181.244C238.173 206.865 221.618 278.012 221.618 278.012V278.021Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M215.801 219.533C214.657 224.916 212.852 231.278 210.802 237.796C210.018 225.536 213.138 203.533 213.138 203.533L215.801 219.525V219.533Z"
                fill="black"
            />
            <path
                d="M225.775 179.977L225.832 182.933L205.585 181.765L205.52 178.27L225.775 179.977Z"
                fill="#263238"
            />
            <path
                d="M209.959 182.28L214.383 182.592L214.619 179.235L210.195 178.923L209.959 182.28Z"
                fill="white"
            />
            <path
                d="M218.795 183.987L219.799 184.02L219.963 178.909L218.959 178.877L218.795 183.987Z"
                fill="#407BFF"
            />
            <path
                opacity={0.4}
                d="M218.795 183.987L219.799 184.02L219.963 178.909L218.959 178.877L218.795 183.987Z"
                fill="white"
            />
            <path
                d="M206.439 182.843L207.443 182.875L207.608 177.765L206.603 177.733L206.439 182.843Z"
                fill="#407BFF"
            />
            <path
                opacity={0.4}
                d="M206.439 182.843L207.443 182.875L207.608 177.765L206.603 177.733L206.439 182.843Z"
                fill="white"
            />
            <path
                d="M205.56 178.644L225.766 179.975C226.085 174.952 226.444 173.409 227.963 167.422C228.445 165.486 229.058 163.322 229.834 160.822C230.25 159.483 230.707 158.054 231.222 156.51C231.606 155.342 232.022 154.101 232.488 152.786C229.736 151.912 226.942 151.226 224.1 150.76C221.102 150.229 218.064 149.911 215.026 149.804C211.097 149.772 206.572 150.213 206.572 150.213C206.572 150.213 205.927 150.368 205.527 154.533C205.16 158.446 204.767 164.4 205.576 178.636L205.56 178.644Z"
                fill="#263238"
            />
            <path
                opacity={0.4}
                d="M205.56 178.644L225.766 179.975C226.085 174.952 226.444 173.409 227.963 167.422C228.445 165.486 229.058 163.322 229.834 160.822C230.25 159.483 230.707 158.054 231.222 156.51C231.606 155.342 232.022 154.101 232.488 152.786C229.736 151.912 226.942 151.226 224.1 150.76C221.102 150.229 218.064 149.911 215.026 149.804C211.097 149.772 206.572 150.213 206.572 150.213C206.572 150.213 205.927 150.368 205.527 154.533C205.16 158.446 204.767 164.4 205.576 178.636L205.56 178.644Z"
                fill="white"
            />
            <path
                d="M216.684 154.264C221.666 153.627 223.161 151.495 223.161 151.495C220 148.514 222.63 143.344 222.63 143.344L216.635 144.463C216.79 145.451 216.864 146.284 216.872 146.986C216.921 150.629 215.271 150.662 215.271 150.662C214.217 152.63 216.692 154.256 216.692 154.256L216.684 154.264Z"
                fill="#EBB376"
            />
            <path
                opacity={0.2}
                d="M216.635 144.472C216.79 145.461 216.863 146.294 216.872 146.996C220.923 146.996 222.638 143.354 222.638 143.354L216.643 144.472H216.635Z"
                fill="black"
            />
            <path
                d="M219.949 127.694C214.738 127.409 212.9 130.112 212.23 135.462C211.389 142.151 212.614 147.002 219.213 145.924C228.173 144.462 228.614 128.168 219.957 127.694H219.949Z"
                fill="#EBB376"
            />
            <path
                d="M215.61 135.355C215.61 135.355 214.81 136.679 213.977 137.438C213.977 137.438 214.385 138.173 215.316 138.1L215.61 135.355Z"
                fill="#D58745"
            />
            <path
                d="M218.83 135.104C218.797 135.528 219.001 135.896 219.287 135.92C219.573 135.945 219.834 135.61 219.867 135.185C219.9 134.76 219.695 134.393 219.41 134.368C219.124 134.344 218.862 134.679 218.83 135.104Z"
                fill="#263238"
            />
            <path
                d="M219.523 134.402L218.625 133.961C218.625 133.961 219.025 134.778 219.523 134.402Z"
                fill="#263238"
            />
            <path
                d="M213.515 134.598C213.482 135.022 213.687 135.39 213.973 135.414C214.258 135.439 214.52 135.104 214.552 134.679C214.585 134.255 214.381 133.887 214.095 133.863C213.809 133.838 213.548 134.173 213.515 134.598Z"
                fill="#263238"
            />
            <path
                d="M214.209 133.896L213.311 133.455C213.311 133.455 213.711 134.272 214.209 133.896Z"
                fill="#263238"
            />
            <path
                d="M219.074 131.508C219.074 131.508 220.553 131.393 221.296 132.422"
                stroke="#2E353A"
                strokeWidth={0.588059}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M215.302 131.075C215.302 131.075 214.404 130.683 213.203 131.393"
                stroke="#2E353A"
                strokeWidth={0.588059}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M215.965 140.189C216.234 140.834 216.471 142.035 216.471 142.035C216.586 142.035 216.7 142.035 216.806 142.019C218.333 141.945 219.019 141.414 219.313 140.834C219.477 140.524 219.518 140.189 219.518 139.92C219.518 139.609 219.452 139.389 219.452 139.389C218.668 139.911 217.247 140.083 216.488 140.148C216.169 140.181 215.965 140.181 215.965 140.181V140.189Z"
                fill="#263238"
            />
            <path
                d="M216.484 140.16L216.648 140.699C218.085 140.626 219.09 140.389 219.515 139.931C219.515 139.621 219.449 139.4 219.449 139.4C218.665 139.923 217.244 140.095 216.484 140.16Z"
                fill="white"
            />
            <path
                d="M216.811 142.028C218.338 141.955 219.024 141.424 219.318 140.844C218.795 140.885 218.175 140.999 217.635 141.26C217.219 141.456 216.974 141.742 216.811 142.028Z"
                fill="#DE5753"
            />
            <path
                d="M225.158 136.8C225.158 136.8 222.953 132.381 223.509 129.098C223.509 129.098 213.405 124.312 212.327 134.766C212.327 134.766 211.829 125.398 220.805 125.684C229.765 125.97 229.022 139.079 224.178 142.73C224.178 142.73 226.531 139.561 225.167 136.8H225.158Z"
                fill="#263238"
            />
            <path
                d="M225.691 127.205C225.691 127.205 221.616 123.57 217.899 124.256C214.183 124.942 214.828 129.826 219.721 131.689C224.613 133.551 225.618 135.927 225.618 135.927L229.857 132.13L225.699 127.205H225.691Z"
                fill="#263238"
            />
            <path
                d="M216.803 125.783C216.803 125.783 215.064 125.906 215.554 128.487C216.036 131.068 219.711 132.374 223.19 133.583C226.662 134.792 226.384 136.164 226.384 136.164"
                stroke="#263238"
                strokeWidth={0.408375}
                strokeMiterlimit={10}
            />
            <path
                d="M224.252 137.856C224.334 137.014 225.273 135.642 226.825 135.904C228.434 136.165 229.022 139.53 224.955 140.102C224.375 140.183 224.081 139.604 224.244 137.856H224.252Z"
                fill="#EBB376"
            />
            <path
                d="M227.785 152.05C227.785 152.05 221.937 156.796 216.49 156.722C211.042 156.649 209.131 150.523 209.131 150.523C209.131 150.523 217.576 148.024 227.785 152.059V152.05Z"
                fill="#EBB376"
            />
            <path
                d="M232.486 152.787L229.856 184.33L224.213 184.804C223.38 180.516 223.233 173.745 223.339 167.358C223.486 158.692 224.098 150.762 224.098 150.762C227.235 151.097 232.486 152.787 232.486 152.787Z"
                fill="#407BFF"
            />
            <path
                opacity={0.4}
                d="M224.998 158.674L226.525 161.01L223.34 167.34C223.487 158.683 224.099 150.752 224.099 150.752L228.959 155.269L224.998 158.658V158.674Z"
                fill="white"
            />
            <path
                d="M213.194 149.756C213.064 149.993 210.254 155.106 208.016 164.89C206.905 169.75 205.941 175.777 205.525 182.932L203.369 181.936C203.369 181.936 202.356 157.507 206.562 150.213L213.194 149.756Z"
                fill="#407BFF"
            />
            <path
                opacity={0.4}
                d="M213.195 149.756C213.064 149.985 210.254 155.097 208.016 164.882L207.012 158.577L209.127 156.715L207.012 152.688L213.195 149.756Z"
                fill="white"
            />
            <path
                d="M240.663 171.832C240.034 173.188 238.947 173.31 237.837 172.763C236.955 172.33 236.048 171.456 235.337 170.435C234.831 169.708 234.153 168.614 233.451 167.454C231.956 164.947 230.404 162.17 230.404 162.17L232.495 152.785C235.501 154.223 241.798 169.406 240.671 171.832H240.663Z"
                fill="#407BFF"
            />
            <path
                opacity={0.2}
                d="M237.837 172.761C236.955 172.329 236.048 171.455 235.338 170.434C234.832 169.707 234.154 168.612 233.451 167.453C234.309 165.876 235.224 164.635 235.224 164.635L236.743 168.906C236.743 168.906 239.405 169.511 239.83 170.907C239.985 171.43 239.079 172.182 237.837 172.77V172.761Z"
                fill="black"
            />
            <path
                d="M237.748 160.562L232.717 179.739C232.603 180.188 232.129 180.507 231.664 180.442L218.31 178.661C217.844 178.596 217.567 178.179 217.689 177.73L222.72 158.553C222.835 158.103 223.308 157.785 223.774 157.85L237.128 159.631C237.593 159.696 237.871 160.113 237.748 160.562Z"
                fill="#263238"
            />
            <path
                opacity={0.1}
                d="M237.747 160.562L232.716 179.74C232.602 180.189 232.128 180.507 231.662 180.442L218.921 178.743C218.456 178.678 218.178 178.261 218.3 177.812L223.332 158.635C223.446 158.186 223.92 157.867 224.385 157.932L237.126 159.631C237.592 159.697 237.87 160.113 237.747 160.562Z"
                fill="white"
            />
            <path
                opacity={0.3}
                d="M228.478 167.88C227.817 167.791 227.139 168.248 226.967 168.893C226.796 169.538 227.196 170.135 227.858 170.224C228.519 170.314 229.197 169.857 229.369 169.212C228.707 169.122 228.307 168.526 228.478 167.88Z"
                fill="white"
            />
            <path
                d="M238.825 176.358C237.927 176.832 235.901 176.113 235.28 175.868C235.158 175.827 235.093 175.795 235.093 175.795C235.093 175.795 233.982 172.185 236.873 170.224C236.889 170.216 236.914 170.192 236.938 170.183C237.853 169.685 240.704 170.322 240.793 171.286C241.136 175.141 239.903 175.786 238.833 176.35L238.825 176.358Z"
                fill="#407BFF"
            />
            <path
                opacity={0.2}
                d="M237.069 173.271C236.718 174.578 235.95 175.386 235.28 175.868C235.158 175.827 235.093 175.795 235.093 175.795C235.093 175.795 233.982 172.185 236.873 170.224C236.889 170.216 236.914 170.192 236.938 170.184C237.192 170.51 237.584 171.368 237.077 173.263L237.069 173.271Z"
                fill="black"
            />
            <path
                d="M232.913 168.689C232.913 168.689 234.767 168.607 235.616 168.689C236.465 168.77 236.204 171.506 236.204 171.506L236.931 171.898C236.931 171.898 237.527 173.507 236.253 174.316L235.6 174.096C235.6 174.096 235.412 174.741 234.889 175.067C234.366 175.394 231.777 174.667 231.777 174.667L232.904 168.689H232.913Z"
                fill="#EBB376"
            />
            <path
                d="M157.596 195.553H131.354L135.707 178.336C135.805 177.96 136.197 177.658 136.597 177.658H161.402C161.794 177.658 162.039 177.96 161.949 178.336L157.596 195.553Z"
                fill="#263238"
            />
            <path
                d="M157.399 194.115H132.275L136.098 179.014H161.221L157.399 194.115Z"
                fill="#407BFF"
            />
            <path
                opacity={0.6}
                d="M157.399 194.115H132.275L136.098 179.014H161.221L157.399 194.115Z"
                fill="white"
            />
            <path
                d="M146.164 195.006H119.889V196.1C119.889 196.566 120.264 196.95 120.738 196.95H156.749C157.214 196.95 157.598 196.574 157.598 196.1V195.006H146.164Z"
                fill="#263238"
            />
            <path
                opacity={0.6}
                d="M146.164 195.006H119.889V196.1C119.889 196.566 120.264 196.95 120.738 196.95H156.749C157.214 196.95 157.598 196.574 157.598 196.1V195.006H146.164Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M133.865 195.006V195.382H129.291V195.006H119.898V196.1C119.898 196.566 120.274 196.95 120.748 196.95H145.324C145.789 196.95 146.173 196.574 146.173 196.1V195.006H133.873H133.865Z"
                fill="white"
            />
            <path
                opacity={0.6}
                d="M147.586 187.927H135.547L137.295 181.025H149.334L147.586 187.927Z"
                fill="white"
            />
            <path
                d="M149.228 181.426H137.197L137.295 181.025H149.334L149.228 181.426Z"
                fill="#407BFF"
            />
            <path
                d="M156.767 193.039H140.023L142.449 183.434H159.201L156.767 193.039Z"
                fill="white"
            />
            <path
                d="M159.054 183.997H142.311L142.449 183.434H159.201L159.054 183.997Z"
                fill="#407BFF"
            />
            <path
                d="M327.434 296.79H323.62L316.768 201.402H321.823L327.434 296.79Z"
                fill="#263238"
            />
            <path
                opacity={0.1}
                d="M327.434 296.79H323.62L317.102 206.139L316.768 201.557H321.823L322.101 206.139L327.434 296.79Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M322.101 205.918H317.102L316.768 201.557H321.823L322.101 205.918Z"
                fill="black"
            />
            <path
                d="M220.424 296.79H216.609L222.212 201.402H227.268L220.424 296.79Z"
                fill="#263238"
            />
            <path
                opacity={0.1}
                d="M227.268 201.557L226.933 206.139L220.415 296.79H216.609L221.935 206.139L222.212 201.557H227.268Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M222.211 201.557H227.267L226.932 205.918H221.934L222.211 201.557Z"
                fill="black"
            />
            <path
                d="M174.124 296.79H170.31L163.457 201.402H168.513L174.124 296.79Z"
                fill="#263238"
            />
            <path
                opacity={0.1}
                d="M174.124 296.79H170.31L163.792 206.139L163.457 201.557H168.513L168.79 206.139L174.124 296.79Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M168.79 205.918H163.792L163.457 201.557H168.513L168.79 205.918Z"
                fill="black"
            />
            <path
                d="M75.2791 296.79H71.4648L77.0677 201.402H82.1316L75.2791 296.79Z"
                fill="#263238"
            />
            <path
                opacity={0.1}
                d="M82.1234 201.557L81.7886 206.139L75.2709 296.79H71.4648L76.79 206.139L77.0677 201.557H82.1234Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M77.0668 201.557H82.1224L81.7876 205.918H76.7891L77.0668 201.557Z"
                fill="black"
            />
            <path
                d="M73.0566 199.014H325.832V202.403C325.832 203.171 325.212 203.792 324.444 203.792H74.4533C73.6855 203.792 73.0648 203.171 73.0648 202.403V199.014H73.0566Z"
                fill="#407BFF"
            />
            <path
                opacity={0.6}
                d="M73.0566 199.014H325.832V202.403C325.832 203.171 325.212 203.792 324.444 203.792H74.4533C73.6855 203.792 73.0648 203.171 73.0648 202.403V199.014H73.0566Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M325.832 199.016H73.0566V201.401H325.832V199.016Z"
                fill="black"
            />
            <path
                d="M328.2 199.637H70.6953C69.952 199.637 69.3477 199.032 69.3477 198.289C69.3477 197.546 69.952 196.941 70.6953 196.941H328.2C328.943 196.941 329.548 197.546 329.548 198.289C329.548 199.032 328.943 199.637 328.2 199.637Z"
                fill="#407BFF"
            />
            <path
                opacity={0.6}
                d="M328.2 199.637H70.6953C69.952 199.637 69.3477 199.032 69.3477 198.289C69.3477 197.546 69.952 196.941 70.6953 196.941H328.2C328.943 196.941 329.548 197.546 329.548 198.289C329.548 199.032 328.943 199.637 328.2 199.637Z"
                fill="white"
            />
            <path
                d="M243.522 195.553H269.764L265.411 178.336C265.313 177.96 264.921 177.658 264.521 177.658H239.716C239.324 177.658 239.079 177.96 239.169 178.336L243.522 195.553Z"
                fill="#263238"
            />
            <path
                d="M243.717 194.115H268.84L265.018 179.014H239.895L243.717 194.115Z"
                fill="#407BFF"
            />
            <path
                opacity={0.6}
                d="M243.717 194.115H268.84L265.018 179.014H239.895L243.717 194.115Z"
                fill="white"
            />
            <path
                d="M254.958 195.006H281.233V196.1C281.233 196.566 280.857 196.95 280.383 196.95H244.373C243.907 196.95 243.523 196.574 243.523 196.1V195.006H254.958Z"
                fill="#263238"
            />
            <path
                opacity={0.6}
                d="M254.958 195.006H281.233V196.1C281.233 196.566 280.857 196.95 280.383 196.95H244.373C243.907 196.95 243.523 196.574 243.523 196.1V195.006H254.958Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M267.256 195.006V195.382H271.829V195.006H281.222V196.1C281.222 196.566 280.846 196.95 280.373 196.95H255.797C255.331 196.95 254.947 196.574 254.947 196.1V195.006H267.248H267.256Z"
                fill="white"
            />
            <path
                d="M244.824 193.015H267.366L264.091 180.078H241.549L244.824 193.015Z"
                fill="white"
            />
            <path
                d="M241.745 180.838H264.287L264.091 180.078H241.549L241.745 180.838Z"
                fill="#407BFF"
            />
            <path
                d="M300.947 290L307.391 289.6C307.612 289.576 307.816 289.739 307.84 289.959L308.469 295.04C308.502 295.595 308.085 296.077 307.53 296.118C305.284 296.224 304.198 296.159 301.364 296.314C299.624 296.42 296.194 296.788 293.792 296.559C291.391 296.33 291.685 293.962 292.722 293.847C295.181 293.57 298.44 291.764 299.975 290.392C300.245 290.155 300.588 290.017 300.939 290H300.947Z"
                fill="#263238"
            />
            <path
                d="M268.129 290L274.573 289.6C274.793 289.576 274.998 289.739 275.022 289.959L275.651 295.04C275.684 295.595 275.267 296.077 274.712 296.118C272.466 296.224 271.379 296.159 268.545 296.314C266.806 296.42 263.375 296.788 260.974 296.559C258.573 296.33 258.867 293.962 259.904 293.847C262.362 293.57 265.621 291.764 267.157 290.392C267.426 290.155 267.769 290.017 268.121 290H268.129Z"
                fill="#263238"
            />
            <path
                d="M292.311 192.874L287.018 193.666C287.018 193.666 285.222 193.07 283.327 192.874C281.432 192.678 279.235 192.874 279.235 192.874C279.235 192.874 276.842 193.773 276.842 196.068C276.842 196.068 282.428 197.562 284.576 196.664L292.311 196.068V192.874Z"
                fill="#EBB376"
            />
            <path
                d="M304.866 172.701C301.574 176.107 299.328 189.281 298.732 190.18C298.136 191.078 286.758 192.728 286.758 192.728C286.758 192.728 285.558 195.219 286.758 197.269C286.758 197.269 300.855 196.624 302.57 195.725C304.278 194.827 308.476 184.993 308.476 184.993C308.476 184.993 308.876 182.404 307.92 178.214C306.973 174.024 304.874 172.718 304.874 172.718L304.866 172.701Z"
                fill="#407BFF"
            />
            <path
                opacity={0.6}
                d="M304.866 172.701C301.574 176.107 299.328 189.281 298.732 190.18C298.136 191.078 286.758 192.728 286.758 192.728C286.758 192.728 285.558 195.219 286.758 197.269C286.758 197.269 300.855 196.624 302.57 195.725C304.278 194.827 308.476 184.993 308.476 184.993C308.476 184.993 308.876 182.404 307.92 178.214C306.973 174.024 304.874 172.718 304.874 172.718L304.866 172.701Z"
                fill="white"
            />
            <path
                d="M279.855 154.562C279.855 154.562 279.153 156.498 278.246 157.699C278.246 157.699 278.965 158.556 280.174 158.23L279.855 154.562Z"
                fill="#D58745"
            />
            <path
                d="M278.963 153.622C279.028 154.194 279.388 154.619 279.772 154.57C280.147 154.529 280.409 154.031 280.335 153.459C280.27 152.887 279.911 152.463 279.535 152.512C279.159 152.552 278.898 153.051 278.963 153.622Z"
                fill="#2E353A"
            />
            <path
                d="M279.104 151.361C279.104 151.361 279.52 151.247 280.026 151.688"
                stroke="#2E353A"
                strokeWidth={0.588059}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M281.349 160.966C281.488 161.219 281.627 161.456 281.782 161.684C282.705 163.056 283.995 164.061 285.425 164.625C285.8 164.78 286.184 164.894 286.576 164.992C288.643 165.474 290.864 165.098 292.612 163.751C293.331 163.212 293.976 162.485 294.491 161.603C296.549 158.115 295.659 154.922 294.597 149.392C294.385 148.2 293.903 147.073 293.176 146.109C290.636 142.703 285.809 142.009 282.411 144.549C277.617 148.077 278.932 156.367 281.357 160.974L281.349 160.966Z"
                fill="#EBB376"
            />
            <path
                opacity={0.2}
                d="M285.426 164.623C285.801 164.778 286.185 164.893 286.577 164.991C288.644 165.473 290.865 165.097 292.613 163.749C291.96 162.794 291.176 162.148 291.176 162.148C291.176 162.148 287.794 162.467 285.426 164.631V164.623Z"
                fill="black"
            />
            <path
                d="M301.27 168.455C299.204 171.575 289.305 172.801 289.305 172.801C289.305 172.801 284.919 172.711 285.458 171.216C287.778 170.203 288.66 168.782 288.782 167.214C288.848 166.299 288.651 165.344 288.333 164.388C288.17 163.882 287.957 163.383 287.737 162.902L294.344 154.832C292.621 163.751 297.922 166.242 301.278 168.464L301.27 168.455Z"
                fill="#EBB376"
            />
            <path
                d="M279.832 144.214C281.702 141.273 283.368 141.788 283.368 141.788C284.561 139.321 291.576 139.223 295.137 141.649C298.698 144.075 299.94 148.616 299.703 152.226C299.36 157.478 296.069 162.819 296.069 162.819C291.928 163.922 288.448 163.015 288.187 160.818C287.917 158.621 285.704 156.996 285.704 156.996C283.662 157.682 281.816 155.411 281.816 155.411C281.816 155.411 279.505 153.369 280.126 150.543C280.746 147.718 278.263 146.68 279.84 144.197L279.832 144.214Z"
                fill="#263238"
            />
            <path
                d="M286.43 155.557C286.406 156.823 285.973 157.86 285.23 158.432C284.241 159.183 283.114 157.803 282.861 156.129C282.649 154.642 282.877 152.404 284.135 152.372C285.393 152.339 286.447 153.989 286.43 155.557Z"
                fill="#EBB376"
            />
            <path
                d="M282.811 155.65C282.811 155.65 283.375 160.411 284.657 162.306C285.939 164.201 285.784 164.846 287.54 165.148C287.54 165.148 286.552 166.463 285.661 166.276C284.771 166.088 284.975 165.336 284.183 165.344C283.399 165.361 282.035 164.814 282.182 163.997C282.329 163.18 282.476 162.96 281.439 162.257C280.402 161.555 280.034 159.97 280.598 159.309C281.161 158.647 282.231 158.851 282.231 158.851L282.149 155.307L282.819 155.65H282.811Z"
                fill="#263238"
            />
            <path
                d="M287.24 171.214C287.24 171.214 288.841 164.034 298.471 164.108L300.913 167.661L287.24 171.214Z"
                fill="#407BFF"
            />
            <path
                opacity={0.8}
                d="M287.24 171.214C287.24 171.214 288.841 164.034 298.471 164.108L300.913 167.661L287.24 171.214Z"
                fill="white"
            />
            <path
                d="M288.85 260.034C292.501 273.902 298.25 291.789 298.25 291.789L308.345 291.021C308.345 291.021 302.865 266.887 301.362 252.071C300.431 242.923 300.513 235.36 303.192 234.772C304.874 234.404 308.819 234.413 313.875 234.535V215.709C299.696 218.617 285.591 224.113 284.521 228.516C283.018 234.715 285.64 247.84 288.858 260.042L288.85 260.034Z"
                fill="#407BFF"
            />
            <path
                opacity={0.2}
                d="M288.85 260.036C292.623 257.651 297.034 254.842 301.362 252.065C300.431 242.917 300.513 235.354 303.192 234.766C304.874 234.399 308.819 234.407 313.875 234.529V215.703C299.696 218.611 285.591 224.107 284.521 228.51C283.018 234.709 285.64 247.834 288.858 260.036H288.85Z"
                fill="black"
            />
            <path
                d="M294.625 210.098L292.223 202.845C292.223 202.845 290.5 189.41 281.736 172.487C281.736 172.487 292.281 164.997 303.968 166.116C312.961 166.974 321.422 190.03 324.068 208.277L294.625 210.09V210.098Z"
                fill="#407BFF"
            />
            <path
                opacity={0.9}
                d="M324.067 208.277L294.623 210.098L292.214 202.846C292.214 202.846 291.92 200.551 290.956 196.655C290.286 193.984 289.306 190.545 287.877 186.584C286.374 182.394 284.373 177.6 281.727 172.487C281.727 172.487 292.271 165.006 303.958 166.116C312.959 166.974 321.421 190.023 324.067 208.277Z"
                fill="white"
            />
            <path
                d="M294.379 213.847L293.488 206.701L322.377 201.947C322.377 201.947 331.827 220.667 327.204 229.847C322.965 238.268 277.807 233.253 270.856 234.772C263.832 236.308 275.585 290.899 275.585 290.899L265.49 291.667C265.49 291.667 248.976 241.747 252.185 228.508C253.582 222.734 277.39 215.089 294.379 213.847Z"
                fill="#407BFF"
            />
            <path
                d="M323.439 205.2L310.052 206.956L309.619 204.979L323.03 203.027L323.439 205.2Z"
                fill="black"
            />
            <path
                d="M308.501 204.996L308.665 206.956L296.291 208.655V206.719L308.501 204.996Z"
                fill="black"
            />
            <path
                d="M295.523 206.826V208.656L293.734 208.901V207.128L295.523 206.826Z"
                fill="black"
            />
            <path
                opacity={0.2}
                d="M290.958 197.293C290.288 194.745 289.308 191.469 287.879 187.696C288.393 186.634 289.096 185.695 289.994 184.984C289.994 184.984 292.763 193.993 290.958 197.293Z"
                fill="black"
            />
            <path
                d="M267.556 194.901L263.097 194.182C263.097 194.182 261.3 193.586 259.405 193.39C257.51 193.194 255.313 193.39 255.313 193.39C255.313 193.39 252.92 194.288 252.92 196.583C252.92 196.583 258.506 198.078 260.655 197.18L267.229 198.666L267.556 194.893V194.901Z"
                fill="#EBB376"
            />
            <path
                d="M281.729 172.701C279.646 174.988 280.863 193.692 280.185 194.974C279.507 196.256 263.924 193.667 263.924 193.667L262.895 198.298C262.895 198.298 281.214 203.68 284.571 203.746C287.928 203.811 289.978 183.989 289.978 183.989C289.978 183.989 289.512 181.228 287.054 177.716C284.596 174.196 281.721 172.709 281.721 172.709L281.729 172.701Z"
                fill="#407BFF"
            />
            <path
                opacity={0.9}
                d="M281.729 172.701C279.646 174.988 280.863 193.692 280.185 194.974C279.507 196.256 263.924 193.667 263.924 193.667L262.895 198.298C262.895 198.298 281.214 203.68 284.571 203.746C287.928 203.811 289.978 183.989 289.978 183.989C289.978 183.989 289.512 181.228 287.054 177.716C284.596 174.196 281.721 172.709 281.721 172.709L281.729 172.701Z"
                fill="white"
            />
            <path
                d="M335.477 237.941H278.247V234.299C278.247 231.236 280.73 228.753 283.793 228.753H329.923C332.985 228.753 335.468 231.236 335.468 234.299V237.941H335.477Z"
                fill="#407BFF"
            />
            <path
                opacity={0.4}
                d="M335.477 237.941H278.247V234.299C278.247 231.236 280.73 228.753 283.793 228.753H329.923C332.985 228.753 335.468 231.236 335.468 234.299V237.941H335.477Z"
                fill="white"
            />
            <path
                d="M316.708 206H285.198C283.581 206 282.38 207.756 282.74 209.594L288.375 237.943H289.674L284.871 213.098C284.52 211.26 285.713 209.52 287.33 209.52H316.055L316.7 206H316.708Z"
                fill="#263238"
            />
            <path
                d="M303.191 238.938V262.893C303.191 263.669 303.82 264.281 304.588 264.281H310.46C311.236 264.281 311.857 263.661 311.857 262.893V238.938H303.2H303.191Z"
                fill="#263238"
            />
            <path
                d="M338.156 296.796C339.563 296.796 340.704 295.655 340.704 294.247C340.704 292.84 339.563 291.699 338.156 291.699C336.748 291.699 335.607 292.84 335.607 294.247C335.607 295.655 336.748 296.796 338.156 296.796Z"
                fill="#263238"
            />
            <path
                opacity={0.2}
                d="M336.883 294.249C336.883 293.546 337.455 292.975 338.157 292.975C338.859 292.975 339.431 293.546 339.431 294.249C339.431 294.951 338.859 295.523 338.157 295.523C337.455 295.523 336.883 294.951 336.883 294.249Z"
                fill="white"
            />
            <path
                d="M274.342 294.247C274.342 292.843 275.485 291.699 276.89 291.699C278.295 291.699 279.438 292.843 279.438 294.247C279.438 295.652 278.295 296.796 276.89 296.796C275.485 296.796 274.342 295.652 274.342 294.247Z"
                fill="#263238"
            />
            <path
                opacity={0.2}
                d="M275.617 294.249C275.617 293.546 276.189 292.975 276.891 292.975C277.594 292.975 278.165 293.546 278.165 294.249C278.165 294.951 277.594 295.523 276.891 295.523C276.189 295.523 275.617 294.951 275.617 294.249Z"
                fill="white"
            />
            <path
                d="M305.413 283.083V255.705H309.628V283.083C326.028 283.499 335.992 289.233 337.985 290.49C338.214 290.637 338.353 290.891 338.353 291.168V293.447C338.353 293.888 337.993 294.256 337.544 294.256C337.258 294.256 336.989 294.1 336.842 293.847L335.87 292.14C335.87 292.14 324.591 288.122 309.62 287.795V292.295C309.62 292.867 309.154 293.341 308.582 293.341H306.451C305.879 293.341 305.405 292.875 305.405 292.295V287.795C290.434 288.122 279.155 292.14 279.155 292.14L278.183 293.847C278.036 294.1 277.775 294.256 277.48 294.256C277.039 294.256 276.672 293.896 276.672 293.447V291.168C276.672 290.891 276.811 290.637 277.039 290.49C279.032 289.233 288.997 283.499 305.397 283.083H305.413Z"
                fill="#263238"
            />
            <path d="M306.148 296.787H308.908V291.699H306.148V296.787Z" fill="#263238" />
            <path
                opacity={0.2}
                d="M311.849 238.938H303.191V248.894H311.849V238.938Z"
                fill="black"
            />
            <path
                d="M276.688 236.472V239.33C276.688 240.139 277.251 240.792 277.945 240.792H335.787C336.482 240.792 337.045 240.139 337.045 239.33V236.472C337.045 235.671 336.49 235.01 335.787 235.01H277.945C277.251 235.01 276.688 235.671 276.688 236.472Z"
                fill="#263238"
            />
            <path
                d="M303.297 237.941H337.037C337.037 237.941 347.761 220.332 347.761 191.239V180.556C347.761 176.17 344.151 172.609 339.7 172.609H325.39C321.029 172.609 317.46 176.032 317.337 180.336C316.921 195.323 314.683 227.789 303.289 237.949L303.297 237.941Z"
                fill="#263238"
            />
            <path
                opacity={0.2}
                d="M310.723 237.941H335.52C336.475 237.941 337.357 237.418 337.799 236.577C340.241 231.93 347.763 215.611 347.763 191.239V180.556C347.763 176.162 344.161 172.609 339.71 172.609H327.344C322.983 172.609 319.414 176.032 319.291 180.336C318.923 193.731 317.094 221.067 308.583 233.988C307.456 235.695 308.657 237.949 310.715 237.949L310.723 237.941Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M276.688 236.472V239.33C276.688 240.139 277.251 240.792 277.945 240.792H303.869C304.808 240.792 305.568 239.91 305.568 238.816V236.986C305.568 235.892 304.808 235.01 303.869 235.01H277.953C277.259 235.01 276.696 235.671 276.696 236.472H276.688Z"
                fill="black"
            />
            <path
                d="M122.602 175.359L120.128 175.947L119.556 173.954C119.556 173.954 118.821 174.444 117.808 173.954C117.53 173.823 117.147 173.586 116.722 173.309C115.619 172.582 114.312 171.585 114.312 171.585L116.224 167.689C116.224 167.689 117.057 168.024 118.078 168.474C119.515 169.119 121.32 169.976 121.639 170.434C122.178 171.21 121.908 172.655 121.908 172.655L122.602 175.359Z"
                fill="#E4897B"
            />
            <path
                opacity={0.2}
                d="M116.722 173.309C115.619 172.582 114.312 171.585 114.312 171.585L116.224 167.689C116.224 167.689 117.057 168.024 118.078 168.474C118.168 169.617 118.078 171.618 116.722 173.309Z"
                fill="black"
            />
            <path
                d="M156.784 265.322L152.692 266.931L149.743 261.377L147.064 256.321L151.606 253.602L154.35 259.801L156.784 265.322Z"
                fill="#E4897B"
            />
            <path
                opacity={0.2}
                d="M154.35 259.801L149.743 261.377L147.064 256.321L151.606 253.602L154.35 259.801Z"
                fill="black"
            />
            <path
                d="M153.501 256.772L147.923 259.762C147.923 259.762 126.859 222.722 123.616 223.032C120.374 223.343 89.6476 242.928 80.2713 235.692C70.9032 228.464 80.0181 206.681 80.0181 206.681C80.0181 206.681 99.4077 201.144 101.507 205.399C101.915 206.224 102.462 207.874 102.895 209.295C103.312 210.626 103.622 211.753 103.622 211.753C103.622 211.753 103.622 211.753 103.63 211.745C104.218 211.508 123.371 203.651 131.914 207.865C140.596 212.145 153.485 256.772 153.485 256.772H153.501Z"
                fill="#263238"
            />
            <path
                opacity={0.3}
                d="M153.501 256.772L147.923 259.762C147.923 259.762 126.859 222.722 123.616 223.032C120.374 223.343 89.6476 242.928 80.2713 235.692C70.9032 228.464 80.0181 206.681 80.0181 206.681C80.0181 206.681 99.4077 201.144 101.507 205.399C101.915 206.224 102.462 207.874 102.895 209.295C103.312 210.626 103.622 211.753 103.622 211.753C103.622 211.753 103.622 211.753 103.63 211.745C104.218 211.508 123.371 203.651 131.914 207.865C140.596 212.145 153.485 256.772 153.485 256.772H153.501Z"
                fill="white"
            />
            <path
                d="M123.215 286.976L118.845 286.478L118.821 280.312L118.812 274.652L124.097 274.398L123.631 281.063L123.215 286.976Z"
                fill="#E4897B"
            />
            <path
                opacity={0.2}
                d="M123.615 281.192L118.805 280.425V274.707L124.097 274.43L123.615 281.192Z"
                fill="black"
            />
            <path
                d="M145.195 257.926L153.355 253.842L154.204 257.297L148.029 260.482L145.195 257.926Z"
                fill="#407BFF"
            />
            <path
                d="M94.7539 235.758C94.7539 235.758 96.1015 235.603 116.708 231.789C116.708 231.789 117.174 258.146 118.154 277.788L124.5 277.993C124.5 277.993 133.607 234.386 132.537 227.803C131.467 221.22 123.634 220.33 123.634 220.33C123.634 220.33 110.027 230.899 94.7621 235.758H94.7539Z"
                fill="#263238"
            />
            <path
                opacity={0.4}
                d="M94.7539 235.758C94.7539 235.758 96.1015 235.603 116.708 231.789C116.708 231.789 117.174 258.146 118.154 277.788L124.5 277.993C124.5 277.993 133.607 234.386 132.537 227.803C131.467 221.22 123.634 220.33 123.634 220.33C123.634 220.33 110.027 230.899 94.7621 235.758H94.7539Z"
                fill="white"
            />
            <path
                opacity={0.3}
                d="M94.7539 235.758C94.7539 235.758 96.1015 235.603 116.708 231.789C116.708 231.789 117.174 258.146 118.154 277.788L124.5 277.993C124.5 277.993 133.607 234.386 132.537 227.803C131.467 221.22 123.634 220.33 123.634 220.33C123.634 220.33 110.027 230.899 94.7621 235.758H94.7539Z"
                fill="black"
            />
            <path
                d="M116.984 276.032L125.642 275.828L124.458 279.397L117.434 279.463L116.984 276.032Z"
                fill="#407BFF"
            />
            <path
                d="M164.937 272.593C164.251 272.389 163.532 272.308 162.822 272.357C162.822 272.357 160.176 271.254 156.982 265.994L154.254 266.354C153.315 266.492 152.555 267.211 152.367 268.142C152.343 268.273 152.335 268.404 152.343 268.534C152.457 269.188 152.621 269.833 152.825 270.47C153.348 272.479 155.3 276.669 155.3 276.669L155.749 276.489L154.352 272.536C153.887 271.189 155.52 272.324 156.386 273.631C157.252 274.929 158.477 276.195 161.392 275.093C164.79 273.786 166.138 273.092 164.945 272.61L164.937 272.593Z"
                fill="#263238"
            />
            <path
                d="M156.277 264.588C156.277 264.588 160.663 271.244 162.811 272.355C162.811 272.355 160.402 274.283 157.184 271.171C153.027 267.144 152.333 268.525 152.333 268.525C152.333 268.525 152.267 266.956 152.439 266.164C152.774 264.637 156.277 264.588 156.277 264.588Z"
                fill="#E4897B"
            />
            <path
                d="M129.757 294.726C129.145 294.359 128.467 294.114 127.756 293.991C127.756 293.991 125.445 292.292 123.591 286.42L120.855 286.118C119.907 286.028 119.001 286.542 118.592 287.408C118.535 287.522 118.494 287.653 118.478 287.776C118.437 288.437 118.437 289.107 118.478 289.768C118.511 291.843 119.417 296.376 119.417 296.376L119.891 296.302L119.466 292.129C119.327 290.708 120.65 292.202 121.181 293.672C121.712 295.143 122.602 296.662 125.698 296.278C129.308 295.821 130.778 295.453 129.741 294.71L129.757 294.726Z"
                fill="#263238"
            />
            <path
                d="M123.25 284.902C123.25 284.902 125.929 292.408 127.758 293.992C127.758 293.992 124.957 295.291 122.572 291.509C119.485 286.609 118.488 287.785 118.488 287.785C118.488 287.785 118.799 286.249 119.158 285.522C119.844 284.118 123.258 284.902 123.258 284.902H123.25Z"
                fill="#E4897B"
            />
            <path
                d="M118.233 159.537C118.233 159.537 116.722 159.496 116.02 160.575"
                stroke="#263238"
                strokeWidth={0.408375}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M117.572 163.133C117.572 163.133 118.454 164.44 119.345 165.175C119.345 165.175 118.969 165.942 118.013 165.918L117.58 163.133H117.572Z"
                fill="#DE5753"
            />
            <path
                d="M118.341 161.591C118.398 162.024 118.21 162.408 117.916 162.441C117.63 162.482 117.344 162.155 117.295 161.722C117.238 161.289 117.426 160.905 117.72 160.873C118.006 160.832 118.292 161.158 118.341 161.591Z"
                fill="#263238"
            />
            <path
                d="M117.598 160.904L118.496 160.414C118.496 160.414 118.129 161.272 117.598 160.904Z"
                fill="#263238"
            />
            <path
                d="M107.061 171.995C107.118 172.036 107.175 172.085 107.24 172.109C108.073 172.64 109.029 173.146 110.091 173.334C113.072 173.873 115.065 173.367 116.306 171.97C118.046 169.879 118.332 166.318 117.866 162.063C117.654 160.111 117.294 158.42 116.69 157.122C116.69 157.105 116.682 157.056 116.674 157.048C116.641 156.999 116.625 156.926 116.592 156.869C115.694 155.055 114.297 153.92 112.133 153.52C111.381 153.381 110.54 153.324 109.592 153.357C101.262 153.675 100.437 167.544 107.052 171.987L107.061 171.995Z"
                fill="#E4897B"
            />
            <path
                opacity={0.2}
                d="M107.24 172.117C108.073 172.648 109.021 173.04 110.083 173.228C113.064 173.767 115.048 173.163 116.298 171.75C114.869 172.142 112.925 172.354 110.997 171.578C109.78 171.088 108.465 171.431 107.24 172.125V172.117Z"
                fill="black"
            />
            <path
                d="M116.699 157.072C116.699 157.072 117.442 162.887 112.027 165.673C112.027 165.673 109.944 171.855 106.448 172.109C101.687 172.46 97.1457 165.877 98.4606 158.885C100.168 149.819 113.709 148.423 116.69 157.072H116.699Z"
                fill="#263238"
            />
            <path
                d="M114.755 163.851C114.706 162.936 113.898 161.442 112.493 161.719C111.039 162.005 110.402 165.656 114.045 166.293C114.567 166.383 114.853 165.754 114.755 163.851Z"
                fill="#E4897B"
            />
            <path
                d="M106.228 151.355C106.228 151.355 111.733 149.672 114.918 151.763C118.642 154.205 120.137 156.843 116.086 161.254C116.086 161.254 106.579 158.681 103.508 159.473C100.429 160.266 98.2481 161.287 98.2481 161.287C97.2925 155.136 103.059 150.252 106.228 151.363V151.355Z"
                fill="#263238"
            />
            <path
                d="M111.456 172.769C111.456 172.769 107.364 174.77 108.556 179.156L98.1426 174.819C98.1426 174.819 103.835 174.459 103.541 170.457C103.541 170.457 109.495 168.17 111.456 172.769Z"
                fill="#E4897B"
            />
            <path
                d="M107.649 152.481C107.649 152.481 104.545 143.53 97.4885 145.073C90.4399 146.617 90.979 154.188 96.0265 156.524C101.066 158.868 108.131 157.978 107.649 152.481Z"
                fill="#263238"
            />
            <path
                d="M97.9707 156.387C97.9707 156.387 91.7471 156.346 92.5148 161.687C92.5148 161.687 93.0539 159.956 94.4505 159.245C94.4505 159.245 92.5638 160.691 94.4424 163.182C94.4424 163.182 93.6909 161.557 96.2637 160.421C98.8365 159.286 105.877 159.392 104.57 155.913C103.255 152.434 97.9707 156.387 97.9707 156.387Z"
                fill="#263238"
            />
            <path
                d="M106.547 152.473C106.547 152.473 105.044 141.578 96.2559 143.775"
                stroke="#263238"
                strokeWidth={0.408375}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M103.052 168.514C103.052 168.514 96.6401 165.272 97.7754 160.143"
                stroke="#263238"
                strokeWidth={0.408375}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M97.3335 156.426C97.3335 156.426 91.7143 156.336 91.2324 160.167"
                stroke="#263238"
                strokeWidth={0.408375}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M98.8613 174.525L101.401 172.695C101.401 172.695 109.765 173.831 109.847 179.156C109.928 184.481 98.8613 174.525 98.8613 174.525Z"
                fill="#407BFF"
            />
            <path
                d="M97.3319 174.525C97.3319 174.525 103.605 173.317 112.327 179.156C112.327 179.156 115.251 191.93 102.902 209.303L79.4941 207.833C79.4941 207.833 85.8321 182.252 97.3319 174.525Z"
                fill="#263238"
            />
            <path
                opacity={0.3}
                d="M103.631 211.761L95.668 215.763C95.668 215.763 98.3061 211.353 102.913 209.303C103.329 210.634 103.639 211.761 103.639 211.761H103.631Z"
                fill="black"
            />
            <path
                opacity={0.1}
                d="M111.617 190.934C111.225 192.576 110.686 194.365 109.967 196.268C108.505 200.164 106.275 204.558 102.902 209.303L79.4941 207.833C79.4941 207.833 85.8321 182.252 97.3319 174.526C97.3319 174.526 103.596 173.325 112.327 179.157C112.327 179.157 113.357 183.657 111.617 190.934Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M111.618 190.936C111.226 192.577 110.687 194.366 109.968 196.269C109.715 193.484 110.017 190.486 110.017 190.486C110.622 190.552 111.153 190.707 111.618 190.936Z"
                fill="black"
            />
            <path
                d="M112.328 179.156C112.328 179.156 119.213 189.055 120.993 190.003C122.774 190.95 119.352 174.525 119.352 174.525L122.676 173.807C122.676 173.807 128.565 193.556 124.612 196.953C120.658 200.351 110.024 190.493 110.024 190.493C110.024 190.493 109.649 186.613 110.024 183.706C110.4 180.798 112.336 179.165 112.336 179.165L112.328 179.156Z"
                fill="#263238"
            />
            <path
                opacity={0.1}
                d="M112.328 179.156C112.328 179.156 119.213 189.055 120.993 190.003C122.774 190.95 119.352 174.525 119.352 174.525L122.676 173.807C122.676 173.807 128.565 193.556 124.612 196.953C120.658 200.351 110.024 190.493 110.024 190.493C110.024 190.493 109.649 186.613 110.024 183.706C110.4 180.798 112.336 179.165 112.336 179.165L112.328 179.156Z"
                fill="white"
            />
            <path
                d="M66.4738 228.75H112.604C115.667 228.75 118.15 231.233 118.15 234.296V237.938H60.9199V234.296C60.9199 231.233 63.4028 228.75 66.4657 228.75H66.4738Z"
                fill="#407BFF"
            />
            <path
                opacity={0.4}
                d="M66.4738 228.75H112.604C115.667 228.75 118.15 231.233 118.15 234.296V237.938H60.9199V234.296C60.9199 231.233 63.4028 228.75 66.4657 228.75H66.4738Z"
                fill="white"
            />
            <path
                d="M79.6973 205.998H111.207C112.825 205.998 114.025 207.754 113.666 209.592L108.03 237.941H106.732L111.534 213.096C111.885 211.258 110.693 209.518 109.076 209.518H80.3507L79.7054 205.998H79.6973Z"
                fill="#263238"
            />
            <path
                d="M93.2165 238.936V262.891C93.2165 263.667 92.5876 264.279 91.8199 264.279H85.9474C85.1715 264.279 84.5508 263.659 84.5508 262.891V238.936H93.2083H93.2165Z"
                fill="#263238"
            />
            <path
                d="M60.7977 294.246C60.7977 292.841 59.6542 291.697 58.2494 291.697C56.8446 291.697 55.7012 292.841 55.7012 294.246C55.7012 295.65 56.8446 296.794 58.2494 296.794C59.6542 296.794 60.7977 295.65 60.7977 294.246Z"
                fill="#263238"
            />
            <path
                opacity={0.2}
                d="M59.5248 294.247C59.5248 293.544 58.9531 292.973 58.2507 292.973C57.5483 292.973 56.9766 293.544 56.9766 294.247C56.9766 294.949 57.5483 295.521 58.2507 295.521C58.9531 295.521 59.5248 294.949 59.5248 294.247Z"
                fill="white"
            />
            <path
                d="M122.063 294.246C122.063 292.841 120.92 291.697 119.515 291.697C118.11 291.697 116.967 292.841 116.967 294.246C116.967 295.65 118.11 296.794 119.515 296.794C120.92 296.794 122.063 295.65 122.063 294.246Z"
                fill="#263238"
            />
            <path
                opacity={0.2}
                d="M119.516 295.521C120.22 295.521 120.79 294.95 120.79 294.247C120.79 293.543 120.22 292.973 119.516 292.973C118.813 292.973 118.242 293.543 118.242 294.247C118.242 294.95 118.813 295.521 119.516 295.521Z"
                fill="white"
            />
            <path
                d="M90.9922 283.081V255.703H86.7778V283.081C70.3775 283.497 60.4131 289.231 58.4203 290.488C58.1916 290.636 58.0527 290.889 58.0527 291.166V293.445C58.0527 293.886 58.4121 294.254 58.8613 294.254C59.1472 294.254 59.4167 294.099 59.5637 293.845L60.5357 292.138C60.5357 292.138 71.815 288.12 86.786 287.793V292.294C86.786 292.865 87.2515 293.339 87.8314 293.339H89.9631C90.5349 293.339 91.0004 292.873 91.0004 292.294V287.793C105.971 288.12 117.251 292.138 117.251 292.138L118.223 293.845C118.37 294.099 118.631 294.254 118.925 294.254C119.366 294.254 119.734 293.894 119.734 293.445V291.166C119.734 290.889 119.595 290.636 119.366 290.488C117.373 289.231 107.409 283.497 91.0086 283.081H90.9922Z"
                fill="#263238"
            />
            <path d="M90.2665 291.705H87.5059V296.793H90.2665V291.705Z" fill="#263238" />
            <path
                opacity={0.2}
                d="M93.2142 238.936H84.5566V248.892H93.2142V238.936Z"
                fill="black"
            />
            <path
                d="M119.717 236.47V239.328C119.717 240.137 119.154 240.79 118.459 240.79H60.6172C59.9229 240.79 59.3594 240.137 59.3594 239.328V236.47C59.3594 235.669 59.9148 235.008 60.6172 235.008H118.459C119.154 235.008 119.717 235.669 119.717 236.47Z"
                fill="#263238"
            />
            <path
                d="M93.1084 237.939H59.3684C59.3684 237.939 48.6445 220.33 48.6445 191.237V180.554C48.6445 176.168 52.2546 172.607 56.7058 172.607H71.0153C75.3767 172.607 78.9459 176.03 79.0684 180.334C79.485 195.321 81.7229 227.787 93.1165 237.947L93.1084 237.939Z"
                fill="#263238"
            />
            <path
                opacity={0.2}
                d="M85.6841 237.939H60.8876C59.932 237.939 59.0499 237.416 58.6089 236.575C56.1668 231.928 48.6445 215.609 48.6445 191.237V180.554C48.6445 176.16 52.2464 172.607 56.6977 172.607H69.0633C73.4247 172.607 76.9939 176.03 77.1164 180.334C77.4839 193.729 79.3135 221.065 87.824 233.986C88.9511 235.693 87.7505 237.947 85.6923 237.947L85.6841 237.939Z"
                fill="white"
            />
            <path
                opacity={0.2}
                d="M119.718 236.47V239.328C119.718 240.137 119.155 240.79 118.46 240.79H92.5367C91.5975 240.79 90.8379 239.908 90.8379 238.814V236.984C90.8379 235.89 91.5975 235.008 92.5367 235.008H118.452C119.146 235.008 119.71 235.669 119.71 236.47H119.718Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_9915_139886">
                <rect
                    width={408.375}
                    height={304.329}
                    fill="white"
                    transform="translate(0 0.835938)"
                />
            </clipPath>
        </defs>
    </svg>
);
