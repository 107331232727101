import {appendPlusToNumber, isAvailable, timeDateFormat} from 'utils/helpers';

export const proprietorInformationLabels = [
    {label: "Proprietor's First Name", key: 'first_name'},
    {label: "Proprietor's Last Name", key: 'last_name'},
    {label: "Proprietor's Gender", key: 'gender', fn: val => val?.label},
    {label: "Proprietor's Date of Birth", key: 'dob', fn: val => timeDateFormat(val).date},
    {label: "Proprietor's Nationality", key: 'nationality', fn: val => val?.label},
    {label: "Proprietor's Phone Number", key: 'phone_number', fn: val => appendPlusToNumber(val)},
    {label: "Proprietor's Email", key: 'email'},
    {label: "Proprietor's ID Type", key: 'id_type', fn: val => val?.label},
    {label: "Proprietor's ID Number", key: 'id_number'},
    {
        label: "Proprietor's ID Issued Date",
        key: 'id_issued_date',
        fn: val => isAvailable(val, timeDateFormat(val).date),
    },
    {
        label: "Proprietor's ID Expiry Date",
        key: 'id_expiry_date',
        fn: val => isAvailable(val, timeDateFormat(val).date),
    },
];

export const proprietorAddressLabels = [
    {label: 'Street Number', key: 'proprietor_street_number'},
    {label: 'Street Name', key: 'proprietor_street_name'},
    {label: 'Country', key: 'proprietor_country', fn: () => 'Nigeria'},
    {label: 'City', key: 'proprietor_city'},
    {label: 'State', key: 'proprietor_state', fn: val => val?.label},
    {label: 'Postcode', key: 'proprietor_postcode'},
    {label: 'Local Government Area (LGA)', key: 'proprietor_lga'},
];

export const businessInformationLabels = [
    {label: 'Line Of Business', key: 'business_line', fn: val => val?.label},
    {
        label: 'Business Commmencement Date',
        key: 'business_start_date',
        fn: val => timeDateFormat(val).date,
    },
    {label: 'Proposed Business Name', key: 'business_name'},
];

export const companyAddressLabels = [
    {label: 'Street Number', key: 'business_street_number'},
    {label: 'Street Name', key: 'business_street_name', fn: val => isAvailable(val)},
    {label: 'Country', key: 'business_country', fn: () => 'Nigeria'},
    {label: 'City', key: 'business_city'},
    {label: 'State', key: 'business_state', fn: val => val?.label},
    {label: 'Company Email', key: 'business_email'},
];
