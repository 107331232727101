import Anime from 'assets/icons/anime.png';
import {Button} from 'utils';
import {useCompliance} from './ComplianceContent';
import {STEPS} from './utils';

export const ComplianceStart = () => {
    const {updateData} = useCompliance();

    return (
        <div className="p-5">
            <img src={Anime} alt="" />

            <h1 className="mt-5">Integrate your verification suite</h1>
            <h4 className="mt-4 mb-2">
                <b>What your verification suite is for</b>
            </h4>

            <span className="text-secondary">
                Your Verification suite account allows you to authenticate your merchant customers’
                identity. You are being charged for each of your customer’s verification.
            </span>

            <Button
                name="Continue to Integrate"
                cls="btn--lg btn--secondary my-5"
                handleClick={() => updateData({step: STEPS.LOGIN})}
            />
        </div>
    );
};
