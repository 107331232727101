import styled from 'styled-components';

export const ButtonContainer = styled.button`
    &.btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        font-weight: 600;
        font-size: 1.1rem;
        transition: all 200ms ease-in-out;
        border-radius: 5px;
        opacity: 1;
        height: 4rem;

        &:hover {
            cursor: pointer;
            color: unset;
        }
        &:focus {
            box-shadow: none;
            outline: none !important;
        }
        &:disabled {
            opacity: 0.5;

            &:hover {
                cursor: not-allowed;
            }
        }
        &.btn--sm {
            font-size: 1em;
            letter-spacing: 0.02em;
        }

        &.btn--lg {
            width: 100%;
            font-size: 1.3rem;
            letter-spacing: 0.04em;
        }
        &.btn--primary,
        &.btn--primary:hover {
            background: var(--primaryFull);
            color: var(--whiteFull);
        }

        &.btn--primary--bordered,
        &.btn--primary--bordered:hover {
            border: 1px solid var(--primaryFull);
            color: var(--primaryFull);
            background: unset;
        }

        &.btn--secondary,
        &.btn--secondary:hover {
            background: var(--secondaryFull);
            color: var(--whiteFull);
        }

        &.btn--gray {
            background: var(--grayThree);
            color: var(--black);
        }
    }
`;
