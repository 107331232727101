import {useContext, useState} from 'react';
import {useMutation} from 'react-query';

import {BackArrowIcon} from 'assets/icons';
import {StepIndicator} from './StepIndicator';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';
import {Button, CustomInputBox, CustomSelect, FormValidator} from 'utils';

import API from 'api';
import {addressOptions, countryOptions} from '../data';
import {updateAddress} from 'api/routes/address';
import {getCountry, getError, getState, getStates} from 'utils/helpers';
import AppContext from 'components/App/AppContext';

const defaultValues = {
    useIdAddress: '',
    addressLandmark: '',
    alias: '',
    addressCountry: '',
    addressState: '',
    addressCity: '',
    addressLine1: '',
    addressLine2: '',
    flatNumber: '',
    buildingName: '',
    buildingNumber: '',
    postalCode: '',
};

const fetchValues = obj => {
    const result = {};
    const keys = Object.keys(defaultValues);
    for (const key of keys) {
        if (obj?.[key]) result[key] = obj?.[key];
    }
    return result;
};

export const AddressStandard = () => {
    const {params} = useContext(AppContext);
    const {otherParams} = params || {};

    const country = getCountry(otherParams?.addressCountry);
    const state = getState(otherParams?.addressState, country?.code);

    const {currentStep, result, handleStep, isMini, isContinue} = useContext(AddressContext);

    const residence = result?.id_result?.residential_info;
    const fullAddress = residence?.address
        ? [residence?.address, residence?.lga_of_residence, residence?.state_of_residence]
              .filter(Boolean)
              .join(', ')
        : 'Not Available';

    const [step, setStep] = useState(1);
    const [errorMsg, setErrorMsg] = useState();
    const [formValues, setFormValues] = useState({
        ...defaultValues,
        ...(isMini &&
            fetchValues({
                ...otherParams,
                addressCountry: country,
                addressState: state,
            })),
        ...fetchValues(result),
    });
    const [formErrors, setFormErrors] = useState({});

    const {clientid, appname, apikey} = params || {};

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const {mutate, isLoading} = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(updateAddress, payload, {
                headers: {
                    clientid,
                    appname,
                    apikey,
                },
            });
            return response;
        },
        {
            onSuccess: ({data, status}) => {
                if (
                    (status === 200 || status === 201) &&
                    (data?.status_code === 200 || data?.status_code === 201)
                )
                    handleStep({step: STEPS.BILL, data: {address_updated: true, ...formValues}});
                // setSuccess(true);
                else setErrorMsg(data?.message);
            },
            onError: error => setErrorMsg(getError(error)),
        }
    );

    const handleBack = e => {
        e.preventDefault();
        handleStep({step: STEPS.LIVENESS_RESULT});
    };

    const handleNextStep = e => {
        e.preventDefault();

        const values = {
            useIdAddress: formValues?.useIdAddress,
        };

        const {isValid, formErrors} = FormValidator(values);

        isValid ? setStep(2) : setFormErrors(formErrors);
    };

    const handleSubmit = e => {
        e.preventDefault();

        const {isValid, formErrors} = FormValidator(formValues);

        if (isValid) {
            const payload = {
                ...formValues,
                reference: result?.id_result?.reference,
                addressCountry: formValues?.addressCountry?.label,
                addressState: formValues?.addressState?.label,
                useIdAddress: formValues?.useIdAddress?.value,
            };
            mutate(payload);
        } else setFormErrors(formErrors);
    };

    const renderStep = () => {
        switch (step) {
            case 1:
            default:
                return (
                    <>
                        <div className="w-100 mb-5">
                            <CustomInputBox
                                label="Full Address"
                                value={fullAddress}
                                disabled={true}
                            />
                            <CustomSelect
                                label="Do you still live here"
                                placeholder="Select an Option"
                                options={addressOptions}
                                value={formValues?.useIdAddress}
                                onChange={value => handleSelect('useIdAddress', value)}
                                error={formErrors?.useIdAddress}
                            />
                        </div>
                        <Button
                            cls="w-100 btn btn-lg rounded-0 mt-5"
                            name="Continue"
                            handleClick={handleNextStep}
                        />
                        {!isContinue && !isMini && (
                            <Button
                                cls="w-100 btn btn-lg btn--transparent black rounded-0 underline"
                                name="Back"
                                handleClick={handleBack}
                            />
                        )}
                    </>
                );

            case 2:
                return (
                    <>
                        <div className="w-100 mb-5">
                            {errorMsg && (
                                <h4 className="text-center text-danger py-4">{errorMsg}</h4>
                            )}
                            <CustomSelect
                                label="Country"
                                placeholder="Select your country"
                                options={countryOptions}
                                value={formValues?.addressCountry}
                                onChange={value => handleSelect('addressCountry', value)}
                                error={formErrors?.addressCountry}
                            />
                            <CustomSelect
                                label="State"
                                placeholder="Select your state"
                                options={getStates(formValues?.addressCountry?.code || 'NG')}
                                value={formValues?.addressState}
                                onChange={value => handleSelect('addressState', value)}
                                error={formErrors?.addressState}
                            />
                            <CustomInputBox
                                label="City"
                                name="addressCity"
                                placeholder="Enter City"
                                value={formValues?.addressCity}
                                onChange={handleChange}
                                error={formErrors?.addressCity}
                            />
                            <CustomInputBox
                                label="Flat Number"
                                name="flatNumber"
                                placeholder="Enter Flat Number"
                                value={formValues?.flatNumber}
                                onChange={handleChange}
                                error={formErrors?.flatNumber}
                            />
                            <CustomInputBox
                                label="Building Name"
                                name="buildingName"
                                placeholder="Enter Building Name"
                                value={formValues?.buildingName}
                                onChange={handleChange}
                                error={formErrors?.buildingName}
                            />
                            <CustomInputBox
                                label="Building Number"
                                name="buildingNumber"
                                placeholder="Enter Building Number"
                                value={formValues?.buildingNumber}
                                onChange={handleChange}
                                error={formErrors?.buildingNumber}
                            />
                            <CustomInputBox
                                label="Landmark"
                                name="addressLandmark"
                                placeholder="Enter Landmark"
                                value={formValues?.addressLandmark}
                                onChange={handleChange}
                                error={formErrors?.addressLandmark}
                                infoText="A popular building or place around your address"
                            />
                            <CustomInputBox
                                label="Address Line 1"
                                name="addressLine1"
                                placeholder="Enter Address Line 1"
                                value={formValues?.addressLine1}
                                onChange={handleChange}
                                error={formErrors?.addressLine1}
                            />
                            <CustomInputBox
                                label="Address Line 2"
                                name="addressLine2"
                                placeholder="Enter Address Line 2"
                                value={formValues?.addressLine2}
                                onChange={handleChange}
                                error={formErrors?.addressLine2}
                            />
                            <CustomInputBox
                                label="Alias"
                                name="alias"
                                placeholder="Enter Alias"
                                value={formValues?.alias}
                                onChange={handleChange}
                                error={formErrors?.alias}
                            />
                            <CustomInputBox
                                cls="mb-5"
                                label="Postal Code"
                                name="postalCode"
                                placeholder="Enter Postal Code"
                                value={formValues?.postalCode}
                                onChange={handleChange}
                                error={formErrors?.postalCode}
                            />
                        </div>
                        <Button
                            cls="w-100 btn btn-lg rounded-0"
                            name={isLoading ? 'Loading' : 'Continue'}
                            handleClick={handleSubmit}
                            disabled={isLoading}
                        />
                        <Button
                            cls="w-100 btn btn-lg btn--transparent black rounded-0 underline"
                            name="Back"
                            handleClick={() => setStep(1)}
                            disabled={isLoading}
                        />
                    </>
                );
        }
    };

    return (
        <div className="d-flex flex-column align-items-center">
            {/* <div className="w-100">
                <BackArrowIcon
                    className="pointer back-arrow"
                    onClick={() => handleStep({step: STEPS.LIVENESS_RESULT})}
                />
            </div> */}
            <StepIndicator currentStep={currentStep} />
            <AddressHeader
                title="Enter Address"
                text={
                    <>
                        Enter your address in detail{' '}
                        {step === 2 && (
                            <>
                                <br />
                                (N/A for fields that are not applicable)
                            </>
                        )}
                    </>
                }
                cls="text-center mb-4"
            />
            {renderStep()}
        </div>
    );
};
