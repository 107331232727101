export const countryOptions = [
    {label: 'Nigeria', value: 'Nigeria', code: 'NG'},
    {label: 'Ghana', value: 'Ghana', code: 'GH'},
    {label: 'Kenya', value: 'Kenya', code: 'KE'},
];

export const IdTypeOptions = country => {
    switch (country) {
        case countryOptions[0].value:
        default:
            return NigeriaIdTypeOptions;

        case countryOptions[1].value:
            return GhanaIdTypeOptions;

        case countryOptions[2].value:
            return KenyaIdTypeOptions;
    }
};

export const GhanaIdTypeOptions = [
    {label: 'Drivers License', value: 'ghana_driver_licence'},
    {label: 'Passport', value: 'ghana_passport'},
];

export const KenyaIdTypeOptions = [
    {label: 'National ID', value: 'kenya_national_id'},
    {label: 'Passport', value: 'kenya_passport'},
];

export const NigeriaIdTypeOptions = [
    {label: 'NIN', value: 'NIN'},
    {label: 'BVN', value: 'BVN'},
];

export const addressOptions = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

export const utilityTypeOptions = [
    {
        label: 'Electricity Bill',
        value: 'electricity_bill',
    },
    {
        label: 'Water Bill',
        value: 'water_bill',
    },
    {
        label: 'Waste Bill',
        value: 'waste_bill',
    },
];
