import {useContext, useRef} from 'react';
import {CustomCheckBoxStyles} from './CustomCheckBoxStyles';
import AppContext from 'components/App/AppContext';

export const CustomCheckBox = ({label, checked, name, secondary, onClick, labelCls, cls}) => {
    const {colors} = useContext(AppContext);

    const checkboxEl = useRef(null);
    const isSecondary = secondary ? ' secondary' : '';

    const handleClick = () => {
        checkboxEl.current.click();
    };

    return (
        <CustomCheckBoxStyles colors={colors}>
            <label className={`custom-check-box${isSecondary} ${cls}`}>
                <input
                    type="checkbox"
                    ref={checkboxEl}
                    className="pointer"
                    name={name}
                    checked={checked}
                    onChange={() => {}}
                    onClick={onClick}
                />
                {label && (
                    <label onClick={handleClick} htmlFor={name} className={labelCls}>
                        {label}
                    </label>
                )}
            </label>
        </CustomCheckBoxStyles>
    );
};
