import {ErrorIcon} from 'assets/icons';
import {Button} from 'utils';

export const FailedState = ({title = 'Failed', btnName = 'Retry', errorMsg, handleClick}) => {
    return (
        <div className="d-flex justify-content-center">
            <div className="text-center">
                <ErrorIcon />
                <div className="text-center pt-3">
                    <h3 className="fw-bold mt-4">{title}</h3>
                    <p className="pb-4 pt-3 font-13">{errorMsg}</p>

                    {btnName && (
                        <Button name={btnName} handleClick={handleClick} cls="mx-auto px-5" />
                    )}
                </div>
            </div>
        </div>
    );
};
