import {Address, Liveness, LivenessAdvanced} from 'pages';
import {BusinessRegistration} from 'pages/business';

const routes = [
    {
        name: 'Liveness',
        path: '/IdentityVerification/LivenessCheck',
        component: Liveness,
        exact: true,
    },
    {
        name: 'Liveness Advanced',
        path: '/IdentityVerification/LivenessCheckAdvanced',
        component: LivenessAdvanced,
        exact: true,
    },
    {
        name: 'Address',
        path: '/AddressVerification',
        component: Address,
        exact: true,
    },
    {
        name: 'Company Registration',
        path: '/CACRegistration/BN/Registration',
        component: BusinessRegistration,
        exact: true,
    },
];

export default routes;
