import {toLower} from 'lodash';
import {useMutation} from 'react-query';
import {useContext, useEffect, useState} from 'react';

import {BackArrowIcon} from 'assets/icons';

import {IdTypeOptions} from '../data';
import {StepIndicator} from './StepIndicator';
import {AddressContext, AddressHeader, STEPS} from './AddressContent';
import {Button, CustomInputBox, CustomPhoneInput, CustomSelect, FormValidator} from 'utils';

import API from 'api';
import {genders} from '../enums';
import {verifyId} from 'api/routes/address';
import AppContext from 'components/App/AppContext';
import {getCountry, getError, getLocalPhoneFormat, isAvailable, titleCase} from 'utils/helpers';

export const AddressIdentity = () => {
    const {params} = useContext(AppContext);
    const {currentStep, isMini, result, handleStep} = useContext(AddressContext);

    const [errorMsg, setErrorMsg] = useState();
    const [success, setSuccess] = useState();

    const {clientid, appname, apikey, otherParams} = params || {};
    const {id_type, id_number, phone_number, country} = otherParams || {};

    const [formValues, setFormValues] = useState({
        id_type: isMini
            ? IdTypeOptions(country).find(type => type.value === id_type)
            : result?.id_type,
        id_number: isMini ? id_number : result?.id_number,
        phone_number: isMini ? phone_number : result?.phone_number,
    });
    const [formErrors, setFormErrors] = useState({});

    const handleSelect = (name, value) => {
        setFormValues(prev => ({...prev, [name]: value}));
        setFormErrors(prev => ({...prev, [name]: ''}));
    };

    const handleChange = ({target: {name, value}}) => handleSelect(name, value);

    const {data, mutate, isLoading} = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(verifyId, payload, {
                headers: {
                    clientid,
                    appname,
                    apikey,
                },
            });
            return response;
        },
        {
            onSuccess: ({data, status}) => {
                if (
                    (status === 200 || status === 201) &&
                    (data?.status_code === 200 || data?.status_code === 201)
                )
                    setSuccess(true);
                else setErrorMsg(data?.message);
            },
            onError: error => setErrorMsg(getError(error)),
        }
    );

    useEffect(() => {
        if (!isMini) return;
        handleSubmit();
    }, []);

    const response = data?.data?.data;

    const handleSubmit = e => {
        e && e.preventDefault();

        const values = {...formValues};

        const {formErrors, isValid} = FormValidator(values);

        if (isValid) {
            const phone_number =
                formValues?.id_type?.value === 'BVN'
                    ? getLocalPhoneFormat(formValues?.phone_number)
                    : formValues?.phone_number;

            const payload = {
                ...formValues,
                id_type: formValues?.id_type?.value,
                country: isMini ? country : result?.country?.value,
                phone_number,
            };

            mutate(payload);
        } else setFormErrors(formErrors);
    };

    const handleNext = e => {
        e.preventDefault();
        handleStep({
            step: isMini ? STEPS.ADDRESS_STANDARD : STEPS.LIVENESS,
            data: {
                ...formValues,
                id_result: response,
                country: isMini ? getCountry(country) : result?.country,
            },
        });
    };

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="w-100">
                {!isMini && (
                    <BackArrowIcon
                        className="pointer back-arrow"
                        onClick={() => handleStep({step: STEPS.COUNTRY})}
                    />
                )}
            </div>
            <StepIndicator currentStep={currentStep} />
            {!success && (
                <>
                    <AddressHeader
                        title="Select ID"
                        text="Select an identity document to verify"
                        cls="text-center mb-4"
                    />
                    <div className="w-100 mb-5">
                        {errorMsg && <h4 className="text-danger py-4">{errorMsg}</h4>}
                        <CustomSelect
                            label="ID"
                            placeholder="Select ID Type"
                            options={IdTypeOptions(isMini ? country : result?.country?.value)}
                            value={formValues?.id_type}
                            onChange={value => !isMini && handleSelect('id_type', value)}
                            error={formErrors?.id_type}
                            disabled={isMini}
                        />
                        <CustomInputBox
                            label="ID Number"
                            name="id_number"
                            placeholder="Enter ID Number"
                            value={formValues?.id_number}
                            onChange={e => !isMini && handleChange(e)}
                            error={formErrors?.id_number}
                            disabled={isMini}
                        />
                        <CustomPhoneInput
                            cls="mb-5"
                            name="phone_number"
                            label="Phone Number"
                            value={formValues?.phone_number}
                            onChange={value => !isMini && handleSelect('phone_number', value)}
                            error={formErrors.phone_number}
                            disabled={isMini}
                            required
                        />
                    </div>
                    <Button
                        cls="w-100 btn btn-lg rounded-0 mt-5"
                        name={isLoading ? 'Loading' : 'Next'}
                        handleClick={handleSubmit}
                        disabled={isLoading}
                    />
                </>
            )}
            {success && (
                <>
                    <AddressHeader
                        title="ID Details"
                        text="Here are the details extracted from your 
                        submitted document."
                        cls="text-center mb-5"
                    />
                    <div className="w-100 px-5 mt-3 info-section">
                        <div className="d-flex justify-content-between">
                            <p className="key">Surname</p>
                            <p className="value">{titleCase(response?.last_name)}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="key">First Name</p>
                            <p className="value">{titleCase(response?.first_name)}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="key">Middle Name</p>
                            <p className="value">{isAvailable(titleCase(response?.middle_name))}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="key">Date of Birth</p>
                            <p className="value">{response?.date_of_birth}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="key">Issue Date</p>
                            <p className="value">{isAvailable(response?.issue_date)}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="key">Nationality</p>
                            <p className="value">
                                {isAvailable(
                                    response?.nationality,
                                    titleCase(response?.nationality)
                                )}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="key">ID Number</p>
                            <p className="value">{formValues?.id_number}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="key">Sex</p>
                            <p className="value">
                                {isAvailable(
                                    genders?.[toLower(response?.gender)],
                                    titleCase(genders?.[toLower(response?.gender)])
                                )}
                            </p>
                        </div>
                    </div>
                    <Button
                        cls="w-100 btn btn-lg rounded-0 mt-5"
                        name="Next"
                        handleClick={handleNext}
                    />
                </>
            )}
        </div>
    );
};
