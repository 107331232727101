import {useState} from 'react';

export function useCamera({constraints = {}, onSuccess = () => {}, onError = () => {}}) {
    const [stream, setStream] = useState(null);

    const startStream = async () => {
        const options = {video: true, ...constraints};

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia(options);
                setStream(mediaStream);
                onSuccess(mediaStream);
            } catch (err) {
                onError(err);
            }
        } else {
            const getWebcam =
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia;

            if (getWebcam) {
                getWebcam.call(
                    navigator,
                    options,
                    stream => {
                        setStream(stream);
                        onSuccess(stream);
                    },
                    err => onError(err)
                );
            } else onError();
        }
    };

    const stopStream = () => {
        if (!stream) return;

        if (stream?.getTracks) {
            stream.getTracks().forEach(track => track.stop());
        } else if (stream?.stop) {
            stream.stop();
        }
        setStream(null);
    };

    return {
        stream,
        startStream,
        stopStream,
    };
}
