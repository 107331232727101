import {useContext} from 'react';
import Select from 'react-select';
import {InfoCircleErrorIcon} from 'assets/icons';
import AppContext from 'components/App/AppContext';
import {CustomInputBoxStyles} from '../CustomInputBox/CustomInputBoxStyles';
import {CustomSelectStyles} from './CustomSelectStyles';

export const CustomSelect = ({
    label,
    LabelIcon,
    name,
    value,
    error,
    isMulti,
    isClearable,
    icon,
    inputCls,
    inputLeft,
    labelChildren,
    options,
    placeholder = '',
    secondary,
    required,
    disabled,
    isLoading,
    getOptionLabel,
    getOptionValue,
    formGroupCls,
    onChange,
    onInputChange,
    showError = true,
    infoText,
    ...rest
}) => {
    const cls = `form-group-select${isMulti ? '--is-multi' : ''}`;
    const isRequired = required ? ' required' : '';
    const isError = error ? '_error' : '';
    const wrapperError = error ? ' error' : '';
    const isSecondary = secondary ? ' secondary-select' : '';
    const isLabelChildren = labelChildren ? ' col-12 flex__between' : '';
    const {colors} = useContext(AppContext);

    return (
        <CustomInputBoxStyles colors={colors}>
            <CustomSelectStyles colors={colors}>
                <div className={`form-group${isSecondary} ${formGroupCls}`}>
                    {label && (
                        <label
                            htmlFor={name}
                            className={`form-group-label${isRequired}${isLabelChildren}`}
                        >
                            {label}
                            {LabelIcon && <LabelIcon className="ms-2 pointer my-auto" />}
                            {labelChildren}
                        </label>
                    )}

                    <div className={`d-flex align-items-center input-wrapper${wrapperError}`}>
                        {icon && <div className="px-3">{icon}</div>}
                        {inputLeft}
                        <div className={`${inputCls} ${cls}${isError}`}>
                            <Select
                                onInputChange={onInputChange}
                                isClearable={isClearable}
                                inputId={name}
                                name={name}
                                placeholder={placeholder}
                                isMulti={isMulti}
                                options={options}
                                value={value}
                                isLoading={isLoading || !options}
                                isDisabled={disabled}
                                getOptionLabel={getOptionLabel}
                                getOptionValue={getOptionValue}
                                classNamePrefix={cls}
                                onChange={onChange}
                                {...rest}
                            />
                        </div>
                    </div>
                    {infoText && <span className="auth-text-gray mt-1">{infoText}</span>}
                    {typeof error === 'string' && error && showError && (
                        <small className="input-text-error">
                            <InfoCircleErrorIcon className="me-2" />
                            {error}
                        </small>
                    )}
                </div>
            </CustomSelectStyles>
        </CustomInputBoxStyles>
    );
};
