import {useState} from 'react';
import {Button, CustomPhoneInput, FormValidator} from 'utils';
import {LivenessHeader, STEPS} from './LivenessContent';
import {useApp} from 'components/App/AppContext';

export const LivenessPhoneInput = ({result, handleStep}) => {
    const [phone, setPhone] = useState(result.phone || '');
    const [formError, setFormError] = useState({});
    const {params} = useApp();

    const handleSelect = value => {
        setPhone(value);
        setFormError({phone: ''});
    };

    const handleSubmit = e => {
        e.preventDefault();
        const {isValid, formErrors} = FormValidator({phone});
        isValid ? handleStep({step: STEPS.CHECK, data: {phone}}) : setFormError(formErrors);
    };
    return (
        <form onSubmit={handleSubmit}>
            <LivenessHeader
                title="Confirm Liveness"
                text="You are required to be in a well lit room, and a short video of you will be recorded just to ensure that nobody is impersonating you, enter your phone number and continue when you are ready"
            />
            {!params?.otherParams?.phone_number && (
                <CustomPhoneInput
                    cls="mt-5"
                    name="phone"
                    label="Phone Number"
                    value={phone}
                    onChange={handleSelect}
                    error={formError.phone}
                    required
                />
            )}
            <div className="col-7 pt-3 pb-5">
                <Button name="Continue" cls="btn--lg" type="submit" />
            </div>

            <div className="verification_result">
                <h4 className="fw-bold text-decoration-underline"> NOTE </h4>

                <ol className="warning">
                    <li>
                        <p>Do this in a very lighten environment and use a plain background. </p>
                    </li>

                    <li>
                        <p>Recorded video or screen play is not allowed.</p>
                    </li>

                    <li>
                        <p>Using of glasses is not allowed.</p>
                    </li>

                    <li>
                        <p>Your eyes must be opened.</p>
                    </li>

                    <li>
                        <p>Your mouth must be closed.</p>
                    </li>

                    <li>
                        <p>Using of mask or anything that covers your mouth is not allowed.</p>
                    </li>
                </ol>
            </div>
        </form>
    );
};
